import React from 'react'
import { useHistory } from 'react-router-dom'

import { container, Service } from 'Services/container'
import { ISurveysService } from 'Services/survey'
import { useAuthorization, RoleName } from 'Integration/authorization'
import { useAuthWithDemo } from 'Demo'
import { Routes } from 'Shared/constants'
import { SettingsContext } from 'App/Settings'
import { PromotionDialogBlackFriday } from './PromotionDialogBlackFriday'

const promoName = '2024 Black-Friday-Promo'
const promoCode = 'BLACKFIRE2024'

export const PromotionsSection: React.FC = () => {
  const history = useHistory()
  const { getAccessTokenWithDemo } = useAuthWithDemo()
  const { pendingSurveys, essentialsLoaded, userGuide, manageSettings } = React.useContext(SettingsContext)
  const authorization = useAuthorization()

  const showPromotion = React.useMemo(() => {
    const hasActivePromo = pendingSurveys.some(survey => survey === promoName)
    const shouldSeePromo = authorization.isInRole(RoleName.User)
    return !userGuide.enabled && essentialsLoaded && shouldSeePromo && hasActivePromo
  }, [ pendingSurveys, authorization.authorizationProfile, essentialsLoaded, userGuide.enabled ])

  React.useEffect(() => {
    if (showPromotion) {
      window.customerly && window.customerly.hide()
    } else {
      window.customerly && window.customerly.show()
    }
  }, [ showPromotion ])

  const completeSurvey = async (surveyName: string) => {
    const surveyService = container.resolve<ISurveysService>(Service.SurveyService)
    const token = await getAccessTokenWithDemo()
    await surveyService.saveSurvey(token, surveyName, {})
  }

  const handlePromotionClick = async () => {
    manageSettings.promo.save(promoCode)
    manageSettings.passSurvey(promoName)

    await completeSurvey(promoName)
    history.push(Routes.PRICING)
  }

  const handlePromotionClose = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    await completeSurvey(promoName)
    manageSettings.passSurvey(promoName)
  }

  return (
    <>
      {showPromotion && (
        <PromotionDialogBlackFriday onClick={handlePromotionClick} onClose={handlePromotionClose} />
      )}
    </>
  )
}
