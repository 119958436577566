export type SpecificationResult = { isValid: true } | { isValid: false, errorKey: string }

export abstract class BaseSpecification<T> {
  protected value: T

  protected constructor(value: T) {
    this.value = value
  }

  valid(): SpecificationResult {
    return { isValid: true }
  }

  invalid(errorKey: string): SpecificationResult {
    return {
      isValid: false,
      errorKey
    }
  }

  abstract verify(): SpecificationResult
}
