import {
  Divider,
  IconButton,
  Link,
  ListItemButton,
  ListItemIcon,
  Drawer as MuiDrawer,
  Stack,
  Toolbar,
  Box
} from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { CSSObject, Theme, styled } from '@mui/material/styles'

import { DesktopHeaderHeight, DrawerWidth, MiniDrawerWidth, MobileHeaderHeight } from 'Shared/constants/spacing'
import { styledWithCustomProp } from 'config/theme'

// Desktop
export const CollapseIcon = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.green.green2,
  border: `1px solid ${theme.palette.green.green1}`,
  borderRadius: '4px',
  position: 'absolute',
  left: 0,
  padding: '5px 8px',
  ':hover': {
    backgroundColor: theme.palette.green.green1,
  },
  'svg': {
    width: 4,
    height: 10,
  }
}))

export const AppLogoWrapper = styled(Link)(() => ({
  color: 'unset',
  height: 24,
})) as typeof Link

const openedMixin = (theme: Theme): CSSObject => ({
  width: DrawerWidth,
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  overflowX: 'hidden',
  width: `calc(${MiniDrawerWidth}px + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${MiniDrawerWidth}px + 1px)`,
  },
})

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const AppBar = styledWithCustomProp(MuiAppBar, 'open')<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  'svg': {
    color: theme.palette.common.black,
  },
  marginLeft: MiniDrawerWidth,
  height: DesktopHeaderHeight,
  width: `calc(100% - (${MiniDrawerWidth}px + 1px))`,
  ...(open && {
    marginLeft: DrawerWidth,
    width: `calc(100% - ${DrawerWidth}px)`,
  }),
  borderBottom: `1px solid ${theme.palette.dark.dark3}`,
  boxSizing: 'content-box',
  paddingRight: '0 !important',
}))

export const DrawerHeader = styledWithCustomProp(Box, 'open')<{ open: boolean }>(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2.5, 3),
  ...(open ? {
    justifyContent: 'flex-start',
  } : {
    justifyContent: 'center',
  }),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

export const Drawer = styledWithCustomProp(MuiDrawer, 'open')(
  ({ theme, open }) => ({
    width: DrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.common.black,
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': {
        ...openedMixin(theme),
        backgroundColor: 'unset'
      },
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': {
        ...closedMixin(theme),
        backgroundColor: 'unset'
      },
    }),
  }),
) as typeof MuiDrawer

export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.dark.dark1
}))

export const StyledListItemButton = styledWithCustomProp(ListItemButton, 'open')(({ theme, open }) => ({
  minHeight: 48,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  fontSize: theme.typography.textPrimary.fontSize,
  lineHeight: '24px',
  color: theme.palette.dark.dark2,
  'a': {
    justifyContent: open ? 'flex-start' : 'center',
  },
  'svg': {
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
  },
  '&.Mui-selected': {
    backgroundColor: 'unset',
    fontWeight: 500,
    color: theme.palette.common.white,
  },
  '&.Mui-selected:hover': {
    backgroundColor: 'unset',
  },
  ':hover': {
    backgroundColor: 'unset',
    fontWeight: 500,
    color: theme.palette.common.white,
  },
})) as typeof ListItemButton

export const StyledListItemIcon = styledWithCustomProp(ListItemIcon, 'open')(({ theme, open }) => ({
  minWidth: 0,
  marginRight: open ? theme.spacing(1.25) : theme.spacing(.5),
  justifyContent: 'center',
  color: 'inherit'
}))

// Mobile
export const StyledAppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  zIndex: theme.zIndex.drawer + 1,
}))

export const StyledToolBar = styled(Toolbar)(({ theme }) => ({
  color: theme.palette.common.white,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

export const StyledDrawer = styled(MuiDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.common.black,
    boxSizing: 'border-box',
    width: '80%',
  }
}))

export const AppBarBottomPanel = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  height: MobileHeaderHeight,
  width: '100%',
  zIndex: theme.zIndex.drawer - 1,
  position: 'fixed',
  top: MobileHeaderHeight,
  left: 'auto',
  right: 0,
  padding: theme.spacing(2),
  flexDirection: 'row',
  borderBottom: `1px solid ${theme.palette.dark.dark3}`,
}))
