/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable max-len */
import { SvgIcon } from '@mui/material'

export const WalletIcon = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path opacity="0.2" d="M5 24C5 24.5304 5.21071 25.0391 5.58579 25.4142C5.96086 25.7893 6.46957 26 7 26H27C27.2652 26 27.5196 25.8946 27.7071 25.7071C27.8946 25.5196 28 25.2652 28 25V11C28 10.7348 27.8946 10.4804 27.7071 10.2929C27.5196 10.1054 27.2652 10 27 10H7C6.46957 10 5.96086 9.78929 5.58579 9.41421C5.21071 9.03914 5 8.53043 5 8V24Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7 7C6.73478 7 6.48043 7.10536 6.29289 7.29289C6.10536 7.48043 6 7.73478 6 8C6 8.26522 6.10536 8.51957 6.29289 8.70711C6.48043 8.89464 6.73478 9 7 9H27C27.5304 9 28.0391 9.21071 28.4142 9.58579C28.7893 9.96086 29 10.4696 29 11V25C29 25.5304 28.7893 26.0391 28.4142 26.4142C28.0391 26.7893 27.5304 27 27 27H7C6.20435 27 5.44129 26.6839 4.87868 26.1213C4.31607 25.5587 4 24.7957 4 24V8C4 7.20435 4.31607 6.44129 4.87868 5.87868C5.44129 5.31607 6.20435 5 7 5H24C24.5523 5 25 5.44772 25 6C25 6.55228 24.5523 7 24 7H7ZM6 10.8284V24C6 24.2652 6.10536 24.5196 6.29289 24.7071C6.48043 24.8946 6.73478 25 7 25H27V11H7C6.65606 11 6.3182 10.9409 6 10.8284Z" />
    <path d="M22.5 19.5C23.3284 19.5 24 18.8284 24 18C24 17.1716 23.3284 16.5 22.5 16.5C21.6716 16.5 21 17.1716 21 18C21 18.8284 21.6716 19.5 22.5 19.5Z" />
  </SvgIcon>
)
