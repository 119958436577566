import { RenewalPeriod, PricingTier, Promo } from 'Services/profile'
import { Nullable, TypedDictionary } from 'Shared/types'
import { PricingPlan } from './pricing-types'

export const BuildPricingPlan = (
  monthlyRegularPrice: number,
  yearlyRegularPrice: number,
  defaultMonthlyPromoPrice: Nullable<number>,
  defaultYearlyPromoPrice: Nullable<number>,
  promo: Nullable<Promo>,
) : TypedDictionary<typeof RenewalPeriod, PricingPlan> => {
  const dailyPriceOverride = promo
    ?.priceOverrides
    ?.find(override =>
      override.tier === PricingTier.Premium &&
      override.period === RenewalPeriod.Day)

  const monthlyPriceOverride = promo
    ?.priceOverrides
    ?.find(override =>
      override.tier === PricingTier.Premium &&
      override.period === RenewalPeriod.Month)

  const yearlyPriceOverride = promo
    ?.priceOverrides
    ?.find(override =>
      override.tier === PricingTier.Premium &&
      override.period === RenewalPeriod.Year)

  return {
    [RenewalPeriod.Month]: {
      renewalPeriod: RenewalPeriod.Month,
      discountedPrice: monthlyPriceOverride?.price ?? defaultMonthlyPromoPrice ?? monthlyRegularPrice,
      originalPrice: monthlyPriceOverride ? defaultMonthlyPromoPrice ?? monthlyRegularPrice : monthlyRegularPrice,
      periodPromoActive: !!monthlyPriceOverride,
      autoRenew: monthlyPriceOverride ? promo?.isAutoRenew ?? true : true,
      firstPeriodOnly: monthlyPriceOverride ? promo?.isFirstPeriodOverrideOnly ?? false : false,
    },
    [RenewalPeriod.Year]: {
      renewalPeriod: RenewalPeriod.Year,
      discountedPrice: yearlyPriceOverride?.price ?? defaultYearlyPromoPrice ?? yearlyRegularPrice,
      originalPrice: yearlyPriceOverride ? defaultYearlyPromoPrice ?? yearlyRegularPrice : yearlyRegularPrice,
      periodPromoActive: !!yearlyPriceOverride,
      autoRenew: yearlyPriceOverride ? promo?.isAutoRenew ?? true : true,
      firstPeriodOnly: yearlyPriceOverride ? promo?.isFirstPeriodOverrideOnly ?? false : false,
    },
    [RenewalPeriod.Day]: {
      renewalPeriod: RenewalPeriod.Day,
      discountedPrice: dailyPriceOverride?.price ?? 0,
      originalPrice: 0,
      periodPromoActive: !!dailyPriceOverride,
      autoRenew: dailyPriceOverride ? promo?.isAutoRenew ?? true : true,
      firstPeriodOnly: dailyPriceOverride ? promo?.isFirstPeriodOverrideOnly ?? false : false,
    },
    [RenewalPeriod.None]: {
      renewalPeriod: RenewalPeriod.None,
      discountedPrice: 0,
      originalPrice: 0,
      periodPromoActive: false,
      autoRenew: false,
      firstPeriodOnly: false,
    },
  }
}
