import { PredictionCalculationData, PredictionType } from 'Prediction/prediction-types'
import { DemoState } from './demo-types'

export const DemoActionTypes = {
  TRANSACTIONS_FIRST_LOADING_COMPLETED: 'TRANSACTIONS_FIRST_LOADING_COMPLETED',
  DEMO_MODE_ENABLED: 'DEMO_MODE_ENABLED',
  DEMO_MODE_DISABLED: 'DEMO_MODE_DISABLED',
}

export const InitialState: DemoState = {
  transactionsFirstLoadingCompleted: false
}

export const LastUpdatedDate = new Date(2024, 8, 13, 23, 59)
export const NumberOfTransactionsToSelect = 4
export const DefaultDemoCurrency = 'USD'

export const DefaultDemoPredictionState: PredictionCalculationData = {
  currentBalance: 0,
  monthlySavings: 500,
  expectedSavingsGrowth: 3,
  profitabilityPercent: 7,
  calculateRetirement: true,
  predictionType: PredictionType.LiveOnCapital,
  liveOnCapitalLifeExpectancy: 112,
  currentAge: 25,
  monthlySpending: 3000,
  expectedInflation: 3.27,
  retirementProfitability: 4,
}

export const DemoOperationWarning = {
  response: {
    data: {
      errors: [ '0000' ]
    },
  },
}
