import { call, put, all, takeEvery, select } from 'redux-saga/effects'

import { container, Service } from 'Services/container'
import { AdvisoryClient, IAdvisoryService } from 'Services/advisor'
import { HistoryItem, IHistoryService } from 'Services/history'
import { Organization, IOrganizationService } from 'Services/organization'
import { Sharing, ISharingService } from 'Services/sharing'
import { IActionWithToken, ITypedActionWithToken } from 'Shared/types'
import { ErrorMessage } from 'Shared/constants'
import { snackbarActionCreators } from 'Snackbar'
import { handleErrorSaga } from 'Integration/sagas'
import { AdvisorPortalActions } from './advisorPortal-actions'
import { AdvisorPortalActionTypes } from './advisorPortal-constants'
import { AdvisorPortalSelectors } from './advisorPortal-selectors'

function* loadClients({ token }: IActionWithToken) {
  try {
    const clientsService = container.resolve<IAdvisoryService>(Service.AdvisoryService)
    const advisoryClients: AdvisoryClient[] = yield call(clientsService.getAdvisoryClients, token)
    yield put(AdvisorPortalActions.loadClientsSuccess(advisoryClients))
  } catch (error) {
    yield put(AdvisorPortalActions.loadClientsFailure())
    yield handleErrorSaga(error, 'common.errorMessages.loading-failed')
  }
}

function* revokeClient({ payload, token }: ITypedActionWithToken<string>) {
  try {
    const sharingService = container.resolve<ISharingService>(Service.SharingService)
    yield call(sharingService.revokeSharing, token, payload)

    const advisoryClients: AdvisoryClient[] = yield select(AdvisorPortalSelectors.advisoryClientsList)
    const username = advisoryClients.find(client => client.sharingKey === payload)?.username

    if (!username) {
      throw new Error('Username not found')
    }

    yield put(snackbarActionCreators.enqueueSuccessSnackbar('advisorPortal.clients-list.client-revoked'))
    yield put(AdvisorPortalActions.revokeClientSuccess(payload))
    yield put(AdvisorPortalActions.revokeClientHistorySuccess(username))
    yield put(AdvisorPortalActions.revokeOrganizationLicence())
  } catch (error) {
    yield handleErrorSaga(error, ErrorMessage.OperationFailure.key)
  }
}

function* loadSharings({ token }: IActionWithToken) {
  try {
    const sharingService = container.resolve<ISharingService>(Service.SharingService)
    const sharings: Sharing[] = yield call(sharingService.getSharingsWithMe, token)
    yield put(AdvisorPortalActions.loadSharingsSuccess(sharings))
  } catch (error) {
    yield handleErrorSaga(error, 'common.errorMessages.loading-failed')
  }
}

function* declineSharing({ payload, token }: ITypedActionWithToken<string>) {
  try {
    const sharingService = container.resolve<ISharingService>(Service.SharingService)
    yield call(sharingService.revokeSharing, token, payload)

    yield put(snackbarActionCreators.enqueueSuccessSnackbar('advisorPortal.clients-list.client-declined'))
    yield put(AdvisorPortalActions.removeSharing(payload))
  } catch (error) {
    yield handleErrorSaga(error, ErrorMessage.OperationFailure.key)
  }
}

function* acceptSharing({ payload, token }: ITypedActionWithToken<string>) {
  try {
    const advisoryClientService = container.resolve<IAdvisoryService>(Service.AdvisoryService)
    yield call(advisoryClientService.createAdvisoryClient, token, { sharingKey: payload })

    yield put(snackbarActionCreators.enqueueSuccessSnackbar('advisorPortal.clients-list.client-accepted'))
    yield put(AdvisorPortalActions.removeSharing(payload))
    yield put(AdvisorPortalActions.useOrganizationLicence())

    yield put(AdvisorPortalActions.loadClients(token))
    yield put(AdvisorPortalActions.loadClientsHistory(token))
  } catch (error: any) {
    yield handleErrorSaga(error, ErrorMessage.OperationFailure.key)
  }
}

function* loadClientsHistory({ token }: IActionWithToken) {
  try {
    const historyService = container.resolve<IHistoryService>(Service.HistoryService)
    const history: HistoryItem[] = yield call(historyService.getClientsHistory, token)
    yield put(AdvisorPortalActions.loadClientsHistorySuccess(history))
  } catch (error) {
    yield put(AdvisorPortalActions.loadClientsHistoryFailure())
    yield handleErrorSaga(error, 'common.errorMessages.loading-failed')
  }
}

function* loadAdvisorPortalOrganization({ token }: IActionWithToken) {
  try {
    const organizationService = container.resolve<IOrganizationService>(Service.OrganizationService)
    const organization: Organization = yield call(organizationService.getOrganization, token)
    yield put(AdvisorPortalActions.loadAdvisorPortalOrganizationSuccess(organization))
  } catch (error) {
    yield put(AdvisorPortalActions.loadAdvisorPortalOrganizationFailure())
    yield handleErrorSaga(error, 'common.errorMessages.loading-failed')
  }
}

export function* advisorPortalSaga() {
  yield all([
    takeEvery(AdvisorPortalActionTypes.GET_ADVISOR_PORTAL_CLIENTS, loadClients),
    takeEvery(AdvisorPortalActionTypes.GET_ADVISOR_PORTAL_SHARINGS, loadSharings),
    takeEvery(AdvisorPortalActionTypes.GET_ADVISOR_CLIENTS_HISTORY, loadClientsHistory),
    takeEvery(AdvisorPortalActionTypes.GET_ADVISOR_PORTAL_ORGANIZATION, loadAdvisorPortalOrganization),
    takeEvery(AdvisorPortalActionTypes.REVOKE_ADVISOR_PORTAL_CLIENT, revokeClient),
    takeEvery(AdvisorPortalActionTypes.DECLINE_ADVISOR_PORTAL_SHARING, declineSharing),
    takeEvery(AdvisorPortalActionTypes.ACCEPT_ADVISOR_PORTAL_SHARING, acceptSharing),
  ])
}
