import type { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { BaseService } from 'Shared/api'
import { Organization, IOrganizationService } from './organization-types'

export class OrganizationService extends BaseService implements IOrganizationService {
  getOrganization = (token: string): Promise<Organization> => axios
    .get('/organization', { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)
}
