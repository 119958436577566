import { useFormikContext } from 'formik'
import React from 'react'

interface Props {
  setFormDirty: () => void
}

export const FormDirtinessDetector: React.FC<React.PropsWithChildren<Props>> = ({
  setFormDirty,
  children
}) => {
  const formProps = useFormikContext()

  React.useEffect(() => {
    if (formProps.dirty) {
      setFormDirty()
    }
  }, [ formProps.dirty ])

  return <>{children}</>
}
