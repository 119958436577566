import * as Yup from 'yup'

import { ValidationLimitation } from './validation-limitations'
import { ErrorMessage as Err } from './errors-messages'
import { tagRule } from './tag'

export const tagsRule = Yup
  .array()
  .max(ValidationLimitation.MaxTagsLength, Err.MaxTagsLength(ValidationLimitation.MaxTagsLength))
  .of(tagRule)
