import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const SummaryHeader = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.textMedium.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
}))

export const SummaryBaseLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.textPrimary.fontSize,
}))

export const SummaryItemLabel = styled(SummaryBaseLabel)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))

export const SummaryGroupLabel = styled(SummaryBaseLabel)(({ theme }) => ({
  color: theme.palette.text.primary,
}))
