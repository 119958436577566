import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { SharingSelectors } from 'Sharing/sharing-selectors'
import { AdvisorPortalSelectors } from 'AdvisorPortal/advisorPortal-selectors'
import { SettingsContext } from 'App/Settings'
import { ViewMode } from './ViewMode-types'
import { ViewModeContext } from './ViewModeContext'

export const useViewMode = () => {
  const settingsContext = useContext(SettingsContext)
  const viewContext = useContext(ViewModeContext)
  const advisorClients = useSelector(AdvisorPortalSelectors.advisoryClients)
  const sharingKey = useSelector(SharingSelectors.selectedSharingKey)

  const openedClientProfile = useMemo(
    () => viewContext.mode === ViewMode.Advisor ?
      advisorClients.list.find(client => client.sharingKey === sharingKey) :
      undefined,
    [ viewContext.mode, advisorClients, sharingKey ])

  const baseCurrency = useMemo(
    () => openedClientProfile?.summaryData?.baseCurrency ?? settingsContext.baseCurrency,
    [ openedClientProfile, settingsContext.baseCurrency ])

  const canEditPortfolio = useMemo(
    () => openedClientProfile?.permissions?.allowEditPortfolio ?? true,
    [ openedClientProfile ])

  return {
    mode: viewContext.mode,
    isAdvisorMode: viewContext.mode === ViewMode.Advisor,
    isUserMode: viewContext.mode === ViewMode.DefaultUser || viewContext.mode === ViewMode.AdvisorClient,
    baseCurrency,
    canEditPortfolio,
    sharingKey,
  }
}
