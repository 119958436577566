import React from 'react'
import { styled, Chip } from '@mui/material'
import { withTranslation } from 'react-i18next'

const StyledChip = styled(Chip)(({ theme }) => ({
  fontSize: theme.typography.textSmall.fontSize,
  fontWeight: 700,
  color: theme.palette.common.white,
  backgroundColor: theme.palette.premium.main,
  borderRadius: 4,
  overflow: 'hidden',
  '& .MuiChip-label': {
    textOverflow: 'ellipsis',
  },
}))

export const PromoCode = withTranslation()(({ t }) => (
  <StyledChip label={t('pricing.promoCode.activated')} size="small" />
))
