import React from 'react'
import { styled } from '@mui/material/styles'
import { Container } from '@mui/material'
import { BasicFooter } from './components/BasicFooter'
import { ExtendedFooter } from './components/ExtendedFooter'

const FooterWrapper = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.dark.dark,
}))

export const LandingFooter: React.FC = () => (
  <FooterWrapper>
    <Container maxWidth="lg" sx={{ px: 2.5 }}>
      <ExtendedFooter />
      <BasicFooter />
    </Container>
  </FooterWrapper>
)
