import * as Yup from 'yup'

import { ErrorMessage as Err, ValidationLimitation } from 'Shared/constants'

// Alphanumeric, period, dash, and underscore. Starting with alpha. Ending with alphanumeric.
const allowedUserNameRegEx = /^[A-Za-z][A-Za-z0-9.\-_]*[A-Za-z0-9]$/
const minUsernameLength = 3

export const UserProfileSchema = Yup
  .object()
  .shape({
    username: Yup
      .string()
      .nullable()
      .trim()
      .min(minUsernameLength, Err.NotLessSymbolsThan(minUsernameLength))
      .max(ValidationLimitation.MaxStringLength, Err.NotMoreSymbolsThan(ValidationLimitation.MaxStringLength))
      .matches(allowedUserNameRegEx, { message: Err.UsernameValidation }),
    baseCurrency: Yup
      .string()
      .nullable()
      .required(Err.Required),
  })
