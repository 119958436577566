import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Button } from '@mui/material'

import { RenewalPeriod } from 'Services/profile'
import { useFeatureFlag } from 'Shared/hooks'
import { TypedDictionary } from 'Shared/types'
import {
  FreePricingCardConfiguration,
  PremiumPricingCardConfiguration,
  FreePricingPlan
} from 'Pricing/pricing-constants'
import { PricingPlan } from 'Pricing/pricing-types'
import { PricingCard } from './PricingCard'

interface Props {
  premiumPricingPlan: TypedDictionary<typeof RenewalPeriod, PricingPlan>
  renewalPeriod: RenewalPeriod
  handlePremiumPlanSelected: (renewalPeriod: RenewalPeriod) => void
  disabled: boolean
}

export const PricingPageCards: React.FC<Props> = ({
  premiumPricingPlan,
  renewalPeriod,
  handlePremiumPlanSelected,
  disabled
}) => {
  const { t } = useTranslation()
  const daySubscriptionFeatureFlag = useFeatureFlag('daySubscription')

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} sm={6} md={5} lg={4}>
        <PricingCard
          title={t('pricing.plans.free')}
          choseButtonText={t('pricing.currentPlan')}
          pricingPlan={FreePricingPlan[renewalPeriod]}
          features={FreePricingCardConfiguration.features}
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={6} md={5} lg={4}>
        <PricingCard
          title={t('pricing.plans.premium')}
          choseButtonText={t('pricing.upgrade')}
          pricingPlan={premiumPricingPlan[renewalPeriod]}
          features={PremiumPricingCardConfiguration.features}
          onChosePlan={() => handlePremiumPlanSelected(renewalPeriod)}
          disabled={disabled}
          highlight
        />
      </Grid>

      {daySubscriptionFeatureFlag.activated && (
        <Grid item xs={12}>
          <Button onClick={() => handlePremiumPlanSelected(RenewalPeriod.Day)}>Daily subscription</Button>
        </Grid>
      )}
    </Grid>
  )
}
