import { createTheme } from '@mui/material/styles'

const palette = {
  white: '#FFFFFF',
  dark: '#252549',
  dark1: '#78788B',
  dark2: '#AFB5C0',
  dark3: '#F9F9FA',
  blue: '#3751FF',
  blue1: '#ABBEFA',
  blue2: '#DEE1FB',
  blue3: '#E1E3F0',
  blue4: '#EEF0F9',
  blue5: '#F4F6FF',
  blue6: '#F7F9FB',
  green1: '#42BA94',
  green2: '#F0FBF3',
  success1: '#3AAA52',
  success2: '#DEFBE6',
  danger: '#C0232B',
  danger1: '#E53943',
  danger2: '#EC9898',
  danger3: '#FFF1F1',
  warning: '#D3AC1E',
  warning1: '#FFF8E1',
  warning2: '#ECD998',
  warning3: '#D3AB1E',
  premium: '#FFA31F',
}

export const theme = createTheme({
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: palette.blue6,
          overscrollBehaviorY: 'none',
        },
        '#root': {
          display: 'flex',
          minHeight: '100vh',
        },
        main: {
          flexGrow: 1,
        },
        footer: {
          flexShrink: 0,
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          boxSizing: 'content-box'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root:
          ({ ownerState }) => ({
            ...(ownerState.size === 'medium' && {
              lineHeight: 2,
            }),
            textTransform: 'unset',
          }),
      },
    },
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          // MUI issue: https://github.com/mui/material-ui/issues/34039
          marginRight: 0
        }
      }
    }
  },
  palette: {
    primary: {
      main: palette.blue,
      light: palette.blue1,
    },
    secondary: {
      main: palette.green1,
      light: palette.green2,
    },
    error: {
      main: '#D32F2F',
      light: palette.danger1,
      dark: palette.danger,
    },
    warning: {
      main: '#ED6C02',
      light: palette.warning,
      dark: '#E65100',
    },
    success: {
      main: '#2E7D32',
      light: palette.success1,
      dark: '#1B5E20',
    },
    dark: {
      dark: palette.dark,
      dark1: palette.dark1,
      dark2: palette.dark2,
      dark3: palette.dark3,
    },
    blue: {
      blue: palette.blue,
      blue1: palette.blue1,
      blue2: palette.blue2,
      blue3: palette.blue3,
      blue4: palette.blue4,
      blue5: palette.blue5,
      blue6: palette.blue6,
    },
    green: {
      green1: palette.green1,
      green2: palette.green2,
      green3: palette.success1,
      green4: palette.success2,
    },
    orange: {
      orange: palette.warning,
      orange1: palette.warning1,
      orange2: palette.warning2,
      orange3: palette.warning3,
    },
    danger: {
      danger: palette.danger,
      danger1: palette.danger1,
      danger2: palette.danger2,
      danger3: palette.danger3,
    },
    premium: {
      main: palette.premium,
    },
    text: {
      primary: palette.dark,
      secondary: palette.dark1,
      disabled: palette.dark2,
    },
    background: {
      default: palette.white,
    },
    common: {
      white: palette.white,
      black: palette.dark
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      // ToDo: change to 1920
      xl: 1536,
      mobileSmall: 320,
      mobile: 375,
      mobileLarge: 426,
      mobileLandscape: 812,
      tablet: 768,
      tabletLandscape: 1024,
      laptop: 1366,
      // ToDo: change to 1440
      desktop: 1200,
    }
  },
  typography: {
    textBigger: {
      fontSize: 28,
      lineHeight: '34px',
    },
    textBig: {
      fontSize: 24,
      lineHeight: '32px',
    },
    textLarger: {
      fontSize: 18,
      lineHeight: '28px',
    },
    textLarge: {
      fontSize: 16,
      lineHeight: '24px',
    },
    textMedium: {
      fontSize: 15,
      lineHeight: '20px',
    },
    textPrimary: {
      fontSize: 14,
      lineHeight: '20px',
    },
    textSmaller1: {
      fontSize: 13,
      lineHeight: '24px',
    },
    textSmaller2: {
      fontSize: 13,
      lineHeight: '16px',
    },
    subTextPrimary1: {
      fontSize: 12,
      lineHeight: '20px',
    },
    subTextPrimary2: {
      fontSize: 12,
      lineHeight: '16px',
    },
    textSmall: {
      fontSize: 10,
      lineHeight: '12px',
    },
    captionSmall: {
      fontSize: 9,
      lineHeight: '14px',
    },
  },
})
