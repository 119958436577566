/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable max-len */
import { SVGProps } from 'react'

export const EyeSlashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M5.3273 4.26022C5.73596 3.88871 6.36841 3.91883 6.73991 4.32749L26.7399 26.3275C27.1114 26.7361 27.0813 27.3686 26.6726 27.7401C26.264 28.1116 25.6315 28.0815 25.26 27.6728L5.26003 5.67283C4.88853 5.26417 4.91864 4.63173 5.3273 4.26022Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.3765 11.6277C13.748 12.0363 13.718 12.6688 13.3093 13.0403C12.5245 13.754 12.0552 14.7502 12.0048 15.8098C11.9544 16.8694 12.3269 17.9056 13.0405 18.6906C13.7541 19.4755 14.7503 19.9448 15.8099 19.9954C16.8695 20.0459 17.9058 19.6734 18.6908 18.9599C19.0994 18.5884 19.7319 18.6186 20.1034 19.0273C20.4748 19.436 20.4447 20.0684 20.036 20.4399C18.8585 21.5101 17.3041 22.0688 15.7146 21.9931C14.1252 21.9173 12.631 21.2133 11.5606 20.0359C10.4903 18.8585 9.93143 17.3041 10.007 15.7147C10.0827 14.1253 10.7865 12.631 11.9638 11.5606C12.3725 11.189 13.0049 11.2191 13.3765 11.6277Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.1414 8.12258C10.3909 8.61529 10.1938 9.21698 9.70106 9.46649C7.32541 10.6695 5.62196 12.4086 4.50734 13.8632C3.95169 14.5884 3.54795 15.2357 3.28512 15.6974C3.221 15.8101 3.16538 15.9115 3.11809 16.0001C3.23573 16.2204 3.40467 16.5196 3.62743 16.8734C4.12847 17.6691 4.89607 18.7317 5.95704 19.7925C8.07321 21.9085 11.3325 23.9992 16 23.9992H16.0079C18.1921 24.0166 20.349 23.5135 22.3001 22.5316C22.7935 22.2833 23.3946 22.482 23.6429 22.9753C23.8912 23.4686 23.6926 24.0698 23.1992 24.3181C20.9656 25.4422 18.4965 26.0185 15.9961 25.9992C10.6656 25.998 6.92616 23.5898 4.54291 21.2068C3.35388 20.0179 2.49648 18.8307 1.93502 17.9391C1.6538 17.4925 1.44539 17.1178 1.30557 16.8509C1.23562 16.7174 1.18272 16.6106 1.14635 16.5351C1.12817 16.4973 1.1141 16.4673 1.1041 16.4457L1.09212 16.4196L1.0884 16.4113L1.0871 16.4084L1.08659 16.4073C1.08638 16.4068 1.08618 16.4063 1.99997 16.0002C1.08615 15.5941 1.08629 15.5937 1.08644 15.5934L1.08763 15.5907L1.08992 15.5856L1.09693 15.5702C1.10269 15.5576 1.11062 15.5405 1.12076 15.5189C1.14102 15.4759 1.1701 15.4155 1.20819 15.3394C1.28436 15.1872 1.39672 14.9721 1.54694 14.7081C1.84709 14.1808 2.30014 13.4555 2.9198 12.6468C4.15579 11.0337 6.07699 9.05989 8.79751 7.68222C9.29022 7.43272 9.8919 7.62987 10.1414 8.12258ZM1.99997 16.0002L1.08644 15.5934C0.971534 15.852 0.971251 16.1478 1.08618 16.4063L1.99997 16.0002Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.2513 6.22314C14.16 6.07263 15.0797 5.99772 16.0008 5.99918C21.333 5.99943 25.0734 8.40866 27.4571 10.7926C28.6461 11.9818 29.5035 13.1692 30.065 14.061C30.3462 14.5077 30.5546 14.8825 30.6944 15.1494C30.7644 15.283 30.8173 15.3898 30.8536 15.4653C30.8718 15.5031 30.8859 15.5331 30.8959 15.5547L30.9079 15.5808L30.9116 15.5891L30.9134 15.5931C30.9136 15.5936 30.9138 15.5941 30 16.0002C29.0862 15.594 29.0863 15.5938 29.0864 15.5936L29.0788 15.61C29.0717 15.6253 29.06 15.6503 29.0435 15.6842C29.0105 15.7521 28.9587 15.8558 28.8875 15.9896C28.8856 15.9931 28.8837 15.9966 28.8819 16.0001C28.7642 15.7797 28.5953 15.4804 28.3725 15.1265C27.8714 14.3307 27.1038 13.2678 26.0428 12.2067C23.9266 10.0903 20.6674 7.99918 16 7.99918L15.9983 7.99918C15.1875 7.99786 14.378 8.06377 13.5781 8.19626C13.0332 8.2865 12.5184 7.91796 12.4281 7.3731C12.3379 6.82824 12.7064 6.31338 13.2513 6.22314ZM30 16.0002L30.9138 16.4063C31.0287 16.1478 31.0287 15.8526 30.9138 15.5941L30 16.0002ZM28.8819 16.0001C28.7391 16.2675 28.521 16.6506 28.2231 17.1063C27.6179 18.0317 26.6915 19.244 25.409 20.3927C24.9976 20.7612 24.9628 21.3934 25.3313 21.8048C25.6997 22.2162 26.332 22.2509 26.7433 21.8825C28.1861 20.5903 29.2215 19.2338 29.897 18.2008C30.2354 17.6832 30.4855 17.2438 30.6527 16.9297C30.7364 16.7726 30.7996 16.6465 30.8428 16.5574C30.8644 16.5128 30.8811 16.4775 30.8929 16.4521L30.907 16.4215L30.9113 16.4119L30.9127 16.4086L30.9138 16.4063C30.9139 16.4061 30.9138 16.4063 30 16.0002C29.0861 16.4063 29.0863 16.4066 29.0865 16.407L29.0805 16.394C29.0748 16.3816 29.0651 16.361 29.0516 16.3329C29.0245 16.2765 28.9817 16.1899 28.9227 16.0774C28.9099 16.0528 28.8962 16.0271 28.8819 16.0001Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M15.9588 10.9001C16.063 10.3577 16.5871 10.0024 17.1295 10.1066C18.4046 10.3514 19.566 11.0031 20.4394 11.9639C21.3128 12.9247 21.8511 14.1427 21.9737 15.4354C22.0258 15.9852 21.6223 16.4731 21.0725 16.5253C20.5227 16.5774 20.0347 16.1739 19.9826 15.6241C19.9009 14.7621 19.5419 13.9499 18.9595 13.3092C18.3771 12.6685 17.6026 12.234 16.7523 12.0707C16.2099 11.9665 15.8547 11.4424 15.9588 10.9001Z" fill="currentColor" />
  </svg>
)
