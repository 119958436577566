import { AppBar, Toolbar, styled } from '@mui/material'

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'fixed',
  zIndex: theme.zIndex.drawer + 1,
  [theme.breakpoints.down('md')]: {
    borderBottom: `1px solid ${theme.palette.blue.blue4}`,
  },
}))

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  [theme.breakpoints.up('xs')]: {
    paddingLeft: 0,
    paddingRight: 0
  }
}))
