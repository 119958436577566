import React from 'react'
import CheckIcon from '@mui/icons-material/Check'
import { styled, ToggleButton } from '@mui/material'

import { AdvisoryClientTask } from 'Services/advisor'
import { TaskCard } from 'Shared/components'

interface Props {
  task: AdvisoryClientTask
  onStatusChange: (taskKey: string, completed: boolean) => void
}

const CompleteTaskButton = styled(ToggleButton)(({ theme }) => ({
  borderRadius: theme.spacing(0.25),
  backgroundColor: theme.palette.common.white,
  borderColor: theme.palette.green.green1,
  color: theme.palette.green.green1,
  '&.Mui-selected': {
    borderColor: theme.palette.green.green1,
    backgroundColor: theme.palette.green.green1,
    color: theme.palette.common.white,
  },
  '&:hover,&.Mui-selected:hover': {
    backgroundColor: theme.palette.green.green2,
    color: theme.palette.green.green1,
  },
}))

export const ClientTaskCard: React.FC<Props> = ({ task, onStatusChange }) => {
  const actions = React.useMemo(
    () => (
      <CompleteTaskButton
        value="check"
        selected={task.completed}
        onClick={() => onStatusChange(task.key, !task.completed)}
      >
        <CheckIcon fontSize="small" />
      </CompleteTaskButton>
    ),
    [ task ])

  return <TaskCard task={task} actions={actions} />
}
