import { User } from '@auth0/auth0-react'
import { Box, Stack } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { PrimaryContentPaper, SubTextSecondary1, TextPrimary, WithHelp } from 'Shared/components'
import { UserAvatar } from './UserAvatar'

interface UserInfoProps {
  user: User
}

export const UserInfo: React.FC<UserInfoProps> = ({ user }) => {
  const { t } = useTranslation()

  return (
    <PrimaryContentPaper elevation={0}>
      <WithHelp helpText={t('app.navbar.settings.userInfo-tooltip')}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <UserAvatar user={user} />
          <Box>
            <TextPrimary>{user?.name}</TextPrimary>
            <SubTextSecondary1>{user?.email}</SubTextSecondary1>
          </Box>
        </Stack>
      </WithHelp>
    </PrimaryContentPaper>
  )
}
