import React from 'react'

import { WithHelp } from 'Shared/components/WithHelp'
import { TextArea } from './TextArea'

interface Props {
  name: string
  label?: string
  placeholder?: string
  helpText?: string
  required?: boolean
  disabled?: boolean
}

export const TextAreaWithHelp: React.FC<Props> = ({ name, label, placeholder, helpText = '', required, disabled }) => (
  <WithHelp helpText={helpText}>
    <TextArea
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
    />
  </WithHelp>
)
