import { createSelector } from 'reselect'

import { StoreState } from 'Shared/types'
import { TasksState } from './Tasks-types'

const tasksStoreSelector = (state: StoreState): TasksState => state.tasks
const tasks = createSelector(tasksStoreSelector, (state: TasksState) => state.tasks)

export const TasksSelectors = {
  tasks,
}
