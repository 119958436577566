import React, { PropsWithChildren } from 'react'
import { useMediaQuery, Theme } from '@mui/material'

import { styledWithCustomProp } from 'config/theme'
import { DesktopHeaderHeight, MobileHeaderHeight, AppBarHeight } from 'Shared/constants/spacing'
import { ContentPaper } from 'Shared/components/Papers'

const Container = styledWithCustomProp(ContentPaper, 'stuck')<{ stuck?: boolean }>(
  ({ theme, stuck }) => ({
    borderRadius: stuck ? 0 : theme.spacing(0.5),
    marginLeft: stuck ? 0 : theme.spacing(2),
    marginRight: stuck ? 0 : theme.spacing(2),

    position: 'sticky',
    top: DesktopHeaderHeight,
    [theme.breakpoints.down('mobileLarge')]: {
      top: MobileHeaderHeight + AppBarHeight
    },
    zIndex: theme.zIndex.appBar,
    padding: 0,
    marginTop: theme.spacing(2),
  })
)

export const StickySubHeader: React.FC<PropsWithChildren> = ({ children }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobileLarge'))
  const [ stuck, setStuck ] = React.useState(false)
  const ref = React.createRef<HTMLDivElement>()

  React.useEffect(() => {
    if (!ref.current) return

    const cachedRef = ref.current
    const observer = new IntersectionObserver(
      ([ e ]) => setStuck(e.intersectionRatio < 1),
      {
        threshold: 1,
        rootMargin: isMobile ?
          `-${MobileHeaderHeight + AppBarHeight + 1}px 0px 0px 0px` :
          `-${DesktopHeaderHeight + 1}px 0px 0px 0px`
      })

    observer.observe(cachedRef)
    return () => observer.unobserve(cachedRef)
  }, [ ref, isMobile ])

  return (
    <Container stuck={stuck} ref={ref} elevation={0}>
      {children}
    </Container>
  )
}
