import { AdvisoryClient } from 'Services/advisor'
import { HistoryItem } from 'Services/history'
import { Organization } from 'Services/organization'
import { Sharing } from 'Services/sharing'
import { Nullable } from 'Shared/types'

export enum ClientSortTerm {
  LastAddedDesc = 'LastAddedDesc',
  LastActivityDesc = 'LastActivityDesc',
  LastContactedAsc = 'LastContactedAsc',
  NextBillingAsc = 'NextBillingAsc',
  NetWorthAsc = 'NetWorthAsc',
  NetWorthDesc = 'NetWorthDesc',
}

export interface ClientsFilterState {
  selectedTags: string[]
  selectedAdvisors: string[]
  searchQuery: string
  sortTerm: ClientSortTerm
}

export interface HistoryFilterState {
  searchQuery: string
  managedBy: string
}

export interface AdvisorPortalState {
  sharings: Sharing[]
  clients: {
    list: AdvisoryClient[]
    isLoading: boolean
    initialLoadCompleted: boolean
  }
  clientsHistory: {
    list: HistoryItem[]
    isLoading: boolean
    initialLoadCompleted: boolean
  }
  organization: Organization
  clientsFilter: ClientsFilterState
  historyFilter: HistoryFilterState
}
