import { useDispatch } from 'react-redux'

import { useAuthWithDemo } from 'Demo'
import { useSnackbar } from 'Snackbar'
import { container, Service } from 'Services/container'
import { IAdvisoryService } from 'Services/advisor'
import { TasksActions } from './Tasks-actions'

export const useTasksManager = () => {
  const dispatch = useDispatch()
  const { getAccessTokenWithDemo } = useAuthWithDemo()
  const { enqueueError } = useSnackbar()

  const loadMyTasksAsync = async () => {
    const advisoryService = container.resolve<IAdvisoryService>(Service.AdvisoryService)

    try {
      const accessToken = await getAccessTokenWithDemo()
      const tasks = await advisoryService.getMyTasks(accessToken)
      dispatch(TasksActions.getMyTasks(tasks))
    } catch (error) {
      enqueueError('common.errorMessages.loading-failed')
    }
  }

  const changeTaskStatusAsync = async (taskKey: string, completed: boolean) => {
    const advisoryService = container.resolve<IAdvisoryService>(Service.AdvisoryService)

    try {
      const accessToken = await getAccessTokenWithDemo()
      await advisoryService.changeMyTaskStatus(accessToken, taskKey, completed)
      dispatch(TasksActions.changeTaskStatus(taskKey, completed))
    } catch (error) {
      enqueueError('common.errorMessages.update-failed')
    }
  }

  return {
    loadMyTasksAsync,
    changeTaskStatusAsync,
  }
}

