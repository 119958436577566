import React from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material'

import { PortfolioAllocationType } from 'Services/portfolio'
import { TextLarge } from 'Shared/components'
import { useAuthorization, Permission } from 'Integration/authorization'
import { PortfolioAllocationTypeOptions } from './assetAllocation-constants'

interface AssetAllocationSelectorProps {
  type: PortfolioAllocationType
  handleOnTypeChanged: (event: SelectChangeEvent) => void
}

export const AssetAllocationSelector : React.FC<AssetAllocationSelectorProps> = ({ type, handleOnTypeChanged }) => {
  const { t } = useTranslation()
  const authorization = useAuthorization()

  const renderOptionLabel = (value?: string) => {
    if (value && Object.keys(PortfolioAllocationType).includes(value)) {
      return t(PortfolioAllocationTypeOptions[value as PortfolioAllocationType].key)
    }

    return t('common.state.notSpecified-he')
  }

  return (
    <Select
      renderValue={type => (
        <Stack direction="row" alignItems="center" spacing={2}>
          <TextLarge>{renderOptionLabel(type)}</TextLarge>
        </Stack>
      )}
      value={type ?? PortfolioAllocationType.Class}
      onChange={handleOnTypeChanged}
      size="small"
      fullWidth
    >
      <MenuItem value={PortfolioAllocationType.Class}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <TextLarge>{t(PortfolioAllocationTypeOptions[PortfolioAllocationType.Class].key)}</TextLarge>
        </Stack>
      </MenuItem>
      <MenuItem value={PortfolioAllocationType.Category}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <TextLarge>{t(PortfolioAllocationTypeOptions[PortfolioAllocationType.Category].key)}</TextLarge>
        </Stack>
      </MenuItem>
      <MenuItem value={PortfolioAllocationType.Crypto} disabled={!authorization.hasAccess(Permission.ViewAdvancedCharts)}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <TextLarge>{t(PortfolioAllocationTypeOptions[PortfolioAllocationType.Crypto].key)}</TextLarge>
        </Stack>
      </MenuItem>
      <MenuItem value={PortfolioAllocationType.Stock} disabled={!authorization.hasAccess(Permission.ViewAdvancedCharts)}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <TextLarge>{t(PortfolioAllocationTypeOptions[PortfolioAllocationType.Stock].key)}</TextLarge>
        </Stack>
      </MenuItem>
    </Select>
  )
}
