import React from 'react'
import { match } from 'ts-pattern'

import { HistoryItem, HistoryKind } from 'Services/history'
import { HoldingHistoryMessage } from './HoldingHistoryMessage'
import { SingleTransactionHistoryMessage } from './SingleTransactionHistoryMessage'
import { TickerTransactionHistoryMessage } from './TickerTransactionHistoryMessage'
import { TransferTransactionHistoryMessage } from './TransferTransactionHistoryMessage'
import { AdvisoryTaskStatusHistoryMessage } from './AdvisoryTaskStatusHistoryMessage'

interface Props {
  history: HistoryItem
}

export const HistoryMessageObject: React.FC<Props> = ({ history }) => (
  match(history)
    .with({ kind: HistoryKind.Holding }, item => <HoldingHistoryMessage history={item} />)
    .with({ kind: HistoryKind.SingleTransaction }, item => <SingleTransactionHistoryMessage history={item} />)
    .with({ kind: HistoryKind.TickerTransaction }, item => <TickerTransactionHistoryMessage history={item} />)
    .with({ kind: HistoryKind.TransferTransaction }, item => <TransferTransactionHistoryMessage history={item} />)
    .with({ kind: HistoryKind.AdvisoryTaskStatus }, item => <AdvisoryTaskStatusHistoryMessage history={item} />)
    .exhaustive()
)
