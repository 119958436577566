import { DemoActionTypes } from 'Demo/demo-constants'
import { IAction } from 'Shared/types'

const transactionsFirstLoadingCompleted = (): IAction => ({
  type: DemoActionTypes.TRANSACTIONS_FIRST_LOADING_COMPLETED
})

const switchToDemoModeOn = (): IAction => ({
  type: DemoActionTypes.DEMO_MODE_ENABLED
})

const switchToDemoModeOff = (): IAction => ({
  type: DemoActionTypes.DEMO_MODE_DISABLED
})

export const DemoActions = {
  transactionsFirstLoadingCompleted,
  switchToDemoModeOn,
  switchToDemoModeOff,
}
