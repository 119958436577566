import React from 'react'
import { useTranslation } from 'react-i18next'
import { Chip } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { match } from 'ts-pattern'

import { AdvisoryClientTask } from 'Services/advisor'
import { styledWithCustomProp } from 'config/theme'
import { DueType } from './Task-types'
import { getTaskDueType } from './Task-utils'

interface Props {
  task: AdvisoryClientTask
}

const TaskContainer = styledWithCustomProp(Chip, 'dueType')<{ dueType: DueType }>(({ theme, dueType }) => ({
  padding: theme.spacing(0, 0.75),
  borderRadius: theme.spacing(0.25),
  backgroundColor: match(dueType)
    .with(DueType.PastDue, () => theme.palette.danger.danger3)
    .with(DueType.Soon, () => theme.palette.orange.orange1)
    .with(DueType.Later, () => theme.palette.dark.dark3)
    .with(DueType.Completed, () => theme.palette.green.green4)
    .exhaustive(),
  borderColor: match(dueType)
    .with(DueType.PastDue, () => theme.palette.danger.danger2)
    .with(DueType.Soon, () => theme.palette.orange.orange2)
    .with(DueType.Later, () => theme.palette.dark.dark2)
    .with(DueType.Completed, () => theme.palette.green.green1)
    .exhaustive(),
  fontSize: theme.typography.captionSmall.fontSize,
  lineHeight: theme.typography.captionSmall.lineHeight,
  '.MuiChip-icon': {
    fontSize: 12,
    color: match(dueType)
      .with(DueType.PastDue, () => theme.palette.danger.danger1)
      .with(DueType.Soon, () => theme.palette.orange.orange)
      .with(DueType.Later, () => theme.palette.dark.dark2)
      .with(DueType.Completed, () => theme.palette.green.green3)
      .exhaustive(),
  }
}))

export const TaskDueLabel: React.FC<Props> = ({ task }) => {
  const { t } = useTranslation()

  const dueDate = new Date(task.dueDate)
  const completedDate = task.completedDateTime ? new Date(task.completedDateTime) : null
  const dueType = React.useMemo(() => getTaskDueType(dueDate, task.completed), [ task ])

  const icon = React.useMemo(
    () => match(dueType)
      .with(DueType.PastDue, () => <ErrorIcon />)
      .with(DueType.Soon, () => <HourglassTopIcon />)
      .with(DueType.Later, () => <AccessTimeFilledIcon />)
      .with(DueType.Completed, () => <CheckCircleIcon />)
      .exhaustive(),
    [ dueType ])

  const label = React.useMemo(
    () => task.completed ?
      t('advisorPortal.task.completed-state', { date: completedDate }) :
      t('advisorPortal.task.due-state', { date: dueDate }),
    [ task ]
  )

  return (
    <TaskContainer
      variant="outlined"
      size="small"
      icon={icon}
      dueType={dueType}
      label={label}
    />
  )
}
