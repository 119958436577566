import type { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { IAnalyticsService, GetChartRequest, UpdateChartRequest } from './analytics-types'
import { AnalyticsService } from './analytics-service'

export class AnalyticsSharedService extends AnalyticsService implements IAnalyticsService {
  private readonly sharingKey: string

  public constructor(sharingKey: string) {
    super()
    this.sharingKey = sharingKey
  }

  public getCharts = (token: string, abortSignal?: AbortSignal) => this.getChartsSharedWithMe(this.sharingKey, token, abortSignal)
  public getChart = (request: GetChartRequest, token: string, abortSignal?: AbortSignal) => this.getChartSharedWithMe(this.sharingKey, request, token, abortSignal)
  public updateChart = (token: string, chartKey: string, request: UpdateChartRequest) => this.updateChartSharedWithMe(this.sharingKey, token, chartKey, request)

  private getChartsSharedWithMe = <T>(sharingKey: string, token: string, abortSignal?: AbortSignal) => {
    const config = {
      headers: { ...super.buildAuthHeader(token) },
      signal: abortSignal,
    }

    return axios
      .get<T>(`/charts/shared-with-me/${sharingKey}`, config)
      .then((response: AxiosResponse) => response?.data)
  }

  private getChartSharedWithMe = <T>(sharingKey: string, request: GetChartRequest, token: string, abortSignal?: AbortSignal) => {
    const config = {
      headers: { ...super.buildAuthHeader(token) },
      signal: abortSignal,
    }
    const queryString = this.buildQueryString(request)

    return axios
      .get<T>(`/charts/shared-with-me/${sharingKey}/${request.key}${queryString}`, config)
      .then((response: AxiosResponse) => response?.data)
  }

  public updateChartSharedWithMe = (sharingKey: string, token: string, chartKey: string, request: UpdateChartRequest) => axios
    .patch(`charts/shared-with-me/${sharingKey}/${chartKey}`, { payload: request }, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)
}
