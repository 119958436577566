import React, { PropsWithChildren } from 'react'
import { Link } from '@mui/material'

interface Props {
  onClick: () => void
  sx?: React.CSSProperties
  disabled?: boolean
}

export const ButtonLink: React.FC<PropsWithChildren<Props>> = ({ onClick, sx, disabled = false, children }) => (
  <Link
    type="button"
    fontSize="inherit"
    fontFamily="inherit"
    lineHeight="inherit"
    underline="none"
    onClick={onClick}
    sx={{
      cursor: 'pointer',
      ...(disabled ? { color: 'text.disabled' } : { }),
      ...sx,
    }}
  >
    {children}
  </Link>
)
