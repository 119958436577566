import { SelectChangeEvent } from '@mui/material'
import { Formik, FormikProps } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { match } from 'ts-pattern'

import { AnalyticsActions } from 'Analytics/analytics-actions'
import { useAuthWithDemo } from 'Demo'
import { PortfolioAllocationType, UpdateAssetAllocationRequest } from 'Services/portfolio'
import { DrawerWithCloseConfirmation, FormDirtinessDetector, HelpText } from 'Shared/components'
import { Dictionary } from 'Shared/types'
import { buildFormSubmit } from 'Shared/utils'
import { useSnackbar } from 'Snackbar'
import { AssetAllocationSelector } from './AssetAllocationSelector'
import { AssetAllocationCryptoSelectorAdapter, AssetAllocationStockSelectorAdapter } from './AssetAllocationSelectors'
import { AssetAllocationForm, AssetAllocationSchema, transformValueToRequest } from './Form'
import { AssetAllocationSelectors } from './assetAllocation-selectors'

interface AssetAllocationDrawerProps {
  isOpen: boolean
  close: () => void
  type: PortfolioAllocationType
  getAssetAllocation: (accessToken: string, type: PortfolioAllocationType) => void
  updateAssetAllocation: (accessToken: string, type: PortfolioAllocationType, allocation: Dictionary<number>) => void
}

export const AssetAllocationDrawer: React.FC<AssetAllocationDrawerProps> = ({
  isOpen,
  close,
  type,
  getAssetAllocation,
  updateAssetAllocation,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { getAccessTokenWithDemo } = useAuthWithDemo()
  const { enqueueSuccess, enqueueError, enqueueWarning } = useSnackbar()
  const [ formDirty, setFormDirty ] = React.useState(false)

  const isLoading = useSelector(AssetAllocationSelectors.isLoading)
  const assetAllocation = useSelector(AssetAllocationSelectors.assetAllocation)

  const [ selectedType, setSelectedType ] = React.useState<PortfolioAllocationType>(type)

  const validationSchema = React.useMemo(
    () => AssetAllocationSchema(Object.keys(assetAllocation?.allocation ?? {})),
    [ assetAllocation?.allocation ])
  const manageSettings = React.useMemo(
    () => match(selectedType)
      .with(PortfolioAllocationType.Class, () => ({ canManage: false, NewKeyComponent: undefined }))
      .with(PortfolioAllocationType.Category, () => ({ canManage: false, NewKeyComponent: undefined }))
      .with(PortfolioAllocationType.Stock, () => ({ canManage: true, NewKeyComponent: AssetAllocationStockSelectorAdapter }))
      .with(PortfolioAllocationType.Crypto, () => ({ canManage: true, NewKeyComponent: AssetAllocationCryptoSelectorAdapter }))
      .exhaustive(),
    [ selectedType ]
  )

  React.useEffect(() => {
    if (isOpen && selectedType) {
      getAccessTokenWithDemo().then(accessToken => {
        getAssetAllocation(accessToken, selectedType)
      })
    }
  }, [ isOpen, selectedType ])

  const handleOnTypeChanged = (event: SelectChangeEvent) => {
    setSelectedType(event.target.value as PortfolioAllocationType)
  }

  const handleSubmit = React.useCallback(buildFormSubmit<Dictionary<number>, UpdateAssetAllocationRequest, void>({
    valuesToRequest: values => transformValueToRequest(values, selectedType ?? PortfolioAllocationType.Class),
    requestAccessToken: getAccessTokenWithDemo,
    actionCall: async (request, accessToken) => {
      await updateAssetAllocation(accessToken, request.type, request.allocation)
    },
    onSuccessCall: async token => {
      enqueueSuccess('app.navbar.asset-allocation.update-success')
      dispatch(AnalyticsActions.refreshAssetAllocationCharts(token))
      close()
    },
    onWarningCall: (_, __, error) => enqueueWarning(error.key, error.prop),
    onErrorCall: () => enqueueError('app.navbar.asset-allocation.update-failure'),
  }), [ assetAllocation ])

  return (
    <DrawerWithCloseConfirmation
      title={t('app.navbar.asset-allocation.name')}
      isOpen={isOpen}
      close={close}
      isLoading={isLoading}
      formDirty={formDirty}
      resetFormDirty={() => setFormDirty(false)}
    >
      <AssetAllocationSelector
        type={selectedType ?? PortfolioAllocationType.Class}
        handleOnTypeChanged={handleOnTypeChanged}
      />

      <HelpText i18nKey="app.navbar.asset-allocation.allocationHint-text" />

      {!isLoading && !!assetAllocation && (
        <Formik
          enableReinitialize
          initialValues={assetAllocation?.allocation ?? {}}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formProps: FormikProps<Dictionary<number>>) => (
            <FormDirtinessDetector setFormDirty={() => setFormDirty(true)}>
              <AssetAllocationForm
                formProps={formProps}
                allocationType={selectedType ?? PortfolioAllocationType.Class}
                manageSettings={manageSettings}
              />
            </FormDirtinessDetector>
          )}
        </Formik>
      )}
    </DrawerWithCloseConfirmation>
  )
}
