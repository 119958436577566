import { all, put, takeEvery, select } from 'redux-saga/effects'
import * as snackbarActionTypes from './snackbar-types'
import type { Snackbar } from './snackbar-types'
import { snackbarActionCreators, snackbarSelectors, SnackbarActions } from '.'

function* enqueueSnackbarSaga({ payload: { message, variant } }: snackbarActionTypes.EnqueueSnackbarAction) {
  const key = new Date().getTime() + Math.random()

  yield put(snackbarActionCreators.addSnackbar({
    message,
    options: {
      key,
      variant,
    },
    key,
  }))
}

function* closeSnackbarSaga(action: snackbarActionTypes.CloseSnackbarAction) {
  const snackbars: Snackbar[] = yield select(snackbarSelectors.getSnackbarsSelector)
  const updatedSnackbars = snackbars.map(snackbar =>
    snackbar.key === action.payload ? { ...snackbar, dismissed: true } : snackbar
  )

  yield put(snackbarActionCreators.updateSnackbars(updatedSnackbars))
}

function* removeSnackbarSaga(action: snackbarActionTypes.CloseSnackbarAction) {
  const snackbars: Snackbar[] = yield select(snackbarSelectors.getSnackbarsSelector)
  const updatedSnackbars = snackbars.filter(snackbar => snackbar.key !== action.payload)

  yield put(snackbarActionCreators.updateSnackbars(updatedSnackbars))
}

export function* snackbarSaga() {
  yield all([
    takeEvery([
      SnackbarActions.ENQUEUE_SUCCESS_SNACKBAR,
      SnackbarActions.ENQUEUE_WARNING_SNACKBAR,
      SnackbarActions.ENQUEUE_ERROR_SNACKBAR,
    ], enqueueSnackbarSaga),
    takeEvery(SnackbarActions.CLOSE_SNACKBAR, closeSnackbarSaga),
    takeEvery(SnackbarActions.REMOVE_SNACKBAR, removeSnackbarSaga),
  ])
}
