import { useMediaQuery, Theme } from '@mui/material'
import React from 'react'

const localStorageAppBarKey = 'settings:appbar'

const appBarStateIngestion = (isDownToTablet: boolean) => {
  try {
    const savedState = localStorage.getItem(localStorageAppBarKey)

    if (savedState === null) {
      return !isDownToTablet
    }

    return savedState === 'true'
  } catch (e) {
    return !isDownToTablet
  }
}

const saveAppBarState = (appBarExpanded: boolean) => {
  try {
    localStorage.setItem(localStorageAppBarKey, appBarExpanded.toString())
  } catch (e) {
    // Safari in private mode throws when writing to localStorage
  }
}

export const useAppBarSettings = () => {
  const isMobileAndTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('tabletLandscape'))
  const [ appBarExpanded, setAppBarExpanded ] = React.useState<boolean>(appBarStateIngestion(isMobileAndTablet))

  React.useEffect(
    () => { saveAppBarState(appBarExpanded) },
    [ appBarExpanded ])

  const toggleAppBar = () => {
    // run animation first, then close/open appbar
    setTimeout(() => setAppBarExpanded(prevState => !prevState), 0)
  }

  return {
    appBarExpanded,
    toggleAppBar: toggleAppBar
  }
}
