import React, { useContext } from 'react'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import { IconButton, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { match } from 'ts-pattern'
import { useSelector } from 'react-redux'

import { NotificationBadge } from 'Shared/components'
import { getTaskDueType, DueType } from 'Shared/components/Task'
import { SettingsContext } from 'App/Settings'
import { TasksSelectors } from 'App/Tasks/Tasks-selectors'

export const TasksButton: React.FC = () => {
  const { t } = useTranslation()

  const tasks = useSelector(TasksSelectors.tasks)
  const { manageSettings } = useContext(SettingsContext)

  const hasPastDueTasks = React.useMemo(
    () => tasks
      .list
      .map(task => getTaskDueType(new Date(task.dueDate), task.completed))
      .some(dueType => dueType === DueType.PastDue),
    [ tasks ])

  const hasSoonTasks = React.useMemo(
    () => tasks
      .list
      .map(task => getTaskDueType(new Date(task.dueDate), task.completed))
      .some(dueType => dueType === DueType.Soon),
    [ tasks ])

  const color = React.useMemo(
    () => match({ hasPastDueTasks, hasSoonTasks })
      .with({ hasPastDueTasks: true }, () => 'error')
      .with({ hasSoonTasks: true }, () => 'warning')
      .otherwise(() => 'primary'),
    [ hasPastDueTasks, hasSoonTasks ])

  return (
    <Tooltip title={t('app.navbar.tasks')}>
      <IconButton onClick={() => manageSettings.myTasks.open()} size="small" color="inherit">
        <NotificationBadge color={color} variant="dot" invisible={!hasPastDueTasks && !hasSoonTasks}>
          <AssignmentOutlinedIcon fontSize="inherit" color="inherit" />
        </NotificationBadge>
      </IconButton>
    </Tooltip>
  )
}
