import React from 'react'
import { useTranslation } from 'react-i18next'

import { SubTextSecondary1 } from 'Shared/components'
import { SubscriptionPlanContainer } from './SubscriptionPlanContainer'

export const LifetimeSubscription: React.FC = () => {
  const { t } = useTranslation()

  return (
    <SubscriptionPlanContainer isPremium>
      <SubTextSecondary1>
        {t('app.navbar.settings.subscription.lifetime')}
      </SubTextSecondary1>
    </SubscriptionPlanContainer>
  )
}
