import React from 'react'
import { Route } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'

import { RedirectingToLogin } from 'App/StaticPages/RedirectingToLogin'

interface Props {
  path: string
  component: React.ComponentType<any>
  exact?: boolean
}

export const ProtectedRoute: React.FC<Props> = ({ component: Component, ...args }: Props) => (
  <Route
    component={withAuthenticationRequired(Component, {
      onRedirecting: () => <RedirectingToLogin />,
    })}
    {...args}
  />
)
