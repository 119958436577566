import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { SxProps, Theme } from '@mui/material/styles'

import { SupportedLanguage } from 'config/i18n-types'
import { ExternalLinks } from 'Shared/constants'
import { InTextExternalLink } from './InTextExternalLink'

interface Props {
  sx?: SxProps<Theme>
}

export const SupportedTickersLink: React.FC<PropsWithChildren<Props>> = ({ children, sx }) => {
  const { i18n } = useTranslation()

  const href = i18n.resolvedLanguage === SupportedLanguage.UK ?
    ExternalLinks.WIKI.FAQ_UK :
    ExternalLinks.WIKI.FAQ_EN

  return (
    <InTextExternalLink sx={sx} href={href}>{children}</InTextExternalLink>
  )
}
