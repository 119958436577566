import { createSelector } from 'reselect'

import type { StoreState } from 'Shared/types'

const currentYear = new Date().getFullYear()
const storeSelector = (state: StoreState) => state.prediction

const isLoading = createSelector(storeSelector, state => state.isLoading)
const isFirstTimeLoading = createSelector(storeSelector, state => state.isFirstTimeLoading)
const firstTimeLoadingCompleted = createSelector(storeSelector, state => state.firstTimeLoadingCompleted)
const getMetadata = createSelector(storeSelector, state => state.prediction.metadata)
const getCalculation = createSelector(storeSelector, state => state.calculation)
const getYearsProjection = createSelector(storeSelector, state => state.yearsProjection)

const getPredictionSavings = createSelector(
  storeSelector,
  state => state
    .prediction
    .data
    .savings
    .filter(income => Number(income.timePoint) <= currentYear + state.yearsProjection)
    .reduce((accum, scopeItem) => ({ ...accum, [scopeItem.timePoint]: scopeItem.value }), {})
)

const getPredictionReturns = createSelector(
  storeSelector,
  state => state
    .prediction
    .data
    .returns
    .filter(income => Number(income.timePoint) <= currentYear + state.yearsProjection)
    .reduce((accum, scopeItem) => ({ ...accum, [scopeItem.timePoint]: scopeItem.value }), {})
)

const getPredictionInfo = createSelector(
  storeSelector,
  state => state
    .prediction
    .data
    .info
    .filter(income => Number(income.timePoint) <= currentYear + state.yearsProjection)
    .reduce((accum, scopeItem) => ({ ...accum, [scopeItem.timePoint]: scopeItem }), {})
)

const getPredictionChartData = createSelector(
  storeSelector,
  state => [
    {
      id: 'savings',
      data: state
        .prediction
        .data
        .savings
        .filter(saving => Number(saving.timePoint) <= currentYear + state.yearsProjection)
        .map(saving => ({ x: saving.timePoint, y: saving.value }))
    },
    {
      id: 'returns',
      data: state
        .prediction
        .data
        .returns
        .filter(income => Number(income.timePoint) <= currentYear + state.yearsProjection)
        .map(income => ({ x: income.timePoint, y: income.value }))
    },
  ]
)

export const PredictionSelectors = {
  isLoading,
  isFirstTimeLoading,
  firstTimeLoadingCompleted,
  getMetadata,
  getCalculation,
  getYearsProjection,
  getPredictionSavings,
  getPredictionReturns,
  getPredictionInfo,
  getPredictionChartData
}
