import React, { PropsWithChildren } from 'react'
import { SxProps, Theme } from '@mui/material/styles'

import { ExternalLink } from './ExternalLink'

interface InTextExternalLinkProps {
  href: string
  spaceBefore?: boolean
  spaceAfter?: boolean
  sx?: SxProps<Theme>
}

export const InTextExternalLink: React.FC<PropsWithChildren<InTextExternalLinkProps>> = ({
  children,
  href,
  spaceBefore = false,
  spaceAfter = false,
  sx = {},
}) => (
  <>
    {spaceBefore && ' '}
    <ExternalLink href={href} sx={sx}>{children}</ExternalLink>
    {spaceAfter && ' '}
  </>
)
