import React from 'react'
import { useField } from 'formik'
import TextFieldMUI from '@mui/material/TextField'

import { localizeError } from 'Shared/utils'
import InputAdornment from '@mui/material/InputAdornment'

interface TextFieldProps {
  name: string
  label?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  prefix?: string
  customOnChange?: Function
}

export const TextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  const {
    required,
    label,
    placeholder,
    disabled,
    prefix,
    customOnChange,
    name,
  } = props
  const [ field, meta ] = useField(props)
  const error = meta.touched && meta.error
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e)
    if (customOnChange) {
      customOnChange(e?.target?.value)
    }
  }

  return (
    <TextFieldMUI
      fullWidth
      error={!!error}
      label={label}
      placeholder={placeholder}
      required={required}
      helperText={localizeError(error)}
      disabled={disabled}
      title={field?.value}
      variant="outlined"
      size="small"
      sx={{
        '.MuiOutlinedInput-input': {
          textOverflow: 'ellipsis',
          autocomplete: 'off',
        }
      }}
      InputProps={{
        ...(prefix && { startAdornment: <InputAdornment position="start" disablePointerEvents>{prefix}</InputAdornment> }),
      }}
      // Disable autocomplete for password extensions (like LastPass)
      data-lpignore="true"
      data-testid={name}
      {...field}
      onChange={handleOnChange}
    />
  )
}
