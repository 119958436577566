import React, { useContext } from 'react'
import { Tooltip, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SharingSelectors } from 'Sharing'
import { SettingsContext } from 'App/Settings'

interface Props {
  icon: React.ReactNode
}

export const ClientHistoryButton: React.FC<Props> = ({ icon }) => {
  const { t } = useTranslation()
  const { manageSettings } = useContext(SettingsContext)
  const sharingKey = useSelector(SharingSelectors.selectedSharingKey)

  if (!sharingKey) {
    return null
  }

  return (
    <Tooltip title={t('app.navbar.clientHistory')}>
      <IconButton onClick={() => manageSettings.sharedHistory.open(sharingKey)}>
        {icon}
      </IconButton>
    </Tooltip>
  )
}
