import { ResponsivePie } from '@nivo/pie'
import React from 'react'

import { PortfolioAllocationType } from 'Services/portfolio'
import { colorScheme, DefaultUndefinedValue } from 'Shared/constants'
import {
  colorRenderProvider,
  defaultColorRender,
  defaultLabelRender,
  labelRenderProvider,
} from 'Shared/providers'
import { Dictionary } from 'Shared/types'
import { AssetAllocationChartTooltip } from './AssetAllocationChartTooltip'

interface AssetAllocationChartProps {
  values: Dictionary<number>
  totalAllocated: number
  type: PortfolioAllocationType
}

export const AssetAllocationChart: React.FC<AssetAllocationChartProps> = ({ values, totalAllocated, type }) => {
  const renderLabel = labelRenderProvider.get(type) ?? defaultLabelRender
  const renderColor = colorRenderProvider.get(type) ?? defaultColorRender(colorScheme, new Map())

  const chartData = React.useMemo(() => {
    const leftToAllocate = totalAllocated < 0 || totalAllocated > 100 ? 100 : 100 - totalAllocated

    const uncategorizedAllocation = {
      id: DefaultUndefinedValue,
      label: renderLabel(DefaultUndefinedValue),
      value: leftToAllocate,
      color: renderColor(DefaultUndefinedValue)
    }

    if (leftToAllocate === 100) {
      return [ uncategorizedAllocation ]
    }

    return [
      ...Object
        .keys(values)
        .map(property => ({
          id: property,
          label: renderLabel(property),
          value: +values[property],
          color: renderColor(property)
        })),
      uncategorizedAllocation
    ]
      .filter(allocation => allocation.value !== 0)
      .sort((x, y) => y.value - x.value)
  }, [ values, totalAllocated ])

  return (
    <ResponsivePie
      data={chartData}
      colors={{ datum: 'data.color' }}
      valueFormat=" >-.3s"
      margin={{ left: 30, right: 30, top: 20, bottom: 20 }}
      innerRadius={0.5}
      padAngle={3}
      cornerRadius={3}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
      enableArcLinkLabels
      arcLinkLabel={point => point.label.toString()}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsThickness={1}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLinkLabelsStraightLength={10}
      arcLinkLabelsDiagonalLength={10}
      enableArcLabels
      arcLabel={point => `${point.data.value}%`}
      arcLabelsSkipAngle={25}
      arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
      tooltip={point => (
        <AssetAllocationChartTooltip
          label={point.datum.label.toString()}
          value={point.datum.value}
          color={point.datum.color}
        />
      )}
    />
  )
}
