import React from 'react'

import type { UserGuideConfig } from 'App/UserGuide/types'

const UserGuideStorageKey = 'UserGuidePassed'

export const useUserGuide = () => {
  const [ userGuidePassed, setUserGuidePassed ] = React.useState<boolean>(true)
  const [ userGuideEnabled, setUserGuideEnabled ] = React.useState<boolean>(false)
  const [ runtimeConfig, setRuntimeConfig ] = React.useState<UserGuideConfig>({
    displayWhenGuidePassedAlready: false,
    stepsRedirects: [],
  })

  React.useEffect(() => {
    const userGuideStorageRecord = sessionStorage.getItem(UserGuideStorageKey)

    if (userGuideStorageRecord === null) {
      setUserGuidePassed(false)
    }
  }, [])

  const runUserGuide = (config: UserGuideConfig) => {
    if (config.displayWhenGuidePassedAlready || !userGuidePassed) {
      setRuntimeConfig(config)
      setUserGuideEnabled(true)
    }
  }

  const completeUserGuide = () => {
    setUserGuideEnabled(false)
    setUserGuidePassed(true)

    sessionStorage.setItem(UserGuideStorageKey, 'Passed')
  }

  return {
    enabled: userGuideEnabled,
    runConfiguration: runtimeConfig,
    runUserGuide,
    completeUserGuide,
  }
}
