import axios from 'axios'

import i18next from 'config/i18n'

export const backendInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/api`,
})

export const exchangeRateInstance = axios.create({
  baseURL: `${process.env.REACT_APP_EXCHANGE_RATE_HOST}/api`,
})

backendInstance.defaults.headers.common['Accept'] = 'application/json'
exchangeRateInstance.defaults.headers.common['Accept'] = 'application/json'

i18next.on('languageChanged', lng => {
  backendInstance.defaults.headers.common['Accept-Language'] = lng
})

export default backendInstance
