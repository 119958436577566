import React from 'react'
import { useLocation } from 'react-router-dom'

export const useFeatureFlag = (featureFlag: string) => {
  const { search } = useLocation()

  const value = React.useMemo(
    () => new URLSearchParams(search).get(featureFlag),
    [ search, featureFlag ])

  return {
    activated: !!value,
    value,
  }
}
