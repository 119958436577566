declare global {
  // eslint-disable-next-line id-denylist
  interface Window {
    dataLayer: any[]
    customerly: any
  }
}

window.dataLayer = window.dataLayer || []
window.customerly = window.customerly || {}

export {}
