export enum Colors {
  Padua = '#B3E2CD',
  Cornflower = '#A2D4F0',
  WaxFlower = '#FCCDAB',
  ClassicRose = '#F9C7E5',
  Melrose = '#C3BFF8',
  SweetCorn = '#F4EA96',
  Spray = '#A2F0E7',
  Azalea = '#F2C9C9',
  Linen = '#F9F1E4',
  Tusk = '#F1F3C9',

  PaudaDarken10 = '#A2CAB8',
  CornflowerDarken10 = '#92BFD8',
  WaxFlowerDarken10 = '#E3B89A',
  ClassicRoseDarken10 = '#E0B3CE',
  MelroseDarken10 = '#AEACDF',
  SweetCornDarken10 = '#DCD387',
  SprayDarken10 = '#92D8D0',
  AzaleaDarken10 = '#DAB5B5',
  LinenDarken10 = '#E0D9CD',
  TuskDarken10 = '#D9DBB5',

  PaudaDarken20 = '#8EB5A5',
  CornflowerDarken20 = '#82AAC0',
  WaxFlowerDarken20 = '#CAA489',
  ClassicRoseDarken20 = '#C79EB7',
  MelroseDarken20 = '#9D99C5',
  SweetCornDarken20 = '#C4BB78',
  SprayDarken20 = '#83C0B9',
  AzaleaDarken20 = '#C2A1A1',
  LinenDarken20 = '#C7C1B6',
  TuskDarken20 = '#C1C2A1',
}

export enum RiskColors {
  Level1 = '#CE3232',
  Level2 = '#CA5B5B',
  Level3 = '#FB9696',
  Level4 = '#FECACA',
  Level5 = '#FFE4E4',
}

export enum LiquidityColors {
  Level1 = '#437DD5',
  Level2 = '#4A9DEA',
  Level3 = '#93D4F9',
  Level4 = '#CAEEFE',
  Level5 = '#E4F5FF',
}

export enum TermColors {
  Level1 = '#D3B71F',
  Level2 = '#DFC952',
  Level3 = '#FBEB96',
  Level4 = '#FEF5CA',
  Level5 = '#FFFBE4',
}

export enum HoldingTypeColors {
  Regular = '#1890FF',
  Crypto = '#FF9C33',
  Stocks = '#9429D6'
}
