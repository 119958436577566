import { Button, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useContext } from 'react'

import { SettingsContext } from 'App/Settings'
import { useTranslation } from 'react-i18next'
import { EyeIcon, EyeSlashIcon } from 'Shared/assets'
import { TextPrimary } from 'Shared/components/Typography'

interface Props {
  closeDrawer?: (event: React.KeyboardEvent | React.MouseEvent) => void
}

const HiddenModeWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
  cursor: 'pointer',
  'button:hover': {
    backgroundColor: 'unset'
  },
  'p': {
    fontWeight: 500,
    color: theme.palette.common.black
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  ':hover': { backgroundColor: 'unset' },
}))

export const ToggleHiddenMode = () => {
  const { t } = useTranslation()
  const { isHiddenMode, manageSettings } = useContext(SettingsContext)

  const VisibleMode = (
    <HiddenModeWrapper>
      <EyeSlashIcon width={18} />
      <TextPrimary>{t('app.navbar.hiddenMode-hide')}</TextPrimary>
    </HiddenModeWrapper>
  )

  const HiddenMode = (
    <HiddenModeWrapper>
      <EyeIcon width={18} />
      <TextPrimary>{t('app.navbar.hiddenMode-show')}</TextPrimary>
    </HiddenModeWrapper>
  )

  return (
    <StyledButton
      disableRipple
      data-userguide-id="stepHiddenMode"
      onClick={manageSettings.toggleHiddenMode}
    >
      {isHiddenMode ? HiddenMode : VisibleMode}
    </StyledButton>
  )
}
