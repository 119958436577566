import { styled } from '@mui/material/styles'

import { TextPrimary } from 'Shared/components/Typography'
import { Dialog } from '@mui/material'

export const NotesText = styled(TextPrimary)(({ theme }) => ({
  border: `1px solid ${theme.palette.blue.blue3}`,
  padding: theme.spacing(1),
  borderRadius: 4,
  whiteSpace: 'pre-line',
  height: '100%',
  overflowWrap: 'anywhere',
}))

export const NotesTextDisabled = styled(NotesText)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))

export const ViewDialog = styled(Dialog)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 2
}))

