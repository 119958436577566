import { styled } from '@mui/material/styles'
import { List } from '@mui/material'

export const BaseOptionsList = styled(List)({
  width: '100%',
  minWidth: 300,
  maxWidth: 350,
  maxHeight: 500,
  overflow: 'auto'
})
