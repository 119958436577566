import { Nullable } from 'Shared/types'
import { AdvisorRole, PricingTier, Subscription } from 'Services/profile'

export enum Permission {
  ViewPremiumBadge = 'ViewPremiumBadge',
  ViewActionsHistory = 'ViewActionsHistory',
  ViewAdvisorPortal = 'ViewAdvisorPortal',
  ViewOrganizationClients = 'ViewOrganizationClients',
  ViewMyTasks = 'ViewMyTasks',
  ViewAdvancedCharts = 'ViewAdvancedCharts',
  ViewSubscribeButton = 'ViewSubscribeButton',
  AddUnlimitedAssets = 'AddUnlimitedAssets',
}

export enum RoleName {
  User = 'User',
  UserWithPaymentIssues = 'UserWithPaymentIssues',
  PremiumUser = 'PremiumUser',
  FinancialAdvisorClient = 'FinancialAdvisorClient',
  OrganizationAdvisor = 'OrganizationAdvisor',
  OrganizationOwner = 'OrganizationOwner',
}

export type Role = {
  name: string,
  permissions: Permission[],
}

export type AuthorizationModel = {
  isAdvisor: boolean
  advisorRole: Nullable<AdvisorRole>
  isAdvisoryClient: boolean
  tier: PricingTier
  subscription: Nullable<Subscription>
}
