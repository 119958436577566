
import { styled } from '@mui/material/styles'

import { EmptyContentPaper } from 'Shared/components/Papers'

export const CenteredOverlay = styled(EmptyContentPaper)({
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
