import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useContext } from 'react'

import { SettingsContext } from 'App/Settings'
import { EyeIcon, EyeSlashIcon } from 'Shared/assets'

const StyledButton = styled(Button)(({ theme }) => ({
  padding: 0,
  minWidth: 'unset',
  color: theme.palette.common.white,
  ':hover': { backgroundColor: 'unset' },
}))

export const ToggleHiddenModeMobile = () => {
  const { isHiddenMode, manageSettings } = useContext(SettingsContext)

  return (
    <StyledButton
      disableRipple
      data-userguide-id="stepHiddenMode"
      onClick={() => { manageSettings.toggleHiddenMode() }}
    >
      {isHiddenMode ? <EyeIcon width={18} /> : <EyeSlashIcon width={18} />}
    </StyledButton>
  )
}
