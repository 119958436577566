import React from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Routes } from 'Shared/constants'

interface Props {
  variant?: 'text' | 'outlined' | 'contained'
  disabled?: boolean
}

export const SubscribeButton: React.FC<Props> = ({ variant = 'contained', disabled = false }) => {
  const { t } = useTranslation()

  return (
    <Button
      size="small"
      variant={variant}
      component={Link}
      to={Routes.PRICING}
      disabled={disabled}
    >
      {t('common.subscribe')}
    </Button>
  )
}
