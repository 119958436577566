import React from 'react'

import { BaseOptionsPopper } from './BaseOptionsPopper'
import { SortingButton } from './SortingButton'
import { SortingOptions } from './SortingOptions'

interface SortingProps {
  label: string
  options: { label: string, value: string }[]
  selectedValue: string
  onChange: (value: string) => void
}

export const Sorting: React.FC<SortingProps> = ({ label, options, selectedValue, onChange }) => {
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)

  const openOptionsList = (event: any) => setAnchorEl(event.currentTarget)
  const closeOptionsList = () => setAnchorEl(null)

  const notifySelectedOptionsChanged = (value: string) => {
    onChange(value)
    closeOptionsList()
  }

  return (
    <>
      <SortingButton
        label={label}
        onClick={openOptionsList}
        expanded={!!anchorEl}
        selectedLabel={options.find(option => option.value === selectedValue)?.label}
        disabled={options.length === 0}
      />

      <BaseOptionsPopper anchorEl={anchorEl} onClose={closeOptionsList}>
        <SortingOptions
          options={options}
          selectedValue={selectedValue}
          onOptionClick={notifySelectedOptionsChanged}
        />
      </BaseOptionsPopper>
    </>
  )
}
