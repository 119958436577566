import { AdvisorPortalState, ClientSortTerm } from './advisorPortal-types'

export const AdvisorPortalActionTypes = {
  GET_ADVISOR_PORTAL: 'GET_ADVISOR_PORTAL',

  GET_ADVISOR_PORTAL_CLIENTS: 'GET_ADVISOR_PORTAL_CLIENTS',
  GET_ADVISOR_PORTAL_CLIENTS_SUCCESS: 'GET_ADVISOR_PORTAL_CLIENTS_SUCCESS',
  GET_ADVISOR_PORTAL_CLIENTS_FAILURE: 'GET_ADVISOR_PORTAL_CLIENTS_FAILURE',
  REVOKE_ADVISOR_PORTAL_CLIENT: 'REVOKE_ADVISOR_PORTAL_CLIENT',
  REVOKE_ADVISOR_PORTAL_CLIENT_SUCCESS: 'REVOKE_ADVISOR_PORTAL_CLIENT_SUCCESS',
  EDIT_ADVISOR_PORTAL_CLIENT_SUCCESS: 'EDIT_ADVISOR_PORTAL_CLIENT_SUCCESS',

  GET_ADVISOR_PORTAL_SHARINGS: 'GET_ADVISOR_PORTAL_SHARINGS',
  GET_ADVISOR_PORTAL_SHARINGS_SUCCESS: 'GET_ADVISOR_PORTAL_SHARINGS_SUCCESS',
  DECLINE_ADVISOR_PORTAL_SHARING: 'DECLINE_ADVISOR_PORTAL_SHARING',
  REMOVE_ADVISOR_PORTAL_SHARING: 'REMOVE_ADVISOR_PORTAL_SHARING',
  ACCEPT_ADVISOR_PORTAL_SHARING: 'ACCEPT_ADVISOR_PORTAL_SHARING',

  GET_ADVISOR_CLIENTS_HISTORY: 'GET_ADVISOR_CLIENTS_HISTORY',
  GET_ADVISOR_CLIENTS_HISTORY_SUCCESS: 'GET_ADVISOR_CLIENTS_HISTORY_SUCCESS',
  GET_ADVISOR_CLIENTS_HISTORY_FAILURE: 'GET_ADVISOR_CLIENTS_HISTORY_FAILURE',
  REVOKE_ADVISOR_CLIENT_HISTORY_SUCCESS: 'REVOKE_ADVISOR_CLIENT_HISTORY_SUCCESS',

  GET_ADVISOR_PORTAL_ORGANIZATION: 'GET_ADVISOR_PORTAL_ORGANIZATION',
  GET_ADVISOR_PORTAL_ORGANIZATION_SUCCESS: 'GET_ADVISOR_PORTAL_ORGANIZATION_SUCCESS',
  GET_ADVISOR_PORTAL_ORGANIZATION_FAILURE: 'GET_ADVISOR_PORTAL_ORGANIZATION_FAILURE',
  USE_ADVISOR_PORTAL_ORGANIZATION_LICENCE: 'USE_ADVISOR_PORTAL_ORGANIZATION_LICENCE',
  REVOKE_ADVISOR_PORTAL_ORGANIZATION_LICENCE: 'REVOKE_ADVISOR_PORTAL_ORGANIZATION_LICENCE',

  SET_ADVISOR_PORTAL_CLIENTS_FILTER: 'SET_ADVISOR_PORTAL_CLIENTS_FILTER',
  SET_ADVISOR_PORTAL_HISTORY_FILTER: 'SET_ADVISOR_PORTAL_HISTORY_FILTER',

  CREATE_ADVISOR_PORTAL_CLIENT_TASK: 'CREATE_ADVISOR_PORTAL_CLIENT_TASK',
  UPDATE_ADVISOR_PORTAL_CLIENT_TASK: 'UPDATE_ADVISOR_PORTAL_CLIENT_TASK',
  DELETE_ADVISOR_PORTAL_CLIENT_TASK: 'DELETE_ADVISOR_PORTAL_CLIENT_TASK',
}

export const AdvisorPortalInitialState: AdvisorPortalState = {
  sharings: [],
  clients: {
    list: [],
    isLoading: false,
    initialLoadCompleted: false,
  },
  clientsHistory: {
    list: [],
    isLoading: false,
    initialLoadCompleted: false,
  },
  clientsFilter: {
    searchQuery: '',
    selectedTags: [],
    selectedAdvisors: [],
    sortTerm: ClientSortTerm.LastAddedDesc,
  },
  organization: {
    name: '',
    tierInformation: {
      clientsThreshold: 0,
      clientSeatsAvailable: 0,
      clientSeatsInUse: 0,
    }
  },
  historyFilter: {
    searchQuery: '',
    managedBy: '',
  }
}
