import {
  IProfileService,
  PricingTier,
  UpdateProfileRequest,
  UserProfile,
} from 'Services/profile'
import { CreateSubscriptionRequest } from 'Services/profile/profile-types'
import { DefaultSettingsState } from 'App/Settings'

export class DemoProfileService implements IProfileService {
  deleteAccount(token: string): any {
    return Promise.resolve()
  }

  getAccount(token: string): Promise<UserProfile> {
    const profile: UserProfile = {
      advisor: false,
      advisorRole: null,
      advisoryClient: false,
      baseCurrency: DefaultSettingsState.baseCurrency,
      brandNew: false,
      organization: null,
      pendingSurveys: [],
      releaseNotesRead: true,
      subscription: null,
      tierInformation: { tier: PricingTier.Premium },
      username: ''
    }

    return Promise.resolve(profile)
  }

  exportAccount(token: string, fileName: string): any {
    return Promise.resolve()
  }

  readReleaseNotes(token: string): any {
    return Promise.resolve()
  }

  updateAccount(token: string, payload: UpdateProfileRequest): any {
    return Promise.resolve()
  }

  createSubscription(token: string, payload: CreateSubscriptionRequest): any {
    return Promise.resolve(null)
  }

  cancelSubscription(token: string): Promise<void> {
    return Promise.resolve()
  }

  getPromoCode(token: string, promoCode: string): any {
    return Promise.resolve()
  }
}
