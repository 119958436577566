import React, { useContext } from 'react'
import { Stack, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { Tag } from 'Shared/components'
import { SettingsContext } from 'App/Settings'
import { useAuthorization, Permission } from 'Integration/authorization'
import { AdvisorPortalSelectors } from 'AdvisorPortal/advisorPortal-selectors'
import { AdvisorPortalActions } from 'AdvisorPortal/advisorPortal-actions'

export const ClientsHistoryFilter: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { profile } = useContext(SettingsContext)
  const authorization = useAuthorization()

  const historyFilter = useSelector(AdvisorPortalSelectors.historyFilter)

  const dispatchFilterChanges = (filter: string, value: any) => {
    dispatch(AdvisorPortalActions.changeHistoryFilter({ ...historyFilter, [filter]: value }))
  }

  const changeSearchQuery = dispatchFilterChanges.bind(null, 'searchQuery')
  const changeMangedBy = dispatchFilterChanges.bind(null, 'managedBy')

  const managedByOptions = React.useMemo(
    () => [
      { label: t('advisorPortal.clientsHistory.filter.managedByOrganization'), value: '' },
      { label: t('advisorPortal.clientsHistory.filter.managedByMe'), value: profile.username },
    ],
    [ profile.username ])

  return (
    <>
      <TextField
        type="search"
        size="small"
        label={t('common.search')}
        value={historyFilter.searchQuery}
        onChange={e => changeSearchQuery(e.target.value)}
        fullWidth
      />

      {authorization.hasAccess(Permission.ViewOrganizationClients) && (
        <Stack direction="row" spacing={1}>
          {managedByOptions.map(option => (
            <Tag
              key={option.label}
              label={option.label}
              active={option.value === historyFilter.managedBy}
              onClick={() => changeMangedBy(option.value)}
              size="small"
            />
          ))}
        </Stack>
      )}
    </>
  )
}
