import { Container, Theme, useMediaQuery } from '@mui/material'

import { DesktopNavigation } from './components-landing-bar/DesktopNavigation'
import { MobileNavigation } from './components-landing-bar/MobileNavigation'
import { StyledAppBar, StyledToolbar, useScrollToTop } from './components-landing-shared'

export const LandingNavigationBar: React.FC = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  useScrollToTop()

  return (
    <StyledAppBar color="inherit" elevation={0}>
      <Container maxWidth="lg">
        <StyledToolbar>
          {isMobile
            ? <MobileNavigation />
            : <DesktopNavigation />
          }
        </StyledToolbar>
      </Container>
    </StyledAppBar>
  )
}
