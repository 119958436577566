import React from 'react'
import { useTranslation } from 'react-i18next'

import { TextLarger } from 'Shared/components'

interface Props {
  routeKey: string
}
export const CurrentRoute: React.FC<Props> = ({ routeKey }) => {
  const { t } = useTranslation()

  return <TextLarger fontWeight={500}>{t(routeKey)}</TextLarger>
}
