import { AssetAllocation } from 'Services/portfolio'
import { ITypedAction } from 'Shared/types'
import { AssetAllocationState } from './assetAllocation-types'
import { InitialState, AssetAllocationTypes } from './assetAllocation-constants'

export const assetAllocation = (state: AssetAllocationState = InitialState, action: ITypedAction<any>): AssetAllocationState => {
  switch (action.type) {
    case AssetAllocationTypes.GET_ASSET_ALLOCATION: {
      return {
        ...state,
        isLoading: true
      }
    }

    case AssetAllocationTypes.GET_ASSET_ALLOCATION_SUCCESS: {
      const assetAllocation = action.payload as AssetAllocation

      return {
        ...state,
        isLoading: false,
        initialLoadCompleted: true,
        assetAllocation: assetAllocation
      }
    }

    case AssetAllocationTypes.GET_ASSET_ALLOCATION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        assetAllocation: null,
      }
    }

    default: {
      return state
    }
  }
}

