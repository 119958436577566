export const TasksActionTypes = {
  GET_TASKS: 'GET_TASKS_SUCCESS',
  CHANGE_TASK_STATUS: 'CHANGE_TASK_STATUS_SUCCESS',
}

export const TasksInitialState = {
  tasks: {
    list: [],
    isLoading: false,
    initialLoadCompleted: false,
  }
}
