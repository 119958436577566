import { Badge as MuiBadge } from '@mui/material'
import { match } from 'ts-pattern'

import { styledWithCustomProp } from 'config/theme'

export const NotificationBadge = styledWithCustomProp(MuiBadge, 'color')<{ color: string }>(({ theme, color }) => ({
  '.MuiBadge-badge': {
    border: `1px solid ${theme.palette.common.white}`,
    backgroundColor: match(color)
      .with('error', () => theme.palette.danger.danger1)
      .with('warning', () => theme.palette.orange.orange)
      .otherwise(() => theme.palette.primary.main),
    borderRadius: '50%',
  }
}))
