import { format, addMonths, differenceInMonths, parse } from 'date-fns'
import { takeRight, take } from 'lodash'

import i18next from 'config/i18n'
import { IAnalyticsService, GetChartRequest, ChartsResponse } from 'Services/analytics'
import { UpdateChartRequest } from 'Services/analytics/analytics-types'
import { ChartType } from 'Analytics/analytics-types'
import { LastUpdatedDate } from 'Demo/demo-constants'

export class DemoAnalyticsService implements IAnalyticsService {
  public getChart = (request: GetChartRequest, token: string): any => {
    const today = new Date()
    const delta = differenceInMonths(today, LastUpdatedDate)

    return import(`./charts/${request.key}.json`)
      .then(chart => JSON.parse(JSON.stringify(chart.default)))
      .then(chart => this.sliceByPeriod(chart, Number(request.period)))
      .then(chart => this.sliceByProjectionPeriod(chart, Number(request.projectionPeriod)))
      .then(chart => this.refreshDates(chart, delta))
      .then(chart => this.localizeChartName(chart))
      .then(chart => this.localizeChartProps(chart))
  }

  public getCharts = (token: string): Promise<ChartsResponse> =>
    import('./charts.json')
      .then(chartsResponse => JSON.parse(JSON.stringify(chartsResponse.default)))
      .then(chartsResponse => ({
        ...chartsResponse,
        charts: chartsResponse.charts.map((chart: any) => this.localizeChartName(chart)),
        tags: chartsResponse.tags.map((tag: any) => i18next.t(tag, { ns: 'demo' })),
      }))

  public updateChart = (token: string, chartKey: string, request: UpdateChartRequest) => Promise.resolve()

  private localizeChartName = (chart: any) => ({
    ...chart,
    name: i18next.t(chart.name, { ns: 'demo' })
  })

  private sliceByPeriod = (chart: any, period: number) => {
    if (chart) {
      switch (chart.type) {
        case ChartType.CAPITAL_GROWTH: {
          const [ portfolio, benchmark ] = chart.data
          chart.data = [
            { ...portfolio, data: take(portfolio.data, period) },
            { ...benchmark, data: takeRight(benchmark.data, period) }
          ]
          chart.metadata.Period = period
          return chart
        }
        case ChartType.OPERATIONS:
        case ChartType.PROPERTY_PERIOD_ALLOCATION: {
          chart.data = chart.data.slice(-period)
          chart.metadata.Period = period
          return chart
        }
        default:
          return chart
      }
    }

    return chart
  }

  private sliceByProjectionPeriod = (chart: any, projectionPeriod: number) => {
    if (chart) {
      switch (chart.type) {
        case ChartType.DIVIDENDS_PROJECTION: {
          chart.data = Object
            .entries(chart.data)
            .slice(0, projectionPeriod)
            .reduce((acc: any, [ key, value ]) => ({ ...acc, [ key ]: value }), {})
          chart.metadata.ProjectionPeriod = projectionPeriod
          return chart
        }
        default:
          return chart
      }
    }

    return chart
  }

  private refreshDates = (chart: any, monthsDelta: number) => {
    if (chart) {
      switch (chart.type) {
        case ChartType.CAPITAL_GROWTH: {
          return {
            ...chart,
            data: chart.data.map((line: any) => ({
              ...line,
              data: line.data.map((item: any) => ({
                ...item,
                label: this.updateDate(item.label, 'M-yyyy', monthsDelta),
              }))
            }))
          }
        }
        case ChartType.OPERATIONS:
        case ChartType.PROPERTY_PERIOD_ALLOCATION: {
          return {
            ...chart,
            data: chart.data.map((item: any) => ({
              ...item,
              label: this.updateDate(item.label, 'M-yyyy', monthsDelta),
            }))
          }
        }
        case ChartType.DIVIDENDS: {
          return {
            ...chart,
            data: Object
              .entries(chart.data)
              .reduce((acc: any, [ key, value ]) => ({
                ...acc,
                [ this.updateDate(key, 'M-yyyy', monthsDelta) ]: value
              }), {})
          }
        }
        default:
          return chart
      }
    }

    return chart
  }

  private localizeChartProps = (chart: any) => {
    switch (true) {
      case this.isCategoryBalanceChart(chart): {
        return {
          ...chart,
          data: {
            ...chart.data,
            expected: chart.data.expected.map((item: any) => ({
              label: i18next.t(item.label, { ns: 'demo' }),
              value: item.value
            })),
            actual: chart.data.actual.map((item: any) => ({
              label: i18next.t(item.label, { ns: 'demo' }),
              value: item.value
            }))
          }
        }
      }
      case this.isAssetDividendsChart(chart):
      case this.isCategoryDividendsChart(chart): {
        return {
          ...chart,
          data: Object
            .keys(chart.data)
            .reduce((dataAcc: any, period: string) => ({
              ...dataAcc,
              [ period ]: Object
                .keys(chart.data[ period ])
                .reduce((periodAcc: any, property: string) => ({
                  ...periodAcc,
                  [ i18next.t(property, { ns: 'demo' }) ]: chart.data[ period ][ property ]
                }), {})
            }), {})
        }
      }
      case this.isTreeMapChart(chart): {
        return {
          ...chart,
          data: {
            ...chart.data,
            // Asset classes level
            children: chart.data.children.map((firstLevelChild: any) => ({
              ...firstLevelChild,
              // Asset Categories level
              children: firstLevelChild.children.map((secondLevelChild: any) => ({
                ...secondLevelChild,
                name: i18next.t(secondLevelChild.name, { ns: 'demo' }),
                // Assets level
                children: secondLevelChild.children.map((leafChild: any) => ({
                  ...leafChild,
                  name: i18next.t(leafChild.name, { ns: 'demo' }),
                  company: i18next.t(leafChild.company, { ns: 'demo' })
                }))
              }))
            }))
          }
        }
      }
      case this.isTagsStructureChart(chart): {
        return {
          ...chart,
          data: Object.keys(chart.data).reduce((tags: any, tag: string) => ({
            ...tags,
            [ i18next.t(tag, { ns: 'demo' }) ]: Object.keys(chart.data[ tag ]).reduce((assets: any, asset: string) => ({
              ...assets,
              [ i18next.t(asset, { ns: 'demo' }) ]: chart.data[tag][asset]
            }), {})
          }), {})
        }
      }

      default: {
        return chart
      }
    }
  }

  private updateDate = (date: string, pattern: string, monthsDelta: number) =>
    format(addMonths(parse(date, pattern, new Date()), monthsDelta), pattern)

  private isCategoryBalanceChart = (chart: any) =>
    chart.type === ChartType.PROPERTY_COMPARING_TO_ALLOCATION &&
    chart.metadata.Property === 'Category'

  private isCategoryDividendsChart = (chart: any) =>
    chart.type === ChartType.DIVIDENDS &&
    chart.metadata.Property === 'Category'

  private isAssetDividendsChart = (chart: any) =>
    chart.type === ChartType.DIVIDENDS &&
    chart.metadata.Property === 'Asset'

  private isTagsStructureChart = (chart: any) =>
    chart.type === ChartType.TAGS_STRUCTURE

  private isTreeMapChart = (chart: any) => chart.type === ChartType.TREE_MAP
}

