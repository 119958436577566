import { Link } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Link as RouterLink } from 'react-router-dom'
import { ReactComponent as AppLogoDark } from 'Shared/assets/AppLogoDark.svg'
import { Routes } from 'Shared/constants'

const AppLogoStyled = styled(AppLogoDark)(({ theme }) => ({
  width: 115,
  height: 36,
  cursor: 'pointer',

  [theme.breakpoints.down('mobileLarge')]: {
    width: 90,
  },
}))

interface Props {
  linkTo?: string
}

export const AppLogo = ({ linkTo = Routes.HOME }: Props) => (
  <Link
    underline="none"
    color="inherit"
    component={RouterLink}
    to={linkTo}
    display="flex"
  >
    <AppLogoStyled />
  </Link>
)
