import React from 'react'

export interface TypedDrawer<T> {
  open: (value: T) => void
  close: () => void
  isOpen: boolean
  value: T | null
}

export function useTypedDrawer<TSelected>(): TypedDrawer<TSelected> {
  const [ state, setState ] = React.useState<{ isOpen: boolean, value: TSelected | null }>({
    isOpen: false,
    value: null
  })

  const open = (value: TSelected) => {
    setState({ isOpen: true, value })
  }

  const close = () => {
    setState({ isOpen: false, value: null })
  }

  return {
    open,
    close,
    value: state.value,
    isOpen: state.isOpen,
  }
}
