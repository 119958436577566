import React from 'react'
import { Helmet } from 'react-helmet'
import { Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { container, Service } from 'Services/container'
import { RenewalPeriod, IProfileService } from 'Services/profile'
import { useAuthWithDemo } from 'Demo'
import { useSnackbar } from 'Snackbar'
import { GoogleTagManagerEvent, ErrorMessage } from 'Shared/constants'
import { selectErrorOrDefault } from 'Shared/utils'
import { ContentPaper } from 'Shared/components'
import { PremiumPricingCardConfiguration } from 'Pricing/pricing-constants'
import { PricingType } from 'Pricing/pricing-types'
import { BuildPricingPlan } from 'Pricing/pricing-utils'
import { usePromo } from 'Pricing/hooks'
import { PricingPageCards } from './PricingPageCards'
import { PricingPageHeader } from './PricingPageHeader'
import { UsePromoCode } from './UsePromoCode'

export const Pricing: React.FC = () => {
  const { t } = useTranslation()

  const { getAccessTokenWithDemo } = useAuthWithDemo()
  const { enqueueWarning, enqueueError } = useSnackbar()
  const { promo, applyPromoCode, clearPromo } = usePromo()

  const [ renewalPeriod, setRenewalPeriod ] = React.useState<RenewalPeriod>(RenewalPeriod.Year)
  const [ checkoutLoading, setCheckoutLoading ] = React.useState(false)

  const premiumPricingPlan = React.useMemo(
    () => BuildPricingPlan(
      PremiumPricingCardConfiguration.price.perMonth,
      PremiumPricingCardConfiguration.price.perYear,
      PremiumPricingCardConfiguration.price.defaultMonthlyDiscountedPrice,
      PremiumPricingCardConfiguration.price.defaultYearlyDiscountedPrice,
      promo),
    [ promo ])

  const createSubscription = async (selectedPeriod: RenewalPeriod) => {
    try {
      setCheckoutLoading(true)
      window.dataLayer.push({
        event: GoogleTagManagerEvent.subscriptionPlanSelect,
        plan: PricingType.Premium,
        period: selectedPeriod })

      const profileService = container.resolve<IProfileService>(Service.ProfileService)
      const token = await getAccessTokenWithDemo()

      const renewalPeriod = selectedPeriod
      const promoCode = premiumPricingPlan[renewalPeriod].periodPromoActive ? promo?.code ?? null : null
      const subscription = await profileService.createSubscription(token, { renewalPeriod, promoCode })

      window.location.href = subscription.checkoutUrl
    } catch (error: any) {
      const errorCodes = error?.response?.data?.errors ?? []
      const errorMessage = selectErrorOrDefault(errorCodes)

      if (errorMessage) {
        enqueueWarning(errorMessage.key, errorMessage.prop)
      } else {
        enqueueError(ErrorMessage.OperationFailure.key)
      }
    } finally {
      setCheckoutLoading(false)
    }
  }

  return (
    <main>
      <Helmet>
        <title>FIREkit - {t('app.navbar.routes.pricing')}</title>
      </Helmet>

      <ContentPaper elevation={0} sx={{ m: 2 }}>
        <Stack spacing={3}>
          <PricingPageHeader
            renewalPeriod={renewalPeriod}
            setRenewalPeriod={setRenewalPeriod}
            discountedPrices={premiumPricingPlan}
          />

          <Box>
            <PricingPageCards
              premiumPricingPlan={premiumPricingPlan}
              renewalPeriod={renewalPeriod}
              handlePremiumPlanSelected={createSubscription}
              disabled={checkoutLoading}
            />
          </Box>

          <UsePromoCode
            promo={promo}
            onPromoCodeApplied={applyPromoCode}
            onPromoCodeRemoved={clearPromo}
          />
        </Stack>
      </ContentPaper>
    </main>
  )
}
