import React from 'react'
import { groupBy } from 'lodash'
import { format } from 'date-fns'
import { Stack } from '@mui/material'

import { HistoryItem } from 'Services/history'
import { HistoryMessagesGroup } from './HistoryMessagesGroup'
import { FlatHistoryItem } from './FlatHistoryItem'

interface Props {
  historyItems: HistoryItem[]
}

export const FlatHistoryList: React.FC<Props> = ({ historyItems }) => {
  const groupedHistory = React.useMemo(
    () => groupBy(historyItems, i => {
      const createdAt = new Date(i.createdAt)
      return format(createdAt, 'dd MMMM yyyy')
    }),
    [ historyItems ])

  return (
    <Stack spacing={3}>
      {Object.keys(groupedHistory).map(key => (
        <HistoryMessagesGroup createdAt={key} key={key}>
          <Stack spacing={1}>
            {groupedHistory[key].map((item, idx) => (
              <FlatHistoryItem key={idx} history={item} />
            ))}
          </Stack>
        </HistoryMessagesGroup>
      ))}
    </Stack>
  )
}
