import React from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import fuzzysort from 'fuzzysort'

import { localizeError } from 'Shared/utils'
import { AutocompleteOption } from './NonForm-types'

interface AutocompleteProps {
  label: string
  options: AutocompleteOption[]
  groupOrder?: string[]
  value: string | null
  onChange?: (value: string | null) => void
  onBlur?: (e: any) => void
  error?: any
  placeholder?: string
  required?: boolean
  disabled?: boolean
  extraProps?: any
  name?: string
}

const OptionsLimit = 100

export const CustomAutocomplete: React.FC<AutocompleteProps> = ({
  label,
  options,
  groupOrder = [],
  value,
  onChange,
  onBlur,
  error,
  placeholder,
  required,
  disabled,
  extraProps,
  name,
}) => {
  const { t } = useTranslation()

  const autoCompleteAdoptedValue = React.useMemo(
    () => options.find(o => o.value === value) ?? null,
    [ value, options ]
  )

  const handleOnChange = (_: any, option: AutocompleteOption | null) => {
    if (onChange) {
      onChange(option?.value ?? null)
    }
  }

  const filterOptions = (options: AutocompleteOption[], state: any) => {
    if (!state.inputValue) {
      return options.slice(0, OptionsLimit)
    }

    return fuzzysort
      .go(state.inputValue, options, { keys: [ 'value', 'label' ] })
      .map(result => result.obj)
      .slice(0, OptionsLimit)
      .sort((x, y) => groupOrder.indexOf(x.group ?? '') - groupOrder.indexOf(y.group ?? ''))
  }

  return (
    <Autocomplete
      value={autoCompleteAdoptedValue}
      onChange={handleOnChange}
      options={options}
      filterOptions={filterOptions}
      renderInput={params => (
        <TextField
          {...params}
          data-testid={name}
          label={label}
          placeholder={placeholder}
          error={!!error}
          helperText={localizeError(error)}
          onBlur={onBlur}
          required={required}
        />
      )}
      groupBy={(option: AutocompleteOption) => option?.group}
      getOptionDisabled={(option: AutocompleteOption) => option?.disabled}
      noOptionsText={t('common.state.noOptions')}
      clearText={t('common.actions.clear')}
      size="small"
      disabled={disabled}
      fullWidth
      autoHighlight
      {...extraProps}
    />
  )
}
