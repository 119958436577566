import { Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'

import { TextPrimary } from 'Shared/components/Typography'

interface CheckBoxFieldProps {
  name: string
  label: string
  checked: boolean
  onChange: (event: any, checked: boolean) => void
}

const CheckBoxFieldBase: React.FC<CheckBoxFieldProps> = ({ name, label, checked, onChange }) => (
  <FormControlLabel
    control={(
      <Checkbox
        size="small"
        name={name}
        checked={checked}
        onChange={onChange}
      />
    )}
    label={<TextPrimary>{label}</TextPrimary>}
  />
)

export const CheckBoxField = React.memo(CheckBoxFieldBase)
