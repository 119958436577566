import React from 'react'

const HiddenModeName = 'HiddenMode'

enum HiddenModeState {
  Enabled = 'Enabled',
  Disabled = 'Disabled'
}

const transferHiddenModeState = (isEnabled: boolean) => isEnabled ? HiddenModeState.Enabled : HiddenModeState.Disabled

export const useHiddenMode = () => {
  const [ isHiddenModeEnabled, setIsHiddenModeEnabled ] = React.useState<boolean>(true)

  React.useEffect(() => {
    const hiddenModeStateFromSession = sessionStorage.getItem(HiddenModeName)

    if (hiddenModeStateFromSession === null) {
      sessionStorage.setItem(HiddenModeName, transferHiddenModeState(isHiddenModeEnabled))
    } else {
      setIsHiddenModeEnabled(hiddenModeStateFromSession === HiddenModeState.Enabled)
    }
  }, [])

  const setHiddenMode = (enabled: boolean) => {
    setIsHiddenModeEnabled(enabled)
    sessionStorage.setItem(HiddenModeName, transferHiddenModeState(enabled))
  }

  return {
    isHiddenModeEnabled,
    setHiddenMode,
    toggleHiddenMode: () => setHiddenMode(!isHiddenModeEnabled)
  }
}
