export interface ISharingService {
  getSharingsByMe(token: string): Promise<Sharing[]>
  getSharingsWithMe(token: string): Promise<Sharing[]>
  createSharing(token: string, sharingRequest: CreateSharingRequest): Promise<void>
  revokeSharing(token: string, key: string): Promise<void>
}

export enum SharingStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
}

export type Sharing = {
  key: string,
  byUsername: string,
  withUsername: string,
  allowEdit: boolean,
  status: SharingStatus,
}

export interface CreateSharingRequest {
  withUsername: string,
  allowEdit: boolean,
}
