import { FormikHelpers } from 'formik'

import { LocalizedError } from 'Shared/types'
import { selectErrorOrDefault } from 'Shared/utils/selectError'

interface BuildFormSubmitProps<TForm, TRequest, TResponse> {
  valuesToRequest: (values: TForm) => TRequest,
  requestAccessToken: () => Promise<string>,
  actionCall: (request: TRequest, accessToken: string) => Promise<TResponse>,
  onSuccessCall: (accessToken: string, values: TForm, response: TResponse) => Promise<void>,
  onErrorCall: (accessToken: string, values: TForm) => void
  onWarningCall: (accessToken: string, values: TForm, error: LocalizedError) => void
}

export function buildFormSubmit<TForm, TRequest, TResponse>({
  valuesToRequest,
  requestAccessToken,
  actionCall,
  onSuccessCall,
  onErrorCall,
  onWarningCall,
}: BuildFormSubmitProps<TForm, TRequest, TResponse>) {
  return async (values: TForm, helpers: FormikHelpers<TForm>) => {
    const request = valuesToRequest(values)
    const accessToken = await requestAccessToken()

    try {
      const response = await actionCall(request, accessToken)
      await onSuccessCall(accessToken, values, response)

      helpers.setStatus({ success: true })
      helpers.resetForm()
    } catch (error: any) {
      helpers.setStatus({ success: false })
      helpers.setSubmitting(false)

      const errorCodes = error?.response?.data?.errors ?? []
      const controlledError = selectErrorOrDefault(errorCodes)

      if (controlledError) {
        onWarningCall(accessToken, values, controlledError)
      } else {
        onErrorCall(accessToken, values)
      }
    }
  }
}
