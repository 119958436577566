import React from 'react'
import type { User } from '@auth0/auth0-react'
import Skeleton from '@mui/material/Skeleton'

import { ProfileAvatar } from 'Shared/components'

interface Props {
  user?: User
}

const UserAvatarBase: React.FC<Props> = ({ user }) => {
  if (!user) {
    return <Skeleton variant="circular" width={32} height={32} />
  }

  const { name, picture } = user

  return (
    <ProfileAvatar
      username={name ?? null}
      preferredName={name ?? null}
      picture={picture}
      outerProps={{ 'data-testid': 'userAvatar' }}
    />
  )
}

export const UserAvatar = React.memo(UserAvatarBase)
