import React, { PropsWithChildren, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Routes } from 'Shared/constants'

export const ScrollToTop: React.FC<PropsWithChildren> = ({ children }) => {
  const history = useHistory()

  const landingPaths = [ Routes.HOME, Routes.ADVISOR_HOME ]

  useEffect(() => {
    const unListen = history.listen(location => {
      if (!location.hash) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: landingPaths.includes(location.pathname) ? 'smooth' : 'auto' })
      }
    })

    return () => unListen()
  }, [])

  return <>{children}</>
}
