import React from 'react'
import { FormGroup, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Formik, FormikHelpers } from 'formik'

import { TextField, SubmitButton, CheckBoxField, StyledForm } from 'Shared/components'
import { FormState, FormFields } from './form-fields'
import { ProfileSharingEmptyItem } from './ProfileSharingEmptyItem'
import { ValidationSchema } from './ShareProfileSection-constants'

interface Props {
  onSubmit: (values: FormState, helpers: FormikHelpers<FormState>) => void
  usernameSpecified: boolean
}

export const CreateSharingForm: React.FC<Props> = ({ onSubmit, usernameSpecified }) => {
  const { t } = useTranslation()

  const initialValues = {
    withUsername: '',
    allowEdit: false
  }
  const [ showCreateSharing, setShowCreateSharing ] = React.useState(false)

  return (
    <Stack spacing={2}>
      <ProfileSharingEmptyItem
        ableToShare={usernameSpecified}
        onShare={() => setShowCreateSharing(true)}
      />

      {showCreateSharing && (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={ValidationSchema}>
          {formProps => (
            <StyledForm autoComplete="off">
              <FormGroup>
                <TextField
                  name={FormFields.withUsername}
                  label={t('app.navbar.settings.sharingSettings.shareToUsername-label')}
                  placeholder={t('app.navbar.settings.sharingSettings.shareToUsername-placeholder')}
                  prefix="@"
                />

                <CheckBoxField
                  name={FormFields.allowEdit}
                  label={t('app.navbar.settings.sharingSettings.allowEdit-label')}
                  checked={formProps.values.allowEdit}
                  onChange={formProps.handleChange}
                />
              </FormGroup>

              <SubmitButton formProps={formProps} />
            </StyledForm>
          )}
        </Formik>
      )}
    </Stack>
  )
}
