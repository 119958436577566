import { AdvisoryClient, AdvisoryClientTask, UpdateAdvisoryClientRequest } from 'Services/advisor'
import { HistoryItem } from 'Services/history'
import { Organization } from 'Services/organization'
import { Sharing } from 'Services/sharing'
import { ITypedAction } from 'Shared/types'
import { AdvisorPortalState, ClientsFilterState, HistoryFilterState } from './advisorPortal-types'
import { AdvisorPortalInitialState, AdvisorPortalActionTypes } from './advisorPortal-constants'

export const advisorPortal = (
  state: AdvisorPortalState = AdvisorPortalInitialState,
  action: ITypedAction<any>): AdvisorPortalState => {
  switch(action.type) {
    case AdvisorPortalActionTypes.GET_ADVISOR_PORTAL_SHARINGS_SUCCESS: {
      const sharings = action.payload as Sharing[]

      return { ...state, sharings }
    }

    case AdvisorPortalActionTypes.REMOVE_ADVISOR_PORTAL_SHARING: {
      const sharingKey = action.payload as string

      return {
        ...state,
        sharings: state.sharings.filter(sharing => sharing.key !== sharingKey),
      }
    }

    case AdvisorPortalActionTypes.GET_ADVISOR_PORTAL_CLIENTS: {
      return {
        ...state,
        clients: {
          ...state.clients,
          isLoading: true,
        }
      }
    }

    case AdvisorPortalActionTypes.GET_ADVISOR_PORTAL_CLIENTS_SUCCESS: {
      const clients = action.payload as AdvisoryClient[]

      return {
        ...state,
        clients: {
          list: clients,
          isLoading: false,
          initialLoadCompleted: true,
        },
      }
    }

    case AdvisorPortalActionTypes.GET_ADVISOR_PORTAL_CLIENTS_FAILURE: {
      return {
        ...state,
        clients: {
          ...state.clients,
          isLoading: false,
          initialLoadCompleted: true,
        }
      }
    }

    case AdvisorPortalActionTypes.REVOKE_ADVISOR_PORTAL_CLIENT_SUCCESS: {
      const sharingKey = action.payload as string

      return {
        ...state,
        clients: {
          ...state.clients,
          list: state.clients.list.filter(client => client.sharingKey !== sharingKey),
        }
      }
    }

    case AdvisorPortalActionTypes.EDIT_ADVISOR_PORTAL_CLIENT_SUCCESS: {
      const clientKey = action.payload.clientKey as string
      const client = action.payload.client as UpdateAdvisoryClientRequest

      const clientIdx = state.clients.list.findIndex(x => x.key === clientKey)

      return {
        ...state,
        clients: {
          ...state.clients,
          list: [
            ...state.clients.list.slice(0, clientIdx),
            {
              ...state.clients.list[ clientIdx ],
              ...client,
            },
            ...state.clients.list.slice(clientIdx + 1),
          ],
        }
      }
    }

    case AdvisorPortalActionTypes.GET_ADVISOR_CLIENTS_HISTORY: {
      return {
        ...state,
        clientsHistory: {
          ...state.clientsHistory,
          isLoading: true,
        }
      }
    }

    case AdvisorPortalActionTypes.GET_ADVISOR_CLIENTS_HISTORY_SUCCESS: {
      const clientsHistory = action.payload as HistoryItem[]

      return {
        ...state,
        clientsHistory: {
          list: clientsHistory,
          isLoading: false,
          initialLoadCompleted: true,
        }
      }
    }

    case AdvisorPortalActionTypes.GET_ADVISOR_CLIENTS_HISTORY_FAILURE: {
      return {
        ...state,
        clientsHistory: {
          ...state.clientsHistory,
          isLoading: false,
          initialLoadCompleted: true,
        }
      }
    }

    case AdvisorPortalActionTypes.REVOKE_ADVISOR_CLIENT_HISTORY_SUCCESS: {
      const username = action.payload as string

      return {
        ...state,
        clientsHistory: {
          ...state.clientsHistory,
          list: state.clientsHistory.list.filter(item => item.portfolioUsername !== username),
        }
      }
    }

    case AdvisorPortalActionTypes.GET_ADVISOR_PORTAL_ORGANIZATION_SUCCESS: {
      const organization = action.payload as Organization

      return { ...state, organization }
    }

    case AdvisorPortalActionTypes.USE_ADVISOR_PORTAL_ORGANIZATION_LICENCE: {
      return {
        ...state,
        organization: {
          ...state.organization,
          tierInformation: {
            ...state.organization.tierInformation,
            clientSeatsAvailable: state.organization.tierInformation.clientSeatsAvailable - 1,
            clientSeatsInUse: state.organization.tierInformation.clientSeatsInUse + 1,
          }
        }
      }
    }

    case AdvisorPortalActionTypes.REVOKE_ADVISOR_PORTAL_ORGANIZATION_LICENCE: {
      return {
        ...state,
        organization: {
          ...state.organization,
          tierInformation: {
            ...state.organization.tierInformation,
            clientSeatsAvailable: state.organization.tierInformation.clientSeatsAvailable + 1,
            clientSeatsInUse: state.organization.tierInformation.clientSeatsInUse - 1,
          }
        }
      }
    }

    case AdvisorPortalActionTypes.SET_ADVISOR_PORTAL_CLIENTS_FILTER: {
      const clientsFilter = action.payload as ClientsFilterState

      return { ...state, clientsFilter }
    }

    case AdvisorPortalActionTypes.SET_ADVISOR_PORTAL_HISTORY_FILTER: {
      const historyFilter = action.payload as HistoryFilterState

      return { ...state, historyFilter }
    }

    case AdvisorPortalActionTypes.CREATE_ADVISOR_PORTAL_CLIENT_TASK: {
      const clientKey = action.payload.clientKey as string
      const task = action.payload.task as AdvisoryClientTask

      const clientIdx = state.clients.list.findIndex(x => x.key === clientKey)

      return {
        ...state,
        clients: {
          ...state.clients,
          list: [
            ...state.clients.list.slice(0, clientIdx),
            {
              ...state.clients.list[clientIdx],
              tasks: [
                ...state.clients.list[clientIdx].tasks,
                task
              ]
            },
            ...state.clients.list.slice(clientIdx + 1)
          ]
        }
      }
    }

    case AdvisorPortalActionTypes.UPDATE_ADVISOR_PORTAL_CLIENT_TASK: {
      const clientKey = action.payload.clientKey as string
      const task = action.payload.task as AdvisoryClientTask

      const clientIdx = state.clients.list.findIndex(x => x.key === clientKey)
      const taskIdx = state.clients.list[clientIdx].tasks.findIndex(x => x.key === task.key)

      return {
        ...state,
        clients: {
          ...state.clients,
          list: [
            ...state.clients.list.slice(0, clientIdx),
            {
              ...state.clients.list[clientIdx],
              tasks: [
                ...state.clients.list[clientIdx].tasks.slice(0, taskIdx),
                task,
                ...state.clients.list[clientIdx].tasks.slice(taskIdx + 1),
              ]
            },
            ...state.clients.list.slice(clientIdx + 1)
          ]
        }
      }
    }

    case AdvisorPortalActionTypes.DELETE_ADVISOR_PORTAL_CLIENT_TASK: {
      const clientKey = action.payload.clientKey as string
      const taskKey = action.payload.taskKey as string

      const clientIdx = state.clients.list.findIndex(x => x.key === clientKey)

      return {
        ...state,
        clients: {
          ...state.clients,
          list: [
            ...state.clients.list.slice(0, clientIdx),
            {
              ...state.clients.list[clientIdx],
              tasks: state.clients.list[clientIdx].tasks.filter(x => x.key !== taskKey),
            },
            ...state.clients.list.slice(clientIdx + 1)
          ]
        }
      }
    }

    default:
      return state
  }
}
