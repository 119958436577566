import React, { useContext } from 'react'

import { SettingsContext } from 'App/Settings'
import { Permission, RoleName } from 'Integration/authorization/authorization-types'
import { extractAuthorizationProfile } from 'Integration/authorization/authorization-domain'

export const useAuthorization = () => {
  const { profile } = useContext(SettingsContext)
  const authorizationProfile = React.useMemo(() => extractAuthorizationProfile(profile), [ profile ])

  const hasAccess = (...permissions: Permission[]) => permissions
    .some(permission => authorizationProfile.permissions.includes(permission))

  const isInRole = (role: RoleName) => authorizationProfile.name === role

  return {
    hasAccess,
    isInRole,
    authorizationProfile
  }
}
