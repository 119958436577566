export interface Render {
  (value: string): string
}

export interface SortComparer {
  (x: string, y: string): number
}

export enum ColorProvider {
  Class = 'Class',
  Risk = 'Risk',
  Liquidity = 'Liquidity',
  Term = 'Term',
  Category = 'Category',
}

export enum LabelProvider {
  Class = 'Class',
  Risk = 'Risk',
  Liquidity = 'Liquidity',
  Term = 'Term',
  Country = 'Country',
  Region = 'Region',
}

export enum SortingProvider {
  Class = 'Class',
  Risk = 'Risk',
  Liquidity = 'Liquidity',
  Term = 'Term',
}
