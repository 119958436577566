import { isBefore, isAfter, isEqual, startOfDay, add } from 'date-fns'

import { DueType } from './Task-types'

export const getTaskDueType = (dueDate: Date, completed: boolean): DueType => {
  if (completed) {
    return DueType.Completed
  }

  const today = startOfDay(new Date())
  if (isBefore(dueDate, today)) {
    return DueType.PastDue
  }

  const next7Days = add(today, { days: 7 })
  if ((isAfter(dueDate, today) || isEqual(dueDate, today)) && isBefore(dueDate, next7Days)) {
    return DueType.Soon
  }

  return DueType.Later
}
