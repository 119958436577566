import { container, Service } from 'Services/container'
import { IExchangeService, UnitType } from 'Services/exchange'
import { useAuthWithDemo } from 'Demo'

export const useExchangeRate = () => {
  const { getAccessTokenWithDemo } = useAuthWithDemo()

  const getExchangeRate = async (from: string, to: string) => {
    const accessToken = await getAccessTokenWithDemo()
    const exchangeService = container.resolve<IExchangeService>(Service.ExchangeService)

    return await exchangeService.getExchangeRate(accessToken, from, to)
  }

  const getUnit = async (unitType: UnitType, symbol: string) => {
    const accessToken = await getAccessTokenWithDemo()
    const exchangeService = container.resolve<IExchangeService>(Service.ExchangeService)

    return await exchangeService.getUnitDetails(accessToken, unitType, symbol)
  }

  return {
    getExchangeRate,
    getUnit,
  }
}
