import React from 'react'
import { useField } from 'formik'
import { Autocomplete, TextField } from '@mui/material'

import { Tag } from 'Shared/components/Tag'
import { localizeError } from 'Shared/utils'

interface Props {
  name: string
  label: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  options?: string[]
}

export const FormikTagsAutocomplete: React.FC<Props> = props => {
  const [ field, meta, helpers ] = useField<string[]>(props.name)
  const error = meta.touched && meta.error

  const setTouched = () => {
    helpers.setTouched(true)
  }

  const handleTagChange = (event: React.ChangeEvent<{}>, value: string[]) => {
    helpers.setValue(value)
  }

  const optionExists = (option: string, value: string) =>
    option.trim() === '' ||
    option.trim().toLowerCase() === value.trim().toLowerCase()

  return (
    <Autocomplete
      multiple
      freeSolo
      autoSelect
      fullWidth
      options={props.options ?? []}
      isOptionEqualToValue={optionExists}
      value={field.value}
      onChange={handleTagChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Tag
            {...getTagProps({ index })}
            key={index}
            label={option}
            size="small"
          />
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          label={props.label}
          placeholder={props.placeholder}
          required={props.required}
          onBlur={setTouched}
          error={!!error}
          helperText={localizeError(error)}
          size="small"
          fullWidth
          inputProps={{
            ...params.inputProps,
            'data-testid': props.name
          }}
        />
      )}
    />
  )
}
