import React from 'react'

import { UnitType } from 'Services/exchange'
import { AssetAllocationKeySelectorProps } from 'App/AssetAllocation/assetAllocation-types'
import { AssetAllocationTickerSelector } from './AssetAllocationTickerSelector'

export const AssetAllocationCryptoSelectorAdapter: React.FC<AssetAllocationKeySelectorProps> = ({ onKeyAdded, existingKeys }) => (
  <AssetAllocationTickerSelector
    unitType={UnitType.CryptoCurrency}
    onTickerAdded={onKeyAdded}
    tickers={existingKeys}
  />
)
