import React from 'react'
import { SubTextPrimary2 } from 'Shared/components'
import { WithTranslation, withTranslation } from 'react-i18next'
import { styledWithCustomProp } from 'config/theme'

const CopyrightTextStyled = styledWithCustomProp(SubTextPrimary2, 'light')<{ light?: boolean }>(({ theme, light }) => ({
  color: light ? theme.palette.blue.blue2 : theme.palette.dark.dark2
}))

const CopyrightTextBase: React.FC<WithTranslation & { light?: boolean }> = ({ t, light }) => (
  <CopyrightTextStyled light={light}>
    {t('app.footer.copyright', { year: new Date().getFullYear() })}
  </CopyrightTextStyled>
)

export const CopyrightText = withTranslation()(CopyrightTextBase)
