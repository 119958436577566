import React from 'react'
import { FormikHelpers } from 'formik'

import { Sharing } from 'Services/sharing'
import { FormState } from './form-fields'
import { ProfileSharingItem } from './ProfileSharingItem'
import { CreateSharingForm } from './CreateSharingForm'

interface Props {
  sharings: Sharing[]
  onRevokeAccess: (username: string, key: string) => void
  onCreateSharing: (values: FormState, helpers: FormikHelpers<FormState>) => void
  usernameSpecified: boolean
}

export const ShareProfileSectionContent: React.FC<Props> = ({
  sharings,
  onCreateSharing,
  onRevokeAccess,
  usernameSpecified,
}) => {
  const hasSharings = !!sharings && sharings.length > 0

  if (hasSharings) {
    return (
      <>
        {sharings.map(sharing => (
          <ProfileSharingItem
            key={sharing.key}
            sharing={sharing}
            onRevokeAccess={onRevokeAccess}
          />
        ))}
      </>
    )
  }

  return <CreateSharingForm onSubmit={onCreateSharing} usernameSpecified={usernameSpecified} />
}
