import React, { ChangeEvent } from 'react'
import {
  TextField,
  InputAdornment,
  ClickAwayListener,
  ListItem,
  ListItemButton,
  Box,
  ListItemText,
  ListSubheader,
  List
} from '@mui/material'
import type { TextFieldProps } from '@mui/material'

import { Money } from 'Shared/types'
import { ContentPaper } from 'Shared/components/Papers'
import { ConfiguredNumberFormat } from 'Shared/components/ConfiguredNumberFormat'
import { TextSecondary } from 'Shared/components/Typography'
import * as S from './MoneyField.styles'
import { NoOptions } from './NoOptions'

export interface FormatProps {
  allowNegative?: boolean
  decimalScale?: number
  maxLength?: number
}

export interface CurrencyOption {
  group: string
  currencies: string[]
}

export interface CustomMoneyFieldProps {
  currency: string
  onCurrencyChange: (currency: string) => void
  currencyOptions: CurrencyOption[]
  currencyDisabled?: boolean

  formatProps?: FormatProps
}

type Props = TextFieldProps & CustomMoneyFieldProps

export const MoneyField: React.FC<Props> = (props: Props) => {
  const {
    currency,
    onCurrencyChange,
    currencyOptions,
    currencyDisabled = false,
    formatProps = null,
    ...textFieldProps
  } = props

  const moneyFieldRef = React.useRef<HTMLDivElement>(null)
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const [ currencySearch, setCurrencySearch ] = React.useState('')

  const filteredOptions = React.useMemo(
    () => currencyOptions
      .map(option => ({
        group: option.group,
        currencies: option.currencies.filter(currency => currency.toLowerCase().includes(currencySearch))
      }))
      .filter(option => option.currencies.length > 0),
    [ currencySearch, currencyOptions ])

  const openCurrencySelection = () => setAnchorEl(moneyFieldRef.current)
  const closeCurrencySelection = () => setAnchorEl(null)

  const handleCurrencyButtonClick = () => {
    openCurrencySelection()
    setCurrencySearch('')
  }

  const handleCurrencyItemClick = (currency: string) => {
    onCurrencyChange(currency)
    closeCurrencySelection()
  }

  const handleOnCurrencySearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrencySearch(event.target.value.toLowerCase())
  }

  return (
    <>
      <TextField
        {...textFieldProps}
        size="small"
        variant="outlined"
        ref={moneyFieldRef}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <S.CurrencyButton
                size="small"
                variant="text"
                color="primary"
                onClick={handleCurrencyButtonClick}
                disabled={textFieldProps.disabled || currencyDisabled}
              >
                {currency}
              </S.CurrencyButton>
            </InputAdornment>
          ),
          inputComponent: ConfiguredNumberFormat as any,
          inputProps: {
            allowNegative: formatProps?.allowNegative ?? false,
            decimalScale: formatProps?.decimalScale ?? Money.RegularDecimalScale,
            maxLength: formatProps?.maxLength,
          }
        }}
        fullWidth
      />

      <S.CurrencySelectionPopper
        open={!!anchorEl}
        anchorEl={anchorEl}
        width={anchorEl?.clientWidth}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={closeCurrencySelection}>
          <ContentPaper sx={{ p: 0 }}>
            <Box sx={{ p: 2 }}>
              <TextField
                variant="standard"
                value={currencySearch}
                onChange={handleOnCurrencySearchChange}
                fullWidth
                autoFocus
              />
            </Box>

            <S.CurrenciesList dense>
              {filteredOptions.length > 0 ? filteredOptions.map(option => (
                <li key={option.group}>
                  <List dense>
                    <ListSubheader disableSticky>{option.group}</ListSubheader>
                    {option.currencies.map(currency => (
                      <ListItem key={currency} disablePadding>
                        <ListItemButton onClick={() => handleCurrencyItemClick(currency)}>
                          <ListItemText>{currency}</ListItemText>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </li>
              )) : (
                <ListItem>
                  <ListItemText>
                    <TextSecondary>
                      <NoOptions />
                    </TextSecondary>
                  </ListItemText>
                </ListItem>
              )}
            </S.CurrenciesList>
          </ContentPaper>
        </ClickAwayListener>
      </S.CurrencySelectionPopper>
    </>
  )
}
