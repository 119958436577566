import i18next from 'config/i18n'

import type { ValidationError } from 'Shared/types'

const buildErrorMessage = (error: any): string => {
  if (error === null || error === undefined) {
    return ''
  }

  if (typeof error === 'string') {
    return i18next.t(error)
  }

  const errorObject = error as ValidationError

  if (errorObject) {
    return i18next.t(errorObject.key, errorObject.prop).toString()
  }

  return ''
}

export const localizeError = (error: any): string => {
  if (Array.isArray(error)) {
    const errorObject = error
      .filter(x => !!x)
      .shift()

    return buildErrorMessage(errorObject)
  }

  return buildErrorMessage(error)
}

