import { Holding, HOLDING_TYPE } from 'Services/holdings'
import { ValidationLimitation } from 'Shared/constants'
import { BaseSpecification, Nullable, SpecificationResult } from 'Shared/types'

export class ArchiveHoldingSpecification extends BaseSpecification<Holding> {
  constructor(holding: Holding) {
    super(holding)
  }

  public verify(): SpecificationResult {
    if (this.hasBalance()) {
      return this.invalid('holdings.manage.archive-warnNonZeroBalance')
    }

    if (this.hasItems()) {
      return this.invalid('holdings.manage.archive-warnNonEmptyItems')
    }

    return this.valid()
  }

  public hasBalance() {
    const balance = this.value.type === HOLDING_TYPE.Custom ?
      this.value.balance :
      this.value.baseCurrencyCashNetWorth

    return ArchiveHoldingSpecification.compareBalanceWithDeviation(balance)
  }

  public hasItems() {
    return this.value.items.length !== 0
  }

  private static compareBalanceWithDeviation(balance: Nullable<number>) {
    return Math.abs(balance ?? 0) > ValidationLimitation.MinCustomOperationDeviation
  }
}
