import { Avatar, Theme } from '@mui/material'
import { styled, SxProps } from '@mui/material/styles'
import React from 'react'

import { stringToColor } from 'Shared/utils'
import { Nullable } from 'Shared/types'
import { DefaultUsername } from 'Shared/constants'

interface Props {
  username: Nullable<string>
  preferredName: Nullable<string>
  picture?: Nullable<string>
  sx?: SxProps<Theme>
  outerProps?: any
}

const StyledAvatar = styled(Avatar)({
  width: 32,
  height: 32
})

export const ProfileAvatar: React.FC<Props> = ({
  username,
  preferredName,
  picture,
  sx = {},
  outerProps = {}
}) => {
  if (picture) {
    return <StyledAvatar sx={sx} src={picture} {...outerProps} />
  }

  const profileSymbol = React.useMemo(
    () => (preferredName || username || DefaultUsername)
      .substring(0, 1)
      .toUpperCase(),
    [ username, preferredName ])

  const profileBackgroundColor = React.useMemo(
    () => stringToColor(username || DefaultUsername),
    [ username ])

  return (
    <StyledAvatar sx={{ backgroundColor: profileBackgroundColor, ...sx }} {...outerProps}>
      {profileSymbol}
    </StyledAvatar>
  )
}
