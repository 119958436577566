import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { format as formatDate } from 'date-fns'
import { isDate, isNumber } from 'lodash'

import { SupportedLanguage, languageService } from 'config/i18n-types'

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    debug: process.env.REACT_APP_ENV !== 'production',
    fallbackLng: SupportedLanguage.EN,
    supportedLngs: Object.values(SupportedLanguage),
    nonExplicitSupportedLngs: true,
    ns: [ 'translation', 'demo', 'notification' ],
    defaultNS: 'translation',
    returnNull: false,
    detection: {
      order: [ 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator' ],
      lookupQuerystring: 'lng',
      lookupCookie: 'firekit-i18next',
      lookupLocalStorage: 'firekit-i18nextLng',
      lookupSessionStorage: 'firekit-i18nextLng',
      caches: [ 'localStorage' ],
      excludeCacheFor: [ 'cimode' ],
    },
    interpolation: {
      escapeValue: false,
      format: (value, format) => {
        const language = i18next.resolvedLanguage as SupportedLanguage

        if (!format || !language) {
          return value
        }

        const locale = languageService.getLocale(language)

        switch (true) {
          case format === 'number' && isNumber(value): {
            return Number(value).toLocaleString(locale.code, { maximumFractionDigits: 6 })
          }

          case format === 'percent' && isNumber(value): {
            return Number(value).toLocaleString(locale.code, { maximumFractionDigits: 2 })
          }

          case isDate(value): {
            return formatDate(value, format, { locale })
          }

          default: {
            return value
          }
        }
      }
    },
    react: {
      useSuspense: false
    }
  })

i18next.on('languageChanged', lng => {
  document.documentElement.setAttribute('lang', lng)
})

export default i18next
