/* eslint-disable max-len */
import { SVGProps } from 'react'

export const TelegramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    fill="none"
  >
    <path
      d="M7.56269 11.5908L15.29 18.3908C15.3794 18.4695 15.4877 18.5236 15.6043 18.5478C15.7209 18.572 15.8418 18.5655 15.9551 18.529C16.0685 18.4924 16.1704 18.4271 16.2509 18.3393C16.3314 18.2515 16.3877 18.1443 16.4143 18.0282L19.6467 3.9231C19.6746 3.80115 19.6688 3.67388 19.6297 3.55501C19.5907 3.43614 19.52 3.33018 19.4252 3.24855C19.3303 3.16691 19.215 3.11269 19.0917 3.09174C18.9684 3.07079 18.8416 3.08389 18.7252 3.12964L2.86482 9.36049C2.7266 9.4148 2.60967 9.5124 2.53154 9.6387C2.45341 9.76499 2.41828 9.9132 2.4314 10.0611C2.44452 10.2091 2.50519 10.3488 2.60433 10.4593C2.70347 10.5699 2.83575 10.6454 2.98138 10.6745L7.56269 11.5908Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5625 11.5905L19.2596 3.14258"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4217 14.9869L8.73614 17.6725C8.63999 17.7686 8.51749 17.8341 8.38413 17.8606C8.25076 17.8872 8.11253 17.8735 7.98691 17.8215C7.86128 17.7695 7.75391 17.6814 7.67837 17.5683C7.60282 17.4552 7.5625 17.3223 7.5625 17.1863V11.5908"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
