export const SnackbarActions = {
  ENQUEUE_SUCCESS_SNACKBAR: 'ENQUEUE_SUCCESS_SNACKBAR',
  ENQUEUE_WARNING_SNACKBAR: 'ENQUEUE_WARNING_SNACKBAR',
  ENQUEUE_ERROR_SNACKBAR: 'ENQUEUE_ERROR_SNACKBAR',
  CLOSE_SNACKBAR: 'CLOSE_SNACKBAR',
  REMOVE_SNACKBAR: 'REMOVE_SNACKBAR',
  SET_SNACKBAR: 'SET_SNACKBAR',
  ADD_SNACKBAR: 'ADD_SNACKBAR',
  UPDATE_SNACKBAR: 'UPDATE_SNACKBAR',
}
