import * as Yup from 'yup'

import { ErrorMessage as Err, ValidationLimitation } from 'Shared/constants'

const AllocationsShape = (allocations: string[]) => allocations
  .reduce((shape, allocation) => ({
    ...shape,
    [ allocation ]: Yup
      .number()
      .typeError(Err.OnlyNumbers)
      .max(ValidationLimitation.MaxPercentValue, Err.NotMoreThan(ValidationLimitation.MaxPercentValue))
      .min(ValidationLimitation.MinPercentValue, Err.NotLessThan(ValidationLimitation.MinPercentValue)),
  }), {})

export const AssetAllocationSchema = (allocations: string[]) => Yup
  .object()
  .shape(AllocationsShape(allocations))
  .test({
    name: 'hundred-percent-in-total',
    message: '',
    exclusive: true,
    test: (values: any, context) => {
      const categorizedAllocation = allocations
        .reduce((allocated, allocation) => allocated + (+values[allocation] || 0), 0)

      if (categorizedAllocation < 0 || categorizedAllocation > 100) {
        // NOTE: Overhead, since formik does not work with global errors
        return context.createError({
          message: '_',
          path: '_summary'
        })
      }

      return true
    }
  })
