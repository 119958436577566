import React from 'react'
import { styled } from '@mui/material/styles'
import { Stack, Typography } from '@mui/material'
import { Trans, withTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { AppLogoWhite, BMCIcon, EnvelopIcon, InstagramIcon, TelegramIcon } from 'Shared/assets'
import { TextMedium } from 'Shared/components'
import { ExternalLinks, Routes } from 'Shared/constants'
import { IconLink } from './IconLink'

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(5)} 0`,
  borderBottom: `1px solid ${theme.palette.common.white}4D`,
  justifyContent: 'space-between',
  flexWrap: 'wrap',

  [theme.breakpoints.down('mobileLandscape')]: {
    padding: `${theme.spacing(4)} 0`,
  },

  [theme.breakpoints.up('tabletLandscape')]: {
    padding: `${theme.spacing(5)} 0`,
  }
}))

const BrandSection = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  marginRight: theme.spacing(4),
}))

const AppLogoStyled = styled(AppLogoWhite)(({ theme }) => ({
  width: 131,
  height: 'auto',
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(2),
  cursor: 'pointer',
}))

const SupportSection = styled(Stack)(({ theme }) => ({
  maxWidth: 400,

  [theme.breakpoints.up('tabletLandscape')]: {
    maxWidth: 600,
  }
}))

const FooterBrand = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 20,
  fontWeight: 800,
  lineHeight: '26px',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2.25),

  [theme.breakpoints.up('tabletLandscape')]: {
    fontSize: 40,
    lineHeight: '52px',
    marginBottom: theme.spacing(3),
    marginTop: 0,
  },
}))

const SupportText = styled(TextMedium)(({ theme }) => ({
  color: theme.palette.common.white,
}))

const IconsGroup = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  color: theme.palette.common.white,
}))

export const ExtendedFooter = withTranslation()(({ t, i18n }) => (
  <Wrapper direction={{ sm: 'column', md: 'row' }}>
    <BrandSection>
      <RouterLink to={Routes.HOME}>
        <AppLogoStyled />
      </RouterLink>
      <IconsGroup>
        <IconLink href={ExternalLinks.CONTACT.BMC}>
          <BMCIcon />
        </IconLink>
        <IconLink href={ExternalLinks.CONTACT.TELEGRAM_NEWS}>
          <TelegramIcon />
        </IconLink>
        <IconLink href={ExternalLinks.CONTACT.INSTAGRAM}>
          <InstagramIcon />
        </IconLink>
        <IconLink href={ExternalLinks.MAILTO}>
          <EnvelopIcon />
        </IconLink>
      </IconsGroup>
    </BrandSection>
    <SupportSection>
      <FooterBrand>{t('app.footer.description.title')}</FooterBrand>
      <SupportText>
        <strong><Trans i18nKey="app.footer.description.subTitle" /></strong>
      </SupportText>
    </SupportSection>
  </Wrapper>
))
