import React, { PropsWithChildren } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

export const AuthenticationGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  React.useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect()
    }
  }, [ isAuthenticated, loginWithRedirect ])

  return <>{children}</>
}
