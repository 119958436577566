import type { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { BaseService } from 'Shared/api'
import { Dictionary } from 'Shared/types'
import { ISurveysService } from './survey-types'

export class SurveysService extends BaseService implements ISurveysService {
  public saveSurvey = (token: string, name: string, data: Dictionary<string>) => axios
    .post('surveys/dynamic', { payload: { name, data } }, {
      headers: { ...super.buildAuthHeader(token) }
    })
    .then((response: AxiosResponse) => response?.data)
}
