import { AppBar, Stack, Toolbar } from '@mui/material'
import React from 'react'

import { AppLogo, DemoButton } from './components'
import { LoginButtonLanding } from './components-landing-shared'

export const PublicPageNavigationBar: React.FC = () => (
  <AppBar position="absolute" color="inherit" elevation={0}>
    <Toolbar sx={{ justifyContent: 'space-between' }}>
      <AppLogo />

      <Stack direction="row" spacing={1}>
        <DemoButton />
        <LoginButtonLanding />
      </Stack>
    </Toolbar>
  </AppBar>
)
