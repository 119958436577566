import React from 'react'

import { FilterButton } from './FilterButton'
import { FilterOptions } from './FilterOptions'
import { BaseOptionsPopper } from './BaseOptionsPopper'

interface FilterProps {
  id: string
  label: string
  options: { label: string, value: string, blockAccess?: boolean }[]
  selectedValues: string[]
  onChange: (values: string[]) => void
  PremiumPromoContent?: JSX.Element
}

export const Filter: React.FC<FilterProps> = ({ id, label, options, selectedValues, onChange, PremiumPromoContent }) => {
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)

  const openOptionsList = (event: any) => setAnchorEl(event.currentTarget)
  const closeOptionsList = () => setAnchorEl(null)

  const notifySelectedOptionsChanged = (value: string) => {
    const index = selectedValues.indexOf(value)

    if (index === -1) {
      onChange([ ...selectedValues, value ])
    } else {
      onChange([
        ...selectedValues.slice(0, index),
        ...selectedValues.slice(index + 1)
      ])
    }
  }

  return (
    <>
      <FilterButton
        label={label}
        onClick={openOptionsList}
        expanded={!!anchorEl}
        numberOfItemsSelected={selectedValues.length}
        disabled={options.length === 0}
      />

      <BaseOptionsPopper anchorEl={anchorEl} onClose={closeOptionsList}>
        <FilterOptions
          groupId={id}
          options={options}
          selectedValues={selectedValues}
          onOptionClick={notifySelectedOptionsChanged}
          PremiumPromoContent={PremiumPromoContent}
        />
      </BaseOptionsPopper>
    </>
  )
}
