import React from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonLink, SubTextSecondary1 } from 'Shared/components'
import { SubscriptionPlanContainer } from './SubscriptionPlanContainer'

interface Props {
  nextBillingDate: Date
  inGracePeriod: boolean
  onCancelSubscriptionClick: () => void
}

export const PaidSubscription: React.FC<Props> = ({ nextBillingDate, inGracePeriod, onCancelSubscriptionClick }) => {
  const { t } = useTranslation()

  return (
    <SubscriptionPlanContainer isPremium>
      {inGracePeriod ? (
        <SubTextSecondary1>
          {t('app.navbar.settings.subscription.waitingForPayment')}
        </SubTextSecondary1>
      ): (
        <SubTextSecondary1>
          {t('app.navbar.settings.subscription.nextBillingDate', { date: nextBillingDate })}
        </SubTextSecondary1>
      )}

      <SubTextSecondary1>
        <ButtonLink onClick={onCancelSubscriptionClick}>
          {t('app.navbar.settings.subscription.cancel')}
        </ButtonLink>
      </SubTextSecondary1>
    </SubscriptionPlanContainer>
  )
}
