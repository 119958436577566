import React from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker as DatePickerMUI, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ukUA, enUS } from '@mui/x-date-pickers/locales'

import { SupportedLanguage, languageService } from 'config/i18n-types'
import { muiMinDate } from 'Shared/constants'
import { localizeError } from 'Shared/utils'

interface Props {
  label: string
  value: Date | null
  onChange: (value: Date | null) => void
  required?: boolean
  disabled?: boolean
  fullWidth?: boolean
  error?: any
  extraProps?: any
  disableFuture?: boolean
  disablePast?: boolean
}

export const CustomDatePicker: React.FC<Props> = (props: Props) => {
  const { i18n } = useTranslation()
  const {
    label,
    value,
    onChange,
    required,
    disabled,
    fullWidth,
    error,
    extraProps,
    disableFuture,
    disablePast
  } = props

  const locale = React.useMemo(() => {
    const language = i18n.resolvedLanguage as SupportedLanguage
    return languageService.getLocale(language)
  }, [ i18n.resolvedLanguage ])

  const localeText = React.useMemo(
    () => i18n.resolvedLanguage === SupportedLanguage.UK ?
      ukUA.components.MuiLocalizationProvider.defaultProps.localeText :
      enUS.components.MuiLocalizationProvider.defaultProps.localeText,
    [ i18n.resolvedLanguage ])

  // TODO: Move LocalizationProvider to top level component
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale} localeText={localeText}>
      <DatePickerMUI
        label={label}
        value={value}
        minDate={muiMinDate}
        disabled={disabled}
        onChange={newValue => onChange(newValue)}
        data-testid="date-picker"
        disableFuture={disableFuture}
        disablePast={disablePast}
        slotProps={{
          textField: {
            variant: 'outlined',
            size: 'small',
            fullWidth: fullWidth,
            required: required,
            error: !!error,
            helperText: localizeError(error),
            ...extraProps
          }
        }}
      />
    </LocalizationProvider>
  )
}
