/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable max-len */
import { SVGProps } from 'react'

export const AppLogoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 29.92 32" {...props}>
    <path d="M8.81126 17.7523C9.68227 16.6886 10.6263 15.6529 11.6491 14.6564C12.7224 13.6151 13.835 12.613 14.9645 11.6444L0 10.5919V16.3191L8.81126 17.7523Z" fill="url(#paint0_linear_626_20603)" />
    <path d="M14.9702 11.6445L10.1712 4.86482L0 10.592L14.9702 11.6445Z" fill="url(#paint1_linear_626_20603)" />
    <path d="M1.62963 1.52835C0.623757 2.50247 0 3.86848 0 5.37445V10.5921L10.1712 4.86499L1.62963 1.52835Z" fill="url(#paint2_linear_626_20603)" />
    <path d="M14.9704 11.6447L18.8646 0H17.6789L10.1714 4.865L14.9704 11.6447Z" fill="url(#paint3_linear_626_20603)" />
    <path d="M5.39441 0C3.93336 0 2.60156 0.582233 1.62939 1.52836L10.1709 4.865L17.6785 0H5.39441Z" fill="url(#paint4_linear_626_20603)" />
    <path d="M29.249 0H19.8364L23.3036 5.29607C25.838 3.55498 28.0689 2.17217 29.603 1.25404C30.1987 0.90134 29.9402 0 29.249 0Z" fill="url(#paint5_linear_626_20603)" />
    <path d="M14.9702 11.6447C17.7743 9.24855 20.7189 7.07637 23.3038 5.30168L19.8422 0H18.842L14.9702 11.6447Z" fill="url(#paint6_linear_626_20603)" />
    <path d="M10.2105 16.0952L5.68683 27.0735L5.77112 27.0119C7.94022 25.1421 10.2386 23.5521 12.3683 22.2477L10.2105 16.0952Z" fill="url(#paint7_linear_626_20603)" />
    <path d="M10.2105 16.0952C3.83245 13.9622 0 10.592 0 10.592L7.92901 21.6319L10.2105 16.0952Z" fill="url(#paint8_linear_626_20603)" />
    <path d="M0 10.592V21.7943L7.92901 21.6319L0 10.592Z" fill="url(#paint9_linear_626_20603)" />
    <path d="M7.92901 21.6319L0 21.7943L5.68686 27.0735L7.92901 21.6319Z" fill="url(#paint10_linear_626_20603)" />
    <path d="M1.18008 31.7537C2.52874 30.0462 4.06284 28.4955 5.67562 27.0959L5.68686 27.0735L0 21.7943V31.3282C0 31.9496 0.792339 32.2407 1.18008 31.7537Z" fill="url(#paint11_linear_626_20603)" />
    <path d="M12.3964 22.2365C15.8805 20.1092 18.9206 18.7432 20.2299 18.161C20.4153 18.077 20.3535 17.8083 20.1512 17.8083C17.6618 17.7747 15.3466 17.45 13.2393 16.9573L12.3964 22.2365Z" fill="url(#paint12_linear_626_20603)" />
    <path d="M13.2394 16.9517C12.1773 16.7054 11.1658 16.4087 10.2105 16.0896L12.3684 22.2477C12.3796 22.2421 12.3908 22.2365 12.4021 22.2253L13.2394 16.9517Z" fill="url(#paint13_linear_626_20603)" />
    <path d="M10.2105 16.0952L7.92902 21.6319L12.3627 22.2533L10.2105 16.0952Z" fill="url(#paint14_linear_626_20603)" />
    <defs>
      <linearGradient id="paint0_linear_626_20603" x1="14.9477" y1="11.6724" x2="-4.42361" y2="18.4356" gradientUnits="userSpaceOnUse">
        <stop offset="0.120887" stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint1_linear_626_20603" x1="20.3704" y1="13.7999" x2="1.87506" y2="6.05277" gradientUnits="userSpaceOnUse">
        <stop offset="0.27714" stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint2_linear_626_20603" x1="18.5441" y1="3.55498" x2="-2.74979" y2="6.85412" gradientUnits="userSpaceOnUse">
        <stop offset="0.247925" stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint3_linear_626_20603" x1="24.0514" y1="7.64181" x2="8.40963" y2="3.30091" gradientUnits="userSpaceOnUse">
        <stop offset="0.261515" stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint4_linear_626_20603" x1="23.1518" y1="-1.11968" x2="1.6325" y2="1.89758" gradientUnits="userSpaceOnUse">
        <stop offset="0.324016" stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint5_linear_626_20603" x1="31.2439" y1="-1.06369" x2="20.2991" y2="3.72502" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint6_linear_626_20603" x1="12.5033" y1="5.82233" x2="23.3027" y2="5.80441" gradientUnits="userSpaceOnUse">
        <stop offset="0.324631" stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint7_linear_626_20603" x1="12.3627" y1="18.9223" x2="5.70802" y2="27.9316" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="0.841586" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint8_linear_626_20603" x1="-6.65902" y1="23.6809" x2="10.1874" y2="16.0637" gradientUnits="userSpaceOnUse">
        <stop offset="0.490817" stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint9_linear_626_20603" x1="12.447" y1="26.536" x2="0.739845" y2="16.4715" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint10_linear_626_20603" x1="8.59772" y1="21.1336" x2="2.76589" y2="24.7923" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint11_linear_626_20603" x1="-1.52582e-07" y1="35.0175" x2="2.06391" y2="20.5996" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint12_linear_626_20603" x1="24.023" y1="21.4975" x2="12.4306" y2="16.9332" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint13_linear_626_20603" x1="16.2682" y1="15.1714" x2="9.82451" y2="20.2615" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint14_linear_626_20603" x1="13.9081" y1="24.7166" x2="9.01356" y2="18.5964" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
    </defs>
  </svg>
)
