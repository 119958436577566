import { Colors } from 'Shared/types'

const baseColorSetup = [
  Colors.Padua,
  Colors.Cornflower,
  Colors.WaxFlower,
  Colors.Melrose,
  Colors.ClassicRose,
  Colors.SweetCorn,
  Colors.Spray,
  Colors.Azalea,
  Colors.Linen,
  Colors.Tusk,

  Colors.PaudaDarken10,
  Colors.CornflowerDarken10,
  Colors.WaxFlowerDarken10,
  Colors.MelroseDarken10,
  Colors.ClassicRoseDarken10,
  Colors.SweetCornDarken10,
  Colors.SprayDarken10,
  Colors.AzaleaDarken10,
  Colors.LinenDarken10,
  Colors.TuskDarken10,

  Colors.PaudaDarken20,
  Colors.CornflowerDarken20,
  Colors.WaxFlowerDarken20,
  Colors.MelroseDarken20,
  Colors.ClassicRoseDarken20,
  Colors.SweetCornDarken20,
  Colors.SprayDarken20,
  Colors.AzaleaDarken20,
  Colors.LinenDarken20,
  Colors.TuskDarken20,
]

export const colorScheme = [
  ...baseColorSetup,
  ...baseColorSetup,
  ...baseColorSetup,
  ...baseColorSetup
]
