import * as Yup from 'yup'

import { ValidationLimitation } from './validation-limitations'
import { ErrorMessage as Err } from './errors-messages'

export const tagRule = Yup
  .string()
  .trim()
  .matches(ValidationLimitation.TagRegex, { message: Err.NotAllowedTag })
  .min(ValidationLimitation.MinStringLength, Err.TooShort)
  .max(ValidationLimitation.MaxStringLength, Err.MaxTagLength(ValidationLimitation.MaxStringLength))
