/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable max-len */
import { SVGProps } from 'react'

export const ComeBackAliveLogoEN = (props: SVGProps<SVGSVGElement>) => (
  <svg width="120" height="48" viewBox="0 0 163 68" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.3679 25.5943C17.3679 25.5943 7.00067 14.5694 12.2846 10.9687C15.3279 8.8953 17.3121 11.5484 17.3121 11.5484C17.3121 11.5484 19.2964 8.88415 22.3397 10.9687C27.6236 14.5805 17.3567 25.5943 17.3567 25.5943H17.3679ZM17.2676 13.5884C17.0669 13.2986 16.4984 12.6966 16.2085 12.4737C15.9187 12.2507 14.7371 11.3255 13.1876 12.3733C12.7194 12.6966 12.4964 13.0645 12.4407 13.6219C12.4295 13.7222 12.4184 13.8337 12.4184 13.934C12.4184 16.3642 15.0603 20.3215 17.3456 23.0527C19.5974 20.3327 22.1948 16.3976 22.1948 13.9674C22.1948 13.856 22.1948 13.7445 22.1836 13.633C22.1167 13.0645 21.8938 12.6855 21.4256 12.3622C19.8761 11.3143 19.0289 11.9609 18.5049 12.3622C17.8138 12.9084 17.5017 13.2763 17.2676 13.5661" fill="currentColor"></path>
    <path d="M34.1338 0.713867H0.691162V34.1565H34.1338V0.713867Z" stroke="currentColor" strokeWidth="1.25" strokeMiterlimit="10"></path>
    <path d="M161.651 0.679688H33.9778V34.1H161.651V0.679688Z" stroke="currentColor" strokeWidth="1.21" strokeMiterlimit="10"></path>
    <path d="M107.585 34.1338H34.0334V67.3869H107.585V34.1338Z" stroke="currentColor" strokeWidth="1.12" strokeMiterlimit="10"></path>
    <path d="M52.6165 20.0213H55.0244V22.5407C55.0244 24.9708 52.8617 25.8403 51.0112 25.8403C49.1607 25.8403 46.9758 24.9708 46.9758 22.5407V12.6862C46.9758 10.2338 49.1385 9.36426 51.0112 9.36426C52.884 9.36426 55.0244 10.2338 55.0244 12.6862V15.2056H52.6165V12.6862C52.6165 11.7721 51.747 11.4377 51.0112 11.4377C50.2755 11.4377 49.3837 11.7498 49.3837 12.6862V22.5407C49.3837 23.4547 50.2755 23.7892 51.0112 23.7892C51.747 23.7892 52.6165 23.4547 52.6165 22.5407V20.0213Z" fill="currentColor"></path>
    <path d="M58.6472 12.6862C58.6472 10.2338 60.8098 9.36426 62.6826 9.36426C64.5554 9.36426 66.6957 10.2338 66.6957 12.6862V22.5407C66.6957 24.9708 64.5331 25.8403 62.6826 25.8403C60.8321 25.8403 58.6472 24.9708 58.6472 22.5407V12.6862ZM64.2879 12.6862C64.2879 11.7721 63.4184 11.4377 62.6826 11.4377C61.9469 11.4377 61.0551 11.7498 61.0551 12.6862V22.5407C61.0551 23.4547 61.9469 23.7892 62.6826 23.7892C63.4184 23.7892 64.2879 23.4547 64.2879 22.5407V12.6862Z" fill="currentColor"></path>
    <path d="M79.4374 25.5951V14.6928L76.2269 21.0692L73.0387 14.6928V25.5951H70.6309V9.63184H72.8381L76.2269 16.4541L79.6381 9.63184H81.8007V25.5951H79.4374Z" fill="currentColor"></path>
    <path d="M93.0038 25.5951H85.9363V9.63184H93.0038V11.7053H88.3442V16.3204H92.2012V18.3938H88.3442V23.4994H93.0038V25.5951Z" fill="currentColor"></path>
    <path d="M102.546 25.5951V9.63184H106.961C108.989 9.63184 110.483 10.8804 110.483 12.7754V14.7374C110.483 15.6961 109.993 16.5433 109.123 17.2568C110.372 17.881 110.951 18.7505 110.951 20.0213V21.9833C110.951 23.923 109.681 25.5951 107.295 25.5951H102.546ZM108.075 13.0207C108.075 12.3518 107.741 11.683 106.76 11.683H104.954V16.3204H106.76C107.741 16.3204 108.075 15.6292 108.075 14.9827V13.0207ZM108.544 19.999C108.544 19.1518 108.031 18.3938 106.871 18.3938H104.954V23.544H106.871C108.053 23.544 108.544 22.8082 108.544 21.961V19.999Z" fill="currentColor"></path>
    <path d="M121.352 25.5951L120.594 22.1394H116.826L116.091 25.5951H113.794V25.1938L117.517 9.63184H119.925L123.649 25.1938V25.5951H121.352ZM118.699 13.4889L117.295 20.0436H120.126L118.699 13.4889Z" fill="currentColor"></path>
    <path d="M132.366 20.0213H134.774V22.5407C134.774 24.9708 132.611 25.8403 130.761 25.8403C128.91 25.8403 126.725 24.9708 126.725 22.5407V12.6862C126.725 10.2338 128.888 9.36426 130.761 9.36426C132.634 9.36426 134.774 10.2338 134.774 12.6862V15.2056H132.366V12.6862C132.366 11.7721 131.496 11.4377 130.761 11.4377C130.025 11.4377 129.133 11.7498 129.133 12.6862V22.5407C129.133 23.4547 130.025 23.7892 130.761 23.7892C131.496 23.7892 132.366 23.4547 132.366 22.5407V20.0213Z" fill="currentColor"></path>
    <path d="M144.929 25.5951L141.83 18.862L140.983 20.2889V25.5951H138.575V9.63184H140.983V15.9636L144.729 9.63184H147.114V10.0331L143.123 16.6771L147.293 25.1938V25.5951H144.929Z" fill="currentColor"></path>
    <path d="M53.7868 59.015L53.0288 55.5593H49.2609L48.5252 59.015H46.2288V58.6137L49.952 43.0518H52.3599L56.0832 58.6137V59.015H53.7868ZM51.1337 46.9088L49.7291 53.4636H52.5606L51.1337 46.9088Z" fill="currentColor"></path>
    <path d="M59.55 59.015V43.0518H61.9579V56.9193H66.6176V59.015H59.55Z" fill="currentColor"></path>
    <path d="M72.4591 43.0518H70.0513V59.015H72.4591V43.0518Z" fill="currentColor"></path>
    <path d="M81.9123 59.015H79.705L75.8926 43.4531V43.0518H78.0998L80.7975 54.9573L83.5398 43.0518H85.747V43.4531L81.9123 59.015Z" fill="currentColor"></path>
    <path d="M96.2814 59.015H89.2139V43.0518H96.2814V45.1252H91.6217V49.7403H95.4788V51.8137H91.6217V56.9193H96.2814V59.015Z" fill="currentColor"></path>
  </svg>
)
