import { Container, Theme, useMediaQuery } from '@mui/material'

import { DesktopNavigationAdvisor } from './components-advisor-landing-bar/DesktopNavigationAdvisor'
import { MobileNavigationAdvisor } from './components-advisor-landing-bar/MobileNavigationAdvisor'
import { StyledAppBar, StyledToolbar, useScrollToTop } from './components-landing-shared'

export const AdvisorLandingNavigationBar: React.FC = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  useScrollToTop()

  return (
    <StyledAppBar color="inherit" elevation={0}>
      <Container maxWidth="lg">
        <StyledToolbar>
          {isMobile
            ? <MobileNavigationAdvisor />
            : <DesktopNavigationAdvisor />
          }
        </StyledToolbar>
      </Container>
    </StyledAppBar>
  )
}
