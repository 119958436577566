import React, { PropsWithChildren } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import { container } from 'Services/container'
import { AdvisorPortalSelectors } from 'AdvisorPortal/advisorPortal-selectors'
import { globalCleanState } from 'Integration/sagas'
import { Routes } from 'Shared/constants'
import { SharingActions } from './sharing-actions'

export const DispatchSharingKey: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { sharingKey } = useParams<{ sharingKey: string }>()
  const advisoryClients = useSelector(AdvisorPortalSelectors.advisoryClients)

  React.useEffect(() => {
    const selectedClient = advisoryClients.list.find(client => client.sharingKey === sharingKey)

    if (selectedClient) {
      container.configureSharingDependencies(sharingKey)
      dispatch(globalCleanState())
      dispatch(SharingActions.selectSharing(sharingKey))
    } else {
      history.push(Routes.ADVISOR_PORTAL)
    }

    return () => {
      container.configureBaseDependencies()
      dispatch(globalCleanState())
      dispatch(SharingActions.cleanSharing())
    }
  }, [ sharingKey ])

  return (
    <>
      {children}
    </>
  )
}
