import React from 'react'
import { match, P } from 'ts-pattern'
import { Trans } from 'react-i18next'

import { TickerTransactionHistory, TransactionType, TransactionChangeType } from 'Services/history'
import { Price } from 'Shared/components/Price'
import { Ticker } from 'Shared/components/Ticker'

interface Props {
  history: TickerTransactionHistory
}

export const TickerTransactionHistoryMessage: React.FC<Props> = ({ history }) => {
  const translationKey = React.useMemo(
    () => {
      const operationKey = match(history)
        .with({ operation: TransactionType.PurchaseSale, amount: P.when(v => v >= 0) }, () => 'purchase')
        .with({ operation: TransactionType.PurchaseSale, amount: P.when(v => v < 0) }, () => 'sale')
        .run()

      const changeTypeKey = match(history.changeType)
        .with(TransactionChangeType.Created, () => 'created')
        .with(TransactionChangeType.Updated, () => 'updated')
        .with(TransactionChangeType.RolledBack, () => 'rolledBack')
        .exhaustive()

      return `app.history.transaction.${changeTypeKey}.${operationKey}`
    },
    [ history ])

  return (
    <Trans
      i18nKey={translationKey}
      values={{ asset: history.asset }}
      components={{
        price: <Price value={history.price} currency={history.currency} inline />,
        ticker: <Ticker value={Math.abs(history.amount)} ticker={history.ticker} inline />
      }}
    />
  )
}
