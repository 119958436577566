import React, { useContext } from 'react'

import { MyAssetAllocationDrawer } from './AssetAllocation'
import { SettingsContext } from './Settings'
import { HistoryDrawer } from './History'
import { ReleaseNotesDialog } from './Notifications/ReleaseNotesDialog'
import { TasksDrawer } from './Tasks'

export const CrossAppDrawers: React.FC = () => {
  const settings = useContext(SettingsContext)

  return (
    <>
      {settings.manageSettings.assetAllocation.value !== null && (
        <MyAssetAllocationDrawer
          isOpen={settings.assetAllocationOpened}
          close={settings.manageSettings.assetAllocation.close}
          type={settings.manageSettings.assetAllocation.value}
        />
      )}

      {settings.myHistoryOpened && (
        <HistoryDrawer
          isOpen={settings.myHistoryOpened}
          close={settings.manageSettings.myHistory.close}
        />
      )}

      <TasksDrawer
        isOpen={settings.myTasksOpened}
        close={settings.manageSettings.myTasks.close}
      />

      {settings.releaseNotesDialogOpened && (
        <ReleaseNotesDialog
          open={settings.releaseNotesDialogOpened}
          onClose={settings.manageSettings.releaseNotesDialog.close}
          allowClickOutside={settings.manageSettings.releaseNotesDialog.value ?? false}
        />
      )}
    </>
  )
}
