import React from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

import { SupportedLanguage, languageService } from 'config/i18n-types'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  decimalScale: number
  maxLength: number
  allowNegative: boolean
}

export const ConfiguredNumberFormat = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props
    const { i18n } = useTranslation()

    const resolvedLanguage = (i18n.resolvedLanguage as SupportedLanguage) ?? SupportedLanguage.EN
    const thousandSeparator = React.useMemo(() => languageService.getThousandsSeparator(resolvedLanguage), [ resolvedLanguage ])
    const decimalSeparator = React.useMemo(() => languageService.getDecimalSeparator(resolvedLanguage), [ resolvedLanguage ])

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        decimalScale={props.decimalScale}
        maxLength={props.maxLength}
        allowNegative={props.allowNegative}
        valueIsNumericString
      />
    )
  },
)
