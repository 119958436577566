import { useTypedDrawer } from './useTypedDrawer'

export interface Drawer {
  open: () => void
  close: () => void
  isOpen: boolean
}

export function useDrawer(): Drawer {
  const drawer = useTypedDrawer()

  const open = () => {
    drawer.open(null)
  }

  const close = () => {
    drawer.close()
  }

  return {
    open,
    close,
    isOpen: drawer.isOpen,
  }
}
