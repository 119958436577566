import { IconButton, Tooltip } from '@mui/material'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { SettingsContext } from 'App/Settings'
import { StepName, UserGuideConfig } from 'App/UserGuide/types'
import { QuestionIcon } from 'Shared/assets'
import { Routes } from 'Shared/constants'

export const UserGuideButton: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { manageSettings } = useContext(SettingsContext)

  const isDemoMode = location.pathname.includes('demo')

  const stepRedirects = []
  stepRedirects[StepName.Welcome] = isDemoMode ? Routes.DEMO_HOLDINGS : Routes.HOLDINGS
  stepRedirects[StepName.Holdings] = isDemoMode ? Routes.DEMO_HOLDINGS : Routes.HOLDINGS
  stepRedirects[StepName.Summary] = isDemoMode ? Routes.DEMO_HOLDINGS : Routes.HOLDINGS
  stepRedirects[StepName.HiddenMode] = isDemoMode ? Routes.DEMO_HOLDINGS : Routes.HOLDINGS
  stepRedirects[StepName.Transactions] = isDemoMode ? Routes.DEMO_TRANSACTIONS : Routes.TRANSACTIONS
  stepRedirects[StepName.Analytics] = isDemoMode ? Routes.DEMO_ANALYTICS : Routes.ANALYTICS
  stepRedirects[StepName.Prediction] = isDemoMode ? Routes.DEMO_FORECAST : Routes.FORECAST
  stepRedirects[StepName.Final] = isDemoMode ? Routes.DEMO_HOLDINGS : Routes.HOLDINGS

  const userGuideConfig: UserGuideConfig = {
    displayWhenGuidePassedAlready: true,
    stepsRedirects: stepRedirects,
  }

  return (
    <Tooltip title={t('app.intro.tooltip')}>
      <IconButton onClick={() => manageSettings.userGuide.runUserGuide(userGuideConfig)} size="small">
        <QuestionIcon width={16} height={16} />
      </IconButton>
    </Tooltip>
  )
}
