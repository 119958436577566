import { Toolbar } from '@mui/material'
import React, { PropsWithChildren, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { AppNavigation, ComeBackAlive } from 'App/NavigationBar/components'
import { SettingsContext } from 'App/Settings'
import { AppLogoWhiteLabeled } from 'App/WhiteLabel/AppLogoWhiteLabeled'
import { CaretLeftIcon, CaretRightIcon } from 'Shared/assets'
import { Routes } from 'Shared/constants'
import { NavigationLink } from 'Shared/types'
import * as S from './AppBar.style'

interface Props {
  links: NavigationLink[]
}

export const AppBarDesktop: React.FC<PropsWithChildren<Props>> = ({ links, children }) => {
  const { t } = useTranslation()
  const { appBarExpanded, manageSettings } = useContext(SettingsContext)

  return (
    <>
      <S.AppBar position="fixed" open={appBarExpanded} color="inherit" elevation={0}>
        <Toolbar>
          <S.CollapseIcon
            color="inherit"
            aria-label="open drawer"
            onClick={manageSettings.appBar.toggle}
            edge="start"
            title={appBarExpanded ? t('app.navbar.menu-close') : t('app.navbar.menu-open')}
          >
            {appBarExpanded ? <CaretLeftIcon /> : <CaretRightIcon />}
          </S.CollapseIcon>

          {children}
        </Toolbar>
      </S.AppBar>

      <S.Drawer variant="permanent" open={appBarExpanded}>
        <S.DrawerHeader open={appBarExpanded}>
          <S.AppLogoWrapper
            underline="none"
            component={RouterLink}
            to={Routes.HOME}
          >
            <AppLogoWhiteLabeled expanded={appBarExpanded} />
          </S.AppLogoWrapper>
        </S.DrawerHeader>
        <S.StyledDivider />
        <AppNavigation open={appBarExpanded} links={links} />
        <ComeBackAlive open={appBarExpanded} />
      </S.Drawer>
    </>
  )
}
