import { CountryCodesSet } from './CountryCodes'

export class IntlFactory {
  private providers: Map<string, Intl.DisplayNames> = new Map<string, Intl.DisplayNames>()

  public getCountryNameProvider = (language: string) => {
    if (this.providers.has(language)) {
      return this.providers.get(language)
    }

    const provider = new Intl.DisplayNames([ language ], { type: 'region' })
    this.providers.set(language, provider)

    return provider
  }

  public getCountryName = (language: string, code: string) => {
    const provider = this.getCountryNameProvider(language)

    if (CountryCodesSet.has(code) && provider) {
      return provider.of(code)
    }

    return null
  }
}

export const intlCountriesFactory = new IntlFactory()
