import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { SupportedLanguage } from 'config/i18n-types'
import { InTextExternalLink } from 'Shared/components/Links'
import { ExternalLinks } from 'Shared/constants'

export const NoOptions: React.FC = () => {
  const { t , i18n } = useTranslation()

  const supportedTickersHref = i18n.resolvedLanguage === SupportedLanguage.UK ?
    ExternalLinks.WIKI.FAQ_UK :
    ExternalLinks.WIKI.FAQ_EN

  return (
    <>
      {t('common.state.noOptions')}.&nbsp;
      <Trans
        i18nKey="common.money.supportedCurrency-message"
        components={{
          explainLink: <InTextExternalLink href={supportedTickersHref} spaceBefore />
        }}
      />
    </>
  )
}
