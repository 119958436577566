import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material'
import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { SettingsContext } from 'App/Settings'
import { CrownIcon } from 'Shared/assets'
import { SubTextPrimary2 } from 'Shared/components/Typography'

const StyledCrownIcon = styled(CrownIcon)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.premium.main,
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(0.5),
  width: 18,
  height: 18,
}))

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.blue.blue3}`,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
  },
}))

export const PremiumUserBadge: React.FC = () => {
  const { t } = useTranslation()
  const { profile } = useContext(SettingsContext)

  return (
    <StyledTooltip
      placement="bottom"
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      title={(
        <>
          {!!profile.organization && (
            <SubTextPrimary2>
              • <Trans
                i18nKey="app.navbar.premiumReason-advisorClient"
                values={{ organization: profile.organization.name }}
              />
            </SubTextPrimary2>
          )}

          <SubTextPrimary2>
            • {t('app.navbar.premiumReason-main')}
          </SubTextPrimary2>
        </>
      )}
    >
      <StyledCrownIcon />
    </StyledTooltip>
  )
}
