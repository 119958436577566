import { Typography } from '@mui/material'
import { styled } from '@mui/system'

export const PriceContainer = styled(Typography)({
  fontSize: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  cursor: 'pointer',
}) as typeof Typography

export const EmptyPriceContainer = styled(Typography)({
  display: 'inline',
  fontSize: 'inherit',
  lineHeight: 'inherit',
  cursor: 'pointer',
}) as typeof Typography

export const CentsContainer = styled(Typography)({
  display: 'inline',
  fontSize: '85%',
}) as typeof Typography
