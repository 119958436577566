import { Stack } from '@mui/material'

import { LanguageSelector } from 'App/NavigationBar/components-landing-bar/LanguageSelector'
import { ComeBackAliveLinkLanding, DesktopLandingAnchorLinks } from 'App/NavigationBar/components-landing-shared'
import { AppLogo } from 'App/NavigationBar/components/AppLogo'
import { Routes } from 'Shared/constants'
import { landingAdvisorSections } from './landingAdvisorSections'
import { LoginButtonAdvisorLanding } from './LoginButtonAdvisorLanding'

export const DesktopNavigationAdvisor = () => (
  <>
    <AppLogo linkTo={Routes.HOME} />
    <DesktopLandingAnchorLinks sections={landingAdvisorSections} />
    <Stack direction="row" alignItems="center" gap={1.5}>
      <ComeBackAliveLinkLanding />
      <LoginButtonAdvisorLanding />
      <LanguageSelector />
    </Stack>
  </>
)
