import React from 'react'
import { Box, Stack } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useTranslation } from 'react-i18next'

import { SubTextSecondary1, SubTextPrimary2, ButtonLink } from 'Shared/components'

interface EditableContentProps {
  label: string
  previewContent: React.ReactElement
  editableContent: React.ReactElement
}

export const EditableContent: React.FC<EditableContentProps> = ({ label, previewContent, editableContent }) => {
  const { t } = useTranslation()
  const [ isEditMode, setIsEditMode ] = React.useState(false)

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <SubTextSecondary1>{label}</SubTextSecondary1>

        {isEditMode ? (
          <SubTextPrimary2>
            <ButtonLink
              onClick={() => setIsEditMode(false)}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <ArrowBackIcon fontSize="inherit" sx={{ mx: 1 }} /> {t('common.actions.return')}
            </ButtonLink>
          </SubTextPrimary2>
        ):(
          <SubTextPrimary2>
            <ButtonLink
              onClick={() => setIsEditMode(true)}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <EditIcon fontSize="inherit" sx={{ mx: 1 }} /> {t('common.actions.edit')}
            </ButtonLink>
          </SubTextPrimary2>
        )}
      </Stack>

      <Box mt={3}>
        {isEditMode ? editableContent : previewContent}
      </Box>
    </Box>
  )
}
