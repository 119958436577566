import React from 'react'
import { SxProps } from '@mui/material/styles'
import { Theme } from '@mui/material'

import { HOLDING_TYPE } from 'Services/holdings'
import { HoldingTypeColors } from 'Shared/types'
import { WalletIcon } from './assets/WalletIcon'
import { CryptoIcon } from './assets/CryptoIcon'
import { ChartBarIcon } from './assets/ChartBarIcon'

interface Props {
  type: string
  sx?: SxProps<Theme>
}

export const HoldingTypeIcon: React.FC<Props> = ({ type, sx }) => {
  switch (type) {
    case HOLDING_TYPE.Crypto:
      return <CryptoIcon sx={{ color: HoldingTypeColors.Crypto, ...sx }} fontSize="small" />
    case HOLDING_TYPE.Custom:
      return <WalletIcon sx={{ color: HoldingTypeColors.Regular, ...sx }} fontSize="small" />
    case HOLDING_TYPE.Stock:
      return <ChartBarIcon sx={{ color: HoldingTypeColors.Stocks, ...sx }} fontSize="small" />
    default:
      return null
  }
}
