import { theme } from 'config/theme'
import { Money } from 'Shared/types'

export const getPriceSign = (money: Money, isHighlighted: boolean, displayCents: boolean) => {
  if (!displayCents && money.dollars === 0) {
    return ''
  }

  if (money.isNegative) return '-\xa0'
  if (money.isPositive && isHighlighted) return '+\xa0'

  return ''
}

export const getPriceColor = (money: Money, isHighlighted: boolean, displayCents: boolean) => {
  const displayZeroPrice = (!displayCents && money.dollars === 0) || money.isZero

  if (displayZeroPrice) {
    return theme.palette.dark.dark1
  }

  if (money.isNegative && isHighlighted) return theme.palette.error.light
  if (money.isPositive && isHighlighted) return theme.palette.success.light

  return ''
}

