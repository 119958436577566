import React from 'react'
import { List } from '@mui/material'

import { PricingPlan } from 'Pricing/pricing-types'
import * as S from './styles'
import { PricingCardSectionHeader } from './PricingCardSectionHeader'
import { PricingCardSectionPrice } from './PricingCardSectionPrice'
import { PricingCardSectionFeatures } from './PricingCardSectionFeatures'
import { PricingCardSectionActions } from './PricingCardSectionActions'

interface Props {
  title: string
  features: { key: string, hint?: string }[]
  pricingPlan: PricingPlan
  onChosePlan?: () => void
  choseButtonText?: string
  highlight?: boolean
  disabled?: boolean
}

export const PricingCard: React.FC<Props> = ({
  title,
  features,
  pricingPlan,
  onChosePlan,
  choseButtonText,
  highlight = false,
  disabled = false,
}) => (
  <S.TierCard highlight={highlight}>
    <PricingCardSectionHeader title={title} promoActive={pricingPlan.periodPromoActive} />
    <PricingCardSectionPrice pricingPlan={pricingPlan} />

    <List sx={{ flexGrow: 1 }}>
      <PricingCardSectionFeatures features={features} />
    </List>

    <PricingCardSectionActions
      onChosePlan={onChosePlan}
      disabled={disabled}
      ctaButtonText={choseButtonText}
    />
  </S.TierCard>
)
