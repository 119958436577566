import { HOLDING_TYPE } from 'Services/holdings'
import { Nullable } from 'Shared/types'

export interface ITransactionsService {
  getTransactions(token: string, from: Date, to: Date): Promise<Transaction[]>
  deleteTransaction(transactionKey: string, token: string): Promise<void>
  updateChangeBalance(key: string, token: string, payload: UpdateChangeBalanceRequest): Promise<void>
  updateProfitLoss(transactionKey: string, token: string, payload: UpdateProfitLossRequest): Promise<void>
  updateTransfer(transactionKey: string, token: string, payload: UpdateTransferRequest): Promise<void>
  updateBuySell(transactionKey: string, token: string, payload: UpdateBuySellItemRequest): Promise<void>
}

export interface UpdateChangeBalanceRequest {
  delta: number
  currency: string
  comment: string
  operationDate: Date
}

export interface UpdateProfitLossRequest {
  delta: number
  operationDate: Date
  comment: string
}

export interface UpdateTransferRequest {
  fromCurrencyBalance: number
  toCurrencyBalance: number
  operationDate: Date
  comment: string
}

export interface UpdateBuySellItemRequest {
  delta: number
  price: number
  operationDate: Date
  comment: string
}

export enum TRANSACTION_TYPE {
  TRANSFER = 'Transfer',
  PROFIT_LOSS = 'ProfitLoss',
  DEPOSIT_WITHDRAW = 'ChangeBalance',
  BUY_SELL_ITEM = 'BuySellItem',
}

export interface TransferTransaction {
  transactionKey: string
  transactionType: TRANSACTION_TYPE.TRANSFER
  operationDate: string
  comment: string

  fromHolding: string
  fromHoldingType: HOLDING_TYPE
  fromHoldingTicker: Nullable<string>
  fromCurrency: Nullable<string>
  fromDelta: number
  fromDeltaInBaseCurrency: number
  fromInitialBalance: number
  fromResultBalance: number
  fromPrice: Nullable<number>

  toHolding: string
  toHoldingType: HOLDING_TYPE
  toHoldingTicker: Nullable<string>
  toCurrency: Nullable<string>
  toDelta: number
  toDeltaInBaseCurrency: number
  toInitialBalance: number
  toResultBalance: number
  toPrice: Nullable<number>
}

export interface FixProfitLossTransaction {
  transactionKey: string
  operationDate: string
  transactionType: TRANSACTION_TYPE.PROFIT_LOSS
  holding: string
  currency: string
  holdingType: HOLDING_TYPE
  delta: number
  deltaInBaseCurrency: number
  initialBalance: number
  resultBalance: number
  comment: string
}

export interface ChangeBalanceTransaction {
  transactionKey: string
  operationDate: string
  transactionType: TRANSACTION_TYPE.DEPOSIT_WITHDRAW
  holding: string
  currency: string
  holdingType: HOLDING_TYPE
  delta: number
  deltaInBaseCurrency: number
  initialBalance: number
  resultBalance: number
  comment: string
}

export interface BuySellItemTransaction {
  transactionKey: string
  operationDate: string
  transactionType: TRANSACTION_TYPE.BUY_SELL_ITEM
  holding: string
  holdingType: HOLDING_TYPE
  ticker: string
  delta: number
  price: number
  currency: string
  initialBalance: number
  resultBalance: number
  cashInitialBalance: number
  cashResultBalance: number
  comment: string

  defaultCurrency: string
  currencyRateToDefault: number
  priceInDefaultCurrency: number
  profitLossInDefaultCurrency: number

  priceInBaseCurrency: number
  profitLossInBaseCurrency: number
}

export type Transaction =
  TransferTransaction |
  FixProfitLossTransaction |
  ChangeBalanceTransaction |
  BuySellItemTransaction
