import React from 'react'
import { styled, alpha } from '@mui/material/styles'
import { Link, Stack } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { AppLogoIcon, EnvelopIcon, InstagramIcon, BMCIcon, TelegramIcon } from 'Shared/assets'
import { ExternalLinks, Routes } from 'Shared/constants'
import { LegalLinkGroup } from './components/LegalLinkGroup'
import { IconLink } from './components/IconLink'
import { CopyrightText } from './components/CopyrightText'

const FooterWrapper = styled('footer')(({ theme }) => ({
  backgroundColor: 'unset',
  padding: `${theme.spacing(2.5)} 0`,
  margin: `${theme.spacing(2.5)} ${theme.spacing(2)} 0`,
  borderTop: '1px solid',
  borderTopColor: alpha(theme.palette.dark.dark2!, 0.5),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  flexDirection: 'row',

  [theme.breakpoints.up('desktop')]: {
    alignItems: 'center',
  },

  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
  },
}))

const AppLogoWrapper = styled(Link)(({ theme }) => ({
  marginRight: theme.spacing(3.5),
  width: 'fit-content',

  [theme.breakpoints.down('tablet')]: {
    marginBottom: theme.spacing(3),
  },
})) as typeof Link

const AppLogoIconStyled = styled(AppLogoIcon)(() => ({
  width: 20,
  height: 20,
}))

const LegalAndAppLogo = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',

  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column-reverse',
    alignItems: 'normal',
  },

  [theme.breakpoints.between('sm', 'desktop')]: {
    alignItems: 'flex-start',
  },

}))

const CopyrightAndIcons = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    alignItems: 'normal',
  },

  [theme.breakpoints.up('lg')]: {
    paddingRight: theme.spacing(11.5)
  },
}))

const IconsGroup = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  marginLeft: theme.spacing(2.5),

  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    marginBottom: theme.spacing(2),
  },
}))

export const AppFooter: React.FC = () => (
  <FooterWrapper>
    <LegalAndAppLogo>
      <AppLogoWrapper underline="none" component={RouterLink} to={Routes.HOME}>
        <AppLogoIconStyled />
      </AppLogoWrapper>
      <LegalLinkGroup />
    </LegalAndAppLogo>
    <CopyrightAndIcons>
      <CopyrightText />
      <IconsGroup>
        <IconLink href={ExternalLinks.CONTACT.BMC}>
          <BMCIcon />
        </IconLink>
        <IconLink href={ExternalLinks.CONTACT.TELEGRAM_NEWS}>
          <TelegramIcon />
        </IconLink>
        <IconLink href={ExternalLinks.CONTACT.INSTAGRAM}>
          <InstagramIcon />
        </IconLink>
        <IconLink href={ExternalLinks.MAILTO}>
          <EnvelopIcon />
        </IconLink>
      </IconsGroup>
    </CopyrightAndIcons>
  </FooterWrapper>
)
