import React from 'react'
import { Trans } from 'react-i18next'

import { SubTextSecondary2 } from 'Shared/components/Typography'

interface Props {
  i18nKey: string
  components?: any
  values?: any
}

export const HelpText: React.FC<Props> = ({ i18nKey, components, values }) => (
  <SubTextSecondary2>
    <Trans
      i18nKey={i18nKey}
      components={components}
      values={values}
    />
  </SubTextSecondary2>
)
