import React from 'react'

import { container, Service } from 'Services/container'
import { Sharing, ISharingService } from 'Services/sharing'
import { AsyncState } from 'Shared/types'
import { useAuthWithDemo } from 'Demo'
import { useSnackbar } from 'Snackbar'

export const useSharingsByMe = () => {
  const { getAccessTokenWithDemo } = useAuthWithDemo()
  const { enqueueError } = useSnackbar()

  const [ sharingsState, setSharingsState ] = React.useState<AsyncState<Sharing[]>>({
    isLoading: false,
    state: null
  })

  const fetchSharings = async () => {
    const token = await getAccessTokenWithDemo()
    setSharingsState(prevState => ({ ...prevState, isLoading: true }))

    try {
      const sharingService = container.resolve<ISharingService>(Service.SharingService)
      const sharingsResponse = await sharingService.getSharingsByMe(token)

      setSharingsState({ isLoading: false, state: sharingsResponse })
    } catch (error) {
      enqueueError('common.errorMessages.loading-failed')
    } finally {
      setSharingsState(prevState => ({ ...prevState, isLoading: false }))
    }
  }

  return {
    fetchSharings,
    isLoading: sharingsState.isLoading,
    sharingsByMe: sharingsState.state,
  }
}
