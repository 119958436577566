import React from 'react'
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined'

import { ClientHistoryButton } from './ClientHistoryButton'

export const ClientHistoryButtonDesktop: React.FC = () => (
  <ClientHistoryButton
    icon={(
      <HistoryOutlinedIcon fontSize="small" />
    )}
  />
)
