import { createSelector } from 'reselect'

import { StoreState } from 'Shared/types'

const assetAllocationSelector = (state: StoreState) => state.assetAllocation

const isLoading = createSelector(assetAllocationSelector, state => state.isLoading)
const initialLoadCompleted = createSelector(assetAllocationSelector, state => state.initialLoadCompleted)
const assetAllocation = createSelector(assetAllocationSelector, state => state.assetAllocation)

export const AssetAllocationSelectors = {
  isLoading,
  initialLoadCompleted,
  assetAllocation,
}
