import { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { BaseService } from 'Shared/api'
import { CreateSharingRequest, Sharing, ISharingService } from './sharing-types'

export class SharingService extends BaseService implements ISharingService {
  public getSharingsByMe = (token: string) => axios
    .get<Sharing[]>('/sharings/by-me', { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public getSharingsWithMe = (token: string) => axios
    .get<Sharing[]>('/sharings/with-me', { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public createSharing = (token: string, sharing: CreateSharingRequest) => axios
    .post('/sharings', { payload: sharing }, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public revokeSharing = (token: string, key: string) => axios
    .delete(`/sharings/${key}`, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)
}
