import { useTranslation } from 'react-i18next'

import { container, Service } from 'Services/container'
import type { IProfileService } from 'Services/profile'
import { useAuthWithDemo } from 'Demo'
import { useSnackbar } from 'Snackbar'

export const useExportAccount = () => {
  const { t } = useTranslation()
  const { enqueueError, enqueueSuccess } = useSnackbar()
  const { getAccessTokenWithDemo } = useAuthWithDemo()

  const exportAccount = async () => {
    const token = await getAccessTokenWithDemo()
    try {
      const userMenuService = container.resolve<IProfileService>(Service.ProfileService)
      await userMenuService.exportAccount(token, t('app.navbar.export-fileName', { date: new Date() }))
      enqueueSuccess('app.navbar.export-success')
    } catch (error) {
      enqueueError('app.navbar.export-failure')
    }
  }

  return { exportAccount }
}
