import { Stack } from '@mui/material'
import { compareAsc } from 'date-fns'
import { chain } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { AdvisoryClientTask } from 'Services/advisor'
import { NoDataOverlay } from 'Shared/components/Overlay'
import { DueType } from './Task-types'
import { getTaskDueType } from './Task-utils'
import { TaskFilter } from './TaskFilter'

interface Props {
  tasks: AdvisoryClientTask[]
  renderTasks: (tasks: AdvisoryClientTask[]) => React.ReactNode
}

const tasksComparer = (a: AdvisoryClientTask, b: AdvisoryClientTask) => {
  if (a.completed && !b.completed) {
    return 1
  }

  if (!a.completed && b.completed) {
    return -1
  }

  return compareAsc(new Date(a.dueDate), new Date(b.dueDate))
}

const dueTypeTranslationKeys = {
  [DueType.PastDue.toString()]: 'advisorPortal.task.dueType-pastDue',
  [DueType.Soon.toString()]: 'advisorPortal.task.dueType-soon',
  [DueType.Later.toString()]: 'advisorPortal.task.dueType-later',
  [DueType.Completed.toString()]: 'advisorPortal.task.dueType-completed',
}

const defaultFilterOption = 'all'

export const TaskList: React.FC<Props> = ({ tasks, renderTasks }) => {
  const { t } = useTranslation()
  const [ selectedOption, setSelectedOption ] = React.useState(defaultFilterOption)

  const dueTypes = React.useMemo(
    () => chain(tasks)
      .sort(tasksComparer)
      .groupBy(task => getTaskDueType(new Date(task.dueDate), task.completed))
      .value(),
    [ tasks ])

  const tasksToRender = React.useMemo(
    () => tasks
      .filter(task =>
        selectedOption === defaultFilterOption ||
        selectedOption === getTaskDueType(new Date(task.dueDate), task.completed).toString())
      .sort(tasksComparer),
    [ tasks, selectedOption ])

  const filterOptions = React.useMemo(
    () => [
      { label: t('common.all'), value: defaultFilterOption },
      ...Object
        .values(DueType)
        .filter(dueType => dueTypes[dueType] || dueType === selectedOption)
        .map(dueType => ({
          label: `${t(dueTypeTranslationKeys[dueType])} [${dueTypes[dueType]?.length ?? 0}]`,
          value: dueType
        }))
    ],
    [ dueTypes, selectedOption ])

  return (
    <Stack spacing={1}>
      <TaskFilter
        options={filterOptions}
        selectedValue={selectedOption}
        setSelectedValue={setSelectedOption}
      />
      {tasksToRender.length === 0 ? <NoDataOverlay /> : renderTasks(tasksToRender)}
    </Stack>
  )
}
