import React from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'

import { SubTextSecondary1, ButtonLink } from 'Shared/components'
import { SubscriptionPlanContainer } from './SubscriptionPlanContainer'

const ErrorText = styled(SubTextSecondary1)(({ theme }) => ({
  color: theme.palette.error.main
}))

interface Props {
  onCancelSubscriptionClick: () => void
}

export const PendingSubscription: React.FC<Props> = ({ onCancelSubscriptionClick }) => {
  const { t } = useTranslation()

  return (
    <SubscriptionPlanContainer isPremium={false}>
      <ErrorText>
        {t('app.navbar.settings.subscription.waitingForPayment')}
      </ErrorText>
      <SubTextSecondary1>
        <ButtonLink onClick={onCancelSubscriptionClick}>
          {t('app.navbar.settings.subscription.cancel')}
        </ButtonLink>
      </SubTextSecondary1>
    </SubscriptionPlanContainer>
  )
}
