import { GetChartRequest, ChartPreRender } from 'Services/analytics'
import type { ITypedAction } from 'Shared/types'
import type { GetChartPayload, AnalyticsState, ChartFilterState, ChartChangedState } from 'Analytics/analytics-types'
import { AnalyticsActionTypes, InitialState } from 'Analytics/analytics-constants'

export const analytics = (state: AnalyticsState = InitialState, action: ITypedAction<any>): AnalyticsState => {
  switch(action.type) {
    case AnalyticsActionTypes.GET_CHARTS: {
      return {
        ...state,
        isLoading: true
      }
    }

    case AnalyticsActionTypes.GET_CHARTS_SUCCESS: {
      const chartsPreRenders = action.payload as ChartPreRender[]

      return {
        ...state,
        isLoading: false,
        initialLoadCompleted: true,
        chartPreRenders: chartsPreRenders
      }
    }

    case AnalyticsActionTypes.GET_CHARTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        initialLoadCompleted: true,
      }
    }

    case AnalyticsActionTypes.GET_CHART: {
      const { key } = action.payload as GetChartRequest

      return {
        ...state,
        chartRenders: {
          ...state.chartRenders,
          [key]: {
            ...state.chartRenders[key],
            isLoading: true
          }
        }
      }
    }

    case AnalyticsActionTypes.GET_CHART_SUCCESS: {
      const { key, chart } = action.payload as GetChartPayload

      return {
        ...state,
        chartRenders: {
          ...state.chartRenders,
          [key]: {
            key: key,
            isLoading: false,
            name: chart.name,
            type: chart.type,
            data: chart.data,
            metadata: chart.metadata,
            errors: chart.errors,
          }
        }
      }
    }

    case AnalyticsActionTypes.GET_CHART_FAILURE: {
      const key = action.payload

      return {
        ...state,
        chartRenders: {
          ...state.chartRenders,
          [key]: {
            ...state.chartRenders[key],
            isLoading: false,
            errors: [ 'loading failed' ],
          }
        }
      }
    }

    case AnalyticsActionTypes.SET_CHART_FILTER_CHANGED: {
      const chartFilter = action.payload as ChartFilterState

      return { ...state, chartFilter }
    }

    case AnalyticsActionTypes.SET_CHARTS_TAGS: {
      return { ...state, tags: action.payload }
    }

    case AnalyticsActionTypes.SET_CHART_CHANGED: {
      const chartFavorite = action.payload as ChartChangedState
      const preRenderIdx = state.chartPreRenders.findIndex(pr => pr.key === chartFavorite.key)

      return {
        ...state,
        chartPreRenders: [
          ...state.chartPreRenders.slice(0, preRenderIdx),
          {
            ...state.chartPreRenders[preRenderIdx],
            isFavorite: chartFavorite.favorite,
          },
          ...state.chartPreRenders.slice(preRenderIdx + 1),
        ]
      }
    }

    case AnalyticsActionTypes.RESET_STATE_ANALYTICS: {
      return InitialState
    }

    default:
      return state
  }
}
