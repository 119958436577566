import { Stack } from '@mui/material'
import React from 'react'

import { ToggleHiddenModeMobile, LoginButton } from 'App/NavigationBar/components'

export const AppBarMobileToolbar = () => (
  <Stack direction="row" alignItems="center" columnGap={1}>
    <ToggleHiddenModeMobile />
    <LoginButton size="small" />
  </Stack>
)
