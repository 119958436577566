import { Chip as ChipMUI, darken } from '@mui/material'

import { styledWithCustomProp } from 'config/theme'

export const Tag = styledWithCustomProp(ChipMUI, 'active')<{ active?: boolean }>(({ theme, active }) => ({
  ...(active ? {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.blue.blue,
    borderColor: theme.palette.blue.blue,
    '&:hover': {
      backgroundColor: darken(theme.palette.blue.blue, 0.05),
      borderColor: darken(theme.palette.blue.blue, 0.05),
    },
  } : {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.blue.blue6,
    borderColor: theme.palette.blue.blue1,
    '&:hover': {
      backgroundColor: theme.palette.blue.blue5,
      borderColor: theme.palette.blue.blue,
      '& .MuiChip-deleteIcon': {
        color: theme.palette.blue.blue,
      },
    },
  }),
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: theme.spacing(0.5),
  '& .MuiChip-deleteIcon': {
    color: theme.palette.primary.light,
  },
}))

export const TimeFrameTag = styledWithCustomProp(ChipMUI, 'active')<{ active?: boolean }>(({ theme, active }) => ({
  ...(active ? {
    backgroundColor: theme.palette.blue.blue5,
    borderColor: theme.palette.blue.blue1,
    borderWidth: 1,
    '&:hover': {
      backgroundColor: darken(theme.palette.blue.blue5, 0.05),
      borderColor: darken(theme.palette.blue.blue1, 0.05),
    },
  } : {
    backgroundColor: theme.palette.common.white,
    borderWidth: 0,
    '&:hover': {
      backgroundColor: theme.palette.blue.blue5,
      borderColor: theme.palette.blue.blue,
      '& .MuiChip-deleteIcon': {
        color: theme.palette.blue.blue,
      },
    },
  }),
  borderStyle: 'solid',
  borderRadius: theme.spacing(0.5),
  '& .MuiChip-deleteIcon': {
    color: theme.palette.primary.light,
  },
}))
