export const ValidationLimitation = {
  MinStringLength : 1,
  MinPreferredNameLength: 2,
  MaxStringLength : 35,
  MaxNotesLength : 300,
  MaxCommentLength : 100,
  MinNumberValue : -999_999_999_999,
  MaxNumberValue : 999_999_999_999,
  MaximumLoss : -100,
  MaxPercentValue: 100,
  MinPercentValue: 0,
  MinBalanceValue : 0,
  MinSavingsValue: 0,
  MinSpendingValue: 0,
  MaxPredictionPreCalculation: 999_999_999_999_999,
  minAge: 1,
  maxAge: 120,
  currentAgeToLifeExpectancyDistanceInYears: 5,
  MinSyncedOperationDeviation: 0.000001,
  MinCustomOperationDeviation: 0.01,
  MaxTagsLength: 10,
  MaxClientNotesLength: 3000,
  TagRegex: /^[^,]+$/i,
}
