import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// Note: scroll to page start (top) is also handled in ScrollToTop component
export const useScrollToTop = () => {
  const location = useLocation()

  useEffect(() => {
    const elementToScroll = document.getElementById(location.hash?.replace('#', ''))
    elementToScroll?.scrollIntoView({ block: 'start' })
  }, [])
}
