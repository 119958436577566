import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { container, Service } from 'Services/container'
import { ITransactionsService, Transaction } from 'Services/transactions'
import type { ITypedActionWithToken } from 'Shared/types'
import { ErrorMessage } from 'Shared/constants'
import { selectErrorOrDefault } from 'Shared/utils'
import { snackbarActionCreators } from 'Snackbar'
import type { GetTransactionsPayload } from './transactions-types'
import { TransactionsSelectors } from './transactions-selectors'
import { TransactionsActionTypes } from './transactions-constants'
import { TransactionsActions } from './transactions-actions'

export function* getTransactions(action: ITypedActionWithToken<GetTransactionsPayload>) {
  const transactionsService = container.resolve<ITransactionsService>(Service.TransactionsService)

  const fromDateTime = action.payload.from
  fromDateTime.setHours(0, 0, 0, 0)

  const toDateTime = action.payload.to
  toDateTime.setHours(23, 59, 59, 999)

  try {
    const response: Transaction[] = yield call(
      transactionsService.getTransactions,
      action.token,
      fromDateTime,
      toDateTime)
    yield put(TransactionsActions.getTransactionsSuccess(response))
  } catch (error) {
    yield put(TransactionsActions.getTransactionsFailure())
    yield put(snackbarActionCreators.enqueueErrorSnackbar('transactions.loading-failure'))
  }
}

export function* deleteTransaction(action: ITypedActionWithToken<string>) {
  try {
    const transactionsService = container.resolve<ITransactionsService>(Service.TransactionsService)

    yield call(
      transactionsService.deleteTransaction,
      action.payload,
      action.token)

    yield put(TransactionsActions.deleteTransactionSuccess())
    yield put(snackbarActionCreators.enqueueSuccessSnackbar('transactions.manage.deleteTransaction-success'))

    const from: Date = yield select(TransactionsSelectors.getFromDate)
    const to: Date = yield select(TransactionsSelectors.getToDate)
    yield put(TransactionsActions.getTransactions(action.token, from, to))
  } catch (error: any) {
    const errorCodes = error?.response?.data?.errors ?? []
    const errorMessage = selectErrorOrDefault(errorCodes)

    if (errorMessage) {
      yield put(snackbarActionCreators.enqueueWarningSnackbar(errorMessage.key, errorMessage.prop))
    } else {
      yield put(snackbarActionCreators.enqueueErrorSnackbar(ErrorMessage.DeleteTransactionFailure.key))
    }

    yield put(TransactionsActions.deleteTransactionFailure())
  }
}

export function* transactionsSaga() {
  yield all([
    takeEvery(TransactionsActionTypes.GET_TRANSACTIONS, getTransactions),
    takeEvery(TransactionsActionTypes.DELETE_TRANSACTION, deleteTransaction),
  ])
}
