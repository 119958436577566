import { put } from 'redux-saga/effects'

import { selectErrorOrDefault } from 'Shared/utils'
import { ErrorMessage } from 'Shared/constants'
import { snackbarActionCreators } from 'Snackbar'

export function* handleErrorSaga(error: any, defaultError: string, defaultProp: any = undefined) {
  const errorCodes = error?.response?.data?.errors ?? []
  const controlledError = selectErrorOrDefault(errorCodes)

  if (controlledError) {
    yield put(snackbarActionCreators.enqueueWarningSnackbar(controlledError.key, controlledError.prop))
  } else {
    yield put(snackbarActionCreators.enqueueErrorSnackbar(ErrorMessage.OperationFailure.key))
  }
}
