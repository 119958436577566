import React from 'react'
import { useField } from 'formik'
import { TextFieldProps } from '@mui/material'
import { OperationMoneyField, FormatProps } from 'Shared/components/NonForm'
import { localizeError } from 'Shared/utils'

interface FormikOperationMoneyFieldProps {
  currencyName: string
  moneyName: string
  cashAccountCurrencies?: string[]
  currencyDisabled?: boolean
  formatProps?: FormatProps
}

type Props = TextFieldProps & FormikOperationMoneyFieldProps

export const FormikOperationMoneyField: React.FC<Props> = (props: Props) => {
  const {
    currencyName,
    moneyName,
    cashAccountCurrencies,
    currencyDisabled,
    formatProps,
    ...textFieldProps
  } = props

  const [ currencyField,, currencyHelper ] = useField(currencyName)
  const [ moneyField, moneyMeta ] = useField(moneyName)

  const onCurrencyChange = (value: string) => currencyHelper.setValue(value)
  const error = moneyMeta.touched && moneyMeta.error

  return (
    <OperationMoneyField
      {...textFieldProps}
      {...moneyField}

      currency={currencyField.value}
      onCurrencyChange={onCurrencyChange}
      cashAccountCurrencies={cashAccountCurrencies}
      currencyDisabled={currencyDisabled}
      error={!!error}
      helperText={localizeError(error)}
      formatProps={formatProps}
    />
  )
}
