import React from 'react'
import { Trans } from 'react-i18next'
import { match } from 'ts-pattern'

import { TransferTransactionHistory, TransactionChangeType } from 'Services/history'
import { Price } from 'Shared/components/Price'
import { Ticker } from 'Shared/components/Ticker'

interface Props {
  history: TransferTransactionHistory
}

export const TransferTransactionHistoryMessage: React.FC<Props> = ({ history }) => {
  const translationKey = React.useMemo(
    () => {
      const changeTypeKey = match(history.changeType)
        .with(TransactionChangeType.Created, () => 'created')
        .with(TransactionChangeType.Updated, () => 'updated')
        .with(TransactionChangeType.RolledBack, () => 'rolledBack')
        .exhaustive()

      return `app.history.transaction.${changeTypeKey}.transfer`
    },
    [ history ])

  return (
    <Trans
      i18nKey={translationKey}
      values={{
        fromAsset: history.fromAsset,
        toAsset: history.toAsset
      }}
      components={{
        unit: history.isCurrency ?
          <Price value={Math.abs(history.amount)} currency={history.unit} inline /> :
          <Ticker value={Math.abs(history.amount)} ticker={history.unit} inline />
      }}
    />
  )
}
