import React, { PropsWithChildren } from 'react'
import { styled } from '@mui/material/styles'
import { Stack, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  onClose: () => void
  actions?: React.ReactElement
}

const DialogHeader = styled(Stack)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.blue.blue4}`,
  padding: theme.spacing(2),
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  columnGap: theme.spacing(1),
}))

export const ViewDialogHeader: React.FC<PropsWithChildren<Props>> = ({ onClose, children, actions }) => (
  <DialogHeader>
    <Stack direction="row" alignItems="center" minWidth={0}>
      {children}
    </Stack>
    <Stack direction="row" spacing={1}>
      {actions}
      <IconButton onClick={onClose} size="small">
        <CloseIcon fontSize="small" />
      </IconButton>
    </Stack>
  </DialogHeader>
)
