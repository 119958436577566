import { differenceInDays, addDays } from 'date-fns'

import {
  UpdateProfitLossRequest,
  UpdateChangeBalanceRequest,
  UpdateBuySellItemRequest,
  UpdateTransferRequest,
  ITransactionsService
} from 'Services/transactions'
import i18next from 'config/i18n'
import { LastUpdatedDate, DemoOperationWarning } from 'Demo/demo-constants'

export class DemoTransactionsService implements ITransactionsService {
  deleteTransaction(transactionKey: string, token: string): any {
    return Promise.reject(DemoOperationWarning)
  }

  getTransactions(token: string, from: Date, to: Date): any {
    const today = new Date()
    const delta = differenceInDays(today, LastUpdatedDate)

    return import('./transactions.json')
      .then(transactions => transactions.default)
      .then(transactions => transactions
        .map(transaction => ({
          ...transaction,
          ...(!!transaction.holding && { holding: i18next.t(transaction.holding, { ns: 'demo' }) }),
          ...(!!transaction.fromHolding && { fromHolding: i18next.t(transaction.fromHolding, { ns: 'demo' }) }),
          ...(!!transaction.toHolding && { toHolding: i18next.t(transaction.toHolding, { ns: 'demo' }) }),
          operationDate: addDays(new Date(transaction.operationDate), delta).toDateString(),
          comment: transaction.comment ? i18next.t(transaction.comment, { ns: 'demo' }) : ''
        }))
        .filter(transaction =>
          new Date(transaction.operationDate) >= from &&
          new Date(transaction.operationDate) <= to
        ))
  }

  updateChangeBalance(key: string, token: string, payload: UpdateChangeBalanceRequest): any {
    return Promise.reject(DemoOperationWarning)
  }

  updateProfitLoss(key: string, token: string, payload: UpdateProfitLossRequest): any {
    return Promise.reject(DemoOperationWarning)
  }

  updateTransfer(key: string, token: string, payload: UpdateTransferRequest): any {
    return Promise.reject(DemoOperationWarning)
  }

  updateBuySell(key: string, token: string, payload: UpdateBuySellItemRequest): any {
    return Promise.reject(DemoOperationWarning)
  }
}
