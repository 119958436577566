import { createSelector } from 'reselect'
import { chain } from 'lodash'

import { SharingStatus } from 'Services/sharing'
import { StoreState } from 'Shared/types'
import { AdvisorPortalState } from './advisorPortal-types'

const storeSelector = (state: StoreState): AdvisorPortalState => state.advisorPortal

const advisoryClients = createSelector(storeSelector, state => state.clients)
const advisoryClientsList = createSelector(storeSelector, state => state.clients.list)
const advisoryClientsTags = createSelector(
  advisoryClientsList,
  state => chain(state)
    .flatMap(client => client.tags)
    .uniq()
    .sort()
    .value())

const pendingSharings = createSelector(
  storeSelector,
  state => state.sharings.filter(sharing => sharing.status === SharingStatus.Pending))

const clientsHistory = createSelector(storeSelector, state => state.clientsHistory)
const clientsFilter = createSelector(storeSelector, state => state.clientsFilter)
const historyFilter = createSelector(storeSelector, state => state.historyFilter)
const organization = createSelector(storeSelector, state => state.organization)

const advisoryClient = createSelector(
  (state: StoreState) => state.advisorPortal.clients.list,
  (state: StoreState, key: string | null) => key,
  (advisoryClients, key) => advisoryClients.find(client => client.key === key) || null
)

export const AdvisorPortalSelectors = {
  advisoryClients,
  advisoryClientsList,
  advisoryClientsTags,
  pendingSharings,
  clientsHistory,
  clientsFilter,
  historyFilter,
  organization,
  advisoryClient,
}
