import ReactDOM from 'react-dom/client'
import { createReduxHistoryContext } from 'redux-first-history'
import { Router } from 'react-router-dom'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { LicenseInfo } from '@mui/x-license-pro'
import * as Sentry from '@sentry/react'

import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-displaynames/polyfill'
import '@formatjs/intl-displaynames/locale-data/en'
import '@formatjs/intl-displaynames/locale-data/uk'
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only'

// TODO: enhance fonts loading and review what font weights we need
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/900.css'

import './global'
import { App, Auth0ProviderWithHistory, rootReducer, rootSaga, ErrorBoundary } from 'App'
import { SnackbarProviderCustom } from 'Snackbar'
import { theme } from 'config/theme'
import 'config/i18n'
import * as serviceWorker from './serviceWorker'
import './config/extensions'

LicenseInfo.setLicenseKey('8c5d95a28dc2aeca459abf4271fc4c27Tz04NzAzNCxFPTE3NDI3NjgxNzkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=')

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const { routerReducer, routerMiddleware, createReduxHistory } = createReduxHistoryContext({ history })
const middleware = applyMiddleware(routerMiddleware, sagaMiddleware)

const store = createStore(rootReducer(routerReducer), composeWithDevTools(middleware))
const reduxHistory = createReduxHistory(store)

sagaMiddleware.run(rootSaga)

if (process.env.REACT_APP_ENV === 'test' || process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://efc170c01d31437d9dbb31b0668b52c7@o1402291.ingest.sentry.io/6734399',
    environment: process.env.REACT_APP_ENV,
    defaultIntegrations: false,
  })
}

const rootElement = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const render = () => rootElement.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <ErrorBoundary>
      <Provider store={store}>
        <Router history={reduxHistory}>
          <Auth0ProviderWithHistory>
            <SnackbarProviderCustom>
              <App />
            </SnackbarProviderCustom>
          </Auth0ProviderWithHistory>
        </Router>
      </Provider>
    </ErrorBoundary>
  </ThemeProvider>
)

render()
serviceWorker.unregister()
