import React from 'react'

import { ConfiguredNumberFormat } from 'Shared/components/ConfiguredNumberFormat'
import { WithHelp } from 'Shared/components/WithHelp'
import { BaseNumberTextField } from './BaseNumberTextField'

interface NumberFieldProps {
  name: string
  label: string
  placeholder: string
  prefix?: string
  helpText?: string | React.ReactElement
  required?: boolean
  disabled?: boolean
  decimalScale?: number
  maxLength?: number
  allowNegative?: boolean
  autoFocus?: boolean
  onFocus?: () => void
}

const NumberFieldBase: React.FC<NumberFieldProps> = ({
  name,
  label,
  prefix,
  placeholder,
  helpText = '',
  required = false,
  disabled = false,
  decimalScale = 2,
  maxLength = null,
  allowNegative = true,
  autoFocus = false,
  onFocus
}) => (
  <WithHelp helpText={helpText} isInputHelp>
    <BaseNumberTextField
      name={name}
      label={label}
      placeholder={placeholder}
      prefixSymbol={prefix}
      inputComponent={ConfiguredNumberFormat}
      inputProps={{
        decimalScale: decimalScale,
        ...(maxLength !== null && { maxLength }),
        allowNegative,
        autoFocus,
      }}
      disabled={disabled}
      required={required}
      onFocus={onFocus}
    />
  </WithHelp>
)

export const NumberField = React.memo(NumberFieldBase)
