import React from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonLink, SubTextSecondary1 } from 'Shared/components'
import { SubscriptionPlanContainer } from './SubscriptionPlanContainer'

interface Props {
  onSubscribeClick: () => void
}

export const NoSubscription: React.FC<Props> = ({ onSubscribeClick }) => {
  const { t } = useTranslation()

  return (
    <SubscriptionPlanContainer isPremium={false}>
      <SubTextSecondary1>
        <ButtonLink onClick={onSubscribeClick}>{t('common.subscribe')}</ButtonLink>
      </SubTextSecondary1>
    </SubscriptionPlanContainer>
  )
}
