import React from 'react'
import { Trans } from 'react-i18next'
import { match, P } from 'ts-pattern'

import { SingleTransactionHistory, TransactionType, TransactionChangeType } from 'Services/history'
import { Price } from 'Shared/components/Price'

interface Props {
  history: SingleTransactionHistory
}

export const SingleTransactionHistoryMessage: React.FC<Props> = ({ history }) => {
  const translationKey = React.useMemo(
    () => {
      const operationKey = match(history)
        .with({ operation: TransactionType.DepositWithdrawal, amount: P.when(v => v >= 0) }, () => 'deposit')
        .with({ operation: TransactionType.DepositWithdrawal, amount: P.when(v => v < 0) }, () => 'withdrawal')
        .with({ operation: TransactionType.ProfitLoss, amount: P.when(v => v >= 0) }, () => 'profit')
        .with({ operation: TransactionType.ProfitLoss, amount: P.when(v => v < 0) }, () => 'loss')
        .run()

      const changeTypeKey = match(history.changeType)
        .with(TransactionChangeType.Created, () => 'created')
        .with(TransactionChangeType.Updated, () => 'updated')
        .with(TransactionChangeType.RolledBack, () => 'rolledBack')
        .exhaustive()

      return `app.history.transaction.${changeTypeKey}.${operationKey}`
    },
    [ history ])

  return (
    <Trans
      i18nKey={translationKey}
      values={{ asset: history.asset }}
      components={{
        price: <Price value={Math.abs(history.amount)} currency={history.currency} inline />,
      }}
    />
  )
}
