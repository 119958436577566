import React, { useContext } from 'react'
import { Box, Stack } from '@mui/material'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useTranslation } from 'react-i18next'

import { TextPrimary, SubTextSecondary1 } from 'Shared/components'
import { SupportedLanguage, languageService } from 'config/i18n-types'
import { SettingsContext } from 'App/Settings'

export const UserSettingsPreview: React.FC = () => {
  const { t, i18n } = useTranslation()
  const { baseCurrency, profile } = useContext(SettingsContext)

  const selectedLanguage: SupportedLanguage = i18n.resolvedLanguage as SupportedLanguage
  const selectedLanguageTranslation = languageService.getTranslation(selectedLanguage ?? SupportedLanguage.EN)

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={1}>
        <AccountCircleIcon fontSize="small" />
        <Box>
          <TextPrimary>{t('app.navbar.settings.username')}:</TextPrimary>
          {profile.username ? (
            <SubTextSecondary1>@{profile.username}</SubTextSecondary1>
          ) : (
            <SubTextSecondary1>{t('common.state.notSpecified-he')}</SubTextSecondary1>
          )}
        </Box>
      </Stack>

      <Stack direction="row" spacing={1}>
        <AccountBalanceWalletOutlinedIcon fontSize="small" />
        <Box>
          <TextPrimary>{t('app.navbar.settings.currency')}:</TextPrimary>
          <SubTextSecondary1>{baseCurrency}</SubTextSecondary1>
        </Box>
      </Stack>

      <Stack direction="row" spacing={1}>
        <TranslateOutlinedIcon fontSize="small" />
        <Box>
          <TextPrimary>{t('app.navbar.settings.language')}:</TextPrimary>
          <SubTextSecondary1>{t(selectedLanguageTranslation)}</SubTextSecondary1>
        </Box>
      </Stack>
    </Stack>
  )
}
