import React from 'react'
import { Stack, Typography } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'

import { ChartTooltip } from 'Shared/components'

interface AssetAllocationChartTooltipProps {
  label: string
  value: number
  color: string
}

const AssetAllocationChartTooltipBase: React.FC<AssetAllocationChartTooltipProps> = ({ label, value, color }) => (
  <ChartTooltip>
    <Stack direction="row" alignItems="center" spacing={1}>
      <CircleIcon fontSize="small" sx={{ color }} />
      <Typography fontSize="inherit">{label}: {value}%</Typography>
    </Stack>
  </ChartTooltip>
)

export const AssetAllocationChartTooltip = React.memo(AssetAllocationChartTooltipBase)
