import { CanceledError } from 'axios'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { IAnalyticsService, GetChartRequest, ChartPreRender, ChartsResponse } from 'Services/analytics'
import type { IActionWithToken, ITypedActionWithToken } from 'Shared/types'
import { container, Service } from 'Services/container'
import { snackbarActionCreators } from 'Snackbar'
import { AnalyticsActions } from './analytics-actions'
import { AnalyticsActionTypes } from './analytics-constants'
import { AnalyticsSelectors } from './analytics-selectors'
import type { ChartDetails, ChartFilterState } from './analytics-types'
import { ChartType } from './analytics-types'

export function* getCharts(action: IActionWithToken) {
  try {
    const analyticsService = container.resolve<IAnalyticsService>(Service.AnalyticsService)
    const response: ChartsResponse = yield call(analyticsService.getCharts, action.token, action.abortSignal)

    yield put(AnalyticsActions.getChartsSuccess(response.charts))
    yield put(AnalyticsActions.setChartsTags(response.tags))
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      yield put(AnalyticsActions.getChartsFailure())
      yield put(snackbarActionCreators.enqueueErrorSnackbar('analytics.chartsLoading-failure'))
    }
  }
}

export function* getChart(action: ITypedActionWithToken<GetChartRequest>) {
  const analyticsService = container.resolve<IAnalyticsService>(Service.AnalyticsService)

  try {
    const response: ChartDetails = yield call(
      analyticsService.getChart,
      action.payload,
      action.token,
      action.abortSignal)
    yield put(AnalyticsActions.getChartSuccess(action.payload.key, response))
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      yield put(AnalyticsActions.getChartFailure(action.payload.key))
      yield put(snackbarActionCreators.enqueueErrorSnackbar('analytics.chartLoading-failure'))
    }
  }
}

export function* refreshAssetAllocationCharts(action: IActionWithToken) {
  try {
    const chartPreRenders: ChartPreRender[] = yield select(AnalyticsSelectors.chartPreRenders)
    const chartFilter: ChartFilterState = yield select(AnalyticsSelectors.chartFilter)

    const chartsToUpdate = chartPreRenders
      .filter(chart =>
        chart.type === ChartType.PROPERTY_COMPARING_TO_ALLOCATION ||
        chart.type === ChartType.SCORECARD)
      .map(chart => chart.key)

    for(const chart of chartsToUpdate) {
      yield put(AnalyticsActions.getChart({ key: chart, tags: chartFilter.selectedTags }, action.token))
    }
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      yield put(snackbarActionCreators.enqueueErrorSnackbar('analytics.chartsLoading-failure'))
    }
  }
}

export function* refreshSunburstExpectedAllocationChart(action: IActionWithToken) {
  try {
    const chartPreRenders: ChartPreRender[] = yield select(AnalyticsSelectors.chartPreRenders)
    const chartFilter: ChartFilterState = yield select(AnalyticsSelectors.chartFilter)

    const chartsToUpdate = chartPreRenders
      .filter(chart => chart.type === ChartType.EXPECTED_SUNBURST)
      .map(chart => chart.key)

    for(const chart of chartsToUpdate) {
      yield put(AnalyticsActions.getChart({ key: chart, tags: chartFilter.selectedTags }, action.token))
    }
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      yield put(snackbarActionCreators.enqueueErrorSnackbar('analytics.chartsLoading-failure'))
    }
  }
}

export function* analyticsSaga() {
  yield all([
    takeEvery(AnalyticsActionTypes.GET_CHARTS, getCharts),
    takeEvery(AnalyticsActionTypes.GET_CHART, getChart),
    takeEvery(AnalyticsActionTypes.REFRESH_ALLOCATION_CHARTS, refreshAssetAllocationCharts),
    takeEvery(AnalyticsActionTypes.REFRESH_SUNBURST_ALLOCATION_CHARTS, refreshSunburstExpectedAllocationChart),
  ])
}
