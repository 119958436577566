import CloseIcon from '@mui/icons-material/Close'
import { CircularProgress, Drawer, IconButton, Stack, styled } from '@mui/material'
import React from 'react'

import { TextLarger } from 'Shared/components/Typography'
import { WithHelp } from 'Shared/components/WithHelp/WithHelp'

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 3,
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    maxWidth: 400,
    minWidth: 400,
    width: 400,
    [theme.breakpoints.down('mobileLarge')]: {
      maxWidth: '100%',
      minWidth: '100%',
      width: '100%',
    }
  }
}))

export interface SideDrawerProps {
  title: string
  helpText?: string
  isOpen: boolean
  isLoading?: boolean
  open?: () => void
  close?: () => void
}

export const SideDrawer: React.FC<React.PropsWithChildren<SideDrawerProps>> = ({
  title,
  helpText,
  isOpen,
  children,
  isLoading = false,
  open = () => {},
  close = () => {},
}) => {
  const closeDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    const preventClose =
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')

    if (preventClose) {
      return
    }

    close()
  }

  return (
    <StyledDrawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
    >
      <Stack data-testid="side-drawer" spacing={3} alignItems="stretch" p={3}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <WithHelp helpText={helpText}>
            <TextLarger sx={{ fontWeight: 500 }}>{title}</TextLarger>
          </WithHelp>
          <IconButton onClick={closeDrawer} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Stack>

        {isLoading ? (
          <Stack height="80vh" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : children}
      </Stack>
    </StyledDrawer>
  )
}
