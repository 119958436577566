import { styled } from '@mui/material/styles'
import { Paper } from '@mui/material'

export const ContentPaper = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.palette.blue.blue3}`,
  padding: theme.spacing(2),
  boxSizing: 'border-box',
})) as typeof Paper

export const PrimaryContentPaper = styled(ContentPaper)(({ theme }) => ({
  backgroundColor: theme.palette.blue.blue5,
  border: `1px solid ${theme.palette.blue.blue1}`,
})) as typeof Paper

export const SecondaryContentPaper = styled(ContentPaper)(({ theme }) => ({
  backgroundColor: theme.palette.blue.blue6,
})) as typeof Paper

export const HighlightedContentPaper = styled(ContentPaper)(({ theme }) => ({
  backgroundColor: theme.palette.dark.dark,
  color: theme.palette.common.white,
})) as typeof Paper

export const EmptyContentPaper = styled(SecondaryContentPaper)(({ theme }) => ({
  color: theme.palette.text.secondary,
  borderStyle: 'dashed',
}))
