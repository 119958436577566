
import { Drawer, List, ListItem, ListItemText, Stack, styled } from '@mui/material'
import { t } from 'i18next'
import { Link as RouterLink } from 'react-router-dom'
import { DrawerWidth, MobileHeaderHeight } from 'Shared/constants'

const StyledNavigationStack = styled(Stack)(() => ({
  minWidth: DrawerWidth,
  flexShrink: 0,
  overflowX: 'hidden',
  justifyContent: 'flex-start',
  alignContent: 'flex-start',
  marginTop: MobileHeaderHeight
})) as typeof Drawer

const StyledListItem = styled(ListItem)(({ theme }) => ({
  minHeight: 48,
  padding: theme.spacing(1, 2),
  fontSize: theme.typography.textPrimary.fontSize,
  lineHeight: theme.spacing(3),
  cursor: 'pointer',
  ['a']: {
    color: 'inherit',
    textDecoration: 'none'
  }
}))

interface Props {
  sections: Record<string, {label: string, id: string}>
  onClose: (event: React.KeyboardEvent | React.MouseEvent) => void
}

export const MobileLandingAnchorLinks = ({ sections, onClose }: Props) => (
  <StyledNavigationStack>
    <List sx={{ py: 0 }}>
      {Object.values(sections).map(({ label, id }) => (
        <StyledListItem key={id} disablePadding>
          <RouterLink
            to={`#${id}`}
            onClick={e => {
              onClose(e)
              document.getElementById(id)?.scrollIntoView({ block: 'start', behavior: 'smooth' })
            }}
          >
            <ListItemText primary={t(label)} sx={{ textOverflow: 'ellipsis' }} />
          </RouterLink>
        </StyledListItem>
      ))}
    </List>
  </StyledNavigationStack>
)
