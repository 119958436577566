import React from 'react'
import { useTranslation } from 'react-i18next'
import { TypographyProps } from '@mui/material'

import { Money } from 'Shared/types'
import { getCurrencySymbol } from 'Shared/utils'
import { SupportedLanguage, languageService } from 'config/i18n-types'
import { DecimalOutput } from './Price-types'
import { CentsContainer, PriceContainer } from './Price-shared'
import { getPriceColor, getPriceSign } from './Price-utils'

interface Props {
  value: Money
  currency: string
  decimalOutput: DecimalOutput,
  isHighlighted: boolean,
  inline: boolean,
}

export const BasePrice: React.FC<Props & TypographyProps> = React.memo(props => {
  const { value, currency, decimalOutput, isHighlighted, inline, ...typographyProps } = props
  const { i18n } = useTranslation()

  const language = i18n.resolvedLanguage as SupportedLanguage
  const showCents = decimalOutput === 'all' || (decimalOutput === 'nonZero' && value.cents !== 0)

  const currencySymbol = React.useMemo(() => getCurrencySymbol(currency), [ currency ])
  const color = React.useMemo(() => getPriceColor(value, isHighlighted, showCents), [ value, isHighlighted, showCents ])
  const sign = React.useMemo(() => getPriceSign(value, isHighlighted, showCents), [ value, isHighlighted, showCents ])

  const priceDisplayParts = React.useMemo(() => ({
    currencyDisplaySymbol: `${currencySymbol || currency}\xa0`,
    dollarsDisplayValue: value.dollars.toLocaleString(languageService.getLocale(language).code),
    decimalSeparator: languageService.getDecimalSeparator(language),
    centsDisplayValue: value.cents.toString().slice(2) || '0'
  }), [ value, currency, language ])

  const title = React.useMemo(
    () => [
      sign,
      priceDisplayParts.currencyDisplaySymbol,
      priceDisplayParts.dollarsDisplayValue,
      showCents ? `${priceDisplayParts.decimalSeparator}${priceDisplayParts.centsDisplayValue}` : '',
    ].join(''),
    [ sign, priceDisplayParts ])

  return (
    <PriceContainer
      {...typographyProps}
      component="span"
      color={color}
      title={title}
      display={inline ? 'inline' : 'block'}
      noWrap
    >
      {sign}
      {priceDisplayParts.currencyDisplaySymbol}
      {priceDisplayParts.dollarsDisplayValue}
      {showCents && (
        <CentsContainer component="span" sx={{ ...(color && { color }) }}>
          {priceDisplayParts.decimalSeparator}{priceDisplayParts.centsDisplayValue}
        </CentsContainer>
      )}
    </PriceContainer>
  )
})
