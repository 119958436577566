import { Chip, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { styledWithCustomProp } from 'config/theme'
import { RenewalPeriod } from 'Services/profile'
import { PrimaryContentPaper } from 'Shared/components'

const StyledChip = styled(Chip)(({ theme }) => ({
  marginLeft: theme.spacing(0.75),
  padding: `${theme.spacing(0.25)} ${theme.spacing(0.75)}`,
  background: theme.palette.green.green1,
  height: 'unset',
  borderRadius: 2,
  '& .MuiChip-label': {
    color: theme.palette.common.white,
    fontSize: 10,
    fontWeight: 'normal',
    lineHeight: 1.5,
    padding: 0
  }
}))

const Container = styled(PrimaryContentPaper)(({ theme }) => ({
  padding: theme.spacing(0.75),
  width: 'fit-content',
  maxWidth: '100%',
}))

const PeriodGroups = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}))

const Period = styledWithCustomProp('div', 'isActive')<{ isActive: boolean } >(({ theme, isActive }) => ({
  display: 'flex',
  padding: `${theme.spacing(.5)} ${theme.spacing(1.75)}`,
  alignItems: 'center',
  borderRadius: 4,
  backgroundColor: isActive ? theme.palette.primary.main : 'transparent',
  color: isActive ? theme.palette.common.white : theme.palette.dark.dark1,
  fontSize: theme.typography.subTextPrimary1.fontSize,
  lineHeight: theme.typography.subTextPrimary1.lineHeight,
  fontWeight: 700,
  letterSpacing: 0.15,
  cursor: 'pointer',
  [':hover']: {
    color: isActive ? theme.palette.common.white : theme.palette.primary.main,
  },
}))

interface Props {
  renewalPeriod: string
  onRenewalPeriodChanged: (period: RenewalPeriod) => void
  monthDiscountTextKey?: string
  yearDiscountTextKey?: string
}

export const PricingPeriodSelector: React.FC<Props> = ({
  renewalPeriod,
  onRenewalPeriodChanged,
  monthDiscountTextKey,
  yearDiscountTextKey
}) => {
  const { t } = useTranslation()

  return (
    <Container elevation={0}>
      <PeriodGroups>
        <Period
          isActive={renewalPeriod === RenewalPeriod.Month}
          onClick={() => onRenewalPeriodChanged(RenewalPeriod.Month)}
        >
          <span>{t('pricing.period.monthly')}</span>
          {monthDiscountTextKey && <StyledChip label={t(monthDiscountTextKey)} variant="filled" />}
        </Period>

        <Period
          isActive={renewalPeriod === RenewalPeriod.Year}
          onClick={() => onRenewalPeriodChanged(RenewalPeriod.Year)}
        >
          <span>{t('pricing.period.yearly')}</span>
          {yearDiscountTextKey && <StyledChip label={t(yearDiscountTextKey)} variant="filled" />}
        </Period>
      </PeriodGroups>
    </Container>
  )
}
