import type { SnackbarKey } from 'notistack'

import type { ITypedAction } from 'Shared/types'
import { SnackbarActions } from './snackbar-constants'
import type { EnqueuePayload, Snackbar } from './snackbar-types'

export const enqueueSuccessSnackbar = (key: string, param: any = null): ITypedAction<EnqueuePayload> => ({
  type: SnackbarActions.ENQUEUE_SUCCESS_SNACKBAR,
  payload: {
    message: { key, param },
    variant: 'success',
  },
})

export const enqueueWarningSnackbar = (key: string, param: any = null): ITypedAction<EnqueuePayload> => ({
  type: SnackbarActions.ENQUEUE_WARNING_SNACKBAR,
  payload: {
    message: { key, param },
    variant: 'warning',
  },
})

export const enqueueErrorSnackbar = (key: string, param: any = null): ITypedAction<EnqueuePayload> => ({
  type: SnackbarActions.ENQUEUE_ERROR_SNACKBAR,
  payload: {
    message: { key, param },
    variant: 'error',
  },
})

export const closeSnackbar = (payload: SnackbarKey): ITypedAction<SnackbarKey> => ({
  type: SnackbarActions.CLOSE_SNACKBAR,
  payload
})

export const removeSnackbar = (payload: SnackbarKey): ITypedAction<SnackbarKey> => ({
  type: SnackbarActions.REMOVE_SNACKBAR,
  payload,
})

export const updateSnackbars = (snackbars: Snackbar[]): ITypedAction<Snackbar[]> => ({
  type: SnackbarActions.UPDATE_SNACKBAR,
  payload: snackbars,
})

export const addSnackbar = (payload: Snackbar): ITypedAction<Snackbar> => ({
  type: SnackbarActions.ADD_SNACKBAR,
  payload
})

// TODO: rename SnackbarActions -> SnackbarActionTypes
// TODO: export const SnackbarActions = {}
