import React from 'react'
import { useTranslation } from 'react-i18next'

import { SideDrawer, NoDataOverlay, FlatHistoryList } from 'Shared/components'
import { useMyActionsHistory } from './useMyActionsHistory'

interface Props {
  isOpen: boolean
  close: () => void
}

export const HistoryDrawer: React.FC<Props> = ({ isOpen, close }) => {
  const { t } = useTranslation()
  const { history, isLoading, fetchUserHistory } = useMyActionsHistory()

  React.useEffect(
    () => { isOpen && fetchUserHistory() },
    [ isOpen ])

  return (
    <SideDrawer
      title={t('app.history.name')}
      isOpen={isOpen}
      close={close}
      isLoading={isLoading}
    >
      {history && history.length !== 0 ? (
        <FlatHistoryList historyItems={history} />
      ) : (
        <NoDataOverlay />
      )}
    </SideDrawer>
  )
}
