import { call, put, all, takeEvery } from 'redux-saga/effects'

import { ITypedActionWithToken } from 'Shared/types'
import { PortfolioAllocationType, IPortfolioService, AssetAllocation } from 'Services/portfolio'
import { container, Service } from 'Services/container'
import { snackbarActionCreators } from 'Snackbar'
import { AssetAllocationActions } from './assetAllocation-actions'
import { AssetAllocationTypes } from './assetAllocation-constants'

export function* getAssetAllocation(action: ITypedActionWithToken<PortfolioAllocationType>) {
  const portfolioService = container.resolve<IPortfolioService>(Service.PortfolioService)

  try {
    const response: AssetAllocation = yield call(
      portfolioService.getAssetAllocation,
      action.token,
      action.payload)
    yield put(AssetAllocationActions.getAssetAllocationSuccess(response))
  } catch (error) {
    yield put(snackbarActionCreators.enqueueErrorSnackbar('app.navbar.asset-allocation.loading-failure'))
    yield put(AssetAllocationActions.getAssetAllocationFailure())
  }
}

export function* getMyAssetAllocation(action: ITypedActionWithToken<PortfolioAllocationType>) {
  const portfolioService = container.resolve<IPortfolioService>(Service.PortfolioService)

  try {
    const response: AssetAllocation = yield call(
      portfolioService.getMyAssetAllocation,
      action.token,
      action.payload)
    yield put(AssetAllocationActions.getAssetAllocationSuccess(response))
  } catch (error) {
    yield put(snackbarActionCreators.enqueueErrorSnackbar('app.navbar.asset-allocation.loading-failure'))
    yield put(AssetAllocationActions.getAssetAllocationFailure())
  }
}

export function* assetAllocationSaga() {
  yield all([
    takeEvery(AssetAllocationTypes.GET_ASSET_ALLOCATION, getAssetAllocation),
    takeEvery(AssetAllocationTypes.GET_MY_ASSET_ALLOCATION, getMyAssetAllocation),
  ])
}
