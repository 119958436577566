/* eslint-disable max-len */
import { SVGProps } from 'react'

export const TickIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Check">
      <g id="Vector">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.1219 3.62815C12.2927 3.79901 12.2927 4.07602 12.1219 4.24687L5.99685 10.3716C5.826 10.5424 5.54901 10.5424 5.37815 10.3716L2.31565 7.30937C2.14479 7.13853 2.14478 6.86152 2.31563 6.69065C2.48647 6.51979 2.76348 6.51978 2.93435 6.69063L5.68749 9.44353L11.5031 3.62813C11.674 3.45728 11.951 3.45729 12.1219 3.62815Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M11.3485 3.47345C11.6048 3.21717 12.0203 3.21718 12.2765 3.47347C12.5328 3.72976 12.5328 4.14527 12.2765 4.40155L6.15153 10.5263C5.89525 10.7825 5.47976 10.7825 5.22348 10.5263L2.16098 7.46406C1.90469 7.20779 1.90467 6.79228 2.16094 6.53598C2.41721 6.27969 2.83273 6.27967 3.08902 6.53594L5.68749 9.13418L11.3485 3.47345ZM11.9672 3.78282C11.8818 3.69739 11.7433 3.69739 11.6578 3.78282L5.84217 9.59821C5.75674 9.68363 5.61825 9.68363 5.53282 9.59822L2.77967 6.84531C2.69424 6.75989 2.55574 6.7599 2.47031 6.84533C2.38489 6.93076 2.3849 7.06926 2.47033 7.15469L5.53283 10.2169C5.61825 10.3023 5.75675 10.3023 5.84218 10.2169L11.9672 4.09218C12.0526 4.00676 12.0526 3.86825 11.9672 3.78282Z" fill="currentColor" />
      </g>
    </g>
  </svg>
)
