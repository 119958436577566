import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { useField } from 'formik'
import React from 'react'

import { styledWithCustomProp } from 'config/theme'
import type { Nullable, SelectOption } from 'Shared/types'
import { localizeError } from 'Shared/utils'

const StyledMenuItem = styledWithCustomProp(MenuItem, 'isEmptyOption')<{ isEmptyOption?: boolean }>(({ isEmptyOption }) => ({
  color: isEmptyOption ? 'text.secondary' : '',
  whiteSpace: 'normal',
}))

export interface SelectProps {
  name: string
  options: SelectOption[]
  required?: boolean
  disabled?: boolean
  label?: string
  customOnChange?: (value: Nullable<string>) => void
}

export const Select: React.FC<SelectProps> = (props: SelectProps) => {
  const {
    name,
    required,
    label,
    disabled,
    options,
    customOnChange,
  } = props
  const [ field, meta ] = useField(props)
  const error = meta.touched && meta.error

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e)
    if (customOnChange) {
      customOnChange(e?.target?.value)
    }
  }

  const valueText = React.useMemo((): string => {
    const option = options.find(option => option.value === field.value)
    return typeof option?.text === 'string' ? option.text : ''
  }, [ field.value, options ])

  return (
    <TextField
      select
      fullWidth
      id={name}
      data-testid={name}
      error={!!error}
      label={label}
      required={required}
      helperText={localizeError(error)}
      disabled={disabled}
      variant="outlined"
      size="small"
      title={valueText}
      {...field}
      onChange={handleOnChange}
    >
      {options.map((option: SelectOption) => (
        <StyledMenuItem
          value={option.value}
          key={option.value}
          disabled={option.disabled}
          isEmptyOption={option.isEmptyOption}
        >
          {option.text}
        </StyledMenuItem>
      ))}
    </TextField>
  )
}
