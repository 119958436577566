import React from 'react'

import { container, Service } from 'Services/container'
import { ISessionStorageService } from 'Services/sessionStorage/sessionStorage-types'

export const useSessionState = <T>(sessionKey: string, defaultValue: T) => {
  const sessionStorageService = container.resolve<ISessionStorageService>(Service.SessionStorageService)
  const [ value, setValue ] = React.useState(sessionStorageService.getItem<T>(sessionKey) || defaultValue)

  const setSessionValue = (newValue: T) => {
    setValue(newValue)
    sessionStorageService.setItem(sessionKey, newValue)
  }

  return [ value, setSessionValue ] as const
}
