import { Stack } from '@mui/material'
import React from 'react'

import { ToggleHiddenModeMobile, UserMenu } from 'App/NavigationBar/components'
import { ClientHistoryButtonMobile } from './ClientHistoryButtonMobile'

export const AppBarMobileToolbar = () => (
  <Stack direction="row" alignItems="center" columnGap={1}>
    <ToggleHiddenModeMobile />
    <ClientHistoryButtonMobile />
    <UserMenu />
  </Stack>
)
