import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Stack } from '@mui/material'

import { styledWithCustomProp } from 'config/theme'
import { SupportedLanguage } from 'config/i18n-types'
import { ExternalLinks } from 'Shared/constants'
import { FooterLink } from './FooterLink'

const LinkGroup = styledWithCustomProp(Stack, 'light')<{ light?: boolean }>(({ theme, light }) => ({
  color: light ? theme.palette.common.white : theme.palette.common.black,
  fontWeight: 600,
  'a': {
    marginRight: theme.spacing(3),
    display: 'inline-block',
  },

  [theme.breakpoints.down('tablet')]: {
    marginBottom: theme.spacing(3),
  },

  [theme.breakpoints.down('desktop')]: {
    'a': {
      marginBottom: theme.spacing(1),
    },
  },
}))

export const LegalLinkGroupBase: React.FC<WithTranslation & { light?: boolean }> = ({ t, i18n, light }) => (
  <LinkGroup direction={{ xs: 'column', lg: 'row' }} light={light}>
    <FooterLink href={ExternalLinks.LEGAL.PRIVACY_POLICY(i18n.resolvedLanguage ?? SupportedLanguage.EN)} text={t('app.footer.legal.privacyPolicy')} />
    <FooterLink href={ExternalLinks.LEGAL.TERMS_OF_USE(i18n.resolvedLanguage ?? SupportedLanguage.EN)} text={t('app.footer.legal.termsOfUse')} />
    <FooterLink href={ExternalLinks.LEGAL.COOKIE_POLICY(i18n.resolvedLanguage ?? SupportedLanguage.EN)} text={t('app.footer.legal.cookiePolicy')} />
    <FooterLink href={ExternalLinks.LEGAL.PUBLIC_OFFER_AGREEMENT(i18n.resolvedLanguage ?? SupportedLanguage.EN)} text={t('app.footer.legal.publicOffer')} />
  </LinkGroup>
)

export const LegalLinkGroup = withTranslation()(LegalLinkGroupBase)
