import React from 'react'
import { DateRange, DateRangePicker as DateRangePickerMUI } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ukUA, enUS } from '@mui/x-date-pickers/locales'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { muiMinDate } from 'Shared/constants'
import { SupportedLanguage, languageService } from 'config/i18n-types'

interface DateRangePickerProps {
  defaultStartDate: Date | null
  defaultEndDate: Date | null
  rangeSelectedHandler: Function
  disableFuture?: boolean
  disablePast?: boolean
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  defaultStartDate,
  defaultEndDate,
  rangeSelectedHandler,
  disableFuture = false,
  disablePast = false,
}) => {
  const { t, i18n } = useTranslation()
  const [ dateRange, setDateRange ] = React.useState<DateRange<Date>>([ defaultStartDate, defaultEndDate ])
  const [ fromError, setFromError ] = React.useState(false)
  const [ toError, setToError ] = React.useState(false)

  const locale = React.useMemo(() => {
    const language = i18n.resolvedLanguage as SupportedLanguage
    return languageService.getLocale(language ?? SupportedLanguage.EN)
  }, [ i18n.resolvedLanguage ])

  const localeText = React.useMemo(
    () => i18n.resolvedLanguage === SupportedLanguage.UK ?
      ukUA.components.MuiLocalizationProvider.defaultProps.localeText :
      enUS.components.MuiLocalizationProvider.defaultProps.localeText,
    [ i18n.resolvedLanguage ])

  const localeTextOverride = React.useMemo(() => ({
    dateRangePickerToolbarTitle: t('common.dateRange.title'),
    start: t('common.dateRange.startDate'),
    end: t('common.dateRange.endDate'),
    cancelButtonLabel: t('common.actions.cancel'),
    okButtonLabel: t('common.actions.confirm'),
    nextMonth: t('common.dateRange.nextMonth'),
    previousMonth: t('common.dateRange.previousMonth'),
  }), [ ])

  const dateValidationSchema = React.useMemo(
    () => Yup
      .date()
      .required()
      .min(muiMinDate),
    [ muiMinDate ])

  React.useEffect(() => {
    setDateRange([ defaultStartDate, defaultEndDate ])

    const isFromValid = dateValidationSchema.isValidSync(defaultStartDate)
    const isToValid = dateValidationSchema.isValidSync(defaultEndDate)

    setFromError(!isFromValid)
    setToError(!isToValid)
  }, [ defaultStartDate, defaultEndDate ])

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={locale}
      localeText={localeText}
    >
      <DateRangePickerMUI
        localeText={localeTextOverride}
        value={dateRange}
        minDate={muiMinDate}
        disableFuture={disableFuture}
        disablePast={disablePast}
        slotProps={{
          textField: ({ position }) => ({
            variant: 'outlined',
            size: 'small',
            fullWidth: true,
            error: position === 'start' ? fromError : toError,
          }),
          fieldSeparator: { sx: { display: 'none' } },
        }}
        onChange={(newRange: any) => {
          const [ from, to ] = newRange

          const isFromValid = dateValidationSchema.isValidSync(from)
          const isToValid = dateValidationSchema.isValidSync(to)

          setFromError(!isFromValid)
          setToError(!isToValid)
          setDateRange(newRange)

          if (isToValid && isFromValid && from <= to) {
            rangeSelectedHandler(from, to)
          }
        }}
      />
    </LocalizationProvider>
  )
}
