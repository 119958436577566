import React, { useContext } from 'react'
import { Alert } from '@mui/material'
import { Trans } from 'react-i18next'

import { useAuthorization } from 'Integration/authorization'
import { SettingsContext } from 'App/Settings'
import { ButtonLink } from 'Shared/components'
import { RoleName } from 'Integration/authorization/authorization-types'

export const PaymentIssuesNotification: React.FC = () => {
  const { manageSettings, profile } = useContext(SettingsContext)
  const authorization = useAuthorization()

  if (!authorization.isInRole(RoleName.UserWithPaymentIssues)) return null

  return (
    <Alert severity="error">
      {profile?.subscription?.paymentProvider === 'Fondy' ? (
        <Trans
          i18nKey="app.navbar.settings.subscription.paymentIssuesFondy-notification"
          components={{
            settingsLink: <ButtonLink onClick={manageSettings.settings.open} />
          }}
        />
      ) : (
        <Trans
          i18nKey="app.navbar.settings.subscription.paymentIssues-notification"
          components={{
            settingsLink: <ButtonLink onClick={manageSettings.settings.open} />
          }}
        />
      )}
    </Alert>
  )
}
