import { PricingTier } from 'Services/profile'
import type { SettingsState } from './settings-types'

export const DefaultSettingsState: SettingsState = {
  essentialsLoaded: false,
  baseCurrency: 'USD',
  currencies: [ 'USD', 'EUR', 'UAH' ],
  pendingSurveys: [],
  profile: {
    username: null,
    isAdvisor: false,
    advisorRole: null,
    isAdvisoryClient: false,
    organization: null,
    tier: PricingTier.Free,
    subscription: null,
    brandNew: false,
  },
  isHiddenMode: true,
  userGuide: {
    enabled: false,
    config: {
      displayWhenGuidePassedAlready: false,
      stepsRedirects: [],
    }
  },
  assetAllocationOpened: false,
  myTasksOpened: false,
  myHistoryOpened: false,
  sharedHistoryOpened: false,
  releaseNotesDialogOpened: false,
  settingsDrawerOpened: false,
  appBarExpanded: true,
  promoCode: null,
  manageSettings: {
    refreshProfile: () => {},
    passSurvey: name => {},
    changeBaseCurrency: currency => {},
    setHiddenMode: enabled => {},
    toggleHiddenMode: () => {},
    userGuide: {
      runUserGuide: () => {},
      completeUserGuide: () => {},
    },
    promo: {
      save: (promoCode: string) => {},
      clear: () => {},
    },
    assetAllocation: {
      open: () => {},
      close: () => {},
      value: null,
    },
    myHistory: {
      open: () => {},
      close: () => {},
    },
    myTasks: {
      open: () => {},
      close: () => {},
    },
    sharedHistory: {
      open: () => {},
      value: null,
      close: () => {},
    },
    // Note: Temporary (eventually will integrate notification provider)
    releaseNotesDialog: {
      open: () => {},
      close: () => {},
      value: null,
      releaseNotesRead: true,
      readReleaseNotes: () => {},
    },
    appBar: {
      toggle: () => {},
    },
    settings: {
      open: () => {},
      close: () => {},
    }
  },
}
