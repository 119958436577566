import { all, put, takeEvery, select } from 'redux-saga/effects'
import { chain } from 'lodash'

import { Transaction } from 'Services/transactions'
import { ITypedAction } from 'Shared/types'
import { TransactionsActions } from 'Transactions/transactions-actions'
import { TransactionsActionTypes } from 'Transactions/transactions-constants'
import { PredictionActions } from 'Prediction/prediction-actions'
import { DefaultDemoPredictionState, DemoActionTypes, NumberOfTransactionsToSelect } from 'Demo/demo-constants'
import { globalCleanState } from 'Integration/sagas'
import { DemoSelectors } from './demo-selectors'
import { DemoActions } from './demo-actions'

function* getTransactionsSuccess(action: ITypedAction<Transaction[]>) {
  const demoPageOpened = window.location.href.includes('/demo/')

  if (demoPageOpened) {
    const transactionsFirstLoadCompleted: boolean = yield select(DemoSelectors.transactionsFirstLoadCompleted)

    if (!transactionsFirstLoadCompleted) {
      const transactionKeysForSelection = chain(action.payload)
        .take(NumberOfTransactionsToSelect)
        .map(transaction => transaction.transactionKey)
        .value()

      yield put(TransactionsActions.setSelectedTransactions(transactionKeysForSelection))
      yield put(DemoActions.transactionsFirstLoadingCompleted())
    }
  }
}

function* demoModeDisabled() {
  yield put(globalCleanState())
}

function* demoModeEnabled() {
  yield put(globalCleanState())
  yield put(PredictionActions.updatePredictionCalculation(DefaultDemoPredictionState))
}

export function* demoSaga() {
  yield all([
    takeEvery(TransactionsActionTypes.GET_TRANSACTIONS_SUCCESS, getTransactionsSuccess),
    takeEvery(DemoActionTypes.DEMO_MODE_DISABLED, demoModeDisabled),
    takeEvery(DemoActionTypes.DEMO_MODE_ENABLED, demoModeEnabled),
  ])
}
