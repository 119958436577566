import { IAction, ITypedAction } from 'Shared/types'
import { SharingActionTypes } from './sharing-constants'

const selectSharing = (sharingKey: string): ITypedAction<string> => ({
  type: SharingActionTypes.SET_SELECTED_SHARING,
  payload: sharingKey,
})

const cleanSharing = (): ITypedAction<null> => ({
  type: SharingActionTypes.SET_SELECTED_SHARING,
  payload: null
})

export const SharingActions = {
  selectSharing,
  cleanSharing,
}
