import type { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { IHoldingsService, HoldingResponse, CreateHoldingRequest } from './holdings-types'
import { Holding } from './holding'
import { HoldingsService } from './holdings-service'

export class HoldingsSharedService extends HoldingsService implements IHoldingsService {
  private readonly sharingKey: string

  public constructor(sharingKey: string) {
    super()
    this.sharingKey = sharingKey
  }

  public getHoldings = <T>(token: string) => this.getHoldingsSharedWithMe(token, this.sharingKey)
  public getSummary = (token: string) => this.getSummarySharedWithMe(token, this.sharingKey)
  public createHolding = (token: string, payload: CreateHoldingRequest) => this.createSharedHolding(token, payload, this.sharingKey)

  private getHoldingsSharedWithMe = (token: string, sharingKey: string) => axios
    .get(`/holdings/shared-with-me/${sharingKey}`, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)
    .then((holdings: HoldingResponse[]) => holdings.map(holding => new Holding(holding)))

  private getSummarySharedWithMe = (token: string, sharingKey: string) => axios
    .get(`/holdings/shared-with-me/summary/${sharingKey}`, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  private createSharedHolding = (token: string, payload: CreateHoldingRequest, sharingKey: string) => axios
    .post(`holdings/${sharingKey}`, { payload }, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)
}

