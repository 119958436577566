import React from 'react'
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined'
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined'
import { Theme, useMediaQuery, Stack } from '@mui/material'

import { Routes } from 'Shared/constants'
import { useCurrentRoute } from 'Shared/hooks'
import { AppBarDesktopPanel } from 'App/NavigationBar/components-app-bar/AppBarDesktopPanel'
import { CurrentRoute } from 'App/NavigationBar/components-app-bar/CurrentRoute'
import { SubscribeButton } from 'App/Premium/SubscribeButton'
import { useAuthorization, Permission } from 'Integration/authorization'

import { AppBarDesktop } from './components-app-bar/AppBarDesktop'
import { AppBarMobile } from './components-app-bar/AppBarMobile'
import { AppBarMobileToolbar } from './components-app-bar/AppBarMobileToolbar'

const buildAppBarLinks = (hasAccessToAdvisorPortal: boolean) => [
  {
    href: Routes.HOLDINGS,
    key: 'app.navbar.routes.holdings',
    userGuideId: 'stepAssets',
    icon: <AccountBalanceOutlinedIcon />
  },
  {
    href: Routes.TRANSACTIONS,
    key: 'app.navbar.routes.transactions',
    userGuideId: 'stepTransactions',
    icon: <ReceiptOutlinedIcon />
  },
  {
    href: Routes.ANALYTICS,
    key: 'app.navbar.routes.analytics',
    userGuideId: 'stepAnalytics',
    icon: <DataSaverOffOutlinedIcon />
  },
  {
    href: Routes.FORECAST,
    key: 'app.navbar.routes.prediction',
    userGuideId: 'stepPrediction',
    icon: <AutoGraphOutlinedIcon />
  },
  ...(hasAccessToAdvisorPortal ? [ {
    href: Routes.ADVISOR_PORTAL,
    key: 'app.navbar.routes.advisorPortal',
    icon: <GroupOutlinedIcon />
  } ] : []),
]

export const AppBar = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const authorization = useAuthorization()
  const { currentRoute } = useCurrentRoute()

  const appBarLinks = React.useMemo(
    () => buildAppBarLinks(authorization.hasAccess(Permission.ViewAdvisorPortal)),
    [ authorization.authorizationProfile ])

  if (isMobile) {
    return (
      <AppBarMobile links={appBarLinks} toolbar={<AppBarMobileToolbar />}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          {currentRoute && <CurrentRoute routeKey={currentRoute} />}
          {authorization.hasAccess(Permission.ViewSubscribeButton) && <SubscribeButton />}
        </Stack>
      </AppBarMobile>
    )
  }

  return (
    <AppBarDesktop links={appBarLinks}>
      <AppBarDesktopPanel />
    </AppBarDesktop>
  )
}
