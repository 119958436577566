import { Stack } from '@mui/material'
import { AppLogo, DemoButton } from 'App/NavigationBar/components'
import { ComeBackAliveLinkLanding, DesktopLandingAnchorLinks, LoginButtonLanding } from 'App/NavigationBar/components-landing-shared'
import { landingSections } from './landingSections'
import { LanguageSelector } from './LanguageSelector'

export const DesktopNavigation = () => (
  <>
    <AppLogo />
    <DesktopLandingAnchorLinks sections={landingSections} />
    <Stack direction="row" alignItems="center" gap={1.5}>
      <ComeBackAliveLinkLanding />
      <DemoButton />
      <LoginButtonLanding />
      <LanguageSelector />
    </Stack>
  </>
)
