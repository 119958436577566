import React from 'react'
import { Stack, styled, Theme, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { InternalLink, TextLarger } from 'Shared/components'
import { useCurrentRoute } from 'Shared/hooks'
import { Routes } from 'Shared/constants'
import { SharingSelectors } from 'Sharing'
import { AdvisorPortalSelectors } from 'AdvisorPortal/advisorPortal-selectors'

const BreadCrumb = styled(TextLarger)({ fontWeight: 500 })

export const AppBarBreadcrumbs: React.FC = () => {
  const { t } = useTranslation()

  const { currentRoute } = useCurrentRoute()
  const expandedBreadcrumbs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const clients = useSelector(AdvisorPortalSelectors.advisoryClients)
  const sharingKey = useSelector(SharingSelectors.selectedSharingKey)

  const activeClient = React.useMemo(
    () => clients.list.find(client => client.sharingKey === sharingKey),
    [ sharingKey, clients.list ])

  if (!activeClient) {
    return <BreadCrumb>{t('app.navbar.routes.advisorPortal')}</BreadCrumb>
  }

  return (
    <Stack spacing={1} direction="row" sx={{ overflow: 'hidden' }}>
      <InternalLink to={Routes.ADVISOR_PORTAL}>
        <BreadCrumb noWrap>{t('app.navbar.routes.advisorPortal')}</BreadCrumb>
      </InternalLink>

      {activeClient && (
        <>
          <TextLarger>›</TextLarger>
          <BreadCrumb noWrap>{activeClient.preferredName || activeClient.username}</BreadCrumb>
        </>
      )}

      {!expandedBreadcrumbs && currentRoute && (
        <>
          <TextLarger>›</TextLarger>
          <BreadCrumb noWrap>{t(currentRoute)}</BreadCrumb>
        </>
      )}
    </Stack>
  )
}
