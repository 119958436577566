import React from 'react'
import { useTranslation } from 'react-i18next'

import { SubTextSecondary1 } from 'Shared/components'
import { SubscriptionPlanContainer } from './SubscriptionPlanContainer'

interface Props {
  validTillDate: Date
}

export const NonRenewableSubscription: React.FC<Props> = ({ validTillDate }) => {
  const { t } = useTranslation()

  return (
    <SubscriptionPlanContainer isPremium>
      <SubTextSecondary1>
        {t('app.navbar.settings.subscription.validUntilDate', { date: validTillDate })}
      </SubTextSecondary1>
    </SubscriptionPlanContainer>
  )
}
