import { styled } from '@mui/material/styles'
import { Stack } from '@mui/material'

import { LegalLinkGroup } from './LegalLinkGroup'
import { CopyrightText } from './CopyrightText'

const Wrapper = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(4)} 0`,
  justifyContent: 'space-between',
  flexWrap: 'wrap',

  [theme.breakpoints.up('desktop')]: {
    alignItems: 'center',
  },
}))

const CopyrightWrapper = styled(Stack)(({ theme }) => ({
}))

export const BasicFooter = () => (
  <Wrapper direction={{ sm: 'column', tablet: 'row' }}>
    <LegalLinkGroup light />
    <CopyrightWrapper>
      <CopyrightText light />
    </CopyrightWrapper>
  </Wrapper>
)
