import { first } from 'lodash'

import { LocalizedError, Nullable } from 'Shared/types'
import { ControlledServerError } from 'Shared/constants'

export const selectErrorOrDefault = (errorCodes: string[]): Nullable<LocalizedError> => {
  if (errorCodes) {
    const errorCode = first(errorCodes)

    if (errorCode && ControlledServerError[errorCode]) {
      return ControlledServerError[errorCode]
    }
  }

  return null
}
