import React from 'react'

interface DemoContextState {
  demo: boolean
}

interface ProviderProps {
  children: React.ReactNode
  value: DemoContextState
}

export const DemoContext = React.createContext<DemoContextState>({ demo: false })

export const DemoProvider = ({ children, value }: ProviderProps) =>
  React.createElement(DemoContext.Provider, { value: value }, children)
