import React from 'react'
import { styled, Stack } from '@mui/material'
import { useTranslation, Trans } from 'react-i18next'

import { TextLarger, SubTextPrimary1 } from 'Shared/components'
import backgroundImage from 'App/assets/black-friday-background.png'
import { PromotionContainer } from './PromotionDialog.styles'

const BlackFridayContainer = styled(PromotionContainer)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundImage: `url(${backgroundImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  minWidth: 300,
  minHeight: 107,
  width: 300,
  height: 107,
  padding: theme.spacing(2)
}))

interface Props {
  onClick: () => void
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const PromotionDialogBlackFriday: React.FC<Props> = ({ onClick, onClose }) => {
  const { t } = useTranslation()

  return (
    <BlackFridayContainer onClick={onClick}>
      <Stack justifyContent="center" height="100%" spacing={0.5}>
        <TextLarger fontWeight={700}>
          {t('blackFriday.title', { ns: 'notification' })}
        </TextLarger>
        <SubTextPrimary1 width="75%">
          <Trans i18nKey="blackFriday.subTitle" ns="notification" />
        </SubTextPrimary1>
      </Stack>
    </BlackFridayContainer>
  )
}
