import { DemoActionTypes, InitialState } from 'Demo/demo-constants'
import { DemoState } from 'Demo/demo-types'
import { ITypedAction } from 'Shared/types'

export const demo = (state: DemoState = InitialState, action: ITypedAction<any>): DemoState => {
  switch(action.type) {
    case DemoActionTypes.TRANSACTIONS_FIRST_LOADING_COMPLETED: {
      return {
        ...state,
        transactionsFirstLoadingCompleted: true
      }
    }
    default:
      return state
  }
}
