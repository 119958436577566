import React from 'react'
import { isNull } from 'lodash'
import Tooltip from '@mui/material/Tooltip'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import { useTranslation } from 'react-i18next'

import { HOLDING_TERM } from 'Services/holdings'
import { HoldingTermOptions } from 'Holdings/holdings-constants'
import { theme } from 'config/theme'

interface Props {
  term: HOLDING_TERM | null
}

const getTermOption = (term: HOLDING_TERM | null) => {
  if (isNull(term) || !HoldingTermOptions[term]) {
    return {
      color: theme.palette.dark.dark2,
      key: 'common.state.notSpecified-he'
    }
  }

  return HoldingTermOptions[term]
}

const TermIconBase: React.FC<Props> = ({ term }: Props) => {
  const { t } = useTranslation()
  const currentTerm = getTermOption(term)

  return (
    <Tooltip
      title={t('holdings.table.term-specific', { option: t(currentTerm.key) })}
      enterTouchDelay={0}
      leaveTouchDelay={5000}
    >
      <AccessTimeFilledIcon sx={{ fill: currentTerm.color }} fontSize="small" />
    </Tooltip>
  )
}

export const TermIcon = React.memo(TermIconBase)
