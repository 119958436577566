/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable max-len */
import { SVGProps } from 'react'

export const NotFoundImg = (props: SVGProps<SVGSVGElement>) => (
  <svg width="568" height="606" viewBox="0 0 568 606" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M102.825 211.736C101.219 211.739 99.6171 211.57 98.0465 211.234C86.495 208.665 75.1129 205.387 63.9647 201.418C35.3934 191.129 19.2637 179.132 13.2223 163.648C-0.711802 127.974 32.1502 80.3021 38.8805 71.1323C42.5201 66.3431 47.8939 63.1735 53.8461 62.3051C59.7983 61.4367 65.8537 62.9387 70.7099 66.4883C75.5662 70.038 78.8356 75.3516 79.815 81.2866C80.7943 87.2216 79.4055 93.3038 75.9472 98.2255C63.5486 115.231 53.9483 138.32 55.7564 146.17C61.2238 152.068 85.8919 161.682 107.575 166.26C113.123 167.44 118.037 170.631 121.372 175.219C124.706 179.806 126.225 185.466 125.635 191.106C125.045 196.747 122.388 201.97 118.177 205.768C113.965 209.567 108.497 211.672 102.825 211.679V211.736Z" fill="#ABBEFA" />
    <path d="M71.5695 270.96C67.6159 270.957 63.7302 269.932 60.2887 267.986C56.8472 266.04 53.9665 263.238 51.9256 259.852C49.8847 256.466 48.7527 252.61 48.6393 248.658C48.5259 244.706 49.4349 240.792 51.2783 237.295L118.61 109.577C119.987 106.862 121.891 104.449 124.21 102.476C126.53 100.504 129.218 99.0125 132.119 98.0889C135.02 97.1653 138.076 96.8278 141.109 97.0962C144.141 97.3646 147.09 98.2334 149.784 99.6521C152.478 101.071 154.863 103.011 156.799 105.36C158.736 107.709 160.187 110.42 161.066 113.334C161.946 116.249 162.237 119.31 161.922 122.338C161.608 125.366 160.695 128.302 159.235 130.974L91.9038 258.705C89.9529 262.407 87.0274 265.505 83.4436 267.665C79.8598 269.825 75.7539 270.964 71.5695 270.96Z" fill="#ABBEFA" />
    <path d="M454.276 378.241C450.318 378.259 446.378 377.698 442.581 376.577C405.859 365.685 388.423 310.494 385.295 299.53C383.626 293.671 384.353 287.389 387.316 282.065C390.279 276.742 395.235 272.814 401.094 271.145C406.954 269.476 413.236 270.203 418.559 273.166C423.882 276.129 427.81 281.086 429.479 286.945C435.305 307.136 447.618 328.919 454.922 332.306C462.93 331.617 485.632 318.085 502.637 303.778C507.301 299.947 513.286 298.107 519.297 298.656C525.307 299.205 530.86 302.099 534.753 306.711C538.647 311.323 540.567 317.282 540.1 323.3C539.632 329.317 536.814 334.909 532.256 338.864C523.124 346.404 513.54 353.377 503.555 359.744C483.852 372.185 467.852 378.241 454.276 378.241Z" fill="#ABBEFA" />
    <path d="M545.012 404.762C540.595 404.762 536.272 403.488 532.561 401.093C528.85 398.698 525.909 395.283 524.089 391.258L464.536 259.681C462.148 254.153 462.027 247.909 464.199 242.293C466.371 236.677 470.663 232.139 476.148 229.657C481.634 227.175 487.875 226.947 493.528 229.022C499.18 231.098 503.791 235.31 506.367 240.753L565.92 372.359C567.504 375.855 568.185 379.693 567.902 383.521C567.62 387.349 566.382 391.045 564.302 394.271C562.222 397.497 559.367 400.15 555.997 401.988C552.627 403.826 548.85 404.789 545.012 404.79V404.762Z" fill="#ABBEFA" />
    <path d="M333.647 180.868C317.991 180.868 303.297 175.056 293.395 164.509C279.547 149.757 272.688 119.234 264.881 68.061C259.012 29.545 285.976 8.1775 306.282 2.81051C334.824 -6.3593 361.573 8.27796 372.393 26.9476C378.348 37.208 385.122 64.5739 387.877 77.6039C397.434 122.893 391.952 137.875 390.144 142.725C386.648 152.096 380.796 160.407 373.152 166.857C365.508 173.307 356.33 177.678 346.505 179.548C342.274 180.417 337.967 180.859 333.647 180.868ZM326.989 133.197C328.209 134.273 332.456 135.565 337.335 134.632C339.463 134.324 341.474 133.465 343.168 132.141C344.862 130.817 346.18 129.074 346.993 127.084C350.38 114.915 338.527 62.9093 332.643 49.9797C331.098 48.2847 329.097 47.0716 326.88 46.4862C324.663 45.9007 322.324 45.9677 320.144 46.6792C319.535 46.9196 318.906 47.1069 318.264 47.2389C308.635 50.1807 309.812 57.8867 310.314 61.1873C312.725 76.9725 315.394 93.4897 318.637 107.639C322.971 126.71 326.3 132.207 326.989 133.197Z" fill="#ABBEFA" />
    <path d="M2 197.701L3.83683 306.533" stroke="#AFB5C0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M170.773 162.771V226.745" stroke="#AFB5C0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M454.994 34.2529V124.344" stroke="#AFB5C0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M563.094 199.725V273.255" stroke="#AFB5C0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M427.055 507.293C427.055 507.293 446.915 490.072 459.788 466.48" stroke="#AFB5C0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M263.432 603.096C279.561 603.698 297.026 604 314.906 604C386.428 604 438.088 598.489 438.088 590.396C438.088 582.302 362.147 576.462 327.046 576.792" stroke="#AFB5C0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M304.632 576.82C304.632 576.82 178.508 579.389 178.508 589.319C178.508 594.27 194.652 597.93 219.693 600.383" stroke="#AFB5C0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M178.68 424.937C178.68 424.937 172.94 418.866 200.205 406.181C227.471 393.495 242.553 392.519 243.342 401.273C243.342 401.273 241.663 420.057 241.204 420.517C240.745 420.976 189.155 436.187 189.155 436.187L178.68 424.937Z" fill="#252549" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M197.249 325.476C147.425 291.035 77.0081 293.547 66.6616 293.547C66.6616 293.547 40.3433 297.134 45.5524 317.942C36.3396 320.037 19.1623 325.117 22.4198 333.627C26.7249 344.648 43.3138 332.407 57.5492 333.01C57.9797 333.211 58.4246 333.383 58.8838 333.555C53.1437 339.64 48.2503 348.867 56.1716 351.852C68.0679 356.329 86.6515 332.637 86.6515 332.637C86.6515 332.637 142.287 334.473 172.753 368.426C203.219 402.379 197.019 443.047 197.019 443.047C197.019 443.047 227.155 443.65 245.064 427.104C245.049 427.133 247.073 359.845 197.249 325.476Z" fill="white" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M53.6611 289.17C53.6611 289.17 58.5689 283.43 63.8785 288.61C68.0401 292.714 65.3135 299.416 61.2381 300.377C59.8068 300.758 58.2901 300.658 56.9212 300.093C55.5523 299.528 54.4069 298.529 53.6611 297.249V289.17Z" fill="white" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M44.7773 289.442C44.7773 289.442 50.417 282.783 56.5158 288.796C61.2945 293.503 58.1661 301.209 53.4879 302.299C51.8452 302.748 50.1003 302.641 48.5249 301.994C46.9495 301.348 45.632 300.199 44.7773 298.726V289.442Z" fill="white" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M47.232 322.074C43.4227 320.6 40.0672 318.151 37.5011 314.973C34.935 311.795 33.2481 307.999 32.6091 303.964C30.4996 290.03 41.0758 286.744 45.3952 287.146C51.8671 287.648 56.3301 296.244 47.5047 301.381C47.5047 301.381 48.3083 307.122 52.4412 308.183L47.232 322.074Z" fill="white" />
    <path d="M52.4408 308.185C48.308 307.094 47.5044 301.383 47.5044 301.383C56.3298 296.245 51.8094 287.65 45.3949 287.147C41.0898 286.803 30.4993 290.017 32.6088 303.966C33.0354 306.925 34.0468 309.771 35.5842 312.335C37.1216 314.9 39.1541 317.133 41.5634 318.904L50.4175 316.766" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M345.431 545.723C345.431 545.723 354.041 548.393 350.755 527.513C347.469 506.633 337.711 477.057 331.827 484.218C325.944 491.379 312.153 536.711 345.431 545.723Z" fill="#252549" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M474.367 392.334C470.636 383.509 454.88 395.764 454.88 395.764C454.88 395.764 475.286 376.334 467.379 368.8C459.873 361.625 452.569 375.071 446.298 384.585L446.112 384.442C448.982 378.457 457.276 358.396 451.579 354.306C439.209 345.395 436.913 372.272 434.359 381.729C433.225 373.377 433.842 353.933 426.552 353.761C414.756 353.502 422.075 372.803 418.401 386.924C418.071 387.268 417.755 387.627 417.44 388C413.709 380.51 407.624 374.009 402.229 380.524C396.402 387.556 408.686 414.362 408.686 414.362C408.686 414.362 387.706 465.937 345.287 482.812C327.777 489.76 308.616 491.431 290.168 487.62C286.604 486.926 282.93 487.046 279.419 487.97C275.909 488.895 272.652 490.601 269.893 492.96C267.133 495.319 264.942 498.271 263.484 501.595C262.025 504.92 261.335 508.531 261.467 512.159V512.934C261.755 518.055 263.686 522.947 266.974 526.884C270.262 530.821 274.732 533.593 279.721 534.789C301.131 540.012 342.288 545.164 377.058 520.683C416.191 493.13 435.607 451.802 444.892 428.31C444.892 428.224 478.113 401.16 474.367 392.334Z" fill="white" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M392.37 404.762C392.37 404.762 391.193 393.684 364.544 385.404C337.896 377.123 263.375 384.126 263.677 397.73C263.978 411.334 326.488 443.077 326.488 443.077C326.488 443.077 376.986 453.912 392.37 404.762Z" fill="#252549" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M261.409 147.202C261.409 147.202 263.892 143.313 259.171 140.802C254.45 138.291 243.027 132.981 218.459 135.923C193.892 138.865 193.203 146.327 193.935 152.282C194.753 158.768 232.738 172.688 261.409 147.202Z" fill="#252549" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M434.846 198.477C434.846 198.477 461.164 197.917 473.921 197.214C486.679 196.511 480.666 239.188 480.666 239.188L432.263 247.526C432.263 247.526 421.615 212.611 434.846 198.477Z" fill="white" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M198.756 154.493C198.756 154.493 205.056 128.935 207.696 116.436C210.336 103.937 249.986 120.842 249.986 120.842L245.452 169.747C245.452 169.747 208.973 170.938 198.756 154.493Z" fill="white" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M304.244 318.904C304.244 318.904 239.783 254.084 261.408 147.203C240.427 157.548 216.256 159.368 193.962 152.283C193.962 152.283 166.395 279.814 263.704 397.672C263.704 397.672 303.153 436.891 324.119 434.194C345.085 431.496 373.068 418.609 373.068 418.609C373.068 418.609 387.274 393.252 384.447 354.765C381.62 316.278 379.166 273.198 379.166 273.198C379.166 273.198 411.77 257.7 457.605 262.349C457.605 262.349 451.104 220.074 452.453 194.817C452.453 194.817 369.365 191.947 336.876 216.343C304.387 240.738 304.244 318.904 304.244 318.904Z" fill="white" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M178.68 424.938C178.68 424.938 194.68 449.936 189.155 470.528C183.631 491.121 198.727 518.874 198.727 518.874C198.727 518.874 224.457 532.851 246.886 540.945C269.316 549.039 317.49 554.362 345.429 545.724C345.429 545.724 336.331 536.898 334.767 514.153C333.203 491.408 331.897 484.218 331.897 484.218C331.897 484.218 312.036 487.089 301.288 484.319C301.288 484.319 389.872 462.176 392.441 404.761C392.441 404.761 385.696 425.052 347.582 418.566C309.468 412.08 298.605 407.732 298.605 407.732C298.605 407.732 281.772 393.381 263.748 397.687C263.748 397.687 256.343 408.277 245.738 413.988C245.738 413.988 244.016 404.359 243.399 401.231C232.469 412.057 219.104 420.107 204.424 424.708C181.004 431.668 178.68 424.938 178.68 424.938Z" fill="#42BA94" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M298.532 407.731C298.532 407.731 317.188 423.861 349.648 426.128" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M301.217 484.319C301.217 484.319 271.34 480.085 253.144 485.754" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M245.667 414.032C245.667 414.032 222.534 427.694 213.795 440.925" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M198.727 518.874C198.727 518.874 225.806 514.411 225.318 485.782C224.83 457.153 197.737 471.145 197.737 471.145L162.809 465.62L152.52 496.875L198.727 518.874Z" fill="white" />
    <path d="M163 502L198.727 518.875C198.727 518.875 225.806 514.412 225.318 485.783C224.83 457.155 197.737 471.146 197.737 471.146L181.22 468.534" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M172.007 481.061C172.007 481.061 192.284 468.146 176.053 442.889C176.053 442.889 171.547 447.195 169.237 446.506C166.927 445.817 167.802 440.923 167.529 439.761C167.257 438.599 161.517 435.356 161.517 435.356C161.517 435.356 162.808 425.153 153.251 421.78C153.251 421.78 143.837 429.903 137.322 431.28C130.807 432.658 101.92 432.715 104.044 464.458C106.168 496.201 151.443 496.014 172.007 481.061Z" fill="white" />
    <path d="M174.288 479.239C179.081 474.934 188.768 462.65 176.053 442.876C176.053 442.876 171.547 447.181 169.237 446.492C166.926 445.803 167.802 440.91 167.529 439.747C167.257 438.585 161.516 435.342 161.516 435.342C161.516 435.342 162.808 425.139 153.251 421.767C153.251 421.767 143.837 429.889 137.322 431.266C133.935 431.97 124.579 432.314 116.629 436.892" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M139.705 452.634C139.705 452.634 147.239 440.379 146.45 436.146C148.369 441.42 149.204 447.029 148.904 452.634" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M124.02 457.168C124.02 457.168 140.264 437.307 129.171 431.797C118.079 426.286 137.624 420.403 140.436 409.74C143.249 399.078 133.089 397.729 126.962 402.264C126.962 402.264 132.529 387.125 118.868 386.938C105.207 386.751 102.38 397.471 101.648 405.45C100.916 413.429 96.3812 399.81 88.6608 407.401C80.9403 414.993 98.0889 421.68 96.8691 422.842C95.6493 424.005 86.9387 421.005 86.0777 424.866C85.2167 428.726 92.8223 428.726 92.5784 432.715C92.3344 436.705 67.1498 447.668 75.846 460.655C84.5422 473.642 98.218 465.305 99.0216 467.73C99.8253 470.155 82.892 472.795 88.2303 483.888C93.5685 494.981 102.38 490.848 102.38 490.848C102.38 490.848 86.5943 502.328 96.496 512.015C106.398 521.701 115.209 510.049 115.209 510.049C115.209 510.049 115.453 526.666 126.689 523.294C137.925 519.922 141.326 510.608 143.163 512.144C145 513.679 144.211 522.863 156.078 517.41C167.946 511.957 162.952 501.84 162.952 501.84L157.929 494.22L139.719 477C139.719 477 149.233 471.475 144.354 464.917C139.475 458.359 133.964 467.615 130.348 464.314C128.014 462.141 125.895 459.748 124.02 457.168Z" fill="#252549" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M145.172 474.331C145.172 474.331 133.276 477.818 135.902 491.48C138.528 505.141 159.379 498.526 159.924 489.887C160.028 487.945 159.78 486 159.192 484.147L145.172 474.331Z" fill="white" />
    <path d="M304.245 318.903L305.953 354.463" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M278.5 393.5C278.5 393.5 272.5 368.5 248 368.5" stroke="#AFB5C0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="12 12" />
    <path d="M379 369.5C379 369.5 347.287 371.198 347 413" stroke="#AFB5C0" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="12 12" />
    <path d="M162.809 70.5861C162.809 70.5861 163.096 60.541 168.793 58.7042C174.49 56.8673 277.625 93.1447 278.73 95.4695C279.835 97.7942 274.654 104.008 274.654 104.008L162.809 70.5861Z" fill="white" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M274.654 104.009C274.654 104.009 271.224 115.116 264.609 122.449C257.993 129.782 250.172 134.819 246.47 132.494C242.768 130.169 236.999 120.21 234.272 120.354C231.546 120.497 223.194 130.184 210.637 128.662C200.061 127.385 168.347 100.105 163.124 79.4842C162.3 76.5991 162.172 73.5598 162.751 70.6157C162.916 69.8349 163.286 69.1122 163.823 68.522C164.361 67.9318 165.045 67.4954 165.807 67.2577C167.788 66.6837 171.16 66.6407 176.771 68.7788C185.553 72.1081 224.5 86.0853 250.531 95.413L274.654 104.009Z" fill="#42BA94" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M234.315 120.354C231.589 120.512 223.237 130.184 210.681 128.663C200.105 127.386 168.39 100.106 163.167 79.4844C179.44 105.372 234.315 120.354 234.315 120.354Z" fill="#42BA94" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M274.655 104.008C274.655 104.008 271.225 115.115 264.61 122.448C264.61 122.448 246.442 114.067 250.518 95.3975L274.655 104.008Z" fill="white" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M191.867 117.542L207.695 110.854" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M185.223 111.601L197.75 106.306" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M533.087 163.62C533.087 163.62 543.003 165.313 544.022 171.211C545.041 177.109 494.772 274.174 492.246 274.935C489.721 275.695 484.368 269.711 484.368 269.711L533.087 163.62Z" fill="white" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M484.384 269.724C484.384 269.724 473.865 264.774 467.508 257.254C461.151 249.735 457.277 241.239 460.104 237.896C462.931 234.552 473.593 230.233 473.822 227.506C474.052 224.78 465.643 215.165 468.9 202.953C471.641 192.649 503.083 165.068 524.235 162.772C527.209 162.36 530.239 162.66 533.075 163.648C533.817 163.925 534.474 164.393 534.978 165.006C535.482 165.618 535.815 166.352 535.945 167.135C536.232 169.187 535.801 172.53 532.902 177.782C528.382 186.02 509.095 222.627 496.223 247.094C489.407 260.11 484.384 269.724 484.384 269.724Z" fill="#42BA94" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M473.821 227.494C474.051 224.781 465.642 215.152 468.899 202.94C471.64 192.637 503.082 165.056 524.234 162.76C496.337 175.244 473.821 227.494 473.821 227.494Z" fill="#42BA94" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M484.383 269.725C484.383 269.725 473.864 264.774 467.507 257.254C467.507 257.254 478.356 240.436 496.207 247.08C489.405 260.11 484.383 269.725 484.383 269.725Z" fill="white" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M482.533 185.848L486.939 202.451" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M489.35 180.094L492.837 193.239" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M144.635 484.636C150.549 482.542 153.28 488.827 148.718 492.99" stroke="#252549" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
