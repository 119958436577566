import { put } from 'redux-saga/effects'

import { TransactionsActions } from 'Transactions/transactions-actions'
import { AnalyticsActions } from 'Analytics/analytics-actions'
import { PredictionActions } from 'Prediction/prediction-actions'
import { HoldingActions } from 'Holdings/holdings-actions'

export function* cleanStateSaga() {
  yield put(TransactionsActions.resetState())
  yield put(AnalyticsActions.resetState())
  yield put(PredictionActions.resetState())
  yield put(HoldingActions.resetState())
}

export const CleanStateActionType = 'GLOBAL/CLEAN_STATE'
export const globalCleanState = () => ({ type: CleanStateActionType })
