import { Typography, styled } from '@mui/material'

export const BaseText = styled(Typography)({
  fontSize: 'init',
  width: 'auto',
}) as typeof Typography

export const TextBigger = styled(Typography)(({ theme }) => ({
  ...theme.typography.textBigger
})) as typeof Typography

export const TextBig = styled(Typography)(({ theme }) => ({
  ...theme.typography.textBig
})) as typeof Typography

export const TextLarger = styled(Typography)(({ theme }) => ({
  ...theme.typography.textLarger
})) as typeof Typography

export const TextLarge = styled(Typography)(({ theme }) => ({
  ...theme.typography.textLarge
})) as typeof Typography

export const TextMedium = styled(Typography)(({ theme }) => ({
  ...theme.typography.textMedium
})) as typeof Typography

export const TextPrimary = styled(Typography)(({ theme }) => ({
  ...theme.typography.textPrimary
})) as typeof Typography

export const TextSecondary = styled(TextPrimary)(({ theme }) => ({
  color: theme.palette.text.secondary
})) as typeof Typography

export const TextSmaller1 = styled(Typography)(({ theme }) => ({
  ...theme.typography.textSmaller1
})) as typeof Typography

export const TextSmaller2 = styled(Typography)(({ theme }) => ({
  ...theme.typography.textSmaller2
})) as typeof Typography

export const SubTextPrimary1 = styled(Typography)(({ theme }) => ({
  ...theme.typography.subTextPrimary1
})) as typeof Typography

export const SubTextPrimary2 = styled(Typography)(({ theme }) => ({
  ...theme.typography.subTextPrimary2
})) as typeof Typography

export const SubTextSecondary1 = styled(SubTextPrimary1)(({ theme }) => ({
  color: theme.palette.text.secondary
})) as typeof Typography

export const SubTextSecondary2 = styled(SubTextPrimary2)(({ theme }) => ({
  color: theme.palette.text.secondary
})) as typeof Typography

export const TextSmall = styled(Typography)(({ theme }) => ({
  ...theme.typography.textSmall
})) as typeof Typography

export const CaptionSmall = styled(Typography)(({ theme }) => ({
  ...theme.typography.captionSmall
})) as typeof Typography
