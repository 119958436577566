import type { ITypedAction } from 'Shared/types'
import { SnackbarActions } from './snackbar-constants'
import type { Snackbar, SnackbarsState } from './snackbar-types'

const initialState: SnackbarsState = {
  snackbars: [],
}

export const snackbarsReducer = (
  state: SnackbarsState = initialState,
  action: ITypedAction<any>): SnackbarsState => {
  switch (action.type) {
    case SnackbarActions.ADD_SNACKBAR:
      return {
        ...state,
        snackbars: [
          ...state.snackbars,
          { ...action.payload as Snackbar },
        ],
      }

    case SnackbarActions.UPDATE_SNACKBAR:
      return {
        ...state,
        snackbars: [ ...action.payload as Snackbar[] ]
      }

    default:
      return state
  }
}
