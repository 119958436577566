import React from 'react'

import { container, Service } from 'Services/container'
import { HistoryItem, IHistoryService } from 'Services/history'
import type { AsyncState } from 'Shared/types'
import { useSnackbar } from 'Snackbar'
import { useAuthWithDemo } from 'Demo'

export const useSharedActionsHistory = () => {
  const { enqueueError } = useSnackbar()
  const { getAccessTokenWithDemo } = useAuthWithDemo()
  const [ historyState, setHistoryState ] = React.useState<AsyncState<HistoryItem[]>>({
    isLoading: false,
    state: null
  })

  const fetchUserHistory = async (sharingKey: string) => {
    setHistoryState({ isLoading: true, state: null })
    const historyService = container.resolve<IHistoryService>(Service.HistoryService)

    const token = await getAccessTokenWithDemo()

    try {
      const history = await historyService.getHistorySharedWithMe(token, sharingKey)
      setHistoryState({ isLoading: false, state: history })
    } catch {
      enqueueError('common.errorMessages.loading-failed')
    } finally {
      setHistoryState(prevState => ({ ...prevState, isLoading: false }))
    }
  }

  return {
    fetchUserHistory,
    history: historyState.state,
    isLoading: historyState.isLoading,
  }
}
