import React from 'react'
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined'

import { ClientHistoryButton } from './ClientHistoryButton'

export const ClientHistoryButtonMobile: React.FC = () => (
  <ClientHistoryButton
    icon={(
      <HistoryOutlinedIcon
        fontSize="small"
        sx={theme => ({ color: theme.palette.common.white })}
      />
    )}
  />
)
