import { useMediaQuery, Theme } from '@mui/material'

export const useWhenMobile = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobileLarge'))
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('tablet'))

  return {
    isMobile,
    isTablet,
  }
}
