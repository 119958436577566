import React, { PropsWithChildren } from 'react'

interface WhenProps {
  condition: boolean
}

export const When: React.FC<PropsWithChildren<WhenProps>> = ({ condition, children }) => {
  if (!condition) {
    return null
  }

  return (
    <>
      {children}
    </>
  )
}
