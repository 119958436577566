import { styled, Box } from '@mui/material'

export const PromotionContainer = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: theme.zIndex.modal,
  cursor: 'pointer',
  minWidth: 300,
}))
