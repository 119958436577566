import * as Sentry from '@sentry/react'
import { enUS, uk } from 'date-fns/locale'

export enum SupportedLanguage {
  EN = 'en',
  UK = 'uk',
}

class LanguageService {
  private _defaultLocale = enUS
  private _defaultTranslation = 'app.navbar.settings.language-en'
  private _defaultTranslationForLanding = 'app.navbar.settings.lng-en'
  private _defaultThousandsSeparator = ','
  private _defaultDecimalSeparator = '.'

  locales = {
    [SupportedLanguage.EN]: enUS,
    [SupportedLanguage.UK]: uk,
  }

  translations = {
    [SupportedLanguage.EN]: 'app.navbar.settings.language-en',
    [SupportedLanguage.UK]: 'app.navbar.settings.language-uk',
  }

  translationsForLanding = {
    [SupportedLanguage.EN]: 'app.navbar.settings.lng-en',
    [SupportedLanguage.UK]: 'app.navbar.settings.lng-uk',
  }

  thousandSeparator = {
    [SupportedLanguage.EN]: ',',
    [SupportedLanguage.UK]: ' ',
  }

  decimalSeparator = {
    [SupportedLanguage.EN]: '.',
    [SupportedLanguage.UK]: ',',
  }

  public getLocale(language: SupportedLanguage): Locale {
    return this.firstOrDefault<Locale>(this.locales, language) ?? this._defaultLocale
  }

  public getTranslation(language: SupportedLanguage): string {
    return this.firstOrDefault<string>(this.translations, language) ?? this._defaultTranslation
  }

  public getTranslationForLanding(language: SupportedLanguage): string {
    return this.firstOrDefault<string>(this.translationsForLanding, language) ?? this._defaultTranslationForLanding
  }

  public getThousandsSeparator(language: SupportedLanguage): string {
    return this.firstOrDefault<string>(this.thousandSeparator, language) ?? this._defaultThousandsSeparator
  }

  public getDecimalSeparator(language: SupportedLanguage): string {
    return this.firstOrDefault<string>(this.decimalSeparator, language) ?? this._defaultDecimalSeparator
  }

  private firstOrDefault<T>(map: { [key in SupportedLanguage]: T }, language: SupportedLanguage) {
    const value = map[language]

    if (!value) {
      if (Sentry) {
        Sentry.captureMessage(`resolved language is not supported: ${language}`, { level: 'warning' })
      }

      return null
    }

    return value
  }
}

export const languageService = new LanguageService()
