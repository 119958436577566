export enum StepName {
  Welcome,
  Holdings,
  Summary,
  HiddenMode,
  Transactions,
  Analytics,
  Prediction,
  Final,
}

export interface UserGuideConfig {
  displayWhenGuidePassedAlready: boolean
  stepsRedirects: string[]
  redirectOnCompletion?: string
}
