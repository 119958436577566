import { PortfolioAllocationType } from 'Services/portfolio'
import { AssetAllocationState } from './assetAllocation-types'

export const PortfolioAllocationTypeOptions = {
  [PortfolioAllocationType.Category]: {
    key: 'holdings.attributes.category'
  },
  [PortfolioAllocationType.Class]: {
    key: 'holdings.attributes.class'
  },
  [PortfolioAllocationType.Stock]: {
    key: 'holdings.types.stock'
  },
  [PortfolioAllocationType.Crypto]: {
    key: 'holdings.types.crypto'
  }
}

export const AssetAllocationTypes = {
  GET_ASSET_ALLOCATION: 'GET_ASSET_ALLOCATION',
  GET_MY_ASSET_ALLOCATION: 'GET_MY_ASSET_ALLOCATION',
  GET_ASSET_ALLOCATION_SUCCESS: 'GET_ASSET_ALLOCATION_SUCCESS',
  GET_ASSET_ALLOCATION_FAILURE: 'GET_ASSET_ALLOCATION_FAILURE',
}

export const InitialState: AssetAllocationState = {
  isLoading: false,
  initialLoadCompleted: false,
  assetAllocation: null
}
