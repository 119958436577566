import React from 'react'
import { ListItemIcon, MenuItem } from '@mui/material'

interface ProfileMenuItemProps {
  onClick: () => void
  icon: JSX.Element
  title: string
}

export const ProfileMenuItem: React.FC<ProfileMenuItemProps> = ({ onClick, icon, title }) => (
  <MenuItem onClick={onClick}>
    <ListItemIcon>
      {icon}
    </ListItemIcon>
    {title}
  </MenuItem>
)
