import React, { PropsWithChildren } from 'react'
import { InternalLink } from '.'

interface InTextExternalLinkProps {
  to: string
  spaceBefore?: boolean
  spaceAfter?: boolean
}

export const InTextInternalLink: React.FC<PropsWithChildren<InTextExternalLinkProps>> = ({ children, to, spaceBefore = false, spaceAfter = false }) => (
  <>
    {spaceBefore && ' '}
    <InternalLink to={to}>{children}</InternalLink>
    {spaceAfter && ' '}
  </>
)
