import { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { BaseService } from 'Shared/api'
import {
  IAdvisoryService,
  AdvisoryClient,
  UpdateAdvisoryClientRequest,
  CreateAdvisoryClientRequest,
  AdvisoryClientTaskRequest,
  AdvisoryClientTask,
} from './advisor-types'

export class AdvisoryService extends BaseService implements IAdvisoryService {
  public getAdvisoryClients = (token: string) => axios
    .get<AdvisoryClient[]>('/advisory-clients', { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse<AdvisoryClient[]>) => response?.data)
    .then(clients => clients.map(client => ({ ...client, tags: client.tags ?? [] })))

  public createAdvisoryClient = (token: string, request: CreateAdvisoryClientRequest) => axios
    .post('/advisory-clients', { payload: request }, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public updateAdvisoryClient = (token: string, clientKey: string, request: UpdateAdvisoryClientRequest) => axios
    .put(`/advisory-clients/${clientKey}`, { payload: request }, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public createAdvisoryTask = (token: string, clientKey: string, task: AdvisoryClientTaskRequest) => axios
    .post(`/advisory-tasks/${clientKey}`, { payload: task }, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public updateAdvisoryTask = (token: string, clientKey: string, taskKey: string, task: AdvisoryClientTaskRequest) => axios
    .put(`/advisory-tasks/${clientKey}/${taskKey}`, { payload: task }, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public deleteAdvisoryTask = (token: string, clientKey: string, taskKey: string) => axios
    .delete(`/advisory-tasks/${clientKey}/${taskKey}`, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public getMyTasks = (token: string) => axios
    .get<AdvisoryClientTask[]>('/advisory-tasks/my', { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse<AdvisoryClientTask[]>) => response?.data)

  public changeMyTaskStatus = (token: string, taskKey: string, completed: boolean) => axios
    .patch(`/advisory-tasks/my/${taskKey}/status`, { payload: { completed } }, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)
}
