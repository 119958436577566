import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined'
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined'
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined'
import { Theme, useMediaQuery } from '@mui/material'
import { CurrentRoute } from 'App/NavigationBar/components-app-bar/CurrentRoute'
import React from 'react'

import { NavigationLink } from 'Shared/types'
import { Routes } from 'Shared/constants'
import { useCurrentRoute } from 'Shared/hooks'
import { AppBarDesktop } from 'App/NavigationBar/components-app-bar/AppBarDesktop'
import { AppBarMobile } from 'App/NavigationBar/components-app-bar/AppBarMobile'
import { AppBarDesktopPanel } from 'Demo/AppBar/AppBarDesktopPanel'
import { AppBarMobileToolbar } from 'Demo/AppBar/AppBarMobileToolbar'

export const demoAppBarLinks: NavigationLink[] = [
  {
    href: Routes.DEMO_HOLDINGS,
    key: 'app.navbar.routes.holdings',
    userGuideId: 'stepAssets',
    icon: <AccountBalanceOutlinedIcon />
  },
  {
    href: Routes.DEMO_TRANSACTIONS,
    key: 'app.navbar.routes.transactions',
    userGuideId: 'stepTransactions',
    icon: <ReceiptOutlinedIcon />
  },
  {
    href: Routes.DEMO_ANALYTICS,
    key: 'app.navbar.routes.analytics',
    userGuideId: 'stepAnalytics',
    icon: <DataSaverOffOutlinedIcon />
  },
  {
    href: Routes.DEMO_FORECAST,
    key: 'app.navbar.routes.prediction',
    userGuideId: 'stepPrediction',
    icon: <AutoGraphOutlinedIcon />
  },
]

export const AppBar = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const { currentRoute } = useCurrentRoute()

  if (isMobile) {
    return (
      <AppBarMobile links={demoAppBarLinks} toolbar={<AppBarMobileToolbar />}>
        {currentRoute && <CurrentRoute routeKey={currentRoute} />}
      </AppBarMobile>
    )
  }

  return (
    <AppBarDesktop links={demoAppBarLinks}>
      <AppBarDesktopPanel />
    </AppBarDesktop>
  )
}
