import React, { PropsWithChildren } from 'react'
import { styled, Box, Stack } from '@mui/material'
import ReceiptIcon from '@mui/icons-material/Receipt'
import { useTranslation } from 'react-i18next'

import { TextPrimary } from 'Shared/components'

interface Props {
  isPremium: boolean
}

const PremiumSubscriptionIcon = styled(ReceiptIcon)(({ theme }) => ({
  color: theme.palette.premium.main
}))

export const SubscriptionPlanContainer: React.FC<PropsWithChildren<Props>> = ({ isPremium, children }) => {
  const { t } = useTranslation()

  return (
    <Stack direction="row" spacing={1}>
      {isPremium ? <PremiumSubscriptionIcon fontSize="small" /> : <ReceiptIcon fontSize="small" />}

      <Box>
        <TextPrimary>
          {isPremium ? t('app.navbar.settings.subscription.premium') : t('app.navbar.settings.subscription.free')}
        </TextPrimary>

        {children}
      </Box>
    </Stack>
  )
}
