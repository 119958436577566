import React from 'react'
import { DefaultSettingsState } from './settings-constants'
import { SettingsState } from './settings-types'

interface SettingsProps {
  children: React.ReactNode
  value: SettingsState
}

export const SettingsContext = React.createContext<SettingsState>(DefaultSettingsState)

export const SettingsProvider = ({ children, value }: SettingsProps) =>
  React.createElement(SettingsContext.Provider, { value: value }, children)
