import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import type { SnackbarKey } from 'notistack'
import { SnackbarProvider } from 'notistack'
import React, { PropsWithChildren } from 'react'
import { useDispatch } from 'react-redux'

import { closeSnackbar } from 'Snackbar/snackbar-actions'

const StyledSnackbarProvider = styled(SnackbarProvider)({
  '&.SnackbarContent-root': {
    flexWrap: 'nowrap',
  },
})

export const SnackbarProviderCustom: React.FC<PropsWithChildren> = ({ children }) => {
  const notistackRef: React.Ref<SnackbarProvider> = React.useRef<SnackbarProvider | null>(null)
  const dispatch = useDispatch()

  const handleClose = React.useCallback((key: SnackbarKey) => {
    dispatch(closeSnackbar(key))
  }, [ closeSnackbar ])

  return (
    <StyledSnackbarProvider
      maxSnack={3}
      style={{ flexWrap: 'nowrap' }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      ref={notistackRef}
      action={(key: SnackbarKey) => (
        <IconButton onClick={() => handleClose(key)} color="inherit">
          <CloseIcon />
        </IconButton>
      )}
    >
      {children}
    </StyledSnackbarProvider>
  )
}
