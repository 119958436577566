import type { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { BaseService } from 'Shared/api'
import { HistoryItem, IHistoryService } from './history-types'

export class HistoryService extends BaseService implements IHistoryService {
  public getMyHistory = (token: string) => axios
    .get<HistoryItem[]>('/history/my', { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public getClientsHistory = (token: string) => axios
    .get<HistoryItem[]>('/history/clients', { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public getHistorySharedWithMe = (token: string, sharingKey: string) => axios
    .get<HistoryItem[]>(`/history/shared-with-me/${sharingKey}`, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)
}
