import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { isArray } from 'lodash'

import { container, Service } from 'Services/container'
import {
  LiveOnCapitalRequest,
  LiveOnPassiveIncomeRequest,
  PredictionRequest,
  IPredictionService,
  Prediction
} from 'Services/prediction'
import type { IAction, ITypedAction } from 'Shared/types'
import { snackbarActionCreators } from 'Snackbar'
import { PredictionActions } from './prediction-actions'
import { DefaultYearsToPredict, PredictionActionTypes, YearsFrameItems, PredictionErrors } from './prediction-constants'
import { PredictionSelectors } from './prediction-selectors'
import type { PredictionCalculationData } from './prediction-types'
import { PredictionType } from './prediction-types'

function* changeSelectedProjection(selectedProjection: number) {
  const currentProjection: number = yield select(PredictionSelectors.getYearsProjection)

  if (selectedProjection > currentProjection) {
    const year = YearsFrameItems.find(availableProjection => availableProjection > selectedProjection)

    yield put(PredictionActions.updatePredictionProjection(year ?? selectedProjection))
  }
}

function* loadPrediction(action: ITypedAction<PredictionRequest>) {
  try {
    const request = action.payload
    const predictionService = container.resolve<IPredictionService>(Service.PredictionService)
    const response: Prediction = yield call(predictionService.getPrediction, request)

    const retirementCalculated = !!response.metadata
    const retirementReached = response?.metadata?.retirePointReached === true

    yield put(PredictionActions.getPredictionSuccess(response))

    if (!retirementCalculated || retirementReached) {
      if (request.liveOnPassiveIncome) {
        const retireYear = response?.metadata?.retirePoint.year ?? 0
        const currentYear = new Date().getFullYear()

        const selectedRetireProjection = retireYear - currentYear
        yield changeSelectedProjection(selectedRetireProjection)
      }

      if (request.liveOnCapital) {
        const selectedProjection = request.liveOnCapital.lifeExpectancy - request.liveOnCapital.currentAge
        yield changeSelectedProjection(selectedProjection)
      }

      yield put(snackbarActionCreators.enqueueSuccessSnackbar('prediction.loading-success'))
    } else {
      yield put(snackbarActionCreators.enqueueWarningSnackbar('prediction.loading-warning'))
    }
  } catch (error: any) {
    yield put(PredictionActions.getPredictionFailure())

    // TODO: Keep track if we need to separate custom errors elsewhere
    const { response } = error
    const isControlledError = response?.data && response.data.errors && isArray(response.data.errors)
    const isOverloadError = isControlledError && response.data.errors.includes(PredictionErrors.PredictionOverload)

    if (isOverloadError) {
      yield put(snackbarActionCreators.enqueueErrorSnackbar('common.errorMessages.prediction-overload'))
    } else {
      yield put(snackbarActionCreators.enqueueErrorSnackbar('prediction.loading-failure'))
    }
  }
}

function* refreshPrediction() {
  const calculation: PredictionCalculationData = yield select(PredictionSelectors.getCalculation)

  const liveOnPassiveIncome: LiveOnPassiveIncomeRequest | null =
    calculation.calculateRetirement && calculation.predictionType === PredictionType.LiveOnPassiveIncome ? {
      monthlySpending: calculation.monthlySpending,
      retirementProfitability: calculation.retirementProfitability,
      inflationPercent: calculation.expectedInflation,
      currentAge: calculation.currentAge,
    } : null

  const liveOnCapital: LiveOnCapitalRequest | null =
    calculation.calculateRetirement && calculation.predictionType === PredictionType.LiveOnCapital ? {
      monthlySpending: calculation.monthlySpending,
      retirementProfitability: calculation.retirementProfitability,
      inflationPercent: calculation.expectedInflation,
      lifeExpectancy: calculation.liveOnCapitalLifeExpectancy,
      currentAge: calculation.currentAge,
    } : null

  const requestModel: PredictionRequest = {
    startingBalance: calculation.currentBalance,
    monthlySavings: calculation.monthlySavings,
    savingsGrowthPercent: calculation.expectedSavingsGrowth,
    profitabilityPercent: calculation.profitabilityPercent,
    liveOnPassiveIncome: liveOnPassiveIncome,
    liveOnCapital: liveOnCapital,
    yearsToForecast: DefaultYearsToPredict,
  }

  yield put(PredictionActions.loadPrediction(requestModel))
}

export function* predictionSaga() {
  yield all([
    takeEvery(PredictionActionTypes.LOAD_PREDICTION, loadPrediction),
    takeEvery(PredictionActionTypes.REFRESH_PREDICTION, refreshPrediction),
  ])
}
