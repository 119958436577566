import type { IAction, ITypedActionWithToken, IActionWithToken, ITypedAction } from 'Shared/types'
import type { GetChartRequest, ChartPreRender } from 'Services/analytics'
import type { ChartDetails, GetChartPayload, ChartFilterState, ChartChangedState } from 'Analytics/analytics-types'
import { AnalyticsActionTypes } from 'Analytics/analytics-constants'

const getCharts = (token: string, abortSignal?: AbortSignal): IActionWithToken => ({
  type: AnalyticsActionTypes.GET_CHARTS,
  token,
  abortSignal: abortSignal,
})

const getChartsSuccess = (payload: ChartPreRender[]): ITypedAction<ChartPreRender[]> => ({
  type: AnalyticsActionTypes.GET_CHARTS_SUCCESS,
  payload
})

const getChartsFailure = (): IAction => ({
  type: AnalyticsActionTypes.GET_CHARTS_FAILURE,
})

const getChart = (payload: GetChartRequest, token: string, abortSignal?: AbortSignal): ITypedActionWithToken<GetChartRequest> => ({
  type: AnalyticsActionTypes.GET_CHART,
  payload,
  token,
  abortSignal,
})

const getChartSuccess = (key: string, chart: ChartDetails): ITypedAction<GetChartPayload> => ({
  type: AnalyticsActionTypes.GET_CHART_SUCCESS,
  payload: { key, chart }
})

const getChartFailure = (payload : string): ITypedAction<string> => ({
  type: AnalyticsActionTypes.GET_CHART_FAILURE,
  payload
})

const refreshAssetAllocationCharts = (token: string): IActionWithToken => ({
  type: AnalyticsActionTypes.REFRESH_ALLOCATION_CHARTS,
  token: token
})

const refreshSunburstExpectedAllocationCharts = (token: string): IActionWithToken => ({
  type: AnalyticsActionTypes.REFRESH_SUNBURST_ALLOCATION_CHARTS,
  token: token
})

const setChartsTags = (payload: string[]): ITypedAction<string[]> => ({
  type: AnalyticsActionTypes.SET_CHARTS_TAGS,
  payload
})

const setChartChanged = (payload: ChartChangedState) : ITypedAction<ChartChangedState> => ({
  type: AnalyticsActionTypes.SET_CHART_CHANGED,
  payload
})

const changeFilter = (filterState: ChartFilterState): ITypedAction<ChartFilterState> => ({
  type: AnalyticsActionTypes.SET_CHART_FILTER_CHANGED,
  payload: filterState
})

const resetState = (): IAction => ({
  type: AnalyticsActionTypes.RESET_STATE_ANALYTICS
})

export const AnalyticsActions = {
  getCharts,
  getChartsSuccess,
  getChartsFailure,
  getChart,
  refreshAssetAllocationCharts,
  refreshSunburstExpectedAllocationCharts,
  getChartSuccess,
  getChartFailure,
  changeFilter,
  setChartsTags,
  setChartChanged,
  resetState,
}
