import type { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { BaseService } from 'Shared/api'
import {
  UpdateChangeBalanceRequest,
  UpdateProfitLossRequest,
  UpdateBuySellItemRequest,
  UpdateTransferRequest,
  ITransactionsService
} from './transactions-types'

export class TransactionsService extends BaseService implements ITransactionsService {
  public getTransactions = (token: string, from: Date, to: Date) => axios
    .get('/transactions/my', {
      params: { from, to },
      headers: { ...super.buildAuthHeader(token) }
    })
    .then((response: AxiosResponse) => response?.data)

  public deleteTransaction = (transactionKey: string, token: string) => axios
    .delete(`/transactions/${transactionKey}`, {
      headers: { ...super.buildAuthHeader(token) }
    })
    .then((response: AxiosResponse) => response?.data)

  public updateChangeBalance = (transactionKey: string, token: string, payload: UpdateChangeBalanceRequest) => axios
    .patch(`/transactions/${transactionKey}/change-balance`,
      { payload },
      { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public updateProfitLoss = (transactionKey: string, token: string, payload: UpdateProfitLossRequest) => axios
    .patch(`/transactions/${transactionKey}/profit-loss`,
      { payload },
      { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public updateTransfer = (transactionKey: string, token: string, payload: UpdateTransferRequest) => axios
    .patch(`/transactions/${transactionKey}/transfer`,
      { payload },
      { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public updateBuySell = (transactionKey: string, token: string, payload: UpdateBuySellItemRequest) => axios
    .patch(`/transactions/${transactionKey}/buy-sell`,
      { payload },
      { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)
}
