import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { styled } from '@mui/material'

import { CaptionSmall } from 'Shared/components/Typography'
import { BaseItemDropdown } from './BaseItemDropdown'

interface FilterButtonProps {
  label: string
  onClick: (event: any) => void
  expanded: boolean
  numberOfItemsSelected: number
  disabled?: boolean
}

const StyledBadge = styled(CaptionSmall)(({ theme }) => ({
  border: `2px solid ${theme.palette.common.white}`,
  borderRadius: theme.spacing(0.5),
  color: theme.palette.common.white,
  backgroundColor: theme.palette.green.green1,
  padding: '1px 5px',
  alignItems: 'center'
}))

export const FilterButton: React.FC<FilterButtonProps> = ({ label, onClick, expanded, numberOfItemsSelected, disabled = false }) => (
  <BaseItemDropdown onClick={onClick} disabled={disabled} size="small">
    {label}
    {!!numberOfItemsSelected && (
      <>
        &nbsp;
        <StyledBadge>{numberOfItemsSelected}</StyledBadge>
      </>
    )}
    &nbsp;
    {expanded ? <ExpandLessIcon fontSize="inherit" /> : <ExpandMoreIcon fontSize="inherit" />}
  </BaseItemDropdown>
)
