import { AdvisoryClientTask } from 'Services/advisor'
import { ITypedAction } from 'Shared/types'
import { TasksActionTypes } from './Tasks-constants'

const getMyTasks = (tasks: AdvisoryClientTask[]): ITypedAction<AdvisoryClientTask[]> => ({
  type: TasksActionTypes.GET_TASKS,
  payload: tasks,
})

const changeTaskStatus = (taskKey: string, completed: boolean): ITypedAction<{ taskKey: string, completed: boolean }> => ({
  type: TasksActionTypes.CHANGE_TASK_STATUS,
  payload: { taskKey, completed }
})

export const TasksActions = {
  getMyTasks,
  changeTaskStatus,
}
