export const randomFromOr = (values: string[], or: string) => values.length > 0 ?
  values[Math.floor(Math.random() * values.length)] :
  or

export const stringToColor = (str: string) => {
  /* eslint-disable */
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  let colour = '#'
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xFF
    colour += ('00' + value.toString(16)).slice(-2)
  }

  /* eslint-enable */
  return colour
}
