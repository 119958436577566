import { Typography } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { SubTextPrimary1 } from 'Shared/components/Typography'
import { SecureNumber } from 'Shared/components/SecureNumber'
import { SupportedLanguage, languageService } from 'config/i18n-types'
import { theme } from 'config/theme'

interface Props {
  ticker: string
  value: number
  isHighlighted?: boolean
  showTicker?: boolean
  inline?: boolean
  hiddenModeChangeDisabled?: boolean,
}

const StyledTypography = styled(Typography)(() => ({
  display: 'inline',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
})) as typeof Typography

const getSign = (value: number, isHighlighted: boolean) => {
  if (value < 0) return '-\xa0'
  if (value > 0 && isHighlighted) return '+\xa0'

  return ''
}

const getColor = (value: number, isHighlighted: boolean) => {
  if (value < 0 && isHighlighted) return theme.palette.error.light
  if (value > 0 && isHighlighted) return theme.palette.success.light

  return ''
}

export const Ticker: React.FC<Props> = React.memo(({
  ticker,
  value,
  isHighlighted = false,
  showTicker = true,
  inline = false,
  hiddenModeChangeDisabled = false,
}) => {
  const { i18n } = useTranslation()

  const language = i18n.resolvedLanguage as SupportedLanguage
  const formattedValue = Math
    .abs(value)
    .toLocaleString(languageService.getLocale(language).code, { maximumFractionDigits: 6 })

  const color = getColor(value, isHighlighted)
  const sign = getSign(value, isHighlighted)

  return (
    <SecureNumber toggleDisabled={hiddenModeChangeDisabled}>
      <StyledTypography
        component="span"
        color={color}
        title={`${sign}${formattedValue} ${ticker}`}
        sx={{ display: inline ? 'inline' : 'block' }}
        noWrap
      >
        {sign}
        {formattedValue}
        {showTicker && (
          <SubTextPrimary1 component="span" display="inline" sx={{ ...(color && { color }) }}>
            {'\xa0'}{ticker}
          </SubTextPrimary1>
        )}
      </StyledTypography>
    </SecureNumber>
  )
})
