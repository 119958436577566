import React from 'react'

import { container, Service } from 'Services/container'
import { HistoryItem, IHistoryService } from 'Services/history'
import type { AsyncState } from 'Shared/types'
import { useSnackbar } from 'Snackbar'
import { useAuthWithDemo } from 'Demo'

// TODO: Create useFetch/usePost hooks to handle common flows.
export const useMyActionsHistory = () => {
  const { enqueueError } = useSnackbar()
  const { getAccessTokenWithDemo } = useAuthWithDemo()
  const [ historyState, setHistoryState ] = React.useState<AsyncState<HistoryItem[]>>({ isLoading: false, state: null })

  const fetchUserHistory = async () => {
    setHistoryState({ isLoading: true, state: null })
    const historyService = container.resolve<IHistoryService>(Service.HistoryService)

    const token = await getAccessTokenWithDemo()

    try {
      const history = await historyService.getMyHistory(token)
      setHistoryState({ isLoading: false, state: history })
    } catch {
      enqueueError('common.errorMessages.loading-failed')
    } finally {
      setHistoryState(prevState => ({ ...prevState, isLoading: false }))
    }
  }

  return {
    fetchUserHistory,
    history: historyState.state,
    isLoading: historyState.isLoading,
  }
}
