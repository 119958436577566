import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { MenuItem, Select, SelectChangeEvent, styled } from '@mui/material'
import i18n from 'config/i18n'
import { languageService, SupportedLanguage } from 'config/i18n-types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const StyledSelect = styled(Select)(({ theme }) => ({
  fontSize: theme.typography.textPrimary.fontSize,
  fontWeight: 700,
  ['fieldset']: {
    border: 0,
  },
  ['&.MuiOutlinedInput-root .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input']: {
    padding: theme.spacing(1),
    paddingRight: theme.spacing(3),
  },
  ['svg']: {
    right: 0,
    pointerEvents: 'none'
  },
  [theme.breakpoints.down('md')]: {
    minHeight: 48,
    fontSize: theme.typography.textLarge.fontSize,
    fontWeight: 400,
    ['&.MuiInputBase-root']: {
      // Note: To make dropdown menu centered under the select
      width: '70px',
    },
    ['&.MuiOutlinedInput-root .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input']: {
      padding: theme.spacing(1, 2),
    },
    ['svg']: {
      right: 'unset',
      left: 36
    }
  }
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  minHeight: 'auto',
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.textPrimary.fontSize,
  }
}))

export const LanguageSelector = () => {
  const { t } = useTranslation()
  const [ currentLanguage, setCurrentLanguage ] = useState(i18n.resolvedLanguage ?? SupportedLanguage.EN)

  const languageOptions = useMemo(
    () => {
      const options = Object
        .keys(languageService.translations)
        .map(key => ({
          text: t(languageService.getTranslationForLanding(key as SupportedLanguage)),
          value: key
        }))

      options.forEach((item, i) => {
        if (item.value === i18n.resolvedLanguage) {
          options.splice(i, 1)
          options.unshift(item)
        }
      })
      return options
    },
    []
  )

  const handleOnChange = (event: SelectChangeEvent<unknown>) => {
    if (event.target?.value) {
      setCurrentLanguage(event.target.value as string)
      i18n.changeLanguage(event.target.value as string)
    }
  }

  return (
    <StyledSelect
      labelId="language-select-label"
      id="language-select"
      value={currentLanguage}
      IconComponent={props => <KeyboardArrowDownIcon {...props} />}
      onChange={handleOnChange}
      autoWidth
    >
      {languageOptions.map(option => (
        <StyledMenuItem
          key={option.value}
          value={option.value}
        >
          {option.text}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  )
}
