import { Alert, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { PortfolioAllocationType } from 'Services/portfolio'
import { Dictionary } from 'Shared/types'
import { AssetAllocationChart } from './AssetAllocationChart'

interface AssetAllocationHighlightsProps {
  values: Dictionary<number>
  type: PortfolioAllocationType
}

const ChartContainer = styled(Box)({
  height: 170
})

const getAlertSeverity = (allocated: number) =>
  allocated === 100 ?
    'success' :
    allocated >= 0 && allocated < 100 ?
      'warning' :
      'error'

export const AssetAllocationHighlights: React.FC<AssetAllocationHighlightsProps> = ({ values, type }) => {
  const { t } = useTranslation()

  const totalAllocated = React.useMemo(
    () => Object
      .keys(values)
      .reduce((allocated, key) => allocated + (+values[key] || 0), 0),
    [ values ])

  const alertSeverity = React.useMemo(() => getAlertSeverity(totalAllocated), [ totalAllocated ])

  return (
    <>
      <ChartContainer>
        <AssetAllocationChart values={values} totalAllocated={totalAllocated} type={type} />
      </ChartContainer>

      <Alert severity={alertSeverity}>
        {t('app.navbar.asset-allocation.allocationAlert', { percent: totalAllocated })}
      </Alert>
    </>
  )
}
