import React from 'react'
import { Stack, Skeleton } from '@mui/material'

interface Props {
  numberOfItems: number
}

export const LoadingState: React.FC<Props> = ({ numberOfItems }) => (
  <Stack spacing={1}>
    {Array.from({ length: numberOfItems }).map((_, idx) => (
      <Skeleton key={idx} variant="rounded" height={82} width="100%" />
    ))}
  </Stack>
)
