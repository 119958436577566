import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import { isNull } from 'lodash'
import { useTranslation } from 'react-i18next'

import { HOLDING_LIQUIDITY } from 'Services/holdings'
import { HoldingLiquidityOptions } from 'Holdings/holdings-constants'
import { theme } from 'config/theme'

interface Props {
  liquidity: HOLDING_LIQUIDITY | null
}

const getLiquidityOption = (liquidity: HOLDING_LIQUIDITY | null) => {
  if (isNull(liquidity) || !HoldingLiquidityOptions[liquidity]) {
    return {
      color: theme.palette.dark.dark2,
      key:  'common.state.notSpecified-she'
    }
  }

  return HoldingLiquidityOptions[liquidity]
}

const LiquidityIconBase: React.FC<Props> = ({ liquidity }: Props) => {
  const { t } = useTranslation()

  const currentLiquidity = getLiquidityOption(liquidity)

  return (
    <Tooltip
      title={t('holdings.table.liquidity-specific', { option: t(currentLiquidity.key) })}
      enterTouchDelay={0}
      leaveTouchDelay={5000}
    >
      <MonetizationOnIcon sx={{ fill: currentLiquidity.color }} fontSize="small" />
    </Tooltip>
  )
}

export const LiquidityIcon = React.memo(LiquidityIconBase)
