import React from 'react'

import { SettingsState } from 'App/Settings/settings-types'
import { DefaultDemoCurrency } from 'Demo/demo-constants'

export const useDemoSettings = (settingsContext: SettingsState) => {
  const [ demoHiddenModeEnabled, setDemoHiddenModeEnabled ] = React.useState(false)

  return React.useMemo(
    () => ({
      ...settingsContext,
      baseCurrency: DefaultDemoCurrency,
      isHiddenMode: demoHiddenModeEnabled,
      profile: {
        ...settingsContext.profile,
        isAdvisoryClient: false,
      },
      manageSettings: {
        ...settingsContext.manageSettings,
        setHiddenMode: setDemoHiddenModeEnabled,
        toggleHiddenMode: () => setDemoHiddenModeEnabled(!demoHiddenModeEnabled),
      }
    }),
    [ settingsContext ])
}
