import React from 'react'

import { Money } from 'Shared/types'
import { SecureNumber } from 'Shared/components/SecureNumber'
import { BasePrice } from './BasePrice'
import { DecimalOutput } from './Price-types'

interface PriceProps {
  value: number
  currency: string
  decimalOutput?: DecimalOutput
  isHighlighted?: boolean
  inline?: boolean
  showAlways?: boolean
  smartRound?: boolean
  hiddenModeChangeDisabled?: boolean
}

export const Price: React.FC<PriceProps> = React.memo(({
  value,
  currency,
  decimalOutput = 'nonZero',
  isHighlighted = false,
  inline = false,
  showAlways = false,
  smartRound = false,
  hiddenModeChangeDisabled = false,
}) => {
  const decimalScale = smartRound && Math.abs(value) < 1 ? Money.SyncedDecimalScale : Money.RegularDecimalScale
  const money = Money.fromDollars(value, decimalScale)

  if (isNaN(value)) {
    return null
  }

  return (
    <SecureNumber showAlways={showAlways} toggleDisabled={hiddenModeChangeDisabled}>
      <BasePrice
        value={money}
        currency={currency}
        decimalOutput={decimalOutput}
        isHighlighted={isHighlighted}
        inline={inline}
      />
    </SecureNumber>
  )
})
