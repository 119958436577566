import type { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { ITransactionsService } from './transactions-types'
import { TransactionsService } from './transactions-service'

export class TransactionsSharedService extends TransactionsService implements ITransactionsService {
  private readonly sharingKey: string

  public constructor(sharingKey: string) {
    super()
    this.sharingKey = sharingKey
  }

  public getTransactions = (token: string, from: Date, to: Date) => this.getTransactionsSharedWithMe(token, this.sharingKey, from, to)

  private getTransactionsSharedWithMe = (token: string, sharingKey: string, from: Date, to: Date) => axios
    .get(`/transactions/shared-with-me/${sharingKey}`, {
      params: { from, to },
      headers: { ...super.buildAuthHeader(token) }
    })
    .then((response: AxiosResponse) => response?.data)
}
