import { all, takeEvery } from 'redux-saga/effects'

import { analyticsSaga } from 'Analytics/analytics-saga'
import { holdingsSaga } from 'Holdings/holdings-saga'
import { transactionsSaga } from 'Transactions/transactions-saga'
import { snackbarSaga } from 'Snackbar/snackbar-saga'
import { predictionSaga } from 'Prediction/prediction-saga'
import { demoSaga } from 'Demo/demo-saga'
import { advisorPortalSaga } from 'AdvisorPortal/advisorPortal-saga'
import { cleanStateSaga, CleanStateActionType } from 'Integration/sagas'
import { assetAllocationSaga } from 'App/AssetAllocation/assetAllocation-saga'

export function* rootSaga() {
  yield all([
    analyticsSaga(),
    holdingsSaga(),
    transactionsSaga(),
    snackbarSaga(),
    predictionSaga(),
    advisorPortalSaga(),
    demoSaga(),
    assetAllocationSaga(),

    all([
      takeEvery(CleanStateActionType, cleanStateSaga)
    ])
  ])
}
