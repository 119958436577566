export const landingSections = {
  features: {
    label: 'app.navbar.landing.navigation.features',
    id: 'features'
  },
  security: {
    label: 'app.navbar.landing.navigation.security',
    id: 'security'
  },
  pricing: {
    label: 'app.navbar.landing.navigation.pricing',
    id: 'pricing'
  },
  faq: {
    label: 'app.navbar.landing.navigation.faq',
    id: 'faq'
  },
  reviews: {
    label: 'app.navbar.landing.navigation.reviews',
    id: 'reviews'
  },
}
