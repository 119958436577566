import { AdvisoryClientTask } from 'Services/advisor'
import { ITypedAction } from 'Shared/types'
import { TasksState } from './Tasks-types'
import { TasksInitialState, TasksActionTypes } from './Tasks-constants'

export const tasks = (
  state: TasksState = TasksInitialState,
  action: ITypedAction<any>): TasksState => {
  switch (action.type) {
    case TasksActionTypes.GET_TASKS: {
      const tasks = action.payload as AdvisoryClientTask[]

      return {
        ...state,
        tasks: {
          list: tasks,
          isLoading: false,
          initialLoadCompleted: true,
        }
      }
    }

    case TasksActionTypes.CHANGE_TASK_STATUS: {
      const taskKey = action.payload.taskKey as string
      const completed = action.payload.completed as boolean

      const taskIdx = state.tasks.list.findIndex(t => t.key === taskKey)

      return {
        ...state,
        tasks: {
          ...state.tasks,
          list: [
            ...state.tasks.list.slice(0, taskIdx),
            {
              ...state.tasks.list[taskIdx],
              completed,
              completedDateTime: completed ? new Date().toString() : null,
            },
            ...state.tasks.list.slice(taskIdx + 1),
          ]
        }
      }
    }

    default: {
      return state
    }
  }
}
