import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { AdvisoryClientTask } from 'Services/advisor'
import { NoDataOverlay, SideDrawer, TaskList } from 'Shared/components'
import { TasksSelectors } from 'App/Tasks/Tasks-selectors'
import { useTasksManager } from 'App/Tasks/useTasksManager'
import { ClientTaskCard } from './ClientTaskCard'

interface Props {
  isOpen: boolean
  close: () => void
}

export const TasksDrawer: React.FC<Props> = ({ isOpen, close }) => {
  const { t } = useTranslation()
  const tasksManager = useTasksManager()

  const tasks = useSelector(TasksSelectors.tasks)
  const showLoading = !tasks.initialLoadCompleted && tasks.isLoading

  React.useEffect(() => { isOpen && tasksManager.loadMyTasksAsync() }, [ isOpen ])

  return (
    <SideDrawer
      title={t('app.navbar.tasks')}
      isOpen={isOpen}
      close={close}
      isLoading={showLoading}
    >
      {tasks.list.length !== 0 ? (
        <TaskList
          tasks={tasks.list}
          renderTasks={(tasksToRender: AdvisoryClientTask[]) => tasksToRender.map(task => (
            <ClientTaskCard
              key={task.key}
              task={task}
              onStatusChange={tasksManager.changeTaskStatusAsync}
            />
          ))}
        />
      ) : (
        <NoDataOverlay />
      )}
    </SideDrawer>
  )
}
