import React from 'react'
import { withTranslation } from 'react-i18next'
import { Button } from '@mui/material'

import { Routes } from 'Shared/constants'
import { SurferImg } from 'App/assets/SurferImg'
import { StaticPage } from './StaticPage'
import { StaticPageTitle, StaticPageSubTitle } from './Typographies'

const RedirectInSeconds = 10

export const ActivationSuccessful = withTranslation()(({ t }) => {
  const [ countdown, setCountdown ] = React.useState(RedirectInSeconds)

  const handleNavigateToProfile = () => {
    window.location.href = Routes.HOLDINGS
  }

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (countdown === 1) {
        clearInterval(timer)
        handleNavigateToProfile()
      } else {
        setCountdown(prevCountdown => prevCountdown - 1)
      }
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [ countdown ])

  return (
    <StaticPage
      title={t('app.navbar.routes.activationSuccessful')}
      messageComponent={(
        <>
          <StaticPageTitle mb={2.5}>
            {t('app.static.activationSuccessful.title')}
          </StaticPageTitle>
          <StaticPageSubTitle mb={1.5}>
            {t('app.static.activationSuccessful.subTitle')}
          </StaticPageSubTitle>
          <StaticPageSubTitle mb={3.5}>
            {t('app.static.activationSuccessful.subTitle2')}
          </StaticPageSubTitle>
          <Button onClick={handleNavigateToProfile} variant="outlined">
            {t('app.navbar.routes.backToAppIn', { seconds: countdown })}
          </Button>
        </>
      )}
      imgComponent={<SurferImg width="100%" height="100%" />}
    />
  )
})
