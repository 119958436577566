import React, { PropsWithChildren } from 'react'
import { isToday, isYesterday } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'

import { SubTextSecondary2 } from 'Shared/components/Typography'

interface Props {
  createdAt: string
}

export const HistoryMessagesGroup: React.FC<PropsWithChildren<Props>> = ({ createdAt, children }) => {
  const { t } = useTranslation()

  const caption = React.useMemo(
    () => {
      const createdAtDate = new Date(createdAt)

      if (isToday(createdAtDate)) return t('common.today')
      if (isYesterday(createdAtDate)) return t('common.yesterday')

      return t('common.date', { date: createdAtDate })
    },
    [ createdAt ])

  return (
    <Stack spacing={2}>
      <SubTextSecondary2>{caption}</SubTextSecondary2>
      {children}
    </Stack>
  )
}
