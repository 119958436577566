import React, { useContext } from 'react'
import { Stack, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { container, Service } from 'Services/container'
import { ISharingService } from 'Services/sharing'
import { buildFormSubmit } from 'Shared/utils'
import { ContentBlock } from 'Shared/components'
import { useTypedDrawer } from 'Shared/hooks'
import { useAuthWithDemo } from 'Demo'
import { SettingsContext } from 'App/Settings'
import { useSnackbar } from 'Snackbar'
import { FormState } from './form-fields'
import { useSharingsByMe } from './useSharingsByMe'
import { ShareProfileSectionContent } from './ShareProfileSectionContent'
import { RevokeSharingDialog } from './RevokeSharingDialog'

interface Props {
  isDrawerOpen: boolean
}

export const ShareProfileSection: React.FC<Props> = ({ isDrawerOpen }) => {
  const { t } = useTranslation()
  const { getAccessTokenWithDemo } = useAuthWithDemo()
  const { enqueueError, enqueueWarning, enqueueSuccess } = useSnackbar()
  const { sharingsByMe, isLoading, fetchSharings } = useSharingsByMe()
  const { profile, manageSettings } = useContext(SettingsContext)
  const revokeSharingDialog = useTypedDrawer<{ username: string, key: string }>()

  React.useEffect(() => {
    if (isDrawerOpen) {
      fetchSharings()
    }
  }, [ isDrawerOpen ])

  const revokeSharing = async (key: string) => {
    const sharingService = container.resolve<ISharingService>(Service.SharingService)
    const token = await getAccessTokenWithDemo()

    await sharingService.revokeSharing(token, key)
    await fetchSharings()
    await manageSettings.refreshProfile()

    enqueueSuccess('app.navbar.settings.sharingSettings.revokeSharing-success')
  }

  const createSharing = buildFormSubmit<FormState, FormState, void>({
    valuesToRequest: values => ({
      withUsername: values.withUsername.trim(),
      allowEdit: values.allowEdit,
    }),
    requestAccessToken: getAccessTokenWithDemo,
    actionCall: async (request, accessToken) => {
      const sharingService = container.resolve<ISharingService>(Service.SharingService)
      await sharingService.createSharing(accessToken, request)
    },
    onSuccessCall: async () => {
      await fetchSharings()
      enqueueSuccess('app.navbar.settings.sharingSettings.createSharing-success')
    },
    onWarningCall: (_, __, error) => enqueueWarning(error.key, error.prop),
    onErrorCall: () => enqueueError('app.navbar.settings.update-failure'),
  })

  const loadingState = isLoading && sharingsByMe === null

  return (
    <ContentBlock
      title={t('app.navbar.settings.sharingSettings.title')}
      helper={t('app.navbar.settings.sharingSettings.tooltip')}
    >
      {loadingState ? (
        <Stack height="100%" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : (
        <ShareProfileSectionContent
          sharings={sharingsByMe ?? []}
          onRevokeAccess={(username, key) => revokeSharingDialog.open({ username, key })}
          onCreateSharing={createSharing}
          usernameSpecified={!!profile.username}
        />
      )}

      {revokeSharingDialog.value && (
        <RevokeSharingDialog
          isOpen={revokeSharingDialog.isOpen}
          close={revokeSharingDialog.close}
          advisorUsername={revokeSharingDialog.value.username}
          sharingKey={revokeSharingDialog.value.key}
          onConfirmRevokeSharing={revokeSharing}
        />
      )}
    </ContentBlock>
  )
}
