import React, { useContext } from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { SupportedLanguage } from 'config/i18n-types'
import { buildFormSubmit } from 'Shared/utils'
import type { IProfileService } from 'Services/profile'
import { container, Service } from 'Services/container'
import { useAuthWithDemo } from 'Demo'
import { useSnackbar } from 'Snackbar'
import { SettingsContext } from 'App/Settings'
import { FormState } from './form-fields'
import { UserSettingsForm } from './UserSettingsForm'
import { EditableContent } from './EditableContent'
import { UserSettingsPreview } from './UserSettingsPreview'
import { UserProfileSchema } from './form-validation'

export const MainSettingsSection: React.FC = () => {
  const { t, i18n } = useTranslation()
  const { baseCurrency, profile, manageSettings } = useContext(SettingsContext)
  const { getAccessTokenWithDemo } = useAuthWithDemo()
  const { enqueueError, enqueueWarning } = useSnackbar()

  const initialValues = {
    baseCurrency: baseCurrency,
    language: i18n.resolvedLanguage ?? SupportedLanguage.EN,
    username: profile.username,
  }

  const handleSubmit = buildFormSubmit<FormState, FormState, void>({
    valuesToRequest: values => values,
    requestAccessToken: getAccessTokenWithDemo,
    actionCall: async (request, accessToken) => {
      const profileService = container.resolve<IProfileService>(Service.ProfileService)

      await profileService.updateAccount(accessToken, {
        baseCurrency: request.baseCurrency,
        username: (request.username ?? '').trim(),
      })

      await i18n.changeLanguage(request.language)
    },
    onSuccessCall: async (_, values) => {
      manageSettings.changeBaseCurrency(values.baseCurrency)
      window.location.reload()
    },
    onWarningCall: (_, __, error) => enqueueWarning(error.key, error.prop),
    onErrorCall: () => enqueueError('app.navbar.settings.update-failure'),
  })

  return (
    <EditableContent
      label={t('app.navbar.settings.commonSettings-title')}
      previewContent={(
        <UserSettingsPreview />
      )}
      editableContent={(
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={UserProfileSchema}
        >
          <UserSettingsForm />
        </Formik>
      )}
    />
  )
}
