import React from 'react'
import { Box, Stack, styled } from '@mui/material'

import { AdvisoryClientTask } from 'Services/advisor'
import { SecondaryContentPaper } from 'Shared/components/Papers'
import { TextPrimary, SubTextPrimary1 } from 'Shared/components/Typography'
import { TaskDueLabel } from './DueDateLabel'
import { ExpandMoreText } from './ExpandMoreText'

interface Props {
  task: AdvisoryClientTask
  actions?: React.ReactElement
}

const TaskDescription = styled(SubTextPrimary1)({
  whiteSpace: 'pre-line',
  height: '100%',
  overflowWrap: 'anywhere',
})

export const TaskCard: React.FC<Props> = ({ task, actions }) => (
  <SecondaryContentPaper elevation={0} key={task.key}>
    <Stack direction="row" alignItems="stretch" justifyContent="space-between" spacing={1}>
      <Box>
        <TaskDueLabel task={task} />

        <Box mt={1}>
          <TextPrimary fontWeight={700} noWrap>{task.title}</TextPrimary>
          <TaskDescription>
            <ExpandMoreText text={task.description} limit={70} />
          </TaskDescription>
        </Box>
      </Box>

      <Box>
        {actions}
      </Box>
    </Stack>
  </SecondaryContentPaper>
)
