import React from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

interface Props {
  options: { label: string, onClick: () => void, danger?: boolean, disabled?: boolean }[]
  disabled?: boolean
}

export const ActionsMenu: React.FC<Props> = ({ options, disabled = false }) => {
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const closeMenu = () => setAnchorEl(null)

  return (
    <>
      <IconButton onClick={openMenu} size="small" disabled={disabled}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={closeMenu}>
        {options.map(option => (
          <MenuItem
            key={option.label}
            onClick={() => {
              closeMenu()
              option.onClick()
            }}
            sx={{ color: option.danger ? 'error.main' : undefined }}
            disabled={option.disabled}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
