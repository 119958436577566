import React from 'react'
import { useTranslation } from 'react-i18next'
import { addDays } from 'date-fns'

import { ButtonLink, SubTextSecondary1 } from 'Shared/components'
import { SubscriptionPlanContainer } from './SubscriptionPlanContainer'

interface Props {
  validUntilDate: Date
  onRenewSubscriptionClick: () => void
}

export const CancelledSubscription: React.FC<Props> = ({ validUntilDate, onRenewSubscriptionClick }) => {
  const { t } = useTranslation()
  const dateWithGracePeriod = addDays(validUntilDate, 1)

  return (
    <SubscriptionPlanContainer isPremium>
      <SubTextSecondary1>
        {t('app.navbar.settings.subscription.validUntilDate', { date: dateWithGracePeriod })}
      </SubTextSecondary1>
      <SubTextSecondary1>
        <ButtonLink onClick={onRenewSubscriptionClick}>{t('app.navbar.settings.subscription.renew')}</ButtonLink>
      </SubTextSecondary1>
    </SubscriptionPlanContainer>
  )
}
