/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable max-len */
import { SVGProps } from 'react'

export const QuestionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M13 2C6.92487 2 2 6.92487 2 13C2 19.0751 6.92487 24 13 24C19.0751 24 24 19.0751 24 13C24 6.92487 19.0751 2 13 2ZM0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13Z" fill="currentColor" />
    <path d="M13 21C13.8284 21 14.5 20.3284 14.5 19.5C14.5 18.6716 13.8284 18 13 18C12.1716 18 11.5 18.6716 11.5 19.5C11.5 20.3284 12.1716 21 13 21Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.2779 6.3431C12.1001 6.00251 13.0049 5.9134 13.8778 6.08703C14.7508 6.26066 15.5526 6.68925 16.1819 7.31858C16.8113 7.94792 17.2398 8.74974 17.4135 9.62266C17.5871 10.4956 17.498 11.4004 17.1574 12.2226C16.8168 13.0449 16.24 13.7477 15.5 14.2422C15.0412 14.5487 14.5325 14.7667 13.9999 14.888V15.0006C13.9999 15.5528 13.5522 16.0006 12.9999 16.0006C12.4477 16.0006 11.9999 15.5528 11.9999 15.0006V14.0006C11.9999 13.7353 12.1053 13.481 12.2928 13.2935C12.4804 13.1059 12.7347 13.0006 12.9999 13.0006C13.4944 13.0006 13.9777 12.8539 14.3889 12.5792C14.8 12.3045 15.1204 11.9141 15.3096 11.4573C15.4989 11.0005 15.5484 10.4978 15.4519 10.0128C15.3554 9.52788 15.1173 9.08243 14.7677 8.7328C14.4181 8.38317 13.9726 8.14506 13.4877 8.0486C13.0027 7.95214 12.5 8.00164 12.0432 8.19086C11.5864 8.38008 11.196 8.70051 10.9213 9.11164C10.6466 9.52276 10.4999 10.0061 10.4999 10.5006C10.4999 11.0528 10.0522 11.5006 9.49994 11.5006C8.94765 11.5006 8.49994 11.0528 8.49994 10.5006C8.49994 9.61055 8.76386 8.74052 9.25832 8.0005C9.75279 7.26048 10.4556 6.6837 11.2779 6.3431Z" fill="currentColor" />
  </svg>
)
