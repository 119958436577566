import React, { PropsWithChildren, useContext } from 'react'
import { Typography, styled } from '@mui/material'

import { SettingsContext } from 'App/Settings'
import { HiddenMoneySymbol } from 'Shared/constants'

export const SecureNumberContainer = styled(Typography)({
  fontSize: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  cursor: 'pointer',
}) as typeof Typography

const HiddenMoneyContent = HiddenMoneySymbol.repeat(5)

interface Props {
  showAlways?: boolean
  toggleDisabled?: boolean
}

export const SecureNumber: React.FC<PropsWithChildren<Props>> = React.memo(({
  children,
  showAlways = false,
  toggleDisabled = false,
}) => {
  const { isHiddenMode, manageSettings } = useContext(SettingsContext)

  const toggleHiddenMode = () => {
    !toggleDisabled && manageSettings.setHiddenMode(!isHiddenMode)
  }

  return (
    <SecureNumberContainer component="span" onClick={toggleHiddenMode}>
      {!showAlways && isHiddenMode ? HiddenMoneyContent : children}
    </SecureNumberContainer>
  )
})
