import i18next from 'config/i18n'
import { SupportedLanguage } from 'config/i18n-types'
import { HOLDING_RISK, HOLDING_LIQUIDITY, HOLDING_CLASS, HOLDING_TERM, HoldingRegion } from 'Services/holdings'
import {
  DefaultUndefinedTranslationKey,
  DefaultUndefinedValue,
  HoldingClassTranslationKeys,
  HoldingLiquidityTranslationKeys,
  HoldingRiskTranslationKeys,
  HoldingTermTranslationKeys,
  HoldingRegionsTranslationKeys,
} from 'Shared/constants'
import { intlCountriesFactory } from 'Shared/modules/Country'
import type { Render } from './providers-types'
import { LabelProvider } from './providers-types'

const riskLabelRender = (value: string) => {
  const holdingRisk = value as HOLDING_RISK

  if (holdingRisk && HoldingRiskTranslationKeys[holdingRisk]) {
    return i18next.t(HoldingRiskTranslationKeys[holdingRisk])
  }

  return i18next.t(DefaultUndefinedTranslationKey)
}

const liquidityLabelRender = (value: string) => {
  const holdingLiquidity = value as HOLDING_LIQUIDITY

  if (holdingLiquidity && HoldingLiquidityTranslationKeys[holdingLiquidity]) {
    return i18next.t(HoldingLiquidityTranslationKeys[holdingLiquidity])
  }

  return i18next.t(DefaultUndefinedTranslationKey)
}

const classLabelRender = (value: string) => {
  const holdingClass = value as HOLDING_CLASS

  if (holdingClass && HoldingClassTranslationKeys[holdingClass]) {
    return i18next.t(HoldingClassTranslationKeys[holdingClass])
  }

  return i18next.t(DefaultUndefinedTranslationKey)
}

const termLabelRender = (value: string) => {
  const holdingTerm = value as HOLDING_TERM

  if (holdingTerm && HoldingTermTranslationKeys[holdingTerm]) {
    return i18next.t(HoldingTermTranslationKeys[holdingTerm])
  }

  return i18next.t(DefaultUndefinedTranslationKey)
}

const regionLabelRender = (value: string) => {
  const region = value as HoldingRegion

  if (region && HoldingRegionsTranslationKeys[region]) {
    return i18next.t(HoldingRegionsTranslationKeys[region])
  }

  return i18next.t(DefaultUndefinedTranslationKey)
}

const countryLabelRender = (value: string) => {
  if (!value || value === DefaultUndefinedValue) {
    return i18next.t(DefaultUndefinedTranslationKey)
  }

  return intlCountriesFactory.getCountryName(i18next.resolvedLanguage ?? SupportedLanguage.EN, value) ?? value
}

export const labelRenderProvider = new Map<string, Render>([
  [ LabelProvider.Risk, riskLabelRender ],
  [ LabelProvider.Liquidity, liquidityLabelRender ],
  [ LabelProvider.Class, classLabelRender ],
  [ LabelProvider.Term, termLabelRender ],
  [ LabelProvider.Country, countryLabelRender ],
  [ LabelProvider.Region, regionLabelRender ],
])

export const defaultLabelRender: Render = value => {
  if (value === DefaultUndefinedValue) {
    return i18next.t(DefaultUndefinedTranslationKey)
  }

  return value
}
