import React, { useContext, useState, useEffect } from 'react'
import { motion, useAnimate } from 'framer-motion'

import { SettingsContext } from 'App/Settings'
import { Nullable } from 'Shared/types'

interface Props {
  expanded: boolean
}

const shortLogoPathForOrganization = (key: string) => `${process.env.REACT_APP_BLOB_HOST_B2B}/${key}/logo-short.svg`
const fullLogoPathForOrganization = (key: string) => `${process.env.REACT_APP_BLOB_HOST_B2B}/${key}/logo-full.svg`
const shortAppLogoPath = `${process.env.PUBLIC_URL}/assets/logo-short.svg`
const fullAppLogoPath = `${process.env.PUBLIC_URL}/assets/logo-full.svg`

const buildLogoPath = (expanded: boolean, organization: Nullable<{ key: string, whiteLabelLogo: boolean }>) => {
  if (organization && organization.whiteLabelLogo) {
    return expanded ?
      fullLogoPathForOrganization(organization.key) :
      shortLogoPathForOrganization(organization.key)
  }

  return expanded ? fullAppLogoPath : shortAppLogoPath
}

export const AppLogoWhiteLabeled: React.FC<Props> = ({ expanded }) => {
  const { profile } = useContext(SettingsContext)
  const [ scope, animate ] = useAnimate()
  const [ logoPath, setLogoPath ] = useState(buildLogoPath(expanded, profile.organization))

  const setLogoWithExitAnimation = (path: string) =>
    animate(scope.current, { opacity: 0 }, { duration: 0.3, delay: 1, ease: 'easeOut' })
      .then(() => setLogoPath(path))

  const setLogoWithoutExitAnimation = (path: string) =>
    animate(scope.current, { opacity: 0 }, { duration: 0 })
      .then(() => setLogoPath(path))

  const showLogoWithEnterAnimation = () =>
    animate(scope.current, { opacity: 1 }, { duration: 1, ease: 'easeIn' })

  useEffect(() => {
    const path = buildLogoPath(expanded, profile.organization)

    if (path !== logoPath) {
      setLogoWithExitAnimation(path)
    }
  }, [ profile.organization ])

  useEffect(() => {
    const path = buildLogoPath(expanded, profile.organization)

    if (path !== logoPath) {
      setLogoWithoutExitAnimation(path)
    }
  }, [ expanded ])

  useEffect(() => { showLogoWithEnterAnimation() }, [ logoPath ])

  return (
    <motion.img
      ref={scope}
      src={logoPath}
      alt="FIREkit logo white label"
      style={{ cursor: 'pointer', maxHeight: 24 }}
    />
  )
}
