import { ITypedAction } from 'Shared/types'
import { SharingState } from './sharing-types'
import { SharingInitialState, SharingActionTypes } from './sharing-constants'

export const sharing = (state: SharingState = SharingInitialState, action: ITypedAction<any>): SharingState => {
  switch(action.type) {
    case SharingActionTypes.SET_SELECTED_SHARING: {
      const selectedSharingKey = action.payload as string

      return { ...state, selectedSharingKey }
    }

    default:
      return state
  }
}
