import { selectErrorOrDefault } from 'Shared/utils'
import { useSnackbar } from 'Snackbar'

export const useErrorHandler = () => {
  const { enqueueWarning, enqueueError } = useSnackbar()

  const handleError = (error: any, defaultErrorKey: string, defaultProp: any = undefined) => {
    const errorCodes = error?.response?.data?.errors ?? []
    const controlledError = selectErrorOrDefault(errorCodes)

    if (controlledError) {
      enqueueWarning(controlledError.key, controlledError.prop)
    } else {
      enqueueError(defaultErrorKey, defaultProp)
    }
  }

  return {
    handleError
  }
}
