/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable max-len */
import { SVGProps } from 'react'

export const AppLogoWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg width="97" height="24" viewBox="0 0 97 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_3048_190445)">
      <path d="M6.52437 13.3142C7.16932 12.5164 7.86836 11.7396 8.62565 10.9923C9.42039 10.2113 10.2443 9.4597 11.0806 8.73331L0 7.94394V12.2393L6.52437 13.3142Z" fill="url(#paint0_linear_3048_190445)" />
      <path d="M11.0848 8.73336L7.53132 3.64862L0 7.94399L11.0848 8.73336Z" fill="url(#paint1_linear_3048_190445)" />
      <path d="M1.20668 1.14627C0.461866 1.87686 0 2.90137 0 4.03084V7.94412L7.53132 3.64875L1.20668 1.14627Z" fill="url(#paint2_linear_3048_190445)" />
      <path d="M11.0849 8.73349L13.9685 0H13.0905L7.53149 3.64875L11.0849 8.73349Z" fill="url(#paint3_linear_3048_190445)" />
      <path d="M3.99432 0C2.91247 0 1.92633 0.436674 1.20648 1.14627L7.53113 3.64875L13.0902 0H3.99432Z" fill="url(#paint4_linear_3048_190445)" />
      <path d="M21.6576 0H14.688L17.2554 3.97206C19.1319 2.66623 20.7838 1.62913 21.9198 0.940529C22.3608 0.676005 22.1694 0 21.6576 0Z" fill="url(#paint5_linear_3048_190445)" />
      <path d="M11.0848 8.73349C13.1612 6.93641 15.3415 5.30728 17.2555 3.97626L14.6924 0H13.9517L11.0848 8.73349Z" fill="url(#paint6_linear_3048_190445)" />
      <path d="M7.56044 12.0714L4.21088 20.3051L4.27329 20.2589C5.87942 18.8565 7.58124 17.6641 9.15824 16.6858L7.56044 12.0714Z" fill="url(#paint7_linear_3048_190445)" />
      <path d="M7.56044 12.0714C2.83777 10.4717 0 7.94403 0 7.94403L5.8711 16.2239L7.56044 12.0714Z" fill="url(#paint8_linear_3048_190445)" />
      <path d="M0 7.94403V16.3457L5.8711 16.2239L0 7.94403Z" fill="url(#paint9_linear_3048_190445)" />
      <path d="M5.8711 16.2239L0 16.3457L4.21088 20.3051L5.8711 16.2239Z" fill="url(#paint10_linear_3048_190445)" />
      <path d="M0.873799 23.8153C1.87243 22.5346 3.00836 21.3716 4.20256 20.3219L4.21088 20.3051L0 16.3457V23.4962C0 23.9622 0.586694 24.1806 0.873799 23.8153Z" fill="url(#paint11_linear_3048_190445)" />
      <path d="M9.17908 16.6774C11.7589 15.0819 14.0099 14.0574 14.9794 13.6207C15.1167 13.5577 15.071 13.3562 14.9212 13.3562C13.0779 13.331 11.3636 13.0875 9.80322 12.718L9.17908 16.6774Z" fill="url(#paint12_linear_3048_190445)" />
      <path d="M9.80318 12.7138C9.01676 12.5291 8.26779 12.3065 7.56042 12.0672L9.15823 16.6858C9.16655 16.6816 9.17487 16.6774 9.18319 16.669L9.80318 12.7138Z" fill="url(#paint13_linear_3048_190445)" />
      <path d="M7.56044 12.0714L5.87109 16.2239L9.15408 16.69L7.56044 12.0714Z" fill="url(#paint14_linear_3048_190445)" />
      <path d="M35.031 7.93984V4.91675H24.0669V19.7635H27.4747V14.4017H34.178V11.3618H27.4747V7.93984H35.031Z" fill="white" />
      <path d="M41.2474 4.91675H37.8396V19.7635H41.2474V4.91675Z" fill="white" />
      <path d="M55.0202 14.9181C55.8191 14.5193 56.4599 13.9692 56.9259 13.2722C57.4793 12.4451 57.7622 11.45 57.7622 10.3163C57.7622 9.21205 57.5001 8.24214 56.9841 7.42758C56.464 6.61303 55.7151 5.98322 54.7497 5.55075C53.8093 5.12667 52.6942 4.91254 51.4376 4.91254H45.3127V19.7593H48.7205V15.6781H51.4376C51.5624 15.6781 51.6706 15.6781 51.7663 15.6739L54.5916 19.7593H58.3822L55.0202 14.9181ZM54.3295 10.3205C54.3295 11.0889 54.0923 11.6599 53.6055 12.0672C53.102 12.4871 52.3322 12.7012 51.3128 12.7012H48.7163V7.96082H51.3128C52.3322 7.96082 53.1061 8.17076 53.6055 8.58644C54.0923 8.98532 54.3295 9.55215 54.3295 10.3205Z" fill="white" />
      <path d="M64.3532 16.7446V13.7215H71.0565V10.7404H64.3532V7.93984H71.9053V4.91675H60.9412V19.7635H72.1841V16.7446H64.3532Z" fill="white" />
      <path d="M79.6488 13.1421L84.3798 8.67041H82.4408L76.137 14.4773V4.26593H74.689V19.5745H76.137V16.3457L78.5794 14.0994L82.8985 19.5745H84.7085L79.6488 13.1421Z" fill="white" />
      <path d="M87.617 8.67041H86.169V19.5745H87.617V8.67041Z" fill="white" />
      <path d="M87.6253 4.79916C87.4214 4.60602 87.176 4.50525 86.893 4.50525C86.6059 4.50525 86.3604 4.61022 86.1565 4.81176C85.9526 5.0175 85.8528 5.26522 85.8528 5.55493C85.8528 5.84465 85.9568 6.09237 86.1607 6.29391C86.3646 6.48705 86.6101 6.58782 86.893 6.58782C87.1801 6.58782 87.4256 6.48286 87.6295 6.28132C87.8334 6.07558 87.9333 5.82785 87.9333 5.53814C87.9333 5.24843 87.8334 5.0007 87.6253 4.79916Z" fill="white" />
      <path d="M96.3426 17.6221L96.1345 17.8111C95.7517 18.1596 95.2774 18.3317 94.6823 18.3317C94.1081 18.3317 93.692 18.1806 93.4133 17.874C93.1303 17.5675 92.9888 17.0973 92.9888 16.4801V9.98881H96.2801V8.66621H92.9888V6.38629H91.5408V8.66621H89.6393V9.98881H91.5408V16.5808C91.5408 17.5339 91.8113 18.2897 92.3397 18.8356C92.8723 19.3772 93.6255 19.6543 94.5866 19.6543C95.0194 19.6543 95.4396 19.5871 95.8391 19.4528C96.2469 19.3142 96.5922 19.1127 96.8668 18.8524L97 18.7264L96.3426 17.6221Z" fill="white" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_3048_190445" x1="11.0681" y1="8.7543" x2="-3.31501" y2="13.712" gradientUnits="userSpaceOnUse">
        <stop offset="0.120887" stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint1_linear_3048_190445" x1="15.0834" y1="10.3499" x2="1.33653" y2="4.66503" gradientUnits="userSpaceOnUse">
        <stop offset="0.27714" stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint2_linear_3048_190445" x1="13.7311" y1="2.66624" x2="-2.04545" y2="5.07947" gradientUnits="userSpaceOnUse">
        <stop offset="0.247925" stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint3_linear_3048_190445" x1="17.809" y1="5.73136" x2="6.20601" y2="2.55225" gradientUnits="userSpaceOnUse">
        <stop offset="0.261515" stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint4_linear_3048_190445" x1="17.1429" y1="-0.839759" x2="1.20101" y2="1.36705" gradientUnits="userSpaceOnUse">
        <stop offset="0.324016" stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint5_linear_3048_190445" x1="23.1348" y1="-0.79777" x2="14.9976" y2="2.71724" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint6_linear_3048_190445" x1="9.25816" y1="4.36675" x2="17.2547" y2="4.35365" gradientUnits="userSpaceOnUse">
        <stop offset="0.324631" stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint7_linear_3048_190445" x1="9.15407" y1="14.1917" x2="4.14462" y2="20.8874" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="0.841586" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint8_linear_3048_190445" x1="-4.93072" y1="17.7607" x2="7.59714" y2="12.1682" gradientUnits="userSpaceOnUse">
        <stop offset="0.490817" stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint9_linear_3048_190445" x1="9.2165" y1="19.902" x2="0.453669" y2="12.4646" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint10_linear_3048_190445" x1="6.36625" y1="15.8502" x2="2.01697" y2="18.5441" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint11_linear_3048_190445" x1="-1.1298e-07" y1="26.2631" x2="1.56707" y2="15.4553" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint12_linear_3048_190445" x1="17.7881" y1="16.1231" x2="9.17513" y2="12.7751" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint13_linear_3048_190445" x1="12.0459" y1="11.3786" x2="7.22781" y2="15.1361" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint14_linear_3048_190445" x1="10.2983" y1="18.5374" x2="6.61739" y2="13.9933" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
      <clipPath id="clip0_3048_190445">
        <rect width="97" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
