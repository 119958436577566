import React from 'react'
import { Stack } from '@mui/material'

import { Promo } from 'Services/profile'
import { PrimaryContentPaper } from 'Shared/components'
import { Nullable } from 'Shared/types'
import { ActivePromoCode } from './ActivePromoCode'
import { UsePromoCodeForm } from './UsePromoCodeForm'

interface Props {
  promo: Nullable<Promo>
  onPromoCodeApplied: (promoCode: string) => void
  onPromoCodeRemoved: () => void
}

export const UsePromoCode: React.FC<Props> = ({
  promo,
  onPromoCodeApplied,
  onPromoCodeRemoved
}) => (
  <Stack spacing={1} alignItems="center">
    <PrimaryContentPaper elevation={0} sx={{ p: 1, width: 'fit-content', maxWidth: '100%' }}>
      {promo ? (
        <ActivePromoCode promoCode={promo.code} onPromoCodeRemoved={onPromoCodeRemoved} />
      ) : (
        <UsePromoCodeForm onPromoCodeApplied={onPromoCodeApplied} />
      )}
    </PrimaryContentPaper>
  </Stack>
)
