import React from 'react'

import { container, Service } from 'Services/container'
import { IExchangeService } from 'Services/exchange'
import { Nullable } from 'Shared/types'
import { useAuthWithDemo } from 'Demo'

export type ExchangeRateState = { from: number, to: number }

export const DefaultExchangeRateState: ExchangeRateState = { from: 1, to: 1 }

export const useExchangeRateState = (fromCurrency: Nullable<string>, toCurrency: Nullable<string>) => {
  const [ exchangeRate, setExchangeRate ] = React.useState<ExchangeRateState>(DefaultExchangeRateState)
  const { getAccessTokenWithDemo } = useAuthWithDemo()

  const exchangeService = container.resolve<IExchangeService>(Service.ExchangeService)

  React.useEffect(() => {
    const refreshExchangeRateState = async () => {
      if (fromCurrency && toCurrency && fromCurrency !== toCurrency) {
        const accessToken = await getAccessTokenWithDemo()
        const exchangeRate = await exchangeService.getExchangeRate(accessToken, fromCurrency, toCurrency)

        setExchangeRate({ from: 1 / exchangeRate, to: exchangeRate })
      } else {
        setExchangeRate(DefaultExchangeRateState)
      }
    }

    refreshExchangeRateState()
  }, [ fromCurrency, toCurrency ])

  return {
    exchangeRate
  }
}
