import { Nullable } from 'Shared/types'

export interface FormState {
  baseCurrency: string
  language: string
  username: Nullable<string>
}

export enum FormFields {
  baseCurrency = 'baseCurrency',
  language = 'language',
  username = 'username',
}
