import React, { useContext } from 'react'
import { Stack, Box } from '@mui/material'
import ShareIcon from '@mui/icons-material/Share'
import { useTranslation, Trans } from 'react-i18next'
import { match } from 'ts-pattern'

import { Sharing, SharingStatus } from 'Services/sharing'
import { TextPrimary, SubTextSecondary1, ButtonLink } from 'Shared/components'
import { SettingsContext } from 'App/Settings'

interface Props {
  sharing: Sharing
  onRevokeAccess: (username: string, key: string) => void
}

export const ProfileSharingItem: React.FC<Props> = ({ sharing, onRevokeAccess }) => {
  const { t } = useTranslation()
  const { profile } = useContext(SettingsContext)

  const statusTranslationKey = match(sharing.status)
    .with(SharingStatus.Pending, () => 'app.navbar.settings.sharingSettings.status-pending')
    .with(SharingStatus.Accepted, () => 'app.navbar.settings.sharingSettings.status-accepted')
    .exhaustive()

  return (
    <Stack direction="row" spacing={1}>
      <ShareIcon fontSize="small" />

      <Box overflow="auto">
        {sharing.status === SharingStatus.Accepted && !!profile.organization && (
          <TextPrimary>
            <Trans
              i18nKey="app.navbar.settings.sharingSettings.sharedWith-organization"
              values={{ organization: profile.organization.name }}
            />
          </TextPrimary>
        )}

        <TextPrimary>
          <Trans
            i18nKey="app.navbar.settings.sharingSettings.sharedWith-user"
            values={{ username: sharing.withUsername }}
          />
        </TextPrimary>

        <SubTextSecondary1>
          {t('app.navbar.settings.sharingSettings.status')}: {t(statusTranslationKey)}
        </SubTextSecondary1>

        <SubTextSecondary1>
          {t('app.navbar.settings.sharingSettings.access-label')}:{' '}

          {sharing.allowEdit ?
            t('app.navbar.settings.sharingSettings.access-edit') :
            t('app.navbar.settings.sharingSettings.access-readonly')}
        </SubTextSecondary1>

        <SubTextSecondary1>
          <ButtonLink onClick={() => onRevokeAccess(sharing.withUsername, sharing.key)}>
            {t('app.navbar.settings.sharingSettings.action-revoke')}
          </ButtonLink>
        </SubTextSecondary1>
      </Box>
    </Stack>
  )
}
