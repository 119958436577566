import React, { useContext } from 'react'

import { SettingsContext } from 'App/Settings'
import { ClientsHistoryDrawer } from './ClientsHistory'

export const AdvisorPortalDrawers: React.FC = () => {
  const settings = useContext(SettingsContext)

  return (
    <>
      {settings.sharedHistoryOpened && (
        <ClientsHistoryDrawer
          isOpen={settings.sharedHistoryOpened}
          sharingKey={settings.manageSettings.sharedHistory.value}
          close={settings.manageSettings.sharedHistory.close}
        />
      )}
    </>
  )
}
