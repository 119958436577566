/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable max-len */
import { SVGProps } from 'react'

export const SurferImg = (props: SVGProps<SVGSVGElement>) => (
  <svg width="372" height="456" viewBox="0 0 372 456" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1083_69626)">
      <path d="M87.8471 308.992C87.8471 308.992 4.27035 239.19 23.513 162.808C42.7556 86.4255 92.3336 96.9524 120.014 105.382C147.695 113.812 177.752 133.56 173.801 146.503C173.801 146.503 132.867 180.562 199.084 254.147L87.8471 308.992Z" fill="#ABBEFA" />
      <path d="M55.7212 220.274C57.1207 224.109 58.8082 228.087 60.6913 232.21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M54.6306 152.661C54.6306 152.661 44.3404 170.323 50.4116 201.482" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M71.2697 172.296C71.2697 172.296 66.2481 191.9 76.2707 217.077" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M89.6995 194.687C89.967 199.991 91.2224 208.76 96.0176 216.666" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M65.1676 307.677C65.1676 307.677 -8.65408 216.799 0.843744 133.303C10.3416 49.8074 71.4961 0.801521 142.694 0.0202236C204.98 -0.678832 279.378 15.7798 317.853 68.7744C317.853 68.7744 347.365 107.325 329.563 137.354C307.954 173.869 289.113 126.631 272.237 133.653C255.361 140.674 278.04 164.38 247.623 165.265C217.205 166.149 223.194 120.947 202.675 123.537C184.873 125.799 196.408 151.695 181.539 153.833C166.67 155.971 173.801 141.425 163.12 140.212C149.917 138.721 156.4 162.098 140.409 161.8C124.418 161.502 128.627 142.083 119.633 141.733C110.64 141.384 118.604 165.964 97.1392 165.964C75.6739 165.964 97.8389 110.646 65.1367 112.578C32.4346 114.511 12.0909 183.872 44.083 242.582C60.9795 273.485 82.5889 297.694 99.1766 313.649C99.2741 313.744 99.3468 313.86 99.3882 313.989C99.4296 314.118 99.4385 314.256 99.4141 314.389C99.3897 314.522 99.3327 314.647 99.2481 314.753C99.1636 314.859 99.0542 314.943 98.9297 314.996L79.5018 323.796C79.33 323.88 79.1346 323.904 78.9476 323.863C78.7606 323.822 78.5932 323.719 78.4728 323.57L65.1676 307.677Z" fill="#ABBEFA" />
      <path d="M65.1676 112.578C32.4757 114.511 12.0909 183.872 44.0831 242.582C60.9795 273.484 82.5889 297.694 99.1766 313.649" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M171.156 149.238C169.469 145.506 168.954 140.87 163.12 140.212C149.917 138.721 156.4 162.099 140.409 161.801C124.418 161.502 128.627 142.083 119.633 141.734C110.64 141.384 118.604 165.964 97.1392 165.964C75.6739 165.964 97.8389 110.646 65.1367 112.579" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M127.382 112.106C105.176 109.124 89.9773 98.9367 69.6851 106.822" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M312.286 89.7662C308.499 94.269 302.15 98.5044 291.84 101.671C246.182 115.652 233.258 82.4673 202.397 82.5804C175.201 82.6934 166.156 105.711 146.831 111.54" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M316.361 82.498C316.072 83.352 315.728 84.1864 315.332 84.9961" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M341.315 449.307C341.315 449.307 251.667 445.873 192.694 423.226C133.721 400.579 110.918 384.86 115.795 375.772C120.673 366.685 131.982 371.948 134.914 366.973C137.847 361.997 109.271 352.354 87.8471 340.892C66.423 329.429 59.2404 315.191 62.8008 310.205C66.3612 305.219 82.3522 313.505 86.2624 308.098C90.1727 302.69 63.9842 280.639 69.7982 274.06C75.6121 267.481 83.906 278.398 89.6993 273.135C95.4927 267.871 71.177 243.24 78.4933 235.992C85.8097 228.745 99.6602 244.823 105.392 240.207C111.123 235.591 103.478 225.815 111.123 222.628C118.769 219.441 127.341 232.374 134.462 231.582C141.582 230.79 141.109 220.993 150.73 218.937C160.352 216.881 178.637 240.526 191.027 239.57C203.416 238.614 197.798 241.729 209.662 241.06C221.527 240.392 237.209 288.483 249.074 288.216C260.938 287.949 279.008 271.623 287.044 301.117C295.081 330.611 294.34 335.042 298.363 335.721C302.387 336.399 303.416 330.097 308.654 331.763C313.891 333.428 317.411 386.382 355.484 417.377L341.315 449.307Z" fill="white" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M294.824 452.885C294.824 452.885 278.874 453.676 269.51 452.885" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M119.407 361.7L114.488 360.25" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M326.373 366.972C324.853 361.682 323.738 356.282 323.039 350.822" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M367.153 449.565C367.153 449.565 384.904 441.844 352.387 409.174C319.87 376.503 261.833 319.088 235.326 292.575C208.818 266.063 164.324 218.116 114.036 240.948C63.7475 263.78 136.849 341.458 190.101 376.226C243.352 410.993 323.379 476.787 367.153 449.565Z" fill="#42BA94" />
      <path d="M321.218 442.934C321.218 442.934 268.357 419.762 230.767 390.258" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M108.417 247.795C108.417 247.795 100.247 261.406 114.478 279.931" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M199.084 242.089C199.084 230.061 173.801 216.666 173.801 216.666C173.801 216.666 182.589 195.077 200.875 188.004C219.16 180.932 240.945 186.421 241.295 186.421L262.667 189.66C262.667 189.66 274.161 187.521 286.684 197.616C299.208 207.712 312.06 228.981 315.024 245.934H231.9L210.012 234.358L199.084 242.089Z" fill="white" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M314.993 245.903C314.993 245.903 321.63 277.772 303.612 289.594C285.594 301.417 251.132 282.737 251.132 282.737L273.986 245.903H314.993Z" fill="#252549" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M100.411 178.968C100.411 178.968 103.231 172.502 98.4357 169.12C93.6405 165.738 87.6105 173.448 90.0904 183.564C92.5703 193.679 118.337 229.156 129.018 240.424C140.852 252.883 179.358 279.93 211.154 233.926L187.333 196.918C187.333 196.918 171.218 218.681 163.943 220.439C156.668 222.197 141.922 208.308 136.818 204.217C131.714 200.125 100.411 178.968 100.411 178.968Z" fill="white" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M92.1896 169.819L112.77 203.435C113.147 204.048 113.667 204.56 114.285 204.929C114.903 205.298 115.601 205.512 116.32 205.553L165.507 208.205C165.933 208.229 166.357 208.131 166.728 207.921C167.099 207.711 167.402 207.399 167.601 207.022C167.799 206.645 167.885 206.218 167.848 205.794C167.81 205.369 167.652 204.964 167.39 204.628L145.102 175.905C144.41 175.019 143.412 174.425 142.303 174.239L94.4328 166.509C94.0184 166.444 93.5943 166.499 93.2098 166.666C92.8253 166.834 92.4963 167.107 92.2612 167.454C92.0261 167.8 91.8946 168.207 91.8819 168.626C91.8692 169.045 91.9759 169.458 92.1896 169.819Z" fill="#252549" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M88.4441 161.646V114.357C88.4448 114.002 88.5315 113.652 88.6967 113.338C88.862 113.024 89.1009 112.754 89.3931 112.552C89.6852 112.35 90.022 112.222 90.3746 112.178C90.7272 112.134 91.0852 112.176 91.418 112.301L138.537 129.469C139.368 129.772 140.09 130.317 140.609 131.033C141.128 131.749 141.42 132.604 141.449 133.488L142.684 171.587C142.696 171.914 142.635 172.24 142.505 172.541C142.375 172.842 142.18 173.111 141.934 173.327C141.688 173.544 141.397 173.703 141.081 173.793C140.766 173.883 140.435 173.902 140.111 173.848L92.1691 166.066C91.1215 165.897 90.1696 165.357 89.4861 164.546C88.8026 163.735 88.4329 162.706 88.4441 161.646Z" fill="#252549" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M93.4143 168.781L113.995 202.397C114.368 203.023 114.89 203.546 115.515 203.921C116.14 204.296 116.848 204.511 117.576 204.546L166.763 207.198C167.188 207.223 167.611 207.125 167.981 206.916C168.352 206.706 168.654 206.395 168.851 206.018C169.049 205.642 169.134 205.216 169.096 204.793C169.057 204.369 168.898 203.966 168.635 203.631L146.357 174.908C145.67 174.018 144.669 173.423 143.558 173.242L95.6679 165.481C95.2552 165.42 94.8338 165.476 94.4517 165.644C94.0696 165.811 93.7423 166.082 93.5072 166.426C93.2721 166.77 93.1388 167.174 93.1223 167.59C93.1059 168.007 93.2071 168.419 93.4143 168.781Z" fill="white" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M90.7183 160.885V113.596C90.7213 113.242 90.8095 112.894 90.9754 112.581C91.1413 112.268 91.3801 112 91.6716 111.798C91.9631 111.597 92.2988 111.468 92.6504 111.423C93.002 111.378 93.3593 111.418 93.6921 111.54L140.811 128.708C141.641 129.014 142.361 129.559 142.879 130.275C143.398 130.991 143.692 131.845 143.723 132.728L144.958 170.826C144.97 171.154 144.909 171.48 144.779 171.781C144.65 172.082 144.455 172.35 144.208 172.567C143.962 172.783 143.671 172.942 143.356 173.032C143.04 173.123 142.709 173.142 142.385 173.088L94.4433 165.306C93.3957 165.136 92.4437 164.597 91.7603 163.786C91.0768 162.975 90.707 161.946 90.7183 160.885Z" fill="white" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M117.833 124.452L123.297 126.375" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M106.256 171.721L140.029 177.169C141.566 177.418 142.944 178.26 143.867 179.513L151.235 189.567C151.365 189.745 151.44 189.956 151.453 190.175C151.465 190.395 151.414 190.613 151.306 190.804C151.197 190.995 151.035 191.151 150.84 191.252C150.645 191.354 150.425 191.397 150.206 191.376L118.234 189.012C117.337 188.946 116.466 188.677 115.689 188.224C114.911 187.772 114.248 187.148 113.748 186.401L105.094 173.479C104.968 173.292 104.9 173.072 104.899 172.846C104.898 172.621 104.964 172.4 105.088 172.212C105.213 172.024 105.39 171.877 105.598 171.79C105.806 171.702 106.035 171.678 106.256 171.721Z" fill="#252549" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M131.498 193.505L143.116 194.265C143.49 194.289 143.856 194.388 144.192 194.556C144.528 194.724 144.827 194.957 145.071 195.242L148.93 199.693C149.051 199.833 149.128 200.006 149.152 200.189C149.175 200.372 149.145 200.559 149.064 200.725C148.983 200.891 148.855 201.03 148.696 201.125C148.536 201.219 148.353 201.265 148.168 201.256L136.252 200.69C135.838 200.669 135.434 200.558 135.068 200.365C134.702 200.171 134.382 199.9 134.132 199.57L130.685 195.016C130.577 194.871 130.512 194.699 130.499 194.519C130.485 194.34 130.523 194.16 130.609 194.001C130.694 193.842 130.823 193.711 130.981 193.623C131.138 193.535 131.318 193.494 131.498 193.505Z" fill="#252549" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M200.607 199.765C200.607 199.765 177.845 192.651 168.172 199.415C158.5 206.18 153.447 214.497 153.766 236.229C154.085 257.961 154.641 272.117 154.641 272.117C154.641 272.117 137.621 279.406 132.363 281.369C132.363 281.369 128 280.228 126.116 283.014C125.582 283.758 125.281 284.644 125.251 285.559C125.222 286.475 125.466 287.378 125.952 288.154C125.424 288.563 124.992 289.082 124.684 289.674C124.377 290.266 124.201 290.918 124.17 291.584C124.139 292.25 124.252 292.916 124.503 293.534C124.754 294.152 125.136 294.709 125.623 295.165C125.623 295.165 121.867 298.938 126.847 302.66C126.847 302.66 123.071 309.27 128.792 312.364C133.618 314.986 137.703 310.38 138.31 310.031C138.917 309.681 161.566 302.937 171.681 301.354C181.797 299.771 186.026 295.782 187.93 291.125C189.833 286.468 191.953 242.15 191.953 242.15L198.014 244.278C198.014 244.278 209.333 248.144 215.415 230.565C221.496 212.985 200.607 199.765 200.607 199.765Z" fill="white" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M126.888 302.66C127.752 301.649 128.92 300.943 130.218 300.648C131.516 300.354 132.875 300.486 134.091 301.026" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M125.664 295.166C126.629 294.525 127.762 294.184 128.92 294.184C130.079 294.184 131.212 294.525 132.177 295.166" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M125.993 288.155C126.897 287.723 127.907 287.558 128.903 287.68C129.898 287.802 130.838 288.206 131.611 288.844" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M131.611 286.078C131.611 286.078 130.757 296.009 134.698 302.146" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M252.295 222.423C252.295 222.423 217.483 204.422 200.607 199.807C200.607 199.807 207.646 206.879 206.524 226.155C205.402 245.43 198.055 244.32 198.055 244.32L243.147 264.88L252.295 222.423Z" fill="#252549" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M273.986 245.903C273.986 245.903 252.984 214.959 228.555 222.073C204.126 229.187 211.762 266.936 215.332 281.955C218.903 296.975 234.544 334.981 234.544 334.981C234.544 334.981 218.316 347.183 210.671 350.679C210.671 350.679 204.61 348.047 200.453 353.249C196.295 358.451 201.482 363.93 207.316 363.344C207.316 363.344 208.397 368.484 214.828 366.551C214.828 366.551 215.024 371.691 221.949 370.396C222.63 371.527 223.656 372.41 224.877 372.915C226.097 373.421 227.448 373.521 228.73 373.203C229.754 373.024 230.723 372.61 231.559 371.993C232.395 371.375 233.075 370.572 233.546 369.646C233.546 369.646 236.345 371.784 242.611 368.001C248.878 364.218 252.994 360.034 263.964 354.924C274.933 349.815 278.473 347.183 279.131 341.694C279.79 336.204 258.078 271.5 258.078 271.5L273.986 245.903Z" fill="white" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M295.112 247.321C291.685 255.926 282.764 276.168 268.893 291.526C268.893 291.526 268.841 280.167 255.402 274.297C240.996 268.015 233.556 271.624 233.556 271.624C233.556 271.624 242.087 251.495 246.789 236.918C251.492 222.34 259.58 199.066 281.189 210.045C292.004 215.525 295.873 223.81 297.036 230.729C297.928 236.331 297.263 242.071 295.112 247.321Z" fill="white" />
      <path d="M271.239 207.198C256.915 206.776 250.658 224.828 246.789 236.908C242.087 251.485 233.556 271.614 233.556 271.614C233.556 271.614 241.006 268.005 255.402 274.286C268.841 280.156 268.893 291.516 268.893 291.516C282.712 276.168 291.685 255.926 295.112 247.311L299.228 237.473" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M234.585 334.991L236.355 338.959" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M191.994 242.161L192.221 232.96" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M254.661 189.053V198.624C254.661 198.624 252.068 207.188 239.102 205.471C234.874 204.894 230.961 202.917 227.989 199.858C227.94 194.41 227.423 188.975 226.445 183.615C231.539 177.683 254.661 189.053 254.661 189.053Z" fill="white" />
      <path d="M254.661 189.053V198.624C254.661 198.624 252.068 207.188 239.102 205.471C234.874 204.894 230.961 202.917 227.989 199.858C227.94 194.41 227.423 188.975 226.445 183.615C231.539 177.683 254.661 189.053 254.661 189.053Z" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M229.347 189.763C229.347 189.763 247.365 200.999 256.421 196.434C265.476 191.87 266.33 178.742 262.276 160.567C258.222 142.391 239.72 151.561 239.72 151.561C239.72 151.561 212.996 160.341 229.347 189.763Z" fill="white" />
      <path d="M231.457 190.986C236.479 193.761 249.249 200.043 256.421 196.434C265.466 191.87 266.711 177.159 262.657 158.983" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M231.457 184.488C231.457 184.488 224.799 190.245 219.983 184.488C215.168 178.731 219.345 174.208 223.07 173.18C226.796 172.152 230.521 175.637 231.508 179.06C232.118 180.822 232.1 182.739 231.457 184.488Z" fill="white" />
      <path d="M231.457 184.489C231.457 184.489 224.799 190.246 219.983 184.489C215.168 178.732 219.345 174.209 223.07 173.181" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M223.678 178.352L228.03 181.857" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M251.646 171.557C251.646 171.557 250.998 177.272 257.234 180.891L255.999 182.947" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M248.789 190.296C247.373 189.038 246.49 187.288 246.32 185.402" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M270.169 162.006L230.49 168.729C230.49 168.729 232.877 182.093 243.538 180.582C249.938 179.657 250.237 173.386 250.062 166.951L251.914 166.755C251.914 166.755 254.116 178.65 263.048 177.241C271.98 175.833 270.169 162.006 270.169 162.006Z" fill="#252549" />
      <path d="M245.297 175.823C245.887 173.76 246.361 171.667 246.717 169.552" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M265.62 171.268C266.297 169.681 266.791 168.022 267.092 166.323" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M262.749 162.798C262.749 162.798 260.578 154.08 257.913 154.83C255.248 155.581 254.486 160.464 249.207 161.286C243.928 162.109 236.509 160.608 235.562 164.37C234.616 168.133 235.141 174.209 229.965 175.103C224.789 175.997 222.35 174.836 221.588 176.285C220.827 177.735 219.222 182.073 210.712 180.151C202.202 178.228 193.908 165.532 197.726 162.057C201.543 158.583 205.248 160.248 206.359 158.84C207.47 157.431 193.218 144.283 199.444 139.019C205.021 134.301 214.385 143.131 218.471 144.303C218.605 144.338 218.746 144.338 218.881 144.304C219.016 144.271 219.14 144.204 219.242 144.11C219.344 144.016 219.421 143.898 219.466 143.766C219.511 143.635 219.523 143.494 219.5 143.358C219.129 141.106 218.656 135.699 222.257 134.208C230.963 130.528 243.29 148.004 249.29 147.13C249.41 147.11 249.524 147.064 249.625 146.995C249.725 146.926 249.81 146.837 249.873 146.732C249.935 146.628 249.974 146.511 249.987 146.39C250 146.268 249.987 146.146 249.948 146.03C249.3 144.17 248.075 139.523 251.296 138.238C255.309 136.624 269.808 148.436 262.749 162.798Z" fill="#252549" />
      <path d="M254.661 198.624C254.661 198.624 252.068 207.187 239.102 205.47C234.874 204.893 230.961 202.917 227.989 199.857C227.276 199.154 226.61 198.405 225.993 197.616" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M268.893 291.516C268.893 291.516 223.564 337.88 207.378 348.767C207.378 348.767 195.585 369.111 188.362 373.439C187.892 373.731 187.363 373.916 186.814 373.982C186.264 374.048 185.707 373.993 185.181 373.82C184.655 373.648 184.174 373.362 183.77 372.984C183.367 372.605 183.051 372.143 182.846 371.63C182.701 371.227 182.598 370.81 182.537 370.386C182.537 370.386 177.516 371.105 176.549 366.12C176.549 366.12 170.817 366.613 170.107 361.843C170.107 361.843 164.818 362.326 165.147 355.932C165.353 351.995 169.026 347.368 171.815 344.397C171.815 344.397 163.655 344.325 164.334 339.165C165.013 334.004 174.244 335.896 178.442 334.683C182.64 333.47 207.357 317.021 233.556 271.603C233.556 271.603 239.185 253.099 264.591 265.342C282.825 274.132 268.893 291.516 268.893 291.516Z" fill="white" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M182.537 370.396C182.443 369.624 182.443 368.844 182.537 368.073C182.712 366.296 183.48 364.63 184.719 363.344" fill="white" />
      <path d="M182.537 370.396C182.443 369.624 182.443 368.844 182.537 368.073C182.712 366.296 183.48 364.63 184.719 363.344" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M176.548 366.13C175.921 362.912 177.938 360.27 179.44 358.79L176.548 366.13Z" fill="white" />
      <path d="M176.548 366.13C175.921 362.912 177.938 360.27 179.44 358.79" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M170.107 361.833C169.947 360.385 170.145 358.919 170.683 357.564C171.221 356.209 172.083 355.007 173.194 354.062" fill="white" />
      <path d="M170.107 361.833C169.947 360.385 170.145 358.919 170.683 357.564C171.221 356.209 172.083 355.007 173.194 354.062" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M171.815 344.408C173.533 342.567 174.902 341.375 174.902 341.375L171.815 344.408Z" fill="white" />
      <path d="M171.815 344.408C173.533 342.567 174.902 341.375 174.902 341.375" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M210.712 350.679C210.712 350.679 204.651 348.048 200.494 353.249C196.336 358.451 201.523 363.931 207.357 363.345" fill="white" />
      <path d="M210.712 350.679C210.712 350.679 204.651 348.048 200.494 353.249C196.336 358.451 201.523 363.931 207.357 363.345" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M207.357 363.344C207.357 363.344 207.357 360.26 209.302 358.677C210.155 358.016 211.142 357.55 212.195 357.312C213.247 357.073 214.34 357.069 215.394 357.3" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M214.869 366.551C214.722 365.772 214.769 364.968 215.003 364.21C215.238 363.452 215.655 362.763 216.217 362.203C216.81 361.586 217.521 361.094 218.307 360.757C219.094 360.419 219.94 360.243 220.796 360.239" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M221.99 370.396C221.99 370.396 219.839 365.852 225.581 362.737" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M213.171 355.974C213.171 355.974 225.149 362.738 230.016 364.506" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M92.7351 151.397C92.7351 151.397 93.6201 146.359 89.4011 145.866C85.1821 145.372 78.617 157.832 88.5367 164.123L92.7351 151.397Z" fill="white" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M92.5293 160.063C92.5293 160.063 94.0934 155.191 89.9876 154.132C85.8818 153.073 77.6394 162.592 86.633 172.091L92.5293 160.063Z" fill="white" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M96.5526 168.668C96.5526 168.668 98.3328 163.127 93.6508 161.913C88.9688 160.7 79.5944 171.546 89.8331 182.371L96.5526 168.668Z" fill="white" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M97.7772 179.482C97.7772 179.482 103.231 172.502 98.4357 169.12C93.6405 165.738 87.6105 173.448 90.0904 183.564" fill="white" />
      <path d="M97.7772 179.482C97.7772 179.482 103.231 172.502 98.4357 169.12C93.6405 165.738 87.6105 173.448 90.0904 183.564" stroke="#252549" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1083_69626">
        <rect width="372" height="456" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
