import { Prediction } from 'Services/prediction'

export interface PredictionState {
  isFirstTimeLoading: boolean
  firstTimeLoadingCompleted: boolean
  isLoading: boolean
  prediction: Prediction
  calculation: PredictionCalculationData
  yearsProjection: number
}

export interface PredictionCalculationData {
  currentBalance: number
  monthlySavings: number
  expectedSavingsGrowth: number
  profitabilityPercent: number
  calculateRetirement: boolean
  predictionType: PredictionType
  currentAge: number
  monthlySpending: number
  liveOnCapitalLifeExpectancy: number
  expectedInflation: number
  retirementProfitability: number
}

export enum PredictionType {
  LiveOnPassiveIncome = 'liveOnPassiveIncome',
  LiveOnCapital = 'liveOnCapital',
}

