import React from 'react'
import { useDispatch } from 'react-redux'

import { PortfolioAllocationType, IPortfolioService } from 'Services/portfolio'
import { container, Service } from 'Services/container'
import { Dictionary } from 'Shared/types'
import { AssetAllocationActions } from './assetAllocation-actions'
import { AssetAllocationDrawer } from './AssetAllocationDrawer'

interface Props {
  isOpen: boolean,
  close: () => void,
  type: PortfolioAllocationType,
}

export const MyAssetAllocationDrawer: React.FC<Props> = ({ isOpen, close, type }) => {
  const dispatch = useDispatch()

  const getAssetAllocation = async (accessToken: string, type: PortfolioAllocationType) => {
    dispatch(AssetAllocationActions.getMyAssetAllocation(accessToken, type))
  }

  const updateAssetAllocation = async (accessToken: string, type: PortfolioAllocationType, allocation: Dictionary<number>) => {
    const portfolioService = container.resolve<IPortfolioService>(Service.PortfolioService)
    await portfolioService.updateMyAssetAllocation(accessToken, type, allocation)
  }

  return (
    <AssetAllocationDrawer
      isOpen={isOpen}
      close={close}
      type={type}
      getAssetAllocation={getAssetAllocation}
      updateAssetAllocation={updateAssetAllocation}
    />
  )
}
