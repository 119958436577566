import React from 'react'
import { ListItemButton, ListItemIcon, Checkbox, ListItemText } from '@mui/material'

interface Props {
  onClick: (value: string) => void
  label: string
  value: string
  checked: boolean
  groupId: string
  disabled?: boolean
}

export const FilterOptionListItem: React.FC<Props> = ({ label, value, onClick, checked, groupId, disabled = false }) => (
  <ListItemButton onClick={() => onClick(value)} role={undefined} disabled={disabled}>
    <ListItemIcon>
      <Checkbox
        checked={checked}
        disableRipple
        inputProps={{ 'aria-labelledby': `cbx-label-${groupId}-${value}` }}
      />
    </ListItemIcon>

    <ListItemText id={`cbx-label-${groupId}-${value}`}>{label}</ListItemText>
  </ListItemButton>
)
