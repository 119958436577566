import React from 'react'
import * as Yup from 'yup'

export type SectionContext<T, TField extends string> = {
  values: T
  errors: any
  touched: any
  onBlur: (name: TField) => () => void
  onChange: (name: TField) => (value: any) => void
  isValid: () => boolean
}

export function useSectionContext<T, TField extends string>(
  initialValue: T,
  validationSchema: Yup.ObjectSchema<any>
): SectionContext<T, TField> {
  const [ values, setValues ] = React.useState<T>(initialValue)
  const [ errors, setErrors ] = React.useState<any>({ })
  const [ touched, setTouched ] = React.useState<any>({ })

  const refreshValidation = (value: T) => {
    try {
      validationSchema.validateSync(value, { abortEarly: false })

      setErrors({ })
    } catch(error: any) {
      const errors = error.inner.reduce((acc: any, innerError: any) => ({
        ...acc,
        ...(acc[innerError.path] === undefined && { [innerError.path]: innerError.message })
      }), { })

      setErrors(errors)
    }
  }

  const onChange = (name: TField) => (value: any) => {
    setValues(prevValues => ({ ...prevValues, [name]: value }))

    const updatedValues = { ...values, [name]: value }
    refreshValidation(updatedValues)
  }

  const onBlur = (name: TField) => () => {
    setTouched((prevTouched: any) => ({ ...prevTouched, [name]: true }))

    refreshValidation(values)
  }

  const isValid = () => validationSchema.isValidSync(values)

  return {
    values,
    errors,
    touched,
    onBlur,
    onChange,
    isValid,
  }
}
