export const HiddenMoneySymbol = '\u2217'
export const InitialCurrency = 'USD'
export const DefaultUsername = 'O'

export enum DataPageState {
  Loading,
  NoData,
  NoFilteredOptions,
  HasData,
}
