import React from 'react'
import { ListItemButton, ListItemText } from '@mui/material'

import { BaseOptionsList } from './BaseOptionsList'

interface SortingOptionsProps {
  selectedValue: string,
  options: { label: string, value: string }[]
  onOptionClick: (value: string) => void
}

export const SortingOptions: React.FC<SortingOptionsProps> = ({ selectedValue, options, onOptionClick }) => (
  <BaseOptionsList>
    {options.map(option => (
      <ListItemButton
        key={option.value}
        onClick={() => onOptionClick(option.value)}
        selected={option.value === selectedValue}
      >
        <ListItemText id={`list-label-${option.value}`}>{option.label}</ListItemText>
      </ListItemButton>
    ))}
  </BaseOptionsList>
)
