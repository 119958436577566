import React, { PropsWithChildren } from 'react'
import { styled, SxProps, Theme } from '@mui/material/styles'
import { Link } from '@mui/material'

interface ExternalLinkProps {
  href: string
  sx?: SxProps<Theme>
}

export const ExternalLink: React.FC<PropsWithChildren<ExternalLinkProps>> = ({
  children,
  href,
  sx = {}
}) => (
  <Link
    href={href}
    target="_blank"
    underline="hover"
    rel="noopener"
    fontSize="inherit"
    lineHeight="inherit"
    sx={sx}
  >
    {children}
  </Link>
)

const StyledButtonLink = styled(Link)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(1, 1.5),
  borderRadius: theme.spacing(.5),
  fontSize: theme.typography.subTextPrimary2.fontSize,
  lineHeight: theme.typography.subTextPrimary2.lineHeight,
  fontWeight: theme.typography.fontWeightBold,
  textAlign: 'center',
  ':hover': {
    backgroundColor: theme.palette.dark.dark2,
  }
}))

export const ExternalButtonStyledLink: React.FC<PropsWithChildren<ExternalLinkProps>> = ({
  children,
  href,
  sx = {}
}) => (
  <StyledButtonLink
    href={href}
    target="_blank"
    underline="none"
    rel="noopener"
    sx={sx}
  >
    {children}
  </StyledButtonLink>
)
