import { List, ListItem, ListItemText } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useRouteMatch } from 'react-router-dom'

import * as S from 'App/NavigationBar/components-app-bar/AppBar.style'
import { NavigationLink } from 'Shared/types'

interface Props {
  open: boolean
  links: NavigationLink[]
  closeMenu?: (event: React.KeyboardEvent | React.MouseEvent) => void
}

export const AppNavigation = ({ open, closeMenu, links }: Props) => {
  const { t } = useTranslation()

  const pages = React.useMemo(() => links.map(link => link.href).reverse(), [ links ])
  const routeMatch = useRouteMatch({ path: pages })
  const currentRouteUrl = routeMatch?.url

  return (
    <List>
      {links.map(navItem => (
        <ListItem
          key={navItem.key}
          data-userguide-id={navItem.userGuideId}
          disablePadding
          onClick={closeMenu}
        >
          <S.StyledListItemButton
            component={RouterLink}
            to={navItem.href}
            selected={navItem.href === currentRouteUrl}
            {...!open && { title: t(navItem.key) }}
          >
            <S.StyledListItemIcon open={open}>{navItem.icon}</S.StyledListItemIcon>
            {open && <ListItemText primary={t(navItem.key)} sx={{ textOverflow: 'ellipsis' }} />}
          </S.StyledListItemButton>
        </ListItem>
      ))}
    </List>
  )
}
