import { HOLDING_RISK, HOLDING_LIQUIDITY, HOLDING_CLASS, HOLDING_TERM } from 'Services/holdings'
import {
  HoldingClassColors,
  HoldingLiquidityColors,
  HoldingRiskColors,
  HoldingTermColors,
  DefaultColor,
  DefaultUndefinedValue,
  colorScheme
} from 'Shared/constants'
import { Colors } from 'Shared/types'
import type { Render } from './providers-types'
import { ColorProvider } from './providers-types'

const classColorRender = (value: string) => {
  const holdingClass = value as HOLDING_CLASS

  if (holdingClass && HoldingClassColors[holdingClass]) {
    return HoldingClassColors[holdingClass]
  }

  return DefaultColor
}

const riskColorRender = (value: string) => {
  const holdingRisk = value as HOLDING_RISK

  if (holdingRisk && HoldingRiskColors[holdingRisk]) {
    return HoldingRiskColors[holdingRisk]
  }

  return DefaultColor
}

const liquidityColorRender = (value: string) => {
  const holdingLiquidity = value as HOLDING_LIQUIDITY

  if (holdingLiquidity && HoldingLiquidityColors[holdingLiquidity]) {
    return HoldingLiquidityColors[holdingLiquidity]
  }

  return DefaultColor
}

const termColorRender = (value: string) => {
  const holdingTerm = value as HOLDING_TERM

  if (holdingTerm && HoldingTermColors[holdingTerm]) {
    return HoldingTermColors[holdingTerm]
  }

  return DefaultColor
}

export const defaultColorRender = (colorScheme: Colors[], accumulator: Map<string, Colors>) =>
  (value: string) => {
    if (value === DefaultUndefinedValue) {
      return DefaultColor
    }

    const savedColor = accumulator.get(value)

    if (savedColor) {
      return savedColor
    }

    const idx = accumulator.size
    const adjustedIdx = idx % colorScheme.length
    const color = colorScheme[adjustedIdx]
    accumulator.set(value, color)

    return color
  }

export const colorRenderProvider = new Map<string, Render>([
  [ ColorProvider.Class, classColorRender ],
  [ ColorProvider.Risk, riskColorRender ],
  [ ColorProvider.Liquidity, liquidityColorRender ],
  [ ColorProvider.Term, termColorRender ],
  [ ColorProvider.Category, defaultColorRender(colorScheme, new Map()) ]
])

