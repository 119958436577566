import React from 'react'
import type { User } from '@auth0/auth0-react'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { SideDrawer } from 'Shared/components'
import { UserInfo } from './UserInfo'
import { MainSettingsSection } from './MainSettingsSection/MainSettingsSection'
import { ShareProfileSection } from './ShareProfileSection'
import { DangerSettingsSection } from './DangerSettingsSection'
import { PaymentSettingsSection } from './PaymentSettingsSection'

interface Props {
  user?: User
  isOpen: boolean
  handleClose: () => void
  handleLogout: () => void
}

const ProfileSettingsBase: React.FC<Props> = ({ user, isOpen, handleClose, handleLogout }) => {
  const { t } = useTranslation()

  return (
    <SideDrawer title={t('app.navbar.settings.name')} isOpen={isOpen} close={handleClose}>
      <Stack direction="column" justifyContent="flex-start" spacing={3}>
        {user && <UserInfo user={user} />}
        <MainSettingsSection />
        <PaymentSettingsSection onClose={handleClose} />
        <ShareProfileSection isDrawerOpen={isOpen} />
        <DangerSettingsSection onLogout={handleLogout} />
      </Stack>

    </SideDrawer>
  )
}

export const ProfileSettings = React.memo(ProfileSettingsBase)
