import { StoreState } from 'Shared/types'
import { createSelector } from 'reselect'
import { Snackbar, SnackbarsState } from './snackbar-types'

export const snackbarsStateSelector = (state: StoreState): SnackbarsState => state.snackbars

export const getSnackbarsSelector = createSelector(
  snackbarsStateSelector,
  (state: SnackbarsState): Snackbar[] => state.snackbars
)
