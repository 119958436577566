import React, { PropsWithChildren } from 'react'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isToday, differenceInHours, differenceInMinutes, format } from 'date-fns'

import { TextSmall } from 'Shared/components/Typography'
import { SecondaryContentPaper } from 'Shared/components/Papers'

interface Props {
  createdAt: string
}

export const HistoryMessageContainer: React.FC<PropsWithChildren<Props>> = ({ createdAt, children }) => {
  const { t } = useTranslation()

  const formattedTime = React.useMemo(
    () => {
      const historyDate = new Date(createdAt)

      if (isToday(historyDate)) {
        const hoursDifference = differenceInHours(new Date(), historyDate)

        if (hoursDifference >= 1) {
          return t('common.hours-ago', { count: hoursDifference })
        }

        const minutesDifference = differenceInMinutes(new Date(), historyDate)
        return t('common.minutes-ago', { count: minutesDifference })
      }

      return format(historyDate, 'HH:mm')
    },
    [ createdAt ])

  return (
    <SecondaryContentPaper elevation={0}>
      <Stack spacing={1}>
        {children}

        <TextSmall>{formattedTime}</TextSmall>
      </Stack>
    </SecondaryContentPaper>
  )
}
