import { match } from 'ts-pattern'

import { AdvisorRole, PricingTier, SubscriptionStatus } from 'Services/profile'
import {
  OrganizationOwner,
  OrganizationAdvisor,
  FinancialAdvisorClient,
  PremiumUser,
  User,
  UserWithPaymentIssues,
} from './authorization-constants'
import { AuthorizationModel, Permission, Role } from './authorization-types'

export const extractAuthorizationProfile = (model: AuthorizationModel) => match(model)
  .with({ isAdvisor: true, advisorRole: AdvisorRole.Owner }, () => OrganizationOwner)
  .with({ isAdvisor: true, advisorRole: AdvisorRole.Advisor }, () => OrganizationAdvisor)
  .with({ isAdvisoryClient: true }, () => FinancialAdvisorClient)
  .with({ tier: PricingTier.Premium }, () => PremiumUser)
  .with({ tier: PricingTier.Free, subscription: { status: SubscriptionStatus.Paid, renewable: true } }, () => UserWithPaymentIssues)
  .otherwise(() => User)

export const hasAccess = (role: Role, ...permissions: Permission[]) => permissions
  .some(permission => role.permissions.includes(permission))

