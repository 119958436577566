import React from 'react'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TextLarger } from 'Shared/components'
import { ClientsHistoryFilter } from './ClientsHistoryFilter'

export const ClientsHistoryHeader: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Stack spacing={2}>
      <TextLarger fontWeight={700}>
        {t('advisorPortal.clientsHistory.title')}
      </TextLarger>

      <ClientsHistoryFilter />
    </Stack>
  )
}
