import React from 'react'
import { useField } from 'formik'

import { CustomDatePicker } from 'Shared/components/NonForm'

interface Props {
  name: string
  label: string
  required?: boolean
  disabled?: boolean
  disableFuture?: boolean
  disablePast?: boolean
}

export const DatePicker: React.FC<Props> = (props: Props) => {
  const { name, label, required, disabled, disableFuture, disablePast } = props
  const [ field, meta, helpers ] = useField(props)
  const error = meta.touched && meta.error

  const [ inputDate, setInputDate ] = React.useState<Date | null>(field.value)

  const handleChange = (date: Date | null) => {
    setInputDate(date)
    helpers.setValue(date)
  }

  return (
    <CustomDatePicker
      label={label}
      value={inputDate}
      onChange={handleChange}
      required={required}
      disabled={disabled}
      error={error}
      disableFuture={disableFuture}
      disablePast={disablePast}
      extraProps={{
        onBlur: () => helpers.setTouched(true),
        inputProps: { 'data-testid': name }
      }}
      fullWidth
    />
  )
}
