import { Popper, styled, List, Button } from '@mui/material'

import { styledWithCustomProp } from 'config/theme'

export const CurrencySelectionPopper = styledWithCustomProp(Popper, 'width')<{ width: number | undefined }>(({ theme, width }) => ({
  zIndex: theme.zIndex.modal,
  width: width,
}))

export const CurrenciesList = styled(List)({
  maxHeight: 300,
  overflowY: 'scroll',
  '& ul': { padding: 0 },
})

export const CurrencyButton = styled(Button)({
  minWidth: 0,
  padding: 0,
})
