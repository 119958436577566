import { Nullable } from 'Shared/types'

export interface IHistoryService {
  getMyHistory(token: string): Promise<HistoryItem[]>
  getHistorySharedWithMe(token: string, sharingKey: string): Promise<HistoryItem[]>
  getClientsHistory(token: string): Promise<HistoryItem[]>
}

export enum HoldingChangeType {
  Created = 'Created',
  Updated = 'Updated',
  Archived = 'Archived',
  Deleted = 'Deleted'
}

export enum TransactionChangeType {
  Created = 'Created',
  Updated = 'Updated',
  RolledBack = 'RolledBack',
}

export enum TransactionType {
  DepositWithdrawal = 'DepositWithdrawal',
  ProfitLoss = 'ProfitLoss',
  Transfer = 'Transfer',
  PurchaseSale = 'PurchaseSale',
}

export enum HistoryKind {
  Holding = 'HoldingHistory',
  SingleTransaction = 'SingleTransactionHistory',
  TickerTransaction = 'TickerTransactionHistory',
  TransferTransaction = 'TransferTransactionHistory',
  AdvisoryTaskStatus = 'AdvisoryTaskStatusHistory',
}

export type BaseHistory = {
  kind: HistoryKind
  createdAt: string

  actorPreferredName: Nullable<string>
  actorUsername: string
  portfolioUserPreferredName: Nullable<string>
  portfolioUsername: string
  andMore: number
}

export type HoldingHistory = BaseHistory & {
  kind: HistoryKind.Holding
  changeType: HoldingChangeType
  asset: string
  company: string
}

export type SingleTransactionHistory = BaseHistory & {
  kind: HistoryKind.SingleTransaction
  changeType: TransactionChangeType
  operation: TransactionType
  asset: string
  amount: number
  currency: string
}

export type TickerTransactionHistory = BaseHistory & {
  kind: HistoryKind.TickerTransaction
  changeType: TransactionChangeType
  operation: TransactionType
  asset: string
  amount: number
  price: number
  currency: string
  ticker: string
}

export type TransferTransactionHistory = BaseHistory & {
  kind: HistoryKind.TransferTransaction
  changeType: TransactionChangeType
  operation: TransactionType
  fromAsset: string
  toAsset: string
  amount: number
  unit: string
  isCurrency: boolean
}

export type AdvisoryTaskStatusHistory = BaseHistory & {
  kind: HistoryKind.AdvisoryTaskStatus
  taskTitle: string
  completed: boolean
}

export type HistoryItem =
  HoldingHistory |
  SingleTransactionHistory |
  TickerTransactionHistory |
  TransferTransactionHistory |
  AdvisoryTaskStatusHistory
