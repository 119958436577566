import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@mui/material'
import { Permission, useAuthorization } from 'Integration/authorization'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { LoginButtonLanding } from 'App/NavigationBar/components-landing-shared'
import { Routes } from 'Shared/constants'

export const LoginButtonAdvisorLanding: React.FC = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const authorization = useAuthorization()
  const { t } = useTranslation()

  if (isAuthenticated && authorization.hasAccess(Permission.ViewAdvisorPortal)) {
    return (
      <Button
        component={Link}
        to={Routes.ADVISOR_PORTAL}
        variant="contained"
        data-testid="backToAppButton"
      >
        {t('app.navbar.landing-advisor.toAdvisorPortal')}
      </Button>
    )
  }

  return <LoginButtonLanding />
}
