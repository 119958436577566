import { createSelector } from 'reselect'

import { StoreState } from 'Shared/types'
import { SharingState } from './sharing-types'

const storeSelector = (state: StoreState): SharingState => state.sharing
const selectedSharingKey = createSelector(storeSelector, state => state.selectedSharingKey)

export const SharingSelectors = {
  selectedSharingKey,
}
