import React from 'react'
import { Button, Card, CardContent, CardActions, Box, styled } from '@mui/material'
import { TooltipRenderProps } from 'react-joyride'

import { TextBig, TextPrimary, SubTextSecondary1 } from 'Shared/components'

const CardStyled = styled(Card)(({ theme }) => ({
  minWidth: '340px',
  maxWidth: '545px',
  padding: theme.spacing(2),
  [theme.breakpoints.down('mobileLarge')]: {
    minWidth: '300px',
    padding: theme.spacing(1.5)
  }
}))

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(1)
}))

export const UserGuideTooltip: React.FC<TooltipRenderProps> = ({
  continuous,
  index,
  size,
  step,
  backProps,
  primaryProps,
  tooltipProps,
  skipProps,
}) => (
  <CardStyled {...tooltipProps}>
    <CardContentStyled>
      {step.title && (
        <TextBig gutterBottom component="h5" align="center">
          {step.title}
        </TextBig>
      )}
      <TextPrimary align="justify" sx={{ hyphens: 'auto' }}>
        {step.content}
      </TextPrimary>
    </CardContentStyled>
    <CardActions sx={{ justifyContent: 'space-between' }}>
      <Box>
        {step.showSkipButton && (
          <Button {...skipProps} data-testid="skip-button" size="small" color="error">
            {skipProps.title}
          </Button>
        )}
      </Box>

      <SubTextSecondary1>
        {index + 1} / {size}
      </SubTextSecondary1>

      <Box>
        {index > 0 && (
          <Button {...backProps} size="small">
            {backProps.title}
          </Button>
        )}

        {continuous && (
          <Button {...primaryProps} size="small">
            {primaryProps.title}
          </Button>
        )}
      </Box>
    </CardActions>
  </CardStyled>
)
