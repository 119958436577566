import React from 'react'
import type { FormikProps } from 'formik'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTranslation } from 'react-i18next'
import { isEmpty, isEqual } from 'lodash'

interface SubmitButtonProps {
  formProps: FormikProps<any>
  label?: string
  compareInitialValues?: boolean
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({ formProps, label, compareInitialValues = true }) => {
  const { t } = useTranslation()

  const hasErrors = !isEmpty(formProps.errors)
  const equalsToInitialValues = compareInitialValues ? isEqual(formProps.values, formProps.initialValues) : false

  return (
    <LoadingButton
      data-testid="confirm"
      type="submit"
      size="medium"
      variant="contained"
      color="primary"
      loading={formProps.isSubmitting}
      disabled={hasErrors || equalsToInitialValues}
    >
      {label ? label : t('common.actions.confirm')}
    </LoadingButton >
  )
}
