import React from 'react'
import { Link } from '@mui/material'

interface FooterLinkProps {
  href: string
  text: string
}

export const FooterLink: React.FC<FooterLinkProps> = ({ href, text }) => (
  <Link
    href={href}
    variant="textPrimary"
    pb={0}
    target="_blank"
    underline="hover"
    rel="noopener"
    color="inherit"
    sx={{ width: 'fit-content' }}
  >
    {text}
  </Link>
)
