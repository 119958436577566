import React from 'react'
import { Autocomplete, TextField, debounce } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { localizeError } from 'Shared/utils'
import { AutocompleteOption } from './NonForm-types'

interface AutocompleteProps {
  label: string
  value: string | null
  fetchOptions: (term: string) => Promise<AutocompleteOption[]>
  initialOptions?: AutocompleteOption[]
  groupOrder?: string[]
  onChange?: (value: string | null) => void
  onInputChange?: (value: string | null) => void
  onBlur?: (e: any) => void
  error?: any
  placeholder?: string
  required?: boolean
  disabled?: boolean
  extraProps?: any
  name?: string
}

export const CustomAsyncAutocomplete: React.FC<AutocompleteProps> = ({
  label,
  value,
  fetchOptions,
  initialOptions = [],
  groupOrder = [],
  onChange,
  onInputChange,
  onBlur,
  error,
  placeholder,
  required,
  disabled,
  extraProps,
  name,
}) => {
  const { t } = useTranslation()
  const [ options, setOptions ] = React.useState(initialOptions)
  const [ loading, setLoading ] = React.useState(false)
  const [ inputValue, setInputValue ] = React.useState('')

  const fetchDebounced = React.useMemo(
    () => debounce(
      (term: string, callback: (options: AutocompleteOption[]) => void) => {
        fetchOptions(term).then(callback)
      }, 400),
    [ fetchOptions ]
  )

  const handleOnChange = (_: any, option: AutocompleteOption | null) => {
    onChange && onChange(option?.value ?? null)
  }

  const handleOnInputChange = (event: any, value: string) => {
    setInputValue(value)
    onInputChange && onInputChange(value)
  }

  React.useEffect(() => {
    let active = true

    const skipServerRequest = !inputValue || inputValue === value
    if (skipServerRequest) {
      setOptions(value ? [ { label: value, value: value }, ...initialOptions ] : initialOptions)
      return undefined
    }

    const refreshOptions = (options: AutocompleteOption[]) => {
      if (active) {
        const sortedOptions = options
          .sort((x, y) => groupOrder.indexOf(x.group ?? '') - groupOrder.indexOf(y.group ?? ''))

        if (value) {
          setOptions([ { label: value, value }, ...sortedOptions ])
        } else {
          setOptions(sortedOptions)
        }
      }

      setLoading(false)
    }

    setLoading(true)
    fetchDebounced(inputValue, refreshOptions)

    return () => {
      active = false
    }
  }, [ inputValue, value ])

  return (
    <Autocomplete
      value={value}
      loading={loading}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      options={options}
      filterOptions={x => x}
      isOptionEqualToValue={(option: AutocompleteOption, v: string) => option.value === v}
      filterSelectedOptions
      renderInput={params => (
        <TextField
          {...params}
          data-testid={name}
          label={label}
          placeholder={placeholder}
          error={!!error}
          helperText={localizeError(error)}
          onBlur={onBlur}
          required={required}
        />
      )}
      groupBy={(option: AutocompleteOption) => option?.group}
      noOptionsText={t('common.state.noOptions')}
      clearText={t('common.actions.clear')}
      loadingText={t('common.state.loadingOptions')}
      size="small"
      disabled={disabled}
      fullWidth
      autoHighlight
      {...extraProps}
    />
  )
}
