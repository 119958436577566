import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { PropsWithChildren } from 'react'

import { WithHelp } from 'Shared/components/WithHelp'

const GroupLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subTextPrimary1.fontSize,
  color: theme.palette.text.secondary,
}))

interface ContentBlockProps {
  title: string
  helper?: string | React.ReactElement
}

export const ContentBlock: React.FC<PropsWithChildren<ContentBlockProps>> = ({ title, helper, children }) => (
  <Box>
    <WithHelp helpText={helper}>
      <GroupLabel>{title}</GroupLabel>
    </WithHelp>

    <Box sx={{ mt: 3 }}>
      {children}
    </Box>
  </Box>
)
