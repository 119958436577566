/* eslint-disable max-len */
import { SVGProps } from 'react'

export const EnvelopIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="26"
    height="20"
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M0.262852 0.324281C0.636044 -0.0828378 1.26861 -0.110341 1.67573 0.262852L13 10.6434L24.3243 0.262852C24.7314 -0.110341 25.364 -0.0828378 25.7372 0.324281C26.1104 0.7314 26.0828 1.36397 25.6757 1.73716L13.6757 12.7372C13.2934 13.0876 12.7066 13.0876 12.3243 12.7372L0.324281 1.73716C-0.0828378 1.36397 -0.110341 0.7314 0.262852 0.324281Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.96596e-06 1.00001C5.96596e-06 0.447721 0.447721 5.72754e-06 1.00001 5.72754e-06H25C25.5523 5.72754e-06 26 0.447721 26 1.00001V18C26 18.5304 25.7893 19.0391 25.4142 19.4142C25.0391 19.7893 24.5304 20 24 20H2.00001C1.46957 20 0.960867 19.7893 0.585793 19.4142C0.21072 19.0391 5.96596e-06 18.5304 5.96596e-06 18V1.00001ZM2.00001 2.00001V18H24V2.00001H2.00001Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.5553 9.3243C11.9285 9.73141 11.901 10.364 11.4939 10.7372L1.98408 19.4546C1.57696 19.8278 0.944397 19.8003 0.571204 19.3931C0.19801 18.986 0.225511 18.3535 0.632629 17.9803L10.1425 9.26287C10.5496 8.88968 11.1821 8.91718 11.5553 9.3243Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M14.4446 9.32424C14.8178 8.91712 15.4504 8.88961 15.8575 9.2628L25.3675 17.9803C25.7746 18.3534 25.8021 18.986 25.4289 19.3931C25.0557 19.8003 24.4232 19.8278 24.0161 19.4546L14.5061 10.7371C14.0989 10.3639 14.0714 9.73136 14.4446 9.32424Z" fill="currentColor" />
  </svg>
)
