import { createSelector } from 'reselect'

import type { StoreState } from 'Shared/types'

const analyticsStateSelector = (state: StoreState) => state.analytics

const isLoading = createSelector(analyticsStateSelector, state => state.isLoading)
const initialLoadCompleted = createSelector(analyticsStateSelector, state => state.initialLoadCompleted)
const chartPreRenders = createSelector(analyticsStateSelector, state => state.chartPreRenders)
const chartPreRendersFavorites = createSelector(analyticsStateSelector, state => state.chartPreRenders.filter(preRender => preRender.isFavorite))
const chartRenders = createSelector(analyticsStateSelector, state => state.chartRenders)
const chartRender = (key: string) => createSelector(chartRenders, state => state[key])
const chartFilter = createSelector(analyticsStateSelector, state => state.chartFilter)
const chartsTags = createSelector(analyticsStateSelector, state => state.tags)

export const AnalyticsSelectors = {
  isLoading,
  initialLoadCompleted,
  chartPreRenders,
  chartPreRendersFavorites,
  chartRenders,
  chartRender,
  chartsTags,
  chartFilter,
}

