import React, { PropsWithChildren } from 'react'
import * as Sentry from '@sentry/react'
import { ClientError } from './StaticPages/ClientError'

interface ErrorState {
  hasError: boolean
}

export class ErrorBoundary extends React.Component<PropsWithChildren> {
  state: ErrorState = {
    hasError: false
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.captureException(error)
  }

  render() {
    if (this.state.hasError) {
      return <ClientError />
    }

    return this.props.children
  }
}
