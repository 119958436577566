import React from 'react'
import { List, ListItem } from '@mui/material'
import { styled } from '@mui/material/styles'

import { FilterOptionListItem } from './FilterOptionListItem'

const FilterOptionsList = styled(List)({
  width: '100%',
  minWidth: 250,
  maxWidth: 300,
  maxHeight: 500,
  overflow: 'auto',
})

interface FilterOptionsProps {
  groupId: string
  selectedValues: string[],
  options: { label: string, value: string, blockAccess?: boolean }[]
  onOptionClick: (value: string) => void
  PremiumPromoContent?: JSX.Element
}

export const FilterOptions: React.FC<FilterOptionsProps> = ({
  groupId,
  selectedValues,
  options,
  onOptionClick,
  PremiumPromoContent,
}) => {
  const availableOptions = React.useMemo(() => options.filter(o => !o.blockAccess), [ options ])
  const blockedOptions = React.useMemo(() => options.filter(o => o.blockAccess), [ options ])

  return (
    <FilterOptionsList>
      {availableOptions.map(option => (
        <FilterOptionListItem
          key={option.value}
          onClick={onOptionClick}
          label={option.label}
          value={option.value}
          checked={selectedValues.indexOf(option.value) !== -1}
          groupId={groupId}
        />
      ))}

      {blockedOptions.length > 0 && (
        <>
          {!!PremiumPromoContent && <ListItem>{PremiumPromoContent}</ListItem>}

          {blockedOptions.map(option => (
            <FilterOptionListItem
              key={option.value}
              label={option.label}
              value={option.value}
              groupId={groupId}
              onClick={() => {}}
              checked={false}
              disabled
            />
          ))}
        </>
      )}
    </FilterOptionsList>
  )
}
