import type { ITypedAction } from 'Shared/types'
import { InitialState, PredictionActionTypes } from './prediction-constants'
import type { PredictionCalculationData, PredictionState } from './prediction-types'

export const prediction = (state: PredictionState = InitialState, action: ITypedAction<any>): PredictionState => {
  switch(action.type) {
    case PredictionActionTypes.LOAD_PREDICTION: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case PredictionActionTypes.LOAD_PREDICTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isFirstTimeLoading: false,
        firstTimeLoadingCompleted: true,
        prediction: action.payload
      }
    }

    case PredictionActionTypes.LOAD_PREDICTION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isFirstTimeLoading: false
      }
    }

    case PredictionActionTypes.UPDATE_PREDICTION_CALCULATION: {
      const calculation = action.payload as PredictionCalculationData

      return {
        ...state,
        calculation,
      }
    }

    case PredictionActionTypes.UPDATE_PREDICTION_PROJECTION: {
      const yearsProjection = action.payload as number

      return {
        ...state,
        yearsProjection
      }
    }

    case PredictionActionTypes.RESET_STATE_PREDICTION: {
      return InitialState
    }

    default:
      return state
  }
}
