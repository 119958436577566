import React, { PropsWithChildren } from 'react'
import { ClickAwayListener, Paper, Popper } from '@mui/material'

interface BaseOptionsPopperProps {
  anchorEl: HTMLElement | null,
  onClose: () => void,
}

export const BaseOptionsPopper: React.FC<PropsWithChildren<BaseOptionsPopperProps>> = ({ anchorEl, onClose, children }) => (
  <Popper open={!!anchorEl} anchorEl={anchorEl} placement="bottom-end" sx={theme => ({ zIndex: theme.zIndex.modal - 1 })}>
    <ClickAwayListener onClickAway={onClose}>
      <Paper elevation={3}>
        {children}
      </Paper>
    </ClickAwayListener>
  </Popper>
)
