import { Stack } from '@mui/material'
import { alpha, styled, Theme } from '@mui/material/styles'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import { ComeBackAliveLogoEN } from 'App/NavigationBar/assets/ComeBackAliveLogoEN'
import { ComeBackAliveLogoMini } from 'App/NavigationBar/assets/ComeBackAliveLogoMini'
import { ComeBackAliveLogoUK } from 'App/NavigationBar/assets/ComeBackAliveLogoUK'
import { SupportedLanguage } from 'config/i18n-types'
import { styledWithCustomProp } from 'config/theme'
import { ExternalButtonStyledLink, ExternalLink, SubTextPrimary2 } from 'Shared/components'
import { ExternalLinks } from 'Shared/constants'

const StyledStack = styledWithCustomProp(Stack, 'open')(({ theme, open }) => ({
  alignItems: 'center',
  border: `1px solid ${theme.palette.dark.dark2}`,
  borderRadius: '8px',
  backgroundColor: alpha(theme.palette.dark.dark2, 0.5),
  margin: `auto ${theme.spacing(2)} ${theme.spacing(2)}`,
  maxWidth: 'fit-content',
  [theme.breakpoints.down('sm')]: {
    margin: `auto auto ${theme.spacing(2)}`,
  },
  ...!open && ({
    padding: theme.spacing(1),
    ':hover': {
      backgroundColor: alpha(theme.palette.dark.dark2, 0.7),
    }
  }),
}))

const ContentWrapper = styledWithCustomProp(Stack, 'open')(({ theme, open }) => ({
  alignItems: 'center',
  gap: theme.spacing(2),
  backgroundColor: alpha(theme.palette.dark.dark2, 0.5),
  padding: theme.spacing(3, 2.5),
}))

const StyledText = styled(SubTextPrimary2)(({ theme }) => ({
  color: theme.palette.blue.blue4,
  whiteSpace: 'break-spaces',
  textAlign: 'center',
}))

const applyCbaLogoStyles = (theme: Theme) => ({
  width: 'auto',
  height: 47,
  color: theme.palette.common.white
})

const StyledCBALogoUK = styled(ComeBackAliveLogoUK)(({ theme }) =>
  applyCbaLogoStyles(theme)
)
const StyledCBALogoEN = styled(ComeBackAliveLogoEN)(({ theme }) =>
  applyCbaLogoStyles(theme)
)

const LinkUK = () => (
  <ExternalLink href={ExternalLinks.COME_BACK_ALIVE_UK} sx={{ display: 'flex' }}>
    <StyledCBALogoUK />
  </ExternalLink>
)

const LinkEN = () => (
  <ExternalLink href={ExternalLinks.COME_BACK_ALIVE_EN} sx={{ display: 'flex' }}>
    <StyledCBALogoEN />
  </ExternalLink>
)

const LinkMiniUK = () => (
  <ExternalLink href={ExternalLinks.COME_BACK_ALIVE_UK} sx={{ display: 'flex', color: 'white' }}>
    <ComeBackAliveLogoMini />
  </ExternalLink>
)

const LinkMiniEN = () => (
  <ExternalLink href={ExternalLinks.COME_BACK_ALIVE_EN} sx={{ display: 'flex', color: 'white' }}>
    <ComeBackAliveLogoMini />
  </ExternalLink>
)

export const ComeBackAliveLink = () => {
  const { i18n } = useTranslation()

  return i18n.resolvedLanguage === SupportedLanguage.UK ? <LinkUK /> : <LinkEN />
}

export const ComeBackAliveMiniLink = () => {
  const { i18n } = useTranslation()

  return i18n.resolvedLanguage === SupportedLanguage.UK ? <LinkMiniUK /> : <LinkMiniEN />
}

interface Props {
  open: boolean
}

export const ComeBackAlive = memo(({ open }: Props) => {
  const { i18n, t } = useTranslation()

  const link = i18n.resolvedLanguage === SupportedLanguage.UK
    ? ExternalLinks.COME_BACK_ALIVE_UK
    : ExternalLinks.COME_BACK_ALIVE_EN

  return (
    <StyledStack open={open}>
      {open ? (
        <ContentWrapper open={open}>
          <ComeBackAliveLink />
          <StyledText>{t('app.navbar.cba-text')}</StyledText>
          <ExternalButtonStyledLink
            href={link}
            sx={{ width: '100%' }}
          >
            {t('app.navbar.cba-button')}
          </ExternalButtonStyledLink>
        </ContentWrapper>
      ) : (
        <ComeBackAliveMiniLink />
      )}
    </StyledStack>
  )
})
