import React from 'react'
import ErrorIcon from '@mui/icons-material/Error'
import Tooltip from '@mui/material/Tooltip'
import { isNull } from 'lodash'
import { useTranslation } from 'react-i18next'

import { HOLDING_RISK } from 'Services/holdings'
import { HoldingRiskOptions } from 'Holdings/holdings-constants'
import { theme } from 'config/theme'

interface Props {
  risk: HOLDING_RISK | null
}

const getRiskOption = (risk: HOLDING_RISK | null) => {
  if (isNull(risk) || !HoldingRiskOptions[risk]) {
    return {
      color: theme.palette.dark.dark2,
      key: 'common.state.notSpecified-he'
    }
  }

  return HoldingRiskOptions[risk]
}

const RiskIconBase: React.FC<Props> = ({ risk }: Props) => {
  const { t } = useTranslation()
  const currentRisk = getRiskOption(risk)

  return (
    <Tooltip
      title={t('holdings.table.risk-specific', { option: t(currentRisk.key) })}
      enterTouchDelay={0}
      leaveTouchDelay={5000}
    >
      <ErrorIcon sx={{ fill: currentRisk.color }} fontSize="small" />
    </Tooltip>
  )
}

export const RiskIcon = React.memo(RiskIconBase)
