import { Stack } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { HistoryItem } from 'Services/history'
import { ButtonLink } from 'Shared/components/Links/ButtonLink'
import { TextSmall, TextSmaller2 } from 'Shared/components/Typography'
import { HistoryMessageContainer } from './HistoryMessageContainer'
import { HistoryMessageObject } from './MessageObjects'

interface Props {
  history: HistoryItem
  sharingKey: string
  onPortfolioClicked: (sharingKey: string) => void
}

export const GroupedHistoryItem: React.FC<Props> = ({ history, sharingKey, onPortfolioClicked }) => {
  const { t } = useTranslation()

  const actor = React.useMemo(() => history.actorPreferredName || history.actorUsername, [ history ])
  const portfolio = React.useMemo(() => history.portfolioUserPreferredName || history.portfolioUsername, [ history ])

  return (
    <HistoryMessageContainer createdAt={history.createdAt}>
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
        <TextSmall noWrap>{t('app.history.byActor')}: {actor}</TextSmall>

        <TextSmall noWrap>
          {t('app.history.forPortfolio')}:
          &nbsp;
          <ButtonLink onClick={() => onPortfolioClicked(sharingKey)}>{portfolio}</ButtonLink>
        </TextSmall>
      </Stack>

      <TextSmaller2>
        <HistoryMessageObject history={history} />
        {history.andMore > 0 && ` ${t('app.history.andMore', { count: history.andMore })}`}
      </TextSmaller2>
    </HistoryMessageContainer>
  )
}
