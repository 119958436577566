import React from 'react'
import { Alert } from '@mui/material'
import { Trans } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'

import { Routes } from 'Shared/constants'
import { InTextInternalLink } from 'Shared/components'
import { demoAppBarLinks } from './AppBar/AppBar'

export const DemoNotification: React.FC = () => {
  const pages = React.useMemo(() => demoAppBarLinks.map(link => link.href), [ demoAppBarLinks ])
  const routeMatch = useRouteMatch({ path: pages })

  const currentRoute = demoAppBarLinks.find(link => link.href === routeMatch?.url)

  // Hide demo notification if on NoMatch (404) page
  if (!currentRoute) return null

  return (
    <Alert severity="warning">
      <Trans
        i18nKey="app.demo-notification"
        components={[ <InTextInternalLink key={0} to={Routes.HOLDINGS} spaceBefore spaceAfter /> ]}
      />
    </Alert>
  )
}
