import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextFieldProps } from '@mui/material'

import { SettingsContext } from 'App/Settings'
import { SupportedCurrencies } from 'Shared/constants'
import { MoneyField, FormatProps } from './MoneyField'

export interface CustomMoneyFieldProps {
  currency: string
  onCurrencyChange: (currency: string) => void
  cashAccountCurrencies?: string[]
  currencyDisabled?: boolean
  formatProps?: FormatProps
}

type Props = CustomMoneyFieldProps & TextFieldProps

export const OperationMoneyField: React.FC<Props> = (props: Props) => {
  const { cashAccountCurrencies, ...otherProps } = props

  const { t } = useTranslation()
  const { currencies } = React.useContext(SettingsContext)

  const currencyOptions = React.useMemo(() => {
    if (!cashAccountCurrencies) {
      const otherCurrencies = SupportedCurrencies.filter(supported => !currencies.includes(supported))
      return [
        { group: t('common.money.popular'), currencies },
        { group: t('common.money.other'), currencies: otherCurrencies },
      ]
    }

    const otherCurrencies = SupportedCurrencies.filter(supported => !cashAccountCurrencies.includes(supported))
    return [
      { group: t('common.money.your'), currencies: cashAccountCurrencies },
      { group: t('common.money.other'), currencies: otherCurrencies },
    ]
  }, [ cashAccountCurrencies ])

  return (
    <MoneyField
      {...otherProps}
      currencyOptions={currencyOptions}
    />
  )
}
