import {
  AmericasRegion,
  GreaterAsiaRegion,
  GreaterEuropeRegion,
  HOLDING_CLASS,
  HOLDING_LIQUIDITY,
  HOLDING_RISK,
  HOLDING_TERM
} from 'Services/holdings'
import { Colors, LiquidityColors, RiskColors, TermColors } from 'Shared/types'

export const DefaultUndefinedTranslationKey = 'common.state.undefined'
export const DefaultUndefinedValue = 'Undefined'
export const DefaultColor = '#E1E1E1'

export const HoldingTermTranslationKeys = {
  [HOLDING_TERM.LessThanHalfOfYear]: 'holdings.term.lessThanHalfOfYear',
  [HOLDING_TERM.HalfToOneYear]: 'holdings.term.halfToOneYear',
  [HOLDING_TERM.OneToFiveYears]: 'holdings.term.oneToFiveYears',
  [HOLDING_TERM.FiveToTenYears]: 'holdings.term.fiveToTenYears',
  [HOLDING_TERM.MoreThanTenYears]: 'holdings.term.moreThanTenYears',
}

export const HoldingClassTranslationKeys = {
  [HOLDING_CLASS.Cash]: 'holdings.class.cash',
  [HOLDING_CLASS.Securities]: 'holdings.class.securities',
  [HOLDING_CLASS.Bonds]: 'holdings.class.bonds',
  [HOLDING_CLASS.RealEstate]: 'holdings.class.realEstate',
  [HOLDING_CLASS.Commodities]: 'holdings.class.commodities',
  [HOLDING_CLASS.BusinessInvestments]: 'holdings.class.businessInvestments',
  [HOLDING_CLASS.VentureCapital]: 'holdings.class.ventureCapital',
  [HOLDING_CLASS.Cryptocurrency]: 'holdings.class.cryptocurrency',
  [HOLDING_CLASS.PrivateDebt]: 'holdings.class.privateDebt',
  [HOLDING_CLASS.PreciousMetals]: 'holdings.class.preciousMetals',
  [HOLDING_CLASS.Tangible]: 'holdings.class.tangible',
  [HOLDING_CLASS.Intangible]: 'holdings.class.intangible',
  [HOLDING_CLASS.Alternatives]: 'holdings.class.alternatives',
}

export const HoldingClassTooltipTranslationKeys = {
  [HOLDING_CLASS.Cash]: 'holdings.manage.createEditHolding-classTooltip-cash',
  [HOLDING_CLASS.Securities]: 'holdings.manage.createEditHolding-classTooltip-securities',
  [HOLDING_CLASS.Bonds]: 'holdings.manage.createEditHolding-classTooltip-bonds',
  [HOLDING_CLASS.RealEstate]: 'holdings.manage.createEditHolding-classTooltip-realEstate',
  [HOLDING_CLASS.Commodities]: 'holdings.manage.createEditHolding-classTooltip-commodities',
  [HOLDING_CLASS.BusinessInvestments]: 'holdings.manage.createEditHolding-classTooltip-businessInvestments',
  [HOLDING_CLASS.VentureCapital]: 'holdings.manage.createEditHolding-classTooltip-ventureCapital',
  [HOLDING_CLASS.Cryptocurrency]: 'holdings.manage.createEditHolding-classTooltip-cryptocurrency',
  [HOLDING_CLASS.PrivateDebt]: 'holdings.manage.createEditHolding-classTooltip-privateDebt',
  [HOLDING_CLASS.PreciousMetals]: 'holdings.manage.createEditHolding-classTooltip-preciousMetals',
  [HOLDING_CLASS.Tangible]: 'holdings.manage.createEditHolding-classTooltip-tangible',
  [HOLDING_CLASS.Intangible]: 'holdings.manage.createEditHolding-classTooltip-intangible',
  [HOLDING_CLASS.Alternatives]: 'holdings.manage.createEditHolding-classTooltip-alternatives',
}

export const HoldingLiquidityTranslationKeys = {
  [HOLDING_LIQUIDITY.UrgentlyLiquid]: 'holdings.liquidity.urgentlyLiquid',
  [HOLDING_LIQUIDITY.HighlyLiquid]: 'holdings.liquidity.highlyLiquid',
  [HOLDING_LIQUIDITY.MediumLiquid]: 'holdings.liquidity.mediumLiquid',
  [HOLDING_LIQUIDITY.WeaklyLiquid]: 'holdings.liquidity.weaklyLiquid',
  [HOLDING_LIQUIDITY.HardToLiquid]: 'holdings.liquidity.hardToLiquid',
}

export const HoldingRiskTranslationKeys = {
  [HOLDING_RISK.Max]: 'holdings.risk.max',
  [HOLDING_RISK.High]: 'holdings.risk.high',
  [HOLDING_RISK.Medium]: 'holdings.risk.medium',
  [HOLDING_RISK.Low]: 'holdings.risk.low',
  [HOLDING_RISK.Min]: 'holdings.risk.min',
}

export const HoldingRegionsTranslationKeys = {
  [AmericasRegion.NorthAmerica]: 'holdings.region.americas-northAmerica',
  [AmericasRegion.LatinAmerica]: 'holdings.region.americas-latinAmerica',
  [GreaterEuropeRegion.UnitedKingdom]: 'holdings.region.greaterEurope-unitedKingdom',
  [GreaterEuropeRegion.EuropeDeveloped]: 'holdings.region.greaterEurope-europeDeveloped',
  [GreaterEuropeRegion.EuropeEmerging]: 'holdings.region.greaterEurope-europeEmerging',
  [GreaterEuropeRegion.AfricaMiddleEast]: 'holdings.region.greaterEurope-africaMiddleEast',
  [GreaterAsiaRegion.Japan]: 'holdings.region.greaterAsia-japan',
  [GreaterAsiaRegion.Australasia]: 'holdings.region.greaterAsia-australasia',
  [GreaterAsiaRegion.AsiaDeveloped]: 'holdings.region.greaterAsia-asiaDeveloped',
  [GreaterAsiaRegion.AsiaEmerging]: 'holdings.region.greaterAsia-asiaEmerging',
}

export const HoldingRiskColors = {
  [HOLDING_RISK.Max]: RiskColors.Level1,
  [HOLDING_RISK.High]: RiskColors.Level2,
  [HOLDING_RISK.Medium]: RiskColors.Level3,
  [HOLDING_RISK.Low]: RiskColors.Level4,
  [HOLDING_RISK.Min]: RiskColors.Level5,
}

export const HoldingLiquidityColors = {
  [HOLDING_LIQUIDITY.HardToLiquid]: LiquidityColors.Level1,
  [HOLDING_LIQUIDITY.WeaklyLiquid]: LiquidityColors.Level2,
  [HOLDING_LIQUIDITY.MediumLiquid]: LiquidityColors.Level3,
  [HOLDING_LIQUIDITY.HighlyLiquid]: LiquidityColors.Level4,
  [HOLDING_LIQUIDITY.UrgentlyLiquid]: LiquidityColors.Level5,
}

export const HoldingClassColors = {
  [HOLDING_CLASS.Cash]: Colors.Cornflower,
  [HOLDING_CLASS.Securities]: Colors.Padua,
  [HOLDING_CLASS.Bonds]: Colors.Spray,
  [HOLDING_CLASS.RealEstate]: Colors.Melrose,
  [HOLDING_CLASS.Commodities]: Colors.SweetCorn,
  [HOLDING_CLASS.BusinessInvestments]: Colors.WaxFlower,
  [HOLDING_CLASS.VentureCapital]: Colors.ClassicRose,
  [HOLDING_CLASS.Cryptocurrency]: Colors.Linen,
  [HOLDING_CLASS.PrivateDebt]: Colors.Tusk,
  [HOLDING_CLASS.PreciousMetals]: Colors.Azalea,
  [HOLDING_CLASS.Tangible]: Colors.WaxFlowerDarken20,
  [HOLDING_CLASS.Intangible]: Colors.WaxFlowerDarken10,
  [HOLDING_CLASS.Alternatives]: Colors.CornflowerDarken10,
}

export const HoldingTermColors = {
  [HOLDING_TERM.LessThanHalfOfYear]: TermColors.Level5,
  [HOLDING_TERM.HalfToOneYear]: TermColors.Level4,
  [HOLDING_TERM.OneToFiveYears]: TermColors.Level3,
  [HOLDING_TERM.FiveToTenYears]: TermColors.Level2,
  [HOLDING_TERM.MoreThanTenYears]: TermColors.Level1,
}

export const HoldingClassWeight = {
  [HOLDING_CLASS.Cash]: 11,
  [HOLDING_CLASS.Securities]: 10,
  [HOLDING_CLASS.Bonds]: 9,
  [HOLDING_CLASS.RealEstate]: 8,
  [HOLDING_CLASS.Commodities]: 7,
  [HOLDING_CLASS.BusinessInvestments]: 6,
  [HOLDING_CLASS.Cryptocurrency]: 5,
  [HOLDING_CLASS.PrivateDebt]: 4,
  [HOLDING_CLASS.PreciousMetals]: 3,
  [HOLDING_CLASS.Alternatives]: 2,
  [DefaultUndefinedValue]: 1,
}

export const HoldingRiskWeight = {
  [HOLDING_RISK.Max]: 6,
  [HOLDING_RISK.High]: 5,
  [HOLDING_RISK.Medium]: 4,
  [HOLDING_RISK.Low]: 3,
  [HOLDING_RISK.Min]: 2,
  [DefaultUndefinedValue]: 1,
}

export const HoldingLiquidityWeight = {
  [HOLDING_LIQUIDITY.HardToLiquid]: 6,
  [HOLDING_LIQUIDITY.WeaklyLiquid]: 5,
  [HOLDING_LIQUIDITY.MediumLiquid]: 4,
  [HOLDING_LIQUIDITY.HighlyLiquid]: 3,
  [HOLDING_LIQUIDITY.UrgentlyLiquid]: 2,
  [DefaultUndefinedValue]: 1,
}

export const HoldingTermWeight = {
  [HOLDING_TERM.MoreThanTenYears]: 6,
  [HOLDING_TERM.FiveToTenYears]: 5,
  [HOLDING_TERM.OneToFiveYears]: 4,
  [HOLDING_TERM.HalfToOneYear]: 3,
  [HOLDING_TERM.LessThanHalfOfYear]: 2,
  [DefaultUndefinedValue]: 1,
}

export const PopularStocks = [
  'NYSE:VOO',
  'NASDAQ:AAPL',
  'NYSE:VTI',
  'NASDAQ:TLT',
  'NYSE:SPY',
  'NYSE:SCHD',
  'NYSE:VT',
  'NASDAQ:QQQ',
  'NYSE:IVV',
  'NASDAQ:TSLA',
  'NASDAQ:MSFT',
  'NASDAQ:AMZN',
  'LSE:SWRD',
  'NASDAQ:NVDA',
  'NASDAQ:GOOG',
  'NASDAQ:BND',
  'NYSE:ITOT',
  'LSE:CSPX',
  'NASDAQ:VXUS',
  'NYSE:O',
]

export const PopularCurrencies = [
  'USD',
  'EUR',
  'GBP',
  'CAD',
]

export const PopularCrypto = [
  'BTC',
  'ETH',
  'USDT',
  'SOL',
  'BNB',
  'XRP',
  'DOT',
  'NEAR',
  'ATOM',
  'USDC',
  'BUSD',
  'TRX',
  'ADA',
  'DOGE',
  'LTC',
  'XMR',
  'LINK',
  'AVAX',
  'MATIC',
  'DAI',
]
