export const TransactionsActionTypes = {
  GET_TRANSACTIONS: 'GET_TRANSACTIONS',
  GET_TRANSACTIONS_SUCCESS: 'GET_TRANSACTIONS_SUCCESS',
  GET_TRANSACTIONS_FAILURE: 'GET_TRANSACTIONS_FAILURE',
  DELETE_TRANSACTION: 'DELETE_TRANSACTION',
  DELETE_TRANSACTION_SUCCESS: 'DELETE_TRANSACTION_SUCCESS',
  DELETE_TRANSACTION_FAILURE: 'DELETE_TRANSACTION_FAILURE',
  SET_TRANSACTIONS_FILTER_CHANGED: 'SET_TRANSACTIONS_FILTER_CHANGED',
  SET_SELECTED_TRANSACTIONS: 'SET_SELECTED_TRANSACTIONS',
  RESET_STATE_TRANSACTIONS: 'RESET_STATE_TRANSACTIONS'
}
