import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { SupportedLanguage } from 'config/i18n-types'
import { ExternalLinks } from 'Shared/constants'
import { InTextExternalLink } from './InTextExternalLink'

export const ChangelogLink: React.FC<PropsWithChildren> = ({ children }) => {
  const { i18n } = useTranslation()

  const changelogHref = i18n.resolvedLanguage === SupportedLanguage.UK ?
    ExternalLinks.CHANGELOG.UK :
    ExternalLinks.CHANGELOG.EN

  return (
    <InTextExternalLink href={changelogHref}>{children}</InTextExternalLink>
  )
}
