import { Button } from '@mui/material'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Routes } from 'Shared/constants'
import { NotFoundImg } from 'App/assets/NotFoundImg'
import { StaticPage } from './StaticPage'
import { StaticPageTitle, StaticPageSubTitle } from './Typographies'

export const NoMatch = withTranslation()(({ t }) => (
  <StaticPage
    title={t('app.navbar.routes.notFound')}
    messageComponent={(
      <>
        <StaticPageTitle mb={2.5}>
          {t('app.static.404.title')}
        </StaticPageTitle>
        <StaticPageSubTitle mb={3.5}>
          {t('app.static.404.subTitle')}
        </StaticPageSubTitle>
        <Button
          component={Link}
          to={Routes.HOME}
          variant="contained"
          size="small"
        >
          {t('app.static.404.goHome-action')}
        </Button>
      </>
    )}
    imgComponent={<NotFoundImg width="100%" height="100%" />}
  />
))
