import React, { useContext } from 'react'
import { IconButton, Tooltip, Badge } from '@mui/material'

import { SettingsContext } from 'App/Settings'
import { BellIcon } from 'Shared/assets'
import { useTranslation } from 'react-i18next'

export const NotificationButton: React.FC = () => {
  const { t } = useTranslation()
  const { manageSettings: { releaseNotesDialog } } = useContext(SettingsContext)

  return (
    <Tooltip title={t('app.navbar.notification')}>
      <IconButton onClick={() => releaseNotesDialog.open(true)} size="small">
        <Badge color="primary" badgeContent={1} invisible={releaseNotesDialog.releaseNotesRead}>
          <BellIcon width={16} height={16} fontSize="inherit" />
        </Badge>
      </IconButton>
    </Tooltip>
  )
}
