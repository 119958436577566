import { Box, Button, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

interface Props {
  sections: Record<string, {label: string, id: string}>
}

export const DesktopLandingAnchorLinks = ({ sections }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Box sx={{ flexGrow: 1, px: 2, ml: 5 }}>
      {Object.values(sections).map(({ label, id }) => (
        <Button
          key={id}
          component={RouterLink}
          to={`#${id}`}
          sx={{
            color: theme.palette.common.black,
          }}
          onClick={() => {
            document.getElementById(id)?.scrollIntoView({ block: 'start', behavior: 'smooth' })
          }}
        >
          {t(label)}
        </Button>
      ))}
    </Box>
  )
}
