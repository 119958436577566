export const fadeColor = (hex: string, percent: number): string => {
  const color = hex.replace('#', '')

  if (color.length !== 6) {
    throw new Error('Invalid hex color format. Use #RRGGBB.')
  }

  const r = parseInt(color.substring(0, 2), 16)
  const g = parseInt(color.substring(2, 4), 16)
  const b = parseInt(color.substring(4, 6), 16)

  const fade = (percent / 100)

  const newR = Math.round(r + (255 - r) * fade)
  const newG = Math.round(g + (255 - g) * fade)
  const newB = Math.round(b + (255 - b) * fade)

  return `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`
}

