/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable max-len */
import { SVGProps } from 'react'

export const ComeBackAliveLogoUK = (props: SVGProps<SVGSVGElement>) => (
  <svg width="120" height="48" viewBox="0 0 172 68" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M33.5752 0.0654297H171.09V34.7088H127.857V67.9239H33.6318V34.7532H0.0703125V0.10984H33.5752V0.0654297ZM33.5752 1.35984H1.32031V33.5032H33.5752V1.35984ZM34.8818 34.7532V66.6739H126.607V34.7197H34.9825V34.7532H34.8818ZM34.9825 33.4588V1.31543H169.84V33.4588H34.9825Z" fill="currentColor"></path>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.4814 25.5686C17.4814 25.5686 7.03493 14.5599 12.3536 10.9646C12.7226 10.6669 13.148 10.4455 13.6046 10.3132C14.0611 10.181 14.5398 10.1407 15.0123 10.1946C15.4849 10.2485 15.9417 10.3956 16.3561 10.6273C16.7705 10.8589 17.1341 11.1704 17.4254 11.5434C17.7156 11.1707 18.0781 10.8595 18.4916 10.628C18.905 10.3965 19.361 10.2494 19.8326 10.1955C20.3042 10.1415 20.7819 10.1818 21.2376 10.3139C21.6933 10.446 22.1177 10.6672 22.4859 10.9646C27.8045 14.5599 17.4814 25.5686 17.4814 25.5686ZM17.3917 13.5915C17.0717 13.1886 16.7149 12.816 16.3257 12.4784C15.9066 12.1198 15.3763 11.9142 14.8231 11.896C14.2699 11.8778 13.7271 12.048 13.2849 12.3782C13.0665 12.5131 12.8835 12.6974 12.7509 12.9161C12.6182 13.1347 12.5398 13.3815 12.5219 13.636C12.5219 13.7362 12.5219 13.8364 12.5219 13.9477C12.5219 16.3743 15.1812 20.3258 17.4814 23.053C19.7256 20.337 22.3625 16.3743 22.3625 13.9811C22.3625 13.8698 22.3625 13.7585 22.3625 13.6472C22.3463 13.3907 22.2686 13.1417 22.1359 12.921C22.0032 12.7003 21.8193 12.5142 21.5994 12.3782C21.2049 12.0047 20.6805 11.7962 20.1351 11.7962C19.5898 11.7962 19.0653 12.0047 18.6708 12.3782C18.2051 12.7239 17.7864 13.128 17.4254 13.5804" fill="currentColor"></path>
    <path d="M55.5535 25.5687H53.1747V11.6993H49.2474V25.5687H46.8237V9.62891H55.5535V25.5687Z" fill="currentColor"></path>
    <path d="M59.6377 12.6787C59.6377 10.2298 61.8145 9.33936 63.6996 9.33936C65.5847 9.33936 67.739 10.2076 67.739 12.6787V22.4963C67.739 24.9229 65.5622 25.8357 63.6996 25.8357C61.837 25.8357 59.6377 24.9675 59.6377 22.4963V12.6787ZM65.3154 12.6787C65.3154 11.7659 64.4402 11.432 63.6996 11.432C62.959 11.432 62.0613 11.7437 62.0613 12.6787V22.4963C62.0613 23.4091 62.959 23.743 63.6996 23.743C64.4402 23.743 65.3154 23.4091 65.3154 22.4963V12.6787Z" fill="currentColor"></path>
    <path d="M71.4199 25.5684V9.62868H75.9082C76.353 9.58384 76.8022 9.63134 77.2275 9.76816C77.6527 9.90498 78.0445 10.1281 78.378 10.4234C78.7116 10.7187 78.9795 11.0796 79.1647 11.4832C79.35 11.8868 79.4484 12.3242 79.4539 12.7676V14.7045C79.4341 15.1996 79.3006 15.6836 79.0633 16.1196C78.826 16.5556 78.4914 16.932 78.085 17.2201C78.6414 17.4357 79.1168 17.8167 79.4463 18.311C79.7757 18.8052 79.9431 19.3884 79.9253 19.9806V21.9397C79.9499 22.4236 79.8712 22.9072 79.6944 23.3588C79.5175 23.8105 79.2465 24.2199 78.8991 24.5604C78.5516 24.9009 78.1355 25.1647 77.6781 25.3346C77.2207 25.5045 76.7323 25.5766 76.2448 25.5462L71.4199 25.5684ZM77.0303 13.0125C77.0492 12.8344 77.0282 12.6544 76.9687 12.4853C76.9091 12.3163 76.8126 12.1624 76.686 12.0347C76.5594 11.907 76.4059 11.8087 76.2365 11.7468C76.0671 11.6849 75.8861 11.661 75.7063 11.6768H73.8885V16.3074H75.6165C75.7958 16.3211 75.976 16.2958 76.1445 16.2332C76.313 16.1707 76.4656 16.0723 76.5918 15.9451C76.7179 15.8178 76.8144 15.6648 76.8747 15.4966C76.9349 15.3285 76.9574 15.1493 76.9405 14.9716L77.0303 13.0125ZM77.5015 19.9806C77.5139 19.7611 77.4783 19.5416 77.3971 19.337C77.3159 19.1325 77.1912 18.9477 77.0313 18.7955C76.8715 18.6433 76.6803 18.5271 76.471 18.455C76.2616 18.3829 76.039 18.3565 75.8184 18.3777H73.8885V23.5203H75.8184C76.0382 23.547 76.2612 23.5253 76.4715 23.4566C76.6818 23.3879 76.8742 23.274 77.035 23.123C77.1958 22.972 77.321 22.7877 77.4017 22.5831C77.4823 22.3785 77.5164 22.1588 77.5015 21.9397V19.9806Z" fill="currentColor"></path>
    <path d="M90.7194 25.5687H83.6055V9.62891H90.7194V11.6993H86.0292V16.3076H89.9115V18.378H86.0292V23.476H90.7194V25.5687Z" fill="currentColor"></path>
    <path d="M94.4893 25.5687V9.62891H98.641C101.199 9.62891 102.838 11.0092 102.838 13.5693V15.5284C102.838 18.0663 101.199 19.4465 98.641 19.4465H96.9354V25.5687H94.4893ZM100.414 13.5916C100.436 13.3499 100.407 13.1063 100.327 12.8769C100.247 12.6474 100.119 12.4374 99.9518 12.2606C99.7842 12.0838 99.5807 11.9443 99.3547 11.8514C99.1287 11.7585 98.8855 11.7142 98.641 11.7216H96.9354V17.4207H98.6634C98.9074 17.4262 99.1498 17.3808 99.375 17.2872C99.6001 17.1937 99.8029 17.0542 99.9701 16.8778C100.137 16.7014 100.265 16.4921 100.346 16.2635C100.426 16.0348 100.457 15.7919 100.436 15.5507L100.414 13.5916Z" fill="currentColor"></path>
    <path d="M114.754 25.5687H112.33V18.378H108.448V25.5687H106.024V9.62891H108.448V16.3076H112.33V9.62891H114.754V25.5687Z" fill="currentColor"></path>
    <path d="M125.481 25.5687V15.5507L121.419 25.5687H119.175V9.62891H121.621V19.6469L125.683 9.62891H127.927V25.5687H125.481Z" fill="currentColor"></path>
    <path d="M137.667 20.0028H140.091V22.4961C140.091 24.9227 137.914 25.8355 136.051 25.8355C134.189 25.8355 131.989 24.9672 131.989 22.4961V12.6562C131.989 10.2074 134.166 9.31689 136.051 9.31689C137.936 9.31689 140.091 10.1851 140.091 12.6562V15.1719H137.667V12.6562C137.667 11.7435 136.792 11.4095 136.051 11.4095C135.311 11.4095 134.413 11.7212 134.413 12.6562V22.4961C134.413 23.4089 135.311 23.7428 136.051 23.7428C136.792 23.7428 137.667 23.4089 137.667 22.4961V20.0028Z" fill="currentColor"></path>
    <path d="M152.366 21.6283C152.366 24.2107 150.728 25.5687 148.169 25.5687H144.062V9.62891H146.486V15.7288H148.214C150.773 15.7288 152.411 17.0868 152.411 19.6692L152.366 21.6283ZM148.169 23.476C148.412 23.4848 148.654 23.4423 148.879 23.3513C149.105 23.2603 149.308 23.1229 149.475 22.9482C149.643 22.7736 149.771 22.5655 149.852 22.338C149.932 22.1104 149.963 21.8685 149.942 21.6283V19.6692C149.95 19.4309 149.91 19.1935 149.825 18.9705C149.74 18.7475 149.611 18.5433 149.446 18.3697C149.282 18.196 149.084 18.0562 148.865 17.9583C148.646 17.8604 148.409 17.8063 148.169 17.7991H146.441V23.476H148.169Z" fill="currentColor"></path>
    <path d="M59.4584 58.9508L55.9126 51.749H55.3067V58.9508H52.883V51.749H52.2995L48.6864 58.9508H46.3301V58.5501L50.0105 50.8363L46.5096 43.4007V42.9999H48.9333L52.1873 49.6786H52.883V42.9999H55.3067V49.6786H56.0248L59.2564 42.9999H61.6801V43.4007L58.2241 50.8363L61.8821 58.5501V58.9508H59.4584Z" fill="currentColor"></path>
    <path d="M71.1278 58.9508V48.9328L67.0659 58.9508H64.8218V42.9999H67.2454V53.0179L71.3073 42.9999H73.5515V58.9508H71.1278Z" fill="currentColor"></path>
    <path d="M77.5239 58.9507V42.9998H82.0122C82.457 42.9549 82.9062 43.0024 83.3315 43.1393C83.7567 43.2761 84.1485 43.4992 84.482 43.7945C84.8156 44.0898 85.0835 44.4507 85.2687 44.8543C85.4539 45.2579 85.5524 45.6953 85.5579 46.1388V48.0978C85.5381 48.5929 85.4045 49.077 85.1672 49.513C84.93 49.949 84.5954 50.3254 84.189 50.6135C84.7454 50.8291 85.2208 51.2101 85.5503 51.7043C85.8797 52.1985 86.047 52.7817 86.0292 53.374V55.3442C86.0539 55.8281 85.9752 56.3117 85.7984 56.7633C85.6215 57.2149 85.3505 57.6244 85.0031 57.9649C84.6556 58.3054 84.2395 58.5692 83.7821 58.7391C83.3247 58.909 82.8363 58.9811 82.3488 58.9507H77.5239ZM83.1343 46.3836C83.1532 46.2056 83.1322 46.0255 83.0726 45.8564C83.0131 45.6874 82.9166 45.5335 82.79 45.4058C82.6634 45.2781 82.5099 45.1798 82.3405 45.1179C82.1711 45.056 81.9901 45.0321 81.8102 45.0479H79.9925V49.6784H81.8102C81.9896 49.6922 82.1698 49.6669 82.3383 49.6043C82.5067 49.5418 82.6594 49.4434 82.7855 49.3162C82.9116 49.1889 83.0082 49.0359 83.0685 48.8677C83.1287 48.6996 83.1512 48.5204 83.1343 48.3427V46.3836ZM83.6055 53.3517C83.6179 53.1322 83.5823 52.9127 83.5011 52.7081C83.4199 52.5036 83.2952 52.3188 83.1353 52.1666C82.9754 52.0144 82.7842 51.8982 82.5749 51.8261C82.3655 51.754 82.143 51.7276 81.9224 51.7488H79.9925V56.9025H81.9224C82.1428 56.9275 82.366 56.9041 82.5763 56.834C82.7865 56.764 82.9787 56.649 83.1393 56.4972C83.2998 56.3453 83.4248 56.1604 83.5053 55.9554C83.5859 55.7504 83.6201 55.5303 83.6055 55.3108V53.3517Z" fill="currentColor"></path>
    <path d="M95.5443 58.9509V48.9329L91.4824 58.9509H89.2383V43H91.6619V53.018L95.7238 43H97.968V58.9509H95.5443Z" fill="currentColor"></path>
    <path d="M110.827 58.9509V48.0981L107.595 54.4651L104.375 48.0981V58.9954H101.951V43H104.195L107.562 49.8122L110.995 43H113.172V58.9509H110.827Z" fill="currentColor"></path>
  </svg>
)
