import { Decimal } from './decimal'

export class Money {
  public static readonly SyncedDecimalScale: number = 6
  public static readonly RegularDecimalScale: number = 2

  private amount: number
  private decimalScale: number

  private constructor (amount: number, decimalScale: number) {
    this.amount = amount
    this.decimalScale = decimalScale
  }

  public static fromDollars(dollars: number, decimalScale: number = Money.RegularDecimalScale) {
    const amount = Math.trunc(Math.round(dollars * Money.scale(decimalScale)))

    return new Money(amount, decimalScale)
  }

  public static fromAmount(amount: number, decimalScale: number = Money.RegularDecimalScale) {
    return new Money(amount, decimalScale)
  }

  public static formatPrecision(amount: number) {
    return amount > 1 ? +(amount.toFixed(Money.RegularDecimalScale)) : amount
  }

  private static scale = (decimalScale: number) => Math.pow(10, decimalScale)

  public get dollars(): number {
    return Decimal.wholeNumber(this.toValue())
  }

  public get cents(): number {
    return Decimal.decimalPart(this.toValue(), this.decimalScale)
  }

  public get isZero(): boolean {
    return this.amount === 0
  }

  public get isPositive(): boolean {
    return this.amount > 0
  }

  public get isNegative(): boolean {
    return this.amount < 0
  }

  public get amountValue(): number {
    return this.amount
  }

  public get sign(): string {
    if (this.isPositive) {
      return '+'
    }

    return '-'
  }

  public add(money: Money): Money {
    return Money.fromAmount(this.amount + money.amountValue)
  }

  public subtract(money: Money): Money {
    return Money.fromAmount(this.amount - money.amountValue)
  }

  public toValue(): number {
    return this.amount / Money.scale(this.decimalScale)
  }

  public toPositive(): Money {
    return new Money(Math.abs(this.amount), this.decimalScale)
  }

  public toNegative(): Money {
    return new Money(-Math.abs(this.amount), this.decimalScale)
  }
}
