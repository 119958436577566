export const landingAdvisorSections = {
  features: {
    label: 'app.navbar.landing.navigation.features',
    id: 'features'
  },
  reviews: {
    label: 'app.navbar.landing.navigation.reviews',
    id: 'reviews'
  },
}
