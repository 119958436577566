import { createSelector } from 'reselect'

import { StoreState } from 'Shared/types'

const stateSelector = (state: StoreState) => state.demo

const transactionsFirstLoadCompleted = createSelector(stateSelector, state => state.transactionsFirstLoadingCompleted)

export const DemoSelectors = {
  transactionsFirstLoadCompleted
}
