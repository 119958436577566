import { ISessionStorageService } from './sessionStorage-types'

export class SessionStorageService implements ISessionStorageService {
  public setItem = (key: string, value: any) => {
    try {
      const jsonValue = JSON.stringify(value)
      sessionStorage.setItem(key, jsonValue)
    } catch (error) {
      // NOTE: skip due to safari private mode
    }
  }

  public getItem = <T>(key: string) => {
    try {
      const stringValue = sessionStorage.getItem(key)
      return stringValue ? JSON.parse(stringValue) as T : null
    } catch (error) {
      return null
    }
  }

  public removeItem = (key: string) => {
    try {
      sessionStorage.removeItem(key)
    } catch (error) {
      // NOTE: skip due to safari private mode
    }
  }

  public clear = () => {
    try {
      sessionStorage.clear()
    } catch (error) {
      // NOTE: skip due to safari private mode
    }
  }
}
