import { Box, Container, GlobalStyles, Stack, styled } from '@mui/material'
import { theme } from 'config/theme'
import { Helmet } from 'react-helmet'
import backgroundImg from '../assets/side-bg.webp'

const BodyGlobalStyles = (
  <GlobalStyles styles={{
    body: {
      backgroundColor: theme.palette.common.white,
      backgroundImage: `url(${backgroundImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right',
      backgroundSize: '45% 100vh',
      [theme.breakpoints.down('tablet')]: {
        background: 'unset'
      },
      [theme.breakpoints.up('xl')]: {
        backgroundSize: '50% 100vh',
      },
      'header.MuiAppBar-root': {
        backgroundColor: 'transparent',
        borderBottom: 'none',
      },
    },
  }} />
)

const PageStack = styled(Stack)(({ theme }) => ({
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  columnGap: '15%',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column-reverse',
  },
  '> div': {
    flex: 1,
    minWidth: 0
  },
}))

const MessageBox = styled(Box)(({ theme }) => ({
  textAlign: 'left',
  [theme.breakpoints.down('tablet')]: {
    textAlign: 'center',
  },
}))

const ImgBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    maxWidth: '65%',
  },
  [theme.breakpoints.down('mobileLarge')]: {
    maxWidth: '55%',
  },
}))

interface Props {
  title: string
  messageComponent: React.ReactNode
  imgComponent: React.ReactNode
}

export const StaticPage = (({ title, messageComponent, imgComponent }: Props) => (
  <Stack component="main" justifyContent="center">
    {BodyGlobalStyles}
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Container maxWidth="lg" sx={{ px: 2.5 }}>
      <PageStack>
        <MessageBox>
          {messageComponent}
        </MessageBox>
        <ImgBox>
          {imgComponent}
        </ImgBox>
      </PageStack>
    </Container>
  </Stack>
))
