import { Nullable } from 'Shared/types'

export interface IProfileService {
  getAccount(token: string): Promise<UserProfile>
  deleteAccount(token: string): Promise<void>
  exportAccount(token: string, fileName: string): Promise<void>
  readReleaseNotes(token: string): Promise<void>
  updateAccount(token: string, payload: UpdateProfileRequest): Promise<void>
  createSubscription(token: string, payload: CreateSubscriptionRequest): Promise<SubscriptionResponse>
  cancelSubscription(token: string): Promise<void>
  getPromoCode(token: string, promoCode: string): Promise<Promo>
}

export enum PricingTier {
  Free = 'Free',
  Premium = 'Premium',
}

export type TierLimitation = {
  holdingsThreshold: Nullable<number>,
  tickersThreshold: Nullable<number>,
}

export type TierInformation = {
  tier: PricingTier
}

export enum AdvisorRole {
  Advisor = 'Advisor',
  Owner = 'Owner',
}

export enum RenewalPeriod {
  None = 'None',
  Day = 'Day',
  Month = 'Month',
  Year = 'Year',
}

export enum SubscriptionStatus {
  Paid = 'Paid',
  PaymentVerification = 'PaymentVerification',
  CancellationRequested = 'CancellationRequested',
}

export interface Subscription {
  paidTillDateTime: string
  renewalPeriod: RenewalPeriod
  renewedDateTime: string
  status: SubscriptionStatus
  inGracePeriod: boolean
  renewable: boolean
  paymentProvider: string
}

export type OrganizationDetails = {
  key: string
  name: string
  whiteLabelLogo: boolean
}

export type UserProfile = {
  advisor: boolean
  advisorRole: Nullable<AdvisorRole>
  advisoryClient: boolean
  baseCurrency: string
  brandNew: boolean
  organization: Nullable<OrganizationDetails>
  pendingSurveys: string[]
  releaseNotesRead: boolean
  subscription: Nullable<Subscription>
  tierInformation: TierInformation
  username: string
}

export interface UpdateProfileRequest {
  baseCurrency: string
  username: string
}

export interface CreateSubscriptionRequest {
  renewalPeriod: RenewalPeriod
  promoCode: Nullable<string>
}

export interface SubscriptionResponse {
  subscriptionKey: string
  externalId: string
  checkoutUrl: string
}

export interface Promo {
  code: string
  isFirstPeriodOverrideOnly: boolean
  isAutoRenew: boolean
  priceOverrides: PriceOverride[]
}

export interface PriceOverride {
  tier: PricingTier
  period: RenewalPeriod
  price: number
}
