/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable max-len */
import { SVGProps } from 'react'

export const BellIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M7 25C7 24.4477 7.44772 24 8 24H16C16.5523 24 17 24.4477 17 25C17 25.5523 16.5523 26 16 26H8C7.44772 26 7 25.5523 7 25Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.0618 2.00024C11.008 1.99378 9.9633 2.19591 8.98796 2.595C8.01243 2.99416 7.12556 3.58245 6.37846 4.32596C5.63135 5.06948 5.0388 5.95351 4.63495 6.9271C4.2311 7.9007 4.02394 8.94459 4.02541 9.99862L4.02541 10L4.0254 11C4.0254 15.6178 3.05783 18.3966 2.12745 19.9971L2.12576 20H21.8743L21.8726 19.9972C20.9427 18.3967 19.9756 15.618 19.9756 11V10.113C19.9756 5.68095 16.4419 2.03316 12.0618 2.00024ZM8.23056 0.743957C9.45035 0.244848 10.7569 -0.00789909 12.0748 0.000284087L12.076 0.000291636C17.593 0.0413069 21.9756 4.62013 21.9756 10.113V11C21.9756 15.3347 22.8813 17.7517 23.6013 18.9913C23.7783 19.2943 23.8725 19.6385 23.8743 19.9895C23.8762 20.3411 23.7853 20.6871 23.6108 20.9924C23.4363 21.2978 23.1844 21.5517 22.8805 21.7286C22.5765 21.9055 22.2313 21.9991 21.8796 22L21.8771 22H2.12298L2.12041 22C1.76869 21.9991 1.42343 21.9055 1.11946 21.7285C0.815491 21.5516 0.56356 21.2976 0.389083 20.9922C0.214607 20.6868 0.123759 20.3408 0.125702 19.9891C0.127641 19.6381 0.221929 19.2938 0.399066 18.9908C1.11938 17.7511 2.0254 15.3342 2.0254 11L2.02541 10.0007C2.02366 8.68299 2.28269 7.37796 2.78758 6.1608C3.29255 4.94343 4.03348 3.83804 4.96765 2.90835C5.90182 1.97866 7.01077 1.24307 8.23056 0.743957Z" fill="currentColor" />
  </svg>
)
