import { Stack } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { HistoryItem } from 'Services/history'
import { TextSmall, TextSmaller2 } from 'Shared/components/Typography'
import { HistoryMessageContainer } from './HistoryMessageContainer'
import { HistoryMessageObject } from './MessageObjects'

interface Props {
  history: HistoryItem
}

export const FlatHistoryItem: React.FC<Props> = ({ history }) => {
  const { t } = useTranslation()

  const actor = history.actorPreferredName || history.actorUsername

  return (
    <HistoryMessageContainer createdAt={history.createdAt}>
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
        <TextSmall noWrap>{t('app.history.byActor')}: {actor}</TextSmall>
      </Stack>

      <TextSmaller2>
        <HistoryMessageObject history={history} />
      </TextSmaller2>
    </HistoryMessageContainer>
  )
}
