import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { Nullable } from 'Shared/types'

const PromoCodeKey = 'promo'
const PromoCodeQueryParam = 'promo'

export const usePromoCode = () => {
  const { search } = useLocation()
  const history = useHistory()

  const [ promoCode, setPromoCode ] = React.useState<Nullable<string>>(window.localStorage.getItem(PromoCodeKey))

  const savePromoCode = (promoCode: string) => {
    try {
      const existingPromoCode = window.localStorage.getItem(PromoCodeKey)

      if (promoCode && existingPromoCode !== promoCode) {
        window.localStorage.setItem(PromoCodeKey, promoCode)
        setPromoCode(promoCode)
      }
    } catch(e) {
      // LocalStorage is not available
    }
  }

  const clearPromoCode = () => {
    window.localStorage.removeItem(PromoCodeKey)
    setPromoCode(null)

    const queryParams = new URLSearchParams(search)
    if (queryParams.has(PromoCodeQueryParam)) {
      queryParams.delete(PromoCodeQueryParam)
      history.replace({ search: queryParams.toString() })
    }
  }

  React.useEffect(() => {
    const promoCode = new URLSearchParams(search).get(PromoCodeQueryParam)

    if (promoCode) {
      savePromoCode(promoCode)
    }
  }, [ search ])

  return {
    promoCode,
    savePromoCode,
    clearPromoCode,
  }
}
