import * as Yup from 'yup'

import { ErrorMessage as Err, ValidationLimitation } from 'Shared/constants'
import { FormFields } from './form-fields'

const minUsernameLength = 3

export const ValidationSchema = Yup
  .object()
  .shape({
    [FormFields.withUsername]: Yup
      .string()
      .trim()
      .required(Err.Required)
      .min(minUsernameLength, Err.NotLessSymbolsThan(minUsernameLength))
      .max(ValidationLimitation.MaxStringLength, Err.NotMoreSymbolsThan(ValidationLimitation.MaxStringLength)),
    [FormFields.allowEdit]: Yup.boolean(),
  })
