import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { match } from 'ts-pattern'
import { useHistory } from 'react-router-dom'

import { PricingTier, IProfileService, SubscriptionStatus, RenewalPeriod } from 'Services/profile'
import { container, Service } from 'Services/container'
import { ContentBlock } from 'Shared/components'
import { Routes } from 'Shared/constants'
import { useTypedDrawer } from 'Shared/hooks'
import { SettingsContext } from 'App/Settings'
import { useAuthWithDemo } from 'Demo'
import { useSnackbar } from 'Snackbar'
import {
  PaidSubscription,
  CancelledSubscription,
  NoSubscription,
  LifetimeSubscription,
  AdvisorySubscription,
  PendingSubscription,
  NonRenewableSubscription,
  PaymentVerification
} from './Subscription'
import { CancelSubscriptionDialog } from './CancelSubscriptionDialog'

interface Props {
  onClose: () => void
}

export const PaymentSettingsSection: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const { profile, manageSettings: { refreshProfile } } = useContext(SettingsContext)
  const { getAccessTokenWithDemo } = useAuthWithDemo()
  const { enqueueError, enqueueSuccess } = useSnackbar()
  const cancelSubscriptionDialog = useTypedDrawer<Date>()

  const showCancelSubscriptionDialog = () => {
    const nextRenewalDate = new Date(profile.subscription!.paidTillDateTime)
    cancelSubscriptionDialog.open(nextRenewalDate)
  }

  const cancelSubscription = async () => {
    const profileService = container.resolve<IProfileService>(Service.ProfileService)
    const token = await getAccessTokenWithDemo()

    try {
      await profileService.cancelSubscription(token)
      enqueueSuccess('app.navbar.settings.subscription.cancel-success')
      await refreshProfile()
    } catch (error) {
      enqueueError('app.navbar.settings.subscription.cancel-failure')
    }
  }

  const openPricingPage = () => {
    history.push(Routes.PRICING)
    onClose()
  }

  return (
    <ContentBlock title={t('app.navbar.settings.subscription.title')}>
      {match(profile)
        .with(
          { subscription: { status: SubscriptionStatus.Paid, renewalPeriod: RenewalPeriod.None } },
          () => <LifetimeSubscription />)
        .with(
          { isAdvisor: true },
          { isAdvisoryClient: true },
          profile => <AdvisorySubscription organizationName={profile?.organization?.name ?? ''} />)
        .with(
          { subscription: { status: SubscriptionStatus.PaymentVerification }, tier: PricingTier.Premium },
          () => <PaymentVerification />)
        .with(
          { subscription: { status: SubscriptionStatus.Paid, renewable: false }, tier: PricingTier.Premium },
          ({ subscription }) => <NonRenewableSubscription validTillDate={new Date(subscription.paidTillDateTime)} />)
        .with(
          { subscription: { status: SubscriptionStatus.Paid }, tier: PricingTier.Premium },
          ({ subscription }) => (
            <PaidSubscription
              nextBillingDate={new Date(subscription.paidTillDateTime)}
              inGracePeriod={subscription.inGracePeriod}
              onCancelSubscriptionClick={showCancelSubscriptionDialog}
            />))
        .with(
          { subscription: { status: SubscriptionStatus.Paid, renewable: true }, tier: PricingTier.Free },
          () => <PendingSubscription onCancelSubscriptionClick={showCancelSubscriptionDialog} />)
        .with(
          { subscription: null },
          () => <NoSubscription onSubscribeClick={openPricingPage} />)
        .with(
          { subscription: { status: SubscriptionStatus.CancellationRequested }, tier: PricingTier.Premium },
          ({ subscription }) => (
            <CancelledSubscription
              validUntilDate={new Date(subscription.paidTillDateTime)}
              onRenewSubscriptionClick={openPricingPage}
            />))
        .otherwise(
          () => <NoSubscription onSubscribeClick={openPricingPage} />)
      }

      {cancelSubscriptionDialog.value && (
        <CancelSubscriptionDialog
          isOpen={cancelSubscriptionDialog.isOpen}
          close={cancelSubscriptionDialog.close}
          validTill={cancelSubscriptionDialog.value}
          onConfirmCancelSubscription={cancelSubscription}
        />
      )}
    </ContentBlock>
  )
}
