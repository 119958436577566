import React from 'react'
import { Stack } from '@mui/material'

import { SubTextSecondary2 } from 'Shared/components/Typography'
import { Tag } from 'Shared/components/Tag'
import { useTranslation } from 'react-i18next'

interface Props {
  options: { label: string, value: string }[]
  selectedValue: string
  setSelectedValue: (value: string) => void
}

export const TaskFilter: React.FC<Props> = ({ options, selectedValue, setSelectedValue }) => {
  const { t } = useTranslation()

  return (
    <Stack direction="row" flexWrap="wrap" alignItems="center" rowGap={1} columnGap={1}>
      <SubTextSecondary2>
        {t('common.actions.display')}:
      </SubTextSecondary2>
      {options.map(option => (
        <Tag
          key={option.value}
          label={option.label}
          active={option.value === selectedValue}
          onClick={() => setSelectedValue(option.value)}
          disabled={options.length === 1}
          size="small"
        />
      ))}
    </Stack>
  )
}
