import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Button, ButtonTypeMap } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { GoogleTagManagerEvent } from 'Shared/constants'

interface Props {
  size?: ButtonTypeMap['props']['size']
}

export const LoginButton: React.FC<Props> = ({ size = 'medium' }) => {
  const { loginWithRedirect } = useAuth0()
  const { t } = useTranslation()

  const login = async () =>{
    window.dataLayer.push({ event: GoogleTagManagerEvent.login })
    await loginWithRedirect()
  }

  return (
    <Button
      onClick={login}
      data-testid="loginButton"
      variant="contained"
      size={size}
    >
      {t('app.navbar.login')}
    </Button>
  )
}
