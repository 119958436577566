import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Dialog, DialogTitle, Alert, DialogActions, Button } from '@mui/material'

interface Props {
  isOpen: boolean
  close: () => void
  validTill: Date
  onConfirmCancelSubscription: () => void
}

export const CancelSubscriptionDialog: React.FC<Props> = ({ isOpen, close, validTill, onConfirmCancelSubscription }) => {
  const { t } = useTranslation()

  const handleConfirm = () => {
    onConfirmCancelSubscription()
    close()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="dialog-cancel-subscription"
    >
      <DialogTitle id="dialog-cancel-subscription">
        {t('app.navbar.settings.subscription.cancel-title')}
      </DialogTitle>

      <Alert severity="error" sx={{ px: 3 }}>
        <Trans
          i18nKey="app.navbar.settings.subscription.cancel-description"
          values={{ date: validTill }}
        />
      </Alert>

      <DialogActions sx={{ px: 3 }}>
        <Button onClick={close}>{t('common.actions.return')}</Button>
        <Button color="error" onClick={handleConfirm}>{t('common.actions.confirm')}</Button>
      </DialogActions>
    </Dialog>
  )
}
