import { Alert, Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { TextPrimary } from 'Shared/components'

interface Props {
  isOpen: boolean
  close: () => void
  advisorUsername: string
  sharingKey: string
  onConfirmRevokeSharing: (sharingKey: string) => void
}

export const RevokeSharingDialog: React.FC<Props> = ({
  isOpen,
  close,
  advisorUsername,
  sharingKey,
  onConfirmRevokeSharing
}) => {
  const { t } = useTranslation()

  const confirm = () => {
    onConfirmRevokeSharing(sharingKey)
    close()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="dialog-revoke-sharing"
    >
      <DialogTitle id="dialog-revoke-sharing">
        {t('app.navbar.settings.sharingSettings.revokeSharing-title')}
      </DialogTitle>

      <Alert severity="info" sx={{ px: 3 }}>
        <TextPrimary>
          <Trans
            i18nKey="app.navbar.settings.sharingSettings.revokeSharing-description"
            values={{ username: advisorUsername }}
          />
        </TextPrimary>
      </Alert>

      <DialogActions sx={{ px: 3 }}>
        <Button onClick={close}>{t('common.actions.return')}</Button>
        <Button color="error" onClick={confirm}>{t('common.actions.confirm')}</Button>
      </DialogActions>
    </Dialog>
  )
}
