import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import { BaseItemDropdown } from './BaseItemDropdown'

interface SortingButtonProps {
  label: string
  onClick: (event: any) => void
  expanded: boolean
  selectedLabel?: string
  disabled?: boolean
}

export const SortingButton: React.FC<SortingButtonProps> = ({ label, onClick, expanded, selectedLabel, disabled = false }) => (
  <BaseItemDropdown onClick={onClick} disabled={disabled}>
    {label}
    {selectedLabel && `: ${selectedLabel}`}
    {expanded ? <ExpandLessIcon fontSize="inherit" /> : <ExpandMoreIcon fontSize="inherit" />}
  </BaseItemDropdown>
)
