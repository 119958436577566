import LockPersonIcon from '@mui/icons-material/LockPerson'
import { Box, Stack } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonLink, SubTextSecondary1, TextPrimary } from 'Shared/components'

interface Props {
  onShare: () => void
  ableToShare: boolean
}

export const ProfileSharingEmptyItem: React.FC<Props> = ({ onShare, ableToShare }) => {
  const { t } = useTranslation()
  const [ sharing, setSharing ] = React.useState(false)

  const handleShareClick = () => {
    setSharing(true)
    onShare()
  }

  return (
    <Stack direction="row">
      <LockPersonIcon fontSize="small" sx={{ mr: 1 }} />
      <Box>
        <TextPrimary>{t('app.navbar.settings.sharingSettings.notShared')}</TextPrimary>

        {ableToShare ? (
          <SubTextSecondary1>
            <ButtonLink onClick={handleShareClick} disabled={sharing}>
              {t('app.navbar.settings.sharingSettings.action-share')}
            </ButtonLink>
          </SubTextSecondary1>
        ) : (
          <SubTextSecondary1>
            {t('app.navbar.settings.sharingSettings.notShared-notAllowed')}
          </SubTextSecondary1>
        )}
      </Box>
    </Stack>
  )
}
