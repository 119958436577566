import React from 'react'
import { Stack, LinearProgress, Container } from '@mui/material'

export const ApplicationLoadingOverlay: React.FC = () => (
  <Container sx={{ flex: 1, px: 8 }} maxWidth="xs">
    <Stack height="100vh" justifyContent="center">
      <LinearProgress />
    </Stack>
  </Container>
)
