import { useAuth0 } from '@auth0/auth0-react'
import DonutLargeIcon from '@mui/icons-material/DonutLarge'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import HistoryIcon from '@mui/icons-material/History'
import LogoutIcon from '@mui/icons-material/Logout'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import { Box, Button, Divider, Menu, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { SettingsContext } from 'App/Settings'
import { Permission, useAuthorization } from 'Integration/authorization'
import { PortfolioAllocationType } from 'Services/portfolio'
import { CaretDownIcon, CaretUpIcon } from 'Shared/assets'
import { GoogleTagManagerEvent } from 'Shared/constants'
import { ProfileMenuItem } from './ProfileMenuItem'
import { ProfileSettings } from './ProfileSettings'
import { UserAvatar } from './UserAvatar'
import { useExportAccount } from './hooks/useExportAccount'

interface Props {
  closeAppMenu?: (event: React.KeyboardEvent | React.MouseEvent) => void
}

const AvatarWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center'
}))

const StyledButton = styled(Button)(({ theme }) => ({
  cursor: 'pointer',
  ':hover': {
    backgroundColor: 'unset'
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: 'unset'
  }
}))

const CaretWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  },
  marginLeft: theme.spacing(1)
}))

export const UserMenu = ({ closeAppMenu }: Props) => {
  const { t } = useTranslation()
  const { user, logout } = useAuth0()
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null)
  const { exportAccount } = useExportAccount()
  const settings = useContext(SettingsContext)
  const authorization = useAuthorization()

  const isMenuOpened = !!anchorEl

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    if (closeAppMenu) {
      closeAppMenu(event)
    }
  }
  const handleMenuClose = () => setAnchorEl(null)
  const handleLogout = () => {
    sessionStorage.clear()
    settings.manageSettings.setHiddenMode(true)
    window.dataLayer.push({ event: GoogleTagManagerEvent.logout })
    window.customerly.logout()
    window.customerly.update()
    logout({ returnTo: window.location.origin })
  }

  return (
    <>
      <AvatarWrapper>
        <Tooltip title={t('app.navbar.settings.tooltip')}>
          <StyledButton
            disableRipple
            size="small"
            onClick={handleAvatarClick}
          >
            <UserAvatar user={user} />
            <CaretWrapper>
              {isMenuOpened ? <CaretUpIcon width={10} height={5} /> : <CaretDownIcon width={10} height={5} />}
            </CaretWrapper>
          </StyledButton>
        </Tooltip>
      </AvatarWrapper>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpened}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        disableScrollLock
      >
        <ProfileMenuItem
          onClick={settings.manageSettings.settings.open}
          icon={<ManageAccountsIcon fontSize="small" />}
          title={t('app.navbar.settings.name')}
        />
        <ProfileMenuItem
          onClick={exportAccount}
          icon={<FileDownloadIcon fontSize="small" />}
          title={t('app.navbar.export')}
        />
        <ProfileMenuItem
          onClick={() => settings.manageSettings.assetAllocation.open(PortfolioAllocationType.Class)}
          icon={<DonutLargeIcon fontSize="small" />}
          title={t('app.navbar.asset-allocation.name')}
        />
        {authorization.hasAccess(Permission.ViewActionsHistory) && (
          <ProfileMenuItem
            onClick={settings.manageSettings.myHistory.open}
            icon={<HistoryIcon fontSize="small" />}
            title={t('app.history.name')}
          />
        )}
        <Divider />
        <ProfileMenuItem
          onClick={handleLogout}
          icon={<LogoutIcon fontSize="small" />}
          title={t('app.navbar.logout')}
        />
      </Menu>

      <ProfileSettings
        user={user}
        isOpen={settings.settingsDrawerOpened}
        handleClose={settings.manageSettings.settings.close}
        handleLogout={handleLogout}
      />
    </>
  )
}
