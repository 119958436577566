import React, { PropsWithChildren, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { useAuthorization, Permission } from 'Integration/authorization'
import { SettingsContext } from 'App/Settings'
import { Routes } from 'Shared/constants'
import { ApplicationLoadingOverlay } from 'Shared/components'
import { useAuthWithDemo } from 'Demo'
import { AdvisorPortalActions } from './advisorPortal-actions'
import { AdvisorPortalSelectors } from './advisorPortal-selectors'

export const RouteResolver: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch()
  const { getAccessTokenWithDemo } = useAuthWithDemo()
  const authorization = useAuthorization()
  const { essentialsLoaded } = useContext(SettingsContext)

  const advisorClients = useSelector(AdvisorPortalSelectors.advisoryClients)

  React.useEffect(() => {
    const loadAdvisorPortal = async () => {
      const accessToken = await getAccessTokenWithDemo()
      dispatch(AdvisorPortalActions.loadClients(accessToken))
    }

    if (essentialsLoaded && authorization.hasAccess(Permission.ViewAdvisorPortal)) {
      loadAdvisorPortal()
    }
  }, [ essentialsLoaded, authorization.authorizationProfile ])

  if (essentialsLoaded && !authorization.hasAccess(Permission.ViewAdvisorPortal)) {
    return <Redirect to={Routes.HOME} />
  }

  if (!advisorClients.initialLoadCompleted) {
    return <ApplicationLoadingOverlay />
  }

  return <>{children}</>
}
