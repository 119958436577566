import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { CrossAppDrawers } from 'App/CrossAppDrawers'
import { Router } from 'App/Router'
import { SettingsProvider, useSettings } from 'App/Settings'
import { UnderMaintenance } from 'App/StaticPages'
import { DemoProvider, useDemoSettings } from 'Demo'
import { ScrollToTop, ApplicationLoadingOverlay } from 'Shared/components'
import { SnackbarManager } from 'Snackbar'

export const App: React.FC = () => {
  const { isLoading: isAuth0Loading, user, isAuthenticated } = useAuth0()
  const { pathname } = useLocation()
  const { i18n } = useTranslation()

  const demo = pathname.includes('/demo/')

  const settingsContext = useSettings(demo)
  const demoSettingsContext = useDemoSettings(settingsContext)

  React.useEffect(() => {
    if (isAuthenticated && user) {
      window.customerly.update({
        user_id: user.sub,
        name: user.name,
        email: user.email,
      })
    } else {
      window.customerly.update({
        user_id: null,
        name: null,
        email: null,
      })
    }
  }, [ isAuthenticated, user ])

  const showLoading = isAuth0Loading || !i18n.isInitialized
  if (showLoading) {
    return <ApplicationLoadingOverlay />
  }

  const maintenance = process.env.REACT_APP_MAINTENANCE === 'enabled'
  if (maintenance) {
    return <UnderMaintenance />
  }

  return (
    <SettingsProvider value={demo ? demoSettingsContext : settingsContext}>
      <DemoProvider value={{ demo }}>
        <ScrollToTop>
          <Router />
        </ScrollToTop>
        <SnackbarManager />
        <CrossAppDrawers />
      </DemoProvider>
    </SettingsProvider>
  )
}
