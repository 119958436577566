import { AdvisoryClient, AdvisoryClientTask, UpdateAdvisoryClientRequest } from 'Services/advisor'
import { HistoryItem } from 'Services/history'
import { Organization } from 'Services/organization'
import { Sharing } from 'Services/sharing'
import { ITypedAction, IActionWithToken, IAction, ITypedActionWithToken } from 'Shared/types'
import { ClientsFilterState, HistoryFilterState } from './advisorPortal-types'
import { AdvisorPortalActionTypes } from './advisorPortal-constants'

const loadClients = (token: string): IActionWithToken => ({
  type: AdvisorPortalActionTypes.GET_ADVISOR_PORTAL_CLIENTS,
  token,
})

const loadClientsSuccess = (clients: AdvisoryClient[]): ITypedAction<AdvisoryClient[]> => ({
  type: AdvisorPortalActionTypes.GET_ADVISOR_PORTAL_CLIENTS_SUCCESS,
  payload: clients,
})

const loadClientsFailure = (): IAction => ({
  type: AdvisorPortalActionTypes.GET_ADVISOR_PORTAL_CLIENTS_FAILURE,
})

const revokeClient = (token: string, sharingKey: string): ITypedActionWithToken<string> => ({
  type: AdvisorPortalActionTypes.REVOKE_ADVISOR_PORTAL_CLIENT,
  payload: sharingKey,
  token,
})

const revokeClientSuccess = (sharingKey: string): ITypedAction<string> => ({
  type: AdvisorPortalActionTypes.REVOKE_ADVISOR_PORTAL_CLIENT_SUCCESS,
  payload: sharingKey,
})

const editClientSuccess = (clientKey: string, client: UpdateAdvisoryClientRequest): ITypedAction<{ clientKey: string, client: UpdateAdvisoryClientRequest }> => ({
  type: AdvisorPortalActionTypes.EDIT_ADVISOR_PORTAL_CLIENT_SUCCESS,
  payload: { clientKey, client },
})

const loadSharings = (token: string): IActionWithToken => ({
  type: AdvisorPortalActionTypes.GET_ADVISOR_PORTAL_SHARINGS,
  token,
})

const loadSharingsSuccess = (sharings: Sharing[]): ITypedAction<Sharing[]> => ({
  type: AdvisorPortalActionTypes.GET_ADVISOR_PORTAL_SHARINGS_SUCCESS,
  payload: sharings,
})

const loadClientsHistory = (token: string): IActionWithToken => ({
  type: AdvisorPortalActionTypes.GET_ADVISOR_CLIENTS_HISTORY,
  token,
})

const declineSharing = (token: string, sharingKey: string): ITypedActionWithToken<string> => ({
  type: AdvisorPortalActionTypes.DECLINE_ADVISOR_PORTAL_SHARING,
  payload: sharingKey,
  token,
})

const removeSharing = (sharingKey: string): ITypedAction<string> => ({
  type: AdvisorPortalActionTypes.REMOVE_ADVISOR_PORTAL_SHARING,
  payload: sharingKey,
})

const acceptSharing = (token: string, sharingKey: string): ITypedActionWithToken<string> => ({
  type: AdvisorPortalActionTypes.ACCEPT_ADVISOR_PORTAL_SHARING,
  payload: sharingKey,
  token,
})

const loadClientsHistorySuccess = (clientsHistory: HistoryItem[]): ITypedAction<HistoryItem[]> => ({
  type: AdvisorPortalActionTypes.GET_ADVISOR_CLIENTS_HISTORY_SUCCESS,
  payload: clientsHistory,
})

const loadClientsHistoryFailure = (): IAction => ({
  type: AdvisorPortalActionTypes.GET_ADVISOR_CLIENTS_HISTORY_FAILURE,
})

const revokeClientHistorySuccess = (username: string): ITypedAction<string> => ({
  type: AdvisorPortalActionTypes.REVOKE_ADVISOR_CLIENT_HISTORY_SUCCESS,
  payload: username,
})

const loadAdvisorPortalOrganization = (token: string): IActionWithToken => ({
  type: AdvisorPortalActionTypes.GET_ADVISOR_PORTAL_ORGANIZATION,
  token,
})

const loadAdvisorPortalOrganizationSuccess = (organization: Organization): ITypedAction<Organization> => ({
  type: AdvisorPortalActionTypes.GET_ADVISOR_PORTAL_ORGANIZATION_SUCCESS,
  payload: organization,
})

const loadAdvisorPortalOrganizationFailure = (): IAction => ({
  type: AdvisorPortalActionTypes.GET_ADVISOR_PORTAL_ORGANIZATION_FAILURE,
})

const useOrganizationLicence = (): IAction => ({
  type: AdvisorPortalActionTypes.USE_ADVISOR_PORTAL_ORGANIZATION_LICENCE,
})

const revokeOrganizationLicence = (): IAction => ({
  type: AdvisorPortalActionTypes.REVOKE_ADVISOR_PORTAL_ORGANIZATION_LICENCE,
})

const changeClientsFilter = (filterState: ClientsFilterState): ITypedAction<ClientsFilterState> => ({
  type: AdvisorPortalActionTypes.SET_ADVISOR_PORTAL_CLIENTS_FILTER,
  payload: filterState,
})

const changeHistoryFilter = (filterState: HistoryFilterState): ITypedAction<HistoryFilterState> => ({
  type: AdvisorPortalActionTypes.SET_ADVISOR_PORTAL_HISTORY_FILTER,
  payload: filterState,
})

const createTask = (clientKey: string, task: AdvisoryClientTask): ITypedAction<{ clientKey: string, task: AdvisoryClientTask}> => ({
  type: AdvisorPortalActionTypes.CREATE_ADVISOR_PORTAL_CLIENT_TASK,
  payload: {
    clientKey,
    task
  }
})

const updateTask = (clientKey: string, task: AdvisoryClientTask): ITypedAction<{ clientKey: string, task: AdvisoryClientTask}> => ({
  type: AdvisorPortalActionTypes.UPDATE_ADVISOR_PORTAL_CLIENT_TASK,
  payload: {
    clientKey,
    task
  }
})

const deleteTask = (clientKey: string, taskKey: string): ITypedAction<{ clientKey: string, taskKey: string }> => ({
  type: AdvisorPortalActionTypes.DELETE_ADVISOR_PORTAL_CLIENT_TASK,
  payload: {
    clientKey,
    taskKey
  }
})

export const AdvisorPortalActions = {
  loadClients,
  loadClientsSuccess,
  loadClientsFailure,
  revokeClient,
  revokeClientSuccess,
  editClientSuccess,

  loadSharings,
  loadSharingsSuccess,
  loadClientsHistoryFailure,
  declineSharing,
  removeSharing,
  acceptSharing,

  loadClientsHistory,
  loadClientsHistorySuccess,
  revokeClientHistorySuccess,

  loadAdvisorPortalOrganization,
  loadAdvisorPortalOrganizationSuccess,
  loadAdvisorPortalOrganizationFailure,
  useOrganizationLicence,
  revokeOrganizationLicence,

  changeClientsFilter,
  changeHistoryFilter,

  createTask,
  updateTask,
  deleteTask,
}
