import React from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'

import { SubTextSecondary1 } from 'Shared/components'
import { SubscriptionPlanContainer } from './SubscriptionPlanContainer'

const WarningText = styled(SubTextSecondary1)(({ theme }) => ({
  color: theme.palette.warning.main
}))

export const PaymentVerification: React.FC = () => {
  const { t } = useTranslation()

  return (
    <SubscriptionPlanContainer isPremium>
      <WarningText>
        {t('app.navbar.settings.subscription.paymentVerification')}
      </WarningText>
    </SubscriptionPlanContainer>
  )
}
