import type { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { Dictionary } from 'Shared/types'
import { PortfolioAllocationType, IPortfolioService, ChangeSunburstAllocation } from './portfolio-types'
import { PortfolioService } from './portfolio-service'

export class PortfolioSharedService extends PortfolioService implements IPortfolioService {
  private readonly sharingKey: string

  public constructor(sharingKey: string) {
    super()
    this.sharingKey = sharingKey
  }

  public getAssetAllocation = (token: string, type: PortfolioAllocationType) =>
    this.getAssetAllocationSharedWithMe(token, this.sharingKey, type)
  public updateAssetAllocation = (token: string, type: PortfolioAllocationType, request: Dictionary<number>) =>
    this.updateAssetAllocationSharedWithMe(token, this.sharingKey, type, request)
  public changeSunburstAllocation = (token: string, firstLevelProperty: string, secondLevelProperty: string, request: ChangeSunburstAllocation) =>
    this.changeSharedSunburstAllocation(token, this.sharingKey, firstLevelProperty, secondLevelProperty, request)
  public resetSunburstAllocation = (token: string, firstLevelProperty: string, secondLevelProperty: string) =>
    this.resetSharedSunburstAllocation(token, this.sharingKey, firstLevelProperty, secondLevelProperty)
  public importPortfolio = (token: string, file: FormData) =>
    this.sharedImportPortfolio(token, this.sharingKey, file)

  private getAssetAllocationSharedWithMe = (
    token: string,
    sharingKey: string,
    type: PortfolioAllocationType
  ) => axios
    .get(`/portfolio/shared-with-me/${sharingKey}/allocations/${type}`, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  private updateAssetAllocationSharedWithMe = (
    token: string,
    sharingKey: string,
    type: PortfolioAllocationType,
    request: Dictionary<number>
  ) => axios
    .patch(`/portfolio/shared-with-me/${sharingKey}/allocations/${type}`, request, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public changeSharedSunburstAllocation = (
    token: string,
    sharingKey: string,
    firstLevelProperty: string,
    secondLevelProperty: string,
    request: ChangeSunburstAllocation
  ) => axios
    .patch(`/portfolio/shared-with-me/${sharingKey}/sunburst-allocation/${firstLevelProperty}/${secondLevelProperty}`, request, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public resetSharedSunburstAllocation = (
    token: string,
    sharingKey: string,
    firstLevelProperty: string,
    secondLevelProperty: string
  ) => axios
    .delete(`/portfolio/shared-with-me/${sharingKey}/sunburst-allocation/${firstLevelProperty}/${secondLevelProperty}`, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public sharedImportPortfolio = (
    token: string,
    sharingKey: string,
    file: FormData
  ) => axios
    .post(`/portfolio/shared-with-me/${sharingKey}/import`, file, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)
}
