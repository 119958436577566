import React, { useContext } from 'react'
import { Box, Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import { match } from 'ts-pattern'

import { ContentPaper, NoDataOverlay, NoOptionsOverlay } from 'Shared/components'
import { GroupedHistoryList } from 'Shared/components/History'
import { DataPageState } from 'Shared/constants'
import { Dictionary } from 'Shared/types'
import { SettingsContext } from 'App/Settings'
import { AdvisorPortalSelectors } from 'AdvisorPortal/advisorPortal-selectors'
import { LoadingState } from 'AdvisorPortal/shared'
import { queryFilter, managedByFilter } from './ClientsHistory-filter'
import { ClientsHistoryHeader } from './ClientsHistoryHeader'

export const ClientsHistory: React.FC = () => {
  const clientsHistory = useSelector(AdvisorPortalSelectors.clientsHistory)
  const historyFilter = useSelector(AdvisorPortalSelectors.historyFilter)
  const { list: advisoryClients } = useSelector(AdvisorPortalSelectors.advisoryClients)
  const { manageSettings } = useContext(SettingsContext)

  const clientsSharingMap: Dictionary<string> = React.useMemo(
    () => advisoryClients
      .reduce((acc, client) => ({ ...acc, [client.username]: client.sharingKey }), {}),
    [ advisoryClients ])

  const clientAdvisorMap: Dictionary<string> = React.useMemo(
    () => advisoryClients
      .reduce((acc, client) => ({ ...acc, [client.username]: client.advisorUsername }), {}),
    [ advisoryClients ])

  const filteredClientsHistory = React.useMemo(
    () => {
      const searchQueryFunction = queryFilter.bind(null, historyFilter.searchQuery.toSearchTerm())
      const managedByFunction = managedByFilter.bind(null, historyFilter.managedBy, clientAdvisorMap)

      return clientsHistory
        .list
        .filter(managedByFunction)
        .filter(searchQueryFunction)
    },
    [ clientsHistory.list, historyFilter, clientAdvisorMap ])

  const dataState = React.useMemo(
    () => {
      const hasLoadedItems = clientsHistory.initialLoadCompleted && clientsHistory.list.length !== 0
      const hasFilteredItems = hasLoadedItems && filteredClientsHistory.length !== 0

      if (clientsHistory.isLoading && !clientsHistory.initialLoadCompleted) return DataPageState.Loading
      if (!hasLoadedItems) return DataPageState.NoData
      if (!hasFilteredItems) return DataPageState.NoFilteredOptions

      return DataPageState.HasData
    },
    [ clientsHistory.isLoading, clientsHistory.initialLoadCompleted, clientsHistory.list, filteredClientsHistory ])

  return (
    <ContentPaper component={Box} elevation={0}>
      <ClientsHistoryHeader />

      <Stack sx={{ overflowY: 'scroll', maxHeight: '65vh', mt: 3 }} spacing={3}>
        {match(dataState)
          .with(DataPageState.Loading, () => <LoadingState numberOfItems={5} />)
          .with(DataPageState.NoData, () => <NoDataOverlay />)
          .with(DataPageState.NoFilteredOptions, () => <NoOptionsOverlay />)
          .with(DataPageState.HasData, () => (
            <GroupedHistoryList
              historyItems={filteredClientsHistory}
              clientSharingMap={clientsSharingMap}
              onProfileClicked={sharingKey => manageSettings.sharedHistory.open(sharingKey)}
            />
          ))
          .run()
        }
      </Stack>
    </ContentPaper>
  )
}
