import React from 'react'

import { ConfiguredNumberFormat } from 'Shared/components/ConfiguredNumberFormat'
import { CustomTextField } from './CustomTextField'

interface Props {
  label: string
  value: any
  name?: string
  onChange: (value: any) => void
  placeholder: string
  prefix?: string
  required?: boolean
  error?: any
  extraProps?: any
}

export const CustomNumberField: React.FC<Props> = ({ label, value, name, onChange, placeholder, prefix, required, error, extraProps }) => (
  <CustomTextField
    name={name}
    value={value}
    onChange={onChange}
    label={label}
    placeholder={placeholder}
    error={error}
    inputComponent={ConfiguredNumberFormat}
    prefixSymbol={prefix}
    inputProps={{
      ...extraProps
    }}
    required={required}
  />
)
