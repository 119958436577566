declare global {
  // eslint-disable-next-line id-denylist
  interface String {
    toSearchTerm() : string
  }
}

Object.defineProperty(String.prototype, 'toSearchTerm', {
  value: function toSearchTerm() {
    return this.toLowerCase().replaceAll(/,/g, '.')
  },
  writable: true,
  configurable: true,
})

export {}
