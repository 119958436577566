import React from 'react'
import { withTranslation } from 'react-i18next'
import { Button } from '@mui/material'

import { Routes } from 'Shared/constants'
import { ClientErrorImg } from 'App/assets/ClientErrorImg'
import { StaticPage } from './StaticPage'
import { StaticPageTitle, StaticPageSubTitle } from './Typographies'

export const PaymentFailed = withTranslation()(({ t }) => {
  const handleNavigateToProfile = () => {
    window.location.href = Routes.HOLDINGS
  }

  return (
    <StaticPage
      title={t('app.navbar.routes.paymentFailed')}
      messageComponent={(
        <>
          <StaticPageTitle mb={2.5}>
            {t('app.static.paymentFailed.title')}
          </StaticPageTitle>
          <StaticPageSubTitle mb={1.5}>
            {t('app.static.paymentFailed.subTitle')}
          </StaticPageSubTitle>
          <StaticPageSubTitle mb={3.5}>
            {t('app.static.paymentFailed.subTitle2')}
          </StaticPageSubTitle>
          <Button onClick={handleNavigateToProfile} variant="outlined">
            {t('app.navbar.routes.backToApp')}
          </Button>
        </>
      )}
      imgComponent={<ClientErrorImg width="100%" height="100%" />}
    />
  )
})
