import type { SnackbarKey } from 'notistack'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import * as snackbarActionCreators from 'Snackbar/snackbar-actions'
import * as snackbarSelectors from 'Snackbar/snackbar-selectors'
import type { Snackbar } from '../snackbar-types'

let displayed: SnackbarKey[] = []

export const SnackbarManager = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const snackbars = useSelector(snackbarSelectors.getSnackbarsSelector)

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [ ...displayed, id ]
  }

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [ ...displayed.filter(key => id !== key) ]
  }
  React.useEffect(() => {
    snackbars.forEach(
      ({ key, message, options = {}, dismissed = false }: Snackbar) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key)
          return
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return

        // display snackbar using notistack
        enqueueSnackbar(t(message.key, message.param).toString(), {
          key,
          ...options,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey)
            }
          },
          onExited: (_, myKey) => {
            // remove this snackbar from redux store
            dispatch(snackbarActionCreators.removeSnackbar(myKey))
            removeDisplayed(myKey)
          },
        })

        // keep track of snackbars that we've displayed
        storeDisplayed(key)
      },
    )
  }, [ snackbars, closeSnackbar, enqueueSnackbar ])

  return null
}
