import { PortfolioAllocationType, AssetAllocation } from 'Services/portfolio'
import { ITypedActionWithToken, ITypedAction, IAction } from 'Shared/types'
import { AssetAllocationTypes } from './assetAllocation-constants'

const getAssetAllocation = (token: string, type: PortfolioAllocationType): ITypedActionWithToken<PortfolioAllocationType> => ({
  type: AssetAllocationTypes.GET_ASSET_ALLOCATION,
  token: token,
  payload: type
})

const getMyAssetAllocation = (token: string, type: PortfolioAllocationType): ITypedActionWithToken<PortfolioAllocationType> => ({
  type: AssetAllocationTypes.GET_MY_ASSET_ALLOCATION,
  token: token,
  payload: type
})

const getAssetAllocationSuccess = (payload: AssetAllocation): ITypedAction<AssetAllocation> => ({
  type: AssetAllocationTypes.GET_ASSET_ALLOCATION_SUCCESS,
  payload: payload
})

const getAssetAllocationFailure = (): IAction => ({
  type: AssetAllocationTypes.GET_ASSET_ALLOCATION_FAILURE
})

export const AssetAllocationActions = {
  getAssetAllocation,
  getMyAssetAllocation,
  getAssetAllocationSuccess,
  getAssetAllocationFailure,
}
