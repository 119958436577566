import React from 'react'
import { Button, Stack } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import { TextLarge } from 'Shared/components'

interface Props {
  promoCode: string
  onPromoCodeRemoved: () => void
}

export const ActivePromoCode: React.FC<Props> = ({ promoCode, onPromoCodeRemoved }) => {
  const { t } = useTranslation()

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <TextLarge mx={1} noWrap>
        <Trans i18nKey="pricing.promoCode.activeMessage" values={{ code: promoCode }} />
      </TextLarge>

      <Button
        variant="text"
        color="error"
        size="small"
        onClick={onPromoCodeRemoved}
      >
        {t('common.actions.remove')}
      </Button>
    </Stack>
  )
}
