import type { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { BaseService } from 'Shared/api'
import { IPlaidService, PlaidConnection } from './plaid-types'

export class PlaidService extends BaseService implements IPlaidService {
  public createLinkToken = (accessToken: string): Promise<string> => axios
    .post('/banking/plaid/link-token', {}, { headers: { ...super.buildAuthHeader(accessToken) } })
    .then((response: AxiosResponse) => response?.data)

  public createConnection = (accessToken: string, linkToken: string): Promise<string> => axios
    .post('/banking/plaid/connection', { payload: { publicToken: linkToken } }, { headers: { ...super.buildAuthHeader(accessToken) } })
    .then((response: AxiosResponse) => response?.data)

  public getConnection = (accessToken: string, connectionKey: string): Promise<PlaidConnection> => axios
    .get(`/banking/plaid/connection/${connectionKey}`, { headers: { ...super.buildAuthHeader(accessToken) } })
    .then((response: AxiosResponse) => response?.data)
}
