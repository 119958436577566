import React from 'react'
import { Alert, Button, Dialog, DialogActions, DialogTitle, Input, Box } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import { TextPrimary, TextSmaller2 } from 'Shared/components'

interface Props {
  isOpen: boolean
  handleClose: () => void
  handleConfirm: () => void
}

const DeleteAccountDialogBase: React.FC<Props> = ({ isOpen, handleClose, handleConfirm }) => {
  const { t } = useTranslation()

  const [ value, setValue ] = React.useState<string>('')
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value)
  const keyword = t('app.navbar.settings.deleteAccount-keyword')
  const isDisabled = value.trim() !== keyword

  const handleDialogClose = () => {
    handleClose()
    setValue('')
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleDialogClose}
      aria-labelledby="delete-account-dialog-title"
      aria-describedby="delete-account-dialog-description"
    >
      <DialogTitle id="delete-account-dialog-title">{t('app.navbar.settings.deleteAccount-title')}</DialogTitle>
      <Alert severity="error" sx={{ px: 3 }}>
        <TextPrimary mb={1}>{t('app.navbar.settings.deleteAccount-description')}</TextPrimary>
        <TextSmaller2 mb={2}>
          <small>
            <Trans i18nKey="app.navbar.settings.deleteAccount-finePrint" />
          </small>
        </TextSmaller2>
        <Box>
          <TextPrimary>{t('app.navbar.settings.dangerAction-confirmation', { keyword: keyword })}</TextPrimary>
          <Input data-testid="confirmDeleteInput" value={value} onChange={handleChange} color="error" />
        </Box>
      </Alert>
      <DialogActions sx={{ px: 3 }}>
        <Button onClick={handleDialogClose}>{t('common.actions.return')}</Button>
        <Button
          onClick={handleConfirm}
          color="error"
          disabled={isDisabled}
          data-testid="deleteAccountButton"
        >
          {t('app.navbar.settings.deleteAccount-approve')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const DeleteAccountDialog = React.memo(DeleteAccountDialogBase)
