import React from 'react'
import { Stack } from '@mui/material'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService'
import { useTranslation } from 'react-i18next'

import { container, Service } from 'Services/container'
import { IPortfolioService } from 'Services/portfolio'
import { IProfileService } from 'Services/profile'
import { useDrawer } from 'Shared/hooks'
import { ButtonLink, TextPrimary, ContentBlock } from 'Shared/components'
import { useErrorHandler } from 'Shared/hooks'
import { useAuthWithDemo } from 'Demo'
import { useSnackbar } from 'Snackbar'
import { DeleteAccountDialog } from './DeleteAccountDialog'
import { CleanDataDialog } from './CleanDataDialog'

interface Props {
  onLogout: () => void
}

export const DangerSettingsSection: React.FC<Props> = ({ onLogout }) => {
  const { t } = useTranslation()

  const { getAccessTokenWithDemo } = useAuthWithDemo()
  const { enqueueSuccess } = useSnackbar()
  const { handleError } = useErrorHandler()
  const deleteAccountDialog = useDrawer()
  const cleanDataDialog = useDrawer()

  const handleDeleteAccount = async () => {
    const profileService = container.resolve<IProfileService>(Service.ProfileService)
    const token = await getAccessTokenWithDemo()

    try {
      await profileService.deleteAccount(token)
      enqueueSuccess('app.navbar.settings.deleteAccount-success')
      onLogout()
    } catch (error) {
      handleError(error, 'app.navbar.settings.deleteAccount-failure')
    }
  }

  const handleCleanData = async () => {
    const portfolioService = container.resolve<IPortfolioService>(Service.PortfolioService)
    const token = await getAccessTokenWithDemo()

    try {
      await portfolioService.deletePortfolios(token)
      enqueueSuccess('app.navbar.settings.cleanData-success')
      window.location.reload()
    } catch (error) {
      handleError(error, 'app.navbar.settings.cleanData-failure')
    }
  }

  return (
    <ContentBlock title={t('app.navbar.settings.dangerZoneSettings-title')}>
      <Stack spacing={2}>
        <ButtonLink onClick={cleanDataDialog.open}>
          <Stack direction="row" spacing={1}>
            <LocalLaundryServiceIcon fontSize="small" color="error" />
            <TextPrimary color="error">{t('app.navbar.settings.cleanData-action')}</TextPrimary>
          </Stack>
        </ButtonLink>

        <ButtonLink onClick={deleteAccountDialog.open}>
          <Stack direction="row" spacing={1}>
            <DeleteOutlineOutlinedIcon fontSize="small" color="error" />
            <TextPrimary color="error">{t('app.navbar.settings.deleteAccount-action')}</TextPrimary>
          </Stack>
        </ButtonLink>
      </Stack>

      {cleanDataDialog.isOpen && (
        <CleanDataDialog
          isOpen={cleanDataDialog.isOpen}
          handleClose={cleanDataDialog.close}
          handleConfirm={handleCleanData}
        />
      )}

      {deleteAccountDialog.isOpen && (
        <DeleteAccountDialog
          isOpen={deleteAccountDialog.isOpen}
          handleClose={deleteAccountDialog.close}
          handleConfirm={handleDeleteAccount}
        />
      )}
    </ContentBlock>
  )
}
