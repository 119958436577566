import { useDrawer } from 'Shared/hooks'
import React from 'react'
import { ConfirmCloseDrawerModal } from './ConfirmCloseDrawerModal'
import { SideDrawer, SideDrawerProps } from './SideDrawer'

interface Props extends SideDrawerProps {
  formDirty: boolean
  resetFormDirty: () => void
}

export const DrawerWithCloseConfirmation: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  helpText,
  isOpen,
  children,
  isLoading,
  formDirty,
  resetFormDirty,
  open,
  close,
}) => {
  const confirmationModal = useDrawer()

  const closeConfirmationModal = () => {
    confirmationModal.close()
  }

  const confirmCloseConfirmationModal = () => {
    close?.()
    confirmationModal.close()
    resetFormDirty()
  }

  const closeWithConfirmation = () => {
    if (formDirty) {
      confirmationModal.open()
    } else {
      close?.()
    }
  }

  return (
    <>
      <SideDrawer
        title={title}
        helpText={helpText}
        isOpen={isOpen}
        isLoading={isLoading}
        open={open}
        close={closeWithConfirmation}
      >
        {children}
      </SideDrawer>

      <ConfirmCloseDrawerModal
        isOpen={confirmationModal.isOpen}
        close={closeConfirmationModal}
        onCancel={closeConfirmationModal}
        onConfirm={confirmCloseConfirmationModal}
      />
    </>
  )
}
