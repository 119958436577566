import React from 'react'
import { Stack } from '@mui/material'

import { PromoCode } from './PromoCode'
import * as S from './styles'

interface Props {
  title: string
  promoActive?: boolean
}

export const PricingCardSectionHeader: React.FC<Props> = ({ title, promoActive }) => (
  <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
    <S.PricingPlanText>{title}</S.PricingPlanText>
    {promoActive && <PromoCode />}
  </Stack>
)
