import React from 'react'
import { Trans } from 'react-i18next'

import { SubTextSecondary1 } from 'Shared/components'
import { SubscriptionPlanContainer } from './SubscriptionPlanContainer'

interface Props {
  organizationName: string
}

export const AdvisorySubscription: React.FC<Props> = ({ organizationName }) => (
  <SubscriptionPlanContainer isPremium>
    <SubTextSecondary1>
      <Trans
        i18nKey="app.navbar.settings.subscription.underOrganization"
        values={{ organization: organizationName }}
      />
    </SubTextSecondary1>
  </SubscriptionPlanContainer>
)
