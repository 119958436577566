/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable max-len */
import { SVGProps } from 'react'

export const AppLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="2332" height="572" viewBox="0 0 2332 572" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_445_12158)">
      <path d="M156.8 317.1C172.3 298.1 189.1 279.599 207.3 261.799C226.4 243.199 246.2 225.299 266.3 207.998L0 189.198V291.5L156.8 317.1Z" fill="url(#paint0_linear_445_12158)" />
      <path d="M266.4 208L181 86.8979L0 189.199L266.4 208Z" fill="url(#paint1_linear_445_12158)" />
      <path d="M29 27.3003C11.1 44.7005 0 69.1008 0 96.0012V189.202L181 86.901L29 27.3003Z" fill="url(#paint2_linear_445_12158)" />
      <path d="M266.404 208.003L335.704 0H314.604L181.004 86.9011L266.404 208.003Z" fill="url(#paint3_linear_445_12158)" />
      <path d="M95.9957 0C69.9956 0 46.2956 10.4001 28.9956 27.3003L180.996 86.9011L314.596 0H95.9957Z" fill="url(#paint4_linear_445_12158)" />
      <path d="M520.498 0H352.998L414.698 94.6012C459.798 63.5008 499.498 38.8005 526.798 22.4003C537.398 16.1002 532.798 0 520.498 0Z" fill="url(#paint5_linear_445_12158)" />
      <path d="M266.401 208.003C316.301 165.202 368.701 126.402 414.701 94.7012L353.101 0H335.301L266.401 208.003Z" fill="url(#paint6_linear_445_12158)" />
      <path d="M181.7 287.5L101.2 483.6L102.7 482.5C141.3 449.1 182.2 420.7 220.1 397.4L181.7 287.5Z" fill="url(#paint7_linear_445_12158)" />
      <path d="M181.7 287.5C68.2 249.4 0 189.2 0 189.2L141.1 386.4L181.7 287.5Z" fill="url(#paint8_linear_445_12158)" />
      <path d="M0 189.2V389.3L141.1 386.4L0 189.2Z" fill="url(#paint9_linear_445_12158)" />
      <path d="M141.1 386.4L0 389.3L101.2 483.6L141.1 386.4Z" fill="url(#paint10_linear_445_12158)" />
      <path d="M21 567.2C45 536.7 72.3 509 101 484L101.2 483.6L0 389.3V559.6C0 570.7 14.1 575.9 21 567.2Z" fill="url(#paint11_linear_445_12158)" />
      <path d="M220.6 397.2C282.6 359.2 336.7 334.8 360 324.4C363.3 322.9 362.2 318.1 358.6 318.1C314.3 317.5 273.1 311.7 235.6 302.9L220.6 397.2Z" fill="url(#paint12_linear_445_12158)" />
      <path d="M235.6 302.8C216.7 298.4 198.7 293.1 181.7 287.4L220.1 397.4C220.3 397.3 220.5 397.2 220.7 397L235.6 302.8Z" fill="url(#paint13_linear_445_12158)" />
      <path d="M181.7 287.5L141.1 386.4L220 397.5L181.7 287.5Z" fill="url(#paint14_linear_445_12158)" />
      <path d="M841.9 189.1V117.1H578.4V470.7H660.3V343H821.4V270.6H660.3V189.1H841.9Z" fill="currentColor" />
      <path d="M991.3 117.1H909.4V470.7H991.3V117.1Z" fill="currentColor" />
      <path d="M1322.3 355.3C1341.5 345.8 1356.9 332.7 1368.1 316.1C1381.4 296.4 1388.2 272.7 1388.2 245.7C1388.2 219.4 1381.9 196.3 1369.5 176.9C1357 157.5 1339 142.5 1315.8 132.2C1293.2 122.1 1266.4 117 1236.2 117H1089V470.6H1170.9V373.4H1236.2C1239.2 373.4 1241.8 373.4 1244.1 373.3L1312 470.6H1403.1L1322.3 355.3ZM1305.7 245.8C1305.7 264.1 1300 277.7 1288.3 287.4C1276.2 297.4 1257.7 302.5 1233.2 302.5H1170.8V189.6H1233.2C1257.7 189.6 1276.3 194.6 1288.3 204.5C1300 214 1305.7 227.5 1305.7 245.8Z" fill="currentColor" />
      <path d="M1546.6 398.8V326.8H1707.7V255.8H1546.6V189.1H1728.1V117.1H1464.6V470.7H1734.8V398.8H1546.6Z" fill="currentColor" />
      <path d="M1914.2 313L2027.9 206.5H1981.3L1829.8 344.8V101.6H1795V466.2H1829.8V389.3L1888.5 335.8L1992.3 466.2H2035.8L1914.2 313Z" fill="currentColor" />
      <path d="M2105.7 206.5H2070.9V466.201H2105.7V206.5Z" fill="currentColor" />
      <path d="M2105.9 114.3C2101 109.7 2095.1 107.3 2088.3 107.3C2081.4 107.3 2075.5 109.8 2070.6 114.6C2065.7 119.5 2063.3 125.4 2063.3 132.3C2063.3 139.2 2065.8 145.1 2070.7 149.9C2075.6 154.5 2081.5 156.9 2088.3 156.9C2095.2 156.9 2101.1 154.4 2106 149.6C2110.9 144.7 2113.3 138.8 2113.3 131.9C2113.3 125 2110.9 119.1 2105.9 114.3Z" fill="currentColor" />
      <path d="M2315.4 419.7L2310.4 424.2C2301.2 432.5 2289.8 436.6 2275.5 436.6C2261.7 436.6 2251.7 433 2245 425.7C2238.2 418.4 2234.8 407.2 2234.8 392.5V237.9H2313.9V206.4H2234.8V152.1H2200V206.4H2154.3V237.9H2200V394.9C2200 417.6 2206.5 435.6 2219.2 448.6C2232 461.5 2250.1 468.1 2273.2 468.1C2283.6 468.1 2293.7 466.5 2303.3 463.3C2313.1 460 2321.4 455.2 2328 449L2331.2 446L2315.4 419.7Z" fill="currentColor" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_445_12158" x1="266" y1="208.498" x2="-79.0009" y2="328.497" gradientUnits="userSpaceOnUse">
        <stop offset="0.120887" stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint1_linear_445_12158" x1="362.5" y1="246.5" x2="32.9988" y2="109.001" gradientUnits="userSpaceOnUse">
        <stop offset="0.27714" stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint2_linear_445_12158" x1="330" y1="63.501" x2="-49.0003" y2="122" gradientUnits="userSpaceOnUse">
        <stop offset="0.247925" stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint3_linear_445_12158" x1="428.005" y1="136.502" x2="149.504" y2="59.5025" gradientUnits="userSpaceOnUse">
        <stop offset="0.261515" stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint4_linear_445_12158" x1="411.996" y1="-20.0003" x2="28.9954" y2="33.4992" gradientUnits="userSpaceOnUse">
        <stop offset="0.324016" stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint5_linear_445_12158" x1="555.998" y1="-19.0002" x2="360.997" y2="65.9992" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint6_linear_445_12158" x1="222.501" y1="104.001" x2="414.681" y2="103.684" gradientUnits="userSpaceOnUse">
        <stop offset="0.324631" stopColor="#3751FE" />
        <stop offset="1" stopColor="#6ABEF6" />
      </linearGradient>
      <linearGradient id="paint7_linear_445_12158" x1="220" y1="338" x2="101" y2="498.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="0.841586" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint8_linear_445_12158" x1="-118.5" y1="423" x2="181.672" y2="287.786" gradientUnits="userSpaceOnUse">
        <stop offset="0.490817" stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint9_linear_445_12158" x1="221.5" y1="474" x2="12.5" y2="295" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint10_linear_445_12158" x1="153" y1="377.5" x2="49" y2="442.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint11_linear_445_12158" x1="-2.71525e-06" y1="625.5" x2="37" y2="368" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint12_linear_445_12158" x1="427.5" y1="384" x2="221" y2="303" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint13_linear_445_12158" x1="289.5" y1="271" x2="174.5" y2="361.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
      <linearGradient id="paint14_linear_445_12158" x1="247.5" y1="441.5" x2="160" y2="332.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF7E06" />
        <stop offset="1" stopColor="#FFC737" />
      </linearGradient>
      <clipPath id="clip0_445_12158">
        <rect width="2331.2" height="571.6" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
