import { Box, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { PropsWithChildren } from 'react'

import { SubTextPrimary2 } from 'Shared/components/Typography'
import { WithHelp } from 'Shared/components/WithHelp'

interface Props {
  label: string
  helpText?: string | React.ReactElement
}

const CardContainer = styled(Stack)(({ theme }) => ({
  height: '100%',
  rowGap: theme.spacing(1),
  justifyContent: 'space-between',
}))

const CardLabel = styled(SubTextPrimary2)(({ theme }) => ({
  color: theme.palette.text.secondary
}))

export const ViewDialogCard: React.FC<PropsWithChildren<Props>> = ({ label, helpText, children }) => (
  <CardContainer>
    <WithHelp helpText={helpText}>
      <CardLabel>{label}:</CardLabel>
    </WithHelp>
    <Box>{children}</Box>
  </CardContainer>
)
