import React from 'react'
import { Grid } from '@mui/material'

import { LegendItem } from './LegendItem'

interface Item {
  label: string
  color: string
  onClick?: () => void
}

interface Props {
  items: Item[]
}

export const Legend: React.FC<Props> = ({ items }) => (
  <Grid container justifyContent={{ xs: 'flex-start', sm: 'center' }} columnSpacing={2} px={2}>
    {items.map(item => (
      <Grid item key={item.label}>
        <LegendItem label={item.label} color={item.color} onClick={item.onClick} />
      </Grid>
    ))}
  </Grid>
)
