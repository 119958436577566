import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import React, { PropsWithChildren } from 'react'

import { styledWithCustomProp } from 'config/theme'

const StyledInfoOutlinedIcon = styledWithCustomProp(HelpOutlineIcon, 'isInputHelp')<{ isInputHelp?: boolean }>(
  ({ theme, isInputHelp }) => ({
    marginLeft: theme.spacing(1),
    marginTop: isInputHelp ? theme.spacing(1.25) : undefined,
    fontSize: theme.typography.textLarger.fontSize,
    color: theme.palette.dark.dark1,
  })
)

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxHeight: 320,
    overflow: 'auto'
  },
}))

interface Props {
  helpText?: string | React.ReactElement
  isInputHelp?: boolean
}

export const WithHelp: React.FC<PropsWithChildren<Props>> = ({ children, helpText, isInputHelp }) => {
  if (!helpText) {
    return <>{children}</>
  }

  return (
    <Stack
      direction="row"
      alignItems={isInputHelp ? 'flex-start' : 'center'}
      justifyContent="space-between"
    >
      {children}
      <StyledTooltip title={helpText} enterTouchDelay={0} leaveTouchDelay={5000} arrow>
        <StyledInfoOutlinedIcon isInputHelp={isInputHelp} />
      </StyledTooltip>
    </Stack>
  )
}
