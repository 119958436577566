import { Summary, Holding } from 'Services/holdings'
import { DrawerState } from 'Shared/types'

export enum OperationType {
  Positive = 'positive',
  Negative = 'negative'
}

export interface HoldingsFilterState {
  selectedTags: string[]
  selectedCategories: string[]
  selectedClasses: string[]
  selectedCountries: string[]
  searchQuery: string
}

export enum HoldingDrawer {
  createHolding = 'createHolding',
  viewHolding = 'viewHolding',
  editHolding = 'editHolding',
  deleteHolding = 'deleteHolding',
  archiveHolding = 'archiveHolding',
  depositWithdraw = 'depositWithdraw',
  fixProfitLoss = 'fixProfitLoss',
  changeTicker = 'changeTicker',
  transfer = 'transfer',
  buySell = 'buySell',
  importHoldings = 'importHoldings',
  connectAccount = 'connectAccount',
  disconnectAccount = 'disconnectAccount'
}

export type TransferDrawerState = { holdingKey: string, fromTicker?: string }
export type BuySellDrawerState = { holdingKey: string, ticker?: string, operationType?: OperationType }
export type ChangeTickerDrawerState = { holdingKey: string, ticker: string }

export interface HoldingsDrawersState {
  [HoldingDrawer.createHolding]: DrawerState<void>
  [HoldingDrawer.viewHolding]: DrawerState<string>
  [HoldingDrawer.editHolding]: DrawerState<string>
  [HoldingDrawer.deleteHolding]: DrawerState<string>
  [HoldingDrawer.archiveHolding]: DrawerState<string>
  [HoldingDrawer.depositWithdraw]: DrawerState<string>
  [HoldingDrawer.fixProfitLoss]: DrawerState<string>
  [HoldingDrawer.changeTicker]: DrawerState<ChangeTickerDrawerState>
  [HoldingDrawer.transfer]: DrawerState<TransferDrawerState>
  [HoldingDrawer.buySell]: DrawerState<BuySellDrawerState>
  [HoldingDrawer.importHoldings]: DrawerState<void>
  [HoldingDrawer.connectAccount]: DrawerState<void>
  [HoldingDrawer.disconnectAccount]: DrawerState<void>
}

export interface HoldingsState {
  isLoading: boolean
  isSummaryLoading: boolean
  isInitialLoadingCompleted: boolean
  holdings: Holding[]
  selectedHoldings: string[]
  summary: Summary | null
  holdingsFilter: HoldingsFilterState
  drawers: HoldingsDrawersState
  globalBackdropEnabled: boolean
}

export interface PinPayload {
  key: string
  pinDate: string
}

export enum TickerType {
  Cash = 'Cash',
  Synced = 'Synced',
  Currency = 'Currency'
}
