import React, { PropsWithChildren } from 'react'
import { Link, styled } from '@mui/material'

const StyledLink = styled(Link)(({ theme }) => ({
  height: 20,
  width: 'fit-content',
  marginRight: theme.spacing(1.5),
  transition: 'color .15s ease-in-out',
  '&:last-of-type': {
    marginRight: 0,
  },
  'svg': {
    display: 'inline-block',
    width: 20,
    height: 20,
  },
  '&:hover': {
    color: theme.palette.primary.main,
  },
}))

interface FooterLinkProps {
  href: string
}

export const IconLink: React.FC<PropsWithChildren<FooterLinkProps>> = ({ href, children }) => (
  <StyledLink
    href={href}
    pb={0}
    target="_blank"
    underline="none"
    rel="noopener"
    color="inherit"
  >
    {children}
  </StyledLink>
)
