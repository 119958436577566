import { createSelector } from 'reselect'

import {
  BuySellItemTransaction,
  ChangeBalanceTransaction,
  FixProfitLossTransaction,
  TRANSACTION_TYPE,
  TransferTransaction
} from 'Services/transactions'
import type { StoreState } from 'Shared/types'

export const transactionsStateSelector = (state: StoreState) => state.transactions

const isLoading = createSelector(transactionsStateSelector, state => state.isLoading)
const getTransactions = createSelector(transactionsStateSelector, state => state.transactions)
const getFromDate = createSelector(transactionsStateSelector, state => state.transactionsFilter.dateFrom)
const getToDate = createSelector(transactionsStateSelector, state => state.transactionsFilter.dateTo)
const getTransactionFilter = createSelector(transactionsStateSelector, state => state.transactionsFilter)
const selectedTransactionKeys = createSelector(transactionsStateSelector, state => state.selectedTransactionKeys)
const hasSelectedTransactions = createSelector(transactionsStateSelector, state => state.selectedTransactionKeys.length > 0)

const changeBalanceTransactions = createSelector(
  transactionsStateSelector,
  (state): ChangeBalanceTransaction[] => state
    .transactions
    .filter(transaction => transaction.transactionType === TRANSACTION_TYPE.DEPOSIT_WITHDRAW)
    .map(transaction => transaction as ChangeBalanceTransaction))

const profitLossTransactions = createSelector(
  transactionsStateSelector,
  (state): FixProfitLossTransaction[] => state
    .transactions
    .filter(transaction => transaction.transactionType === TRANSACTION_TYPE.PROFIT_LOSS)
    .map(transaction => transaction as FixProfitLossTransaction))

const transferTransactions = createSelector(
  transactionsStateSelector,
  (state): TransferTransaction[] => state
    .transactions
    .filter(transaction => transaction.transactionType === TRANSACTION_TYPE.TRANSFER)
    .map(transaction => transaction as TransferTransaction))

const buySellTransactions = createSelector(
  transactionsStateSelector,
  (state): BuySellItemTransaction[] => state
    .transactions
    .filter(transaction => transaction.transactionType === TRANSACTION_TYPE.BUY_SELL_ITEM)
    .map(transaction => transaction as BuySellItemTransaction))

export const TransactionsSelectors = {
  isLoading,
  getTransactions,
  getFromDate,
  getToDate,
  getTransactionFilter,
  selectedTransactionKeys,
  hasSelectedTransactions,
  changeBalanceTransactions,
  profitLossTransactions,
  transferTransactions,
  buySellTransactions,
}
