import { Button } from '@mui/material'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Routes } from 'Shared/constants'

export const DemoButton = withTranslation()(({ t }) => (
  <Button component={Link} to={Routes.DEMO_HOLDINGS}>
    {t('app.demoAccount')}
  </Button>
))
