import React from 'react'
import { Trans } from 'react-i18next'
import { match } from 'ts-pattern'

import { HoldingHistory, HoldingChangeType } from 'Services/history'
import { When } from 'Shared/components/Helpers'

interface Props {
  history: HoldingHistory
}

export const HoldingHistoryMessage: React.FC<Props> = ({ history }) => {
  const translationKey = React.useMemo(
    () => match(history)
      .with({ changeType: HoldingChangeType.Created }, () => 'app.history.holding.created')
      .with({ changeType: HoldingChangeType.Updated }, () => 'app.history.holding.updated')
      .with({ changeType: HoldingChangeType.Archived }, () => 'app.history.holding.archived')
      .with({ changeType: HoldingChangeType.Deleted }, () => 'app.history.holding.deleted')
      .exhaustive(),
    [ history ])

  return (
    <Trans
      i18nKey={translationKey}
      values={{
        asset: history.asset,
        company: history.company
      }}
      components={{
        company: <When condition={!!history.company} />
      }}
    />
  )
}
