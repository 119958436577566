import React from 'react'
import { DefaultViewModeState } from './ViewMode-constants'
import { ViewModeState } from './ViewMode-types'

interface ProviderProps {
  children: React.ReactNode
  value: ViewModeState
}

export const ViewModeContext = React.createContext<ViewModeState>(DefaultViewModeState)

export const ViewModeProvider = ({ children, value }: ProviderProps) =>
  React.createElement(ViewModeContext.Provider, { value: value }, children)
