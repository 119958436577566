export const SupportedCurrencies = [
  'UAH',
  'USD',
  'EUR',
  'GBP',
  'ALL',
  'AMD',
  'AZN',
  'BGN',
  'HRK',
  'CZK',
  'DKK',
  'GEL',
  'HUF',
  'ISK',
  'MDL',
  'MKD',
  'NOK',
  'PLN',
  'RON',
  'RSD',
  'SEK',
  'CHF',
  'TRY',
  'CAD',
  'AUD',
  'NZD',
  'CNY',
  'HKD',
  'TWD',
  'KRW',
  'SGD',
  'MXN',
  'KZT',
  'INR',
  'ZAR',
  'ILS',
  'AED',
  'EGP'
]

