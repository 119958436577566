import { Alert, Button, Dialog, DialogActions, DialogTitle, styled } from '@mui/material'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { TextPrimary } from 'Shared/components/Typography'

interface Props {
  isOpen: boolean
  close: () => void
  onCancel: () => void
  onConfirm: () => void
}

const ReturnButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary
}))

export const ConfirmCloseDrawerModal: React.FC<Props> = ({ isOpen, close, onCancel, onConfirm }) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="confirm-close-dialog-title"
      aria-describedby="confirm-close-dialog-description"
    >
      <DialogTitle>
        {t('common.notifications.confirmClose.title')}
      </DialogTitle>
      <Alert severity="info" sx={{ px: 3 }}>
        <TextPrimary>
          <Trans i18nKey="common.notifications.confirmClose.content" />
        </TextPrimary>
      </Alert>
      <DialogActions sx={{ px: 3 }}>
        <ReturnButton onClick={onCancel}>{t('common.actions.return')}</ReturnButton>
        <Button onClick={onConfirm} autoFocus>{t('common.actions.discard')}</Button>
      </DialogActions>
    </Dialog>
  )
}
