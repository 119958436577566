import type { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { BaseService } from 'Shared/api'
import { IAnalyticsService, GetChartRequest, UpdateChartRequest } from './analytics-types'

export class AnalyticsService extends BaseService implements IAnalyticsService {
  public getCharts = <T>(token: string, abortSignal?: AbortSignal) => {
    const config = {
      headers: { ...super.buildAuthHeader(token) },
      signal: abortSignal,
    }

    return axios
      .get<T>('/charts/my', config)
      .then((response: AxiosResponse) => response?.data)
  }

  public getChart = <T>(request: GetChartRequest, token: string, abortSignal?: AbortSignal) => {
    const config = {
      headers: { ...super.buildAuthHeader(token) },
      signal: abortSignal,
    }
    const queryString = this.buildQueryString(request)

    return axios
      .get<T>(`/charts/my/${request.key}${queryString}`, config)
      .then((response: AxiosResponse) => response?.data)
  }

  public updateChart = (token: string, chartKey: string, request: UpdateChartRequest) => axios
    .patch(`charts/my/${chartKey}`, { payload: request }, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  protected buildQueryString = (request: GetChartRequest) => {
    const queryString = [
      request.period ? `period=${request.period}` : '',
      request.projectionPeriod ? `projectionPeriod=${request.projectionPeriod}` : '',
      request.interval ? `interval=${request.interval}` : '',
      request.tags && request.tags.length > 0 ? `tags=${request.tags.join(',')}` : '',
      request.benchmark ? `benchmark=${request.benchmark}` : '',
    ]
      .filter(x => !!x)
      .join('&')

    if (queryString) {
      return '?' + queryString
    }

    return ''
  }
}
