import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Routes } from 'Shared/constants'

export const LoginButtonLanding: React.FC = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const { t } = useTranslation()

  if (isAuthenticated) {
    return (
      <Button
        component={Link}
        to={Routes.HOLDINGS}
        variant="contained"
        data-testid="backToAppButton"
      >
        {t('app.navbar.landing.backToApp')}
      </Button>
    )
  }

  return (
    <Button onClick={loginWithRedirect} data-testid="loginButton" variant="contained">
      {t('app.navbar.landing.login')}
    </Button>
  )
}
