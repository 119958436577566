import React from 'react'

import { TextPrimary } from 'Shared/components/Typography'
import { CenteredOverlay } from './CenteredOverlay'

interface Props {
  text: string
}

export const CustomOverlay: React.FC<Props> = ({ text }) => (
  <CenteredOverlay elevation={0}>
    <TextPrimary fontSize="inherit">{text}</TextPrimary>
  </CenteredOverlay>
)
