import React, { useContext } from 'react'

import { Promo, IProfileService } from 'Services/profile'
import { container, Service } from 'Services/container'
import { Nullable } from 'Shared/types'
import { SettingsContext } from 'App/Settings'
import { useAuthWithDemo } from 'Demo'
import { useSnackbar } from 'Snackbar'

export const usePromo = () => {
  const { getAccessTokenWithDemo } = useAuthWithDemo()
  const { enqueueWarning, enqueueSuccess } = useSnackbar()
  const { promoCode, manageSettings } = useContext(SettingsContext)

  const [ promo, setPromo ] = React.useState<Nullable<Promo>>(null)

  const clearPromo = () => {
    manageSettings.promo.clear()
    setPromo(null)
  }

  const fetchPromoCodeInformation = async (code: string) => {
    const profileService = container.resolve<IProfileService>(Service.ProfileService)
    const token = await getAccessTokenWithDemo()
    return await profileService.getPromoCode(token, code)
  }

  const loadPromoCode = async () => {
    if (promoCode) {
      const promoCodeInformation = await fetchPromoCodeInformation(promoCode)

      if (promoCodeInformation) {
        setPromo(promoCodeInformation)
      } else {
        clearPromo()
      }
    } else {
      clearPromo()
    }
  }

  const applyPromoCode = async (code: Nullable<string>) => {
    if (code) {
      const promoCodeInformation = await fetchPromoCodeInformation(code)

      if (promoCodeInformation) {
        setPromo(promoCodeInformation)
        manageSettings.promo.save(code)
        enqueueSuccess('pricing.promoCode.activate-success')
      } else {
        clearPromo()
        enqueueWarning('pricing.promoCode.activate-failure')
      }
    }
  }

  React.useEffect(() => { loadPromoCode() }, [ promoCode ])

  return {
    promo,
    applyPromoCode,
    clearPromo,
  }
}
