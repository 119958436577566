import React from 'react'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { RenewalPeriod } from 'Services/profile'
import { TextLarger } from 'Shared/components'
import { TypedDictionary } from 'Shared/types'
import { YearDiscountTextKey } from 'Pricing/pricing-constants'
import { PricingPlan } from 'Pricing/pricing-types'
import { PricingPeriodSelector } from './PricingPeriod'

interface Props {
  renewalPeriod: RenewalPeriod
  setRenewalPeriod: (value: RenewalPeriod) => void
  discountedPrices: TypedDictionary<typeof RenewalPeriod, PricingPlan>
}

export const PricingPageHeader: React.FC<Props> = ({ renewalPeriod, setRenewalPeriod, discountedPrices }) => {
  const { t } = useTranslation()

  const yearDiscountTextKey = React.useMemo(
    () => discountedPrices[RenewalPeriod.Year].periodPromoActive ? 'pricing.promoCode.activated-short' : YearDiscountTextKey,
    [ discountedPrices ])

  const monthDiscountTextKey = React.useMemo(
    () => discountedPrices[RenewalPeriod.Month].periodPromoActive ? 'pricing.promoCode.activated-short' : undefined,
    [ discountedPrices ])

  return (
    <Stack
      direction="row"
      justifyContent={{ xs: 'center', sm: 'space-between' }}
      alignItems="center"
      flexWrap="wrap"
      gap={1}
      spacing={1}
    >
      <TextLarger fontWeight={700}>{t('pricing.header')}</TextLarger>

      <PricingPeriodSelector
        renewalPeriod={renewalPeriod}
        onRenewalPeriodChanged={value => setRenewalPeriod(value)}
        yearDiscountTextKey={yearDiscountTextKey}
        monthDiscountTextKey={monthDiscountTextKey}
      />
    </Stack>
  )
}
