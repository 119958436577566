import { HoldingLiquidityWeight, HoldingRiskWeight, HoldingTermWeight, HoldingClassWeight } from 'Shared/constants'
import { SortComparer, SortingProvider } from './providers-types'

const baseComparer = (weights: { [key: string]: number }) =>
  (x: string, y: string) => {
    if (x && y && weights[x] && weights[y]) {
      if (weights[y] > weights[x]) return 1
      if (weights[y] < weights[x]) return -1
    }

    return 0
  }

export const sortComparerProvider = new Map<string, SortComparer>([
  [ SortingProvider.Class, baseComparer(HoldingClassWeight) ],
  [ SortingProvider.Risk, baseComparer(HoldingRiskWeight) ],
  [ SortingProvider.Liquidity, baseComparer(HoldingLiquidityWeight) ],
  [ SortingProvider.Term, baseComparer(HoldingTermWeight) ],
])

export const defaultStringComparer = (x: string, y: string) => x.localeCompare(y)

export const defaultNumberComparer = (x: number, y: number) => y - x

