import React, { PropsWithChildren } from 'react'
import { Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

interface InternalLinkProps {
  to: string
}

export const InternalLink: React.FC<PropsWithChildren<InternalLinkProps>> = ({ children, to }) => (
  <Link
    to={to}
    underline="hover"
    component={RouterLink}
    fontSize="inherit"
    lineHeight="inherit"
    paddingBottom="inherit"
  >
    {children}
  </Link>
)
