import { Stack, Box, Typography, ListItem } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'

import { styledWithCustomProp } from 'config/theme'
import { fontRubik } from 'Shared/components/Typography'

export const TierCard = styledWithCustomProp(Stack, 'highlight')<{ highlight: boolean }>(({ theme, highlight }) => ({
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  height: '100%',
  border: `1px solid ${theme.palette.blue.blue1}`,
  borderRadius: 12,
  ['button']: {
    backgroundColor: theme.palette.common.white,
  },

  ...(!highlight && {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  }),

  ...(highlight && {
    backgroundColor: theme.palette.blue.blue,
    color: theme.palette.common.white,
    ['button:hover']: {
      backgroundColor: theme.palette.blue.blue6,
      borderColor: theme.palette.common.white,
    },
  }),

  [theme.breakpoints.down('mobileLarge')]: {
    padding: theme.spacing(3),
  },
}))

export const PricingPlanText = styled(Typography)(({ theme }) => ({
  fontFamily: fontRubik,
  fontSize: theme.typography.textBig.fontSize,
  fontWeight: 700,
  lineHeight: 'normal',
  textAlign: 'center',
  textTransform: 'uppercase',
})) as typeof Typography

export const FeatureListIcon = styled(Box)(({ theme }) => ({
  minHeight: 24,
  minWidth: 24,
  textAlign: 'center',
  backgroundColor: alpha(theme.palette.dark.dark2!, 0.3),
  borderRadius: '25%'
}))

export const FeatureListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(0.5, 0),
  gap: theme.spacing(1)
}))
