import React from 'react'
import { Stack, Tooltip } from '@mui/material'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import { match } from 'ts-pattern'
import { useTranslation } from 'react-i18next'

import { ProfileAvatar, TextSmaller2, TextLarge } from 'Shared/components'
import { Nullable } from 'Shared/types'
import { S } from 'AdvisorPortal/shared'

interface Props {
  username: string
  preferredName?: Nullable<string>
  size?: 'small' | 'medium' | 'large'
  onClick?: () => void
  showError?: boolean
  showWarning?: boolean
  allowEdit?: boolean
}

export const ProfileHeader: React.FC<Props> = ({
  username,
  preferredName = null,
  size = 'medium',
  onClick,
  showError,
  showWarning,
  allowEdit = false,
}) => {
  const { t } = useTranslation()

  const avatarSize = match(size)
    .with('small', () => 24)
    .with('medium', () => 32)
    .with('large', () => 48)
    .exhaustive()

  const color = match({ showError, showWarning })
    .with({ showError: true }, () => 'error')
    .with({ showWarning: true }, () => 'warning')
    .otherwise(() => 'primary')

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      overflow="hidden"
      onClick={onClick}
      sx={{
        ...(onClick && { cursor: 'pointer' }),
      }}
    >
      <S.StyledNotificationBadge
        overlap="circular"
        color={color}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
        invisible={!showError && !showWarning}
      >
        <ProfileAvatar
          username={username}
          preferredName={preferredName}
          sx={{ width: avatarSize, height: avatarSize }}
        />
      </S.StyledNotificationBadge>

      <Stack direction="column" justifyContent="center" overflow="hidden">
        {!!preferredName && <TextLarge fontWeight={700} noWrap title={preferredName}>{preferredName}</TextLarge>}
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <TextSmaller2 color="text.secondary" noWrap title={`@${username}`}>@{username}</TextSmaller2>
          {allowEdit && (
            <Tooltip title={t('advisorPortal.client.canEditPortfolio')}>
              <AppRegistrationIcon sx={{ fontSize: 13 }} />
            </Tooltip>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
