import { useRouteMatch } from 'react-router-dom'
import { RouteNames } from 'Shared/constants'
import React from 'react'

export const useCurrentRoute = () => {
  const routeMatch = useRouteMatch({ path: Object.keys(RouteNames), exact: false })

  const currentRoute = React.useMemo(
    () => routeMatch?.path ? RouteNames[routeMatch?.path] : null,
    [ routeMatch ])

  return {
    currentRoute
  }
}
