import React from 'react'
import { Grid, Stack } from '@mui/material'

import { Filter } from './Filter'
import { Sorting } from './Sorting'
import { ControlPanelGroupIcon } from './ControlPanelGroupIcon'
import { ControlPanelGroupLabel } from './ControlPanelGroupLabel'

interface ControlPanelGroupProps {
  label?: string
  filters?: {
    id: string
    label: string
    options: { label: string, value: string, blockAccess?: boolean }[]
    selectedValues: string[]
    onSelectedValuesChanged: (values: string[]) => void
  }[]
  sorting?: {
    label: string,
    options: { label: string, value: string }[]
    selectedValue: string
    onSelectedValueChanged: (value: string) => void
  }
  PremiumPromoContent?: JSX.Element
}

export const ControlPanelGroup: React.FC<ControlPanelGroupProps> = ({ label, filters, sorting, PremiumPromoContent }) => (
  <Grid container alignItems="center" spacing={1}>
    {!!label && (
      <Grid item>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <ControlPanelGroupIcon />
          <ControlPanelGroupLabel>{label}:</ControlPanelGroupLabel>
        </Stack>
      </Grid>
    )}

    {filters && filters.map(filter => (
      <Grid item key={filter.id}>
        <Filter
          id={filter.id}
          label={filter.label}
          options={filter.options}
          selectedValues={filter.selectedValues}
          onChange={filter.onSelectedValuesChanged}
          PremiumPromoContent={PremiumPromoContent}
        />
      </Grid>
    ))}

    {sorting && (
      <Grid item>
        <Sorting
          label={sorting.label}
          options={sorting.options}
          selectedValue={sorting.selectedValue}
          onChange={sorting.onSelectedValueChanged}
        />
      </Grid>
    )}
  </Grid>
)
