import React from 'react'
import { Trans } from 'react-i18next'
import { match } from 'ts-pattern'

import { AdvisoryTaskStatusHistory } from 'Services/history/history-types'

interface Props {
  history: AdvisoryTaskStatusHistory
}

export const AdvisoryTaskStatusHistoryMessage: React.FC<Props> = ({ history }) => {
  const translationKey = React.useMemo(
    () => match(history)
      .with({ completed: true }, () => 'app.history.task.completed')
      .with({ completed: false }, () => 'app.history.task.uncompleted')
      .run(),
    [ history ])

  return <Trans i18nKey={translationKey} values={{ task: history.taskTitle }} />
}
