import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const ControlPanelGroupLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.textPrimary.fontSize,
  width: 'auto',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 700
}))
