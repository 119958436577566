import { Theme, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ComeBackAliveLogoEN } from 'App/NavigationBar/assets/ComeBackAliveLogoEN'
import { ComeBackAliveLogoUK } from 'App/NavigationBar/assets/ComeBackAliveLogoUK'
import { ExternalLink } from 'Shared/components'
import { ExternalLinks } from 'Shared/constants'
import { SupportedLanguage } from 'config/i18n-types'

const applyCbaLogoStyles = (theme: Theme) => ({
  width: 'auto',
  height: 36,
  color: theme.palette.common.black
})

const StyledCBALogoUK = styled(ComeBackAliveLogoUK)(({ theme }) =>
  applyCbaLogoStyles(theme)
)
const StyledCBALogoEN = styled(ComeBackAliveLogoEN)(({ theme }) =>
  applyCbaLogoStyles(theme)
)

const LinkUK = () => (
  <ExternalLink href={ExternalLinks.COME_BACK_ALIVE_UK} sx={{ display: 'flex' }}>
    <StyledCBALogoUK />
  </ExternalLink>
)

const LinkEN = () => (
  <ExternalLink href={ExternalLinks.COME_BACK_ALIVE_EN} sx={{ display: 'flex' }}>
    <StyledCBALogoEN />
  </ExternalLink>
)

export const ComeBackAliveLinkLanding = () => {
  const { i18n } = useTranslation()

  return i18n.resolvedLanguage === SupportedLanguage.UK ? <LinkUK /> : <LinkEN />
}
