import { Stack } from '@mui/material'
import React from 'react'

import { useAuthorization, Permission } from 'Integration/authorization'
import { PremiumUserBadge } from 'Shared/components'
import { useCurrentRoute } from 'Shared/hooks'
import { ToggleHiddenMode, UserMenu } from 'App/NavigationBar/components'
import { UserGuideButton } from 'App/UserGuide/UserGuideButton'
import { TasksButton } from 'App/Tasks'
import { NotificationButton } from 'App/Notifications/NotificationButton'
import { SubscribeButton } from 'App/Premium/SubscribeButton'
import { CurrentRoute } from './CurrentRoute'

export const AppBarDesktopPanel: React.FC = () => {
  const authorization = useAuthorization()
  const { currentRoute } = useCurrentRoute()

  return (
    <Stack
      direction="row"
      justifyContent={currentRoute ? 'space-between' : 'end'}
      alignItems="center"
      width="100%"
    >
      {currentRoute && <CurrentRoute routeKey={currentRoute} />}

      <Stack flexDirection="row" alignItems="center" columnGap={1}>
        <ToggleHiddenMode />
        <UserGuideButton />
        {authorization.hasAccess(Permission.ViewMyTasks) && <TasksButton />}
        <NotificationButton />
        {authorization.hasAccess(Permission.ViewPremiumBadge) && <PremiumUserBadge />}
        {authorization.hasAccess(Permission.ViewSubscribeButton) && <SubscribeButton />}
        <UserMenu />
      </Stack>
    </Stack>
  )
}
