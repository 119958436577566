import { useDispatch } from 'react-redux'

import { snackbarActionCreators } from 'Snackbar'

export const useSnackbar = () => {
  const dispatch = useDispatch()

  const enqueueSuccess = (message: string, param: any = null) => {
    dispatch(snackbarActionCreators.enqueueSuccessSnackbar(message, param))
  }

  const enqueueError = (message: string, param: any = null) => {
    dispatch(snackbarActionCreators.enqueueErrorSnackbar(message, param))
  }

  const enqueueWarning = (message: string, param: any = null) => {
    dispatch(snackbarActionCreators.enqueueWarningSnackbar(message, param))
  }

  return {
    enqueueSuccess,
    enqueueError,
    enqueueWarning
  }
}
