import { RenewalPeriod } from 'Services/profile'

export enum PricingType {
  Free = 'Free',
  Premium = 'Premium',
}

export type PricingPlan = {
  renewalPeriod: RenewalPeriod
  discountedPrice: number
  originalPrice: number
  periodPromoActive: boolean
  firstPeriodOnly: boolean
  autoRenew: boolean
}
