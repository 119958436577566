import {
  DemoAnalyticsService,
  DemoHoldingsService,
  DemoPortfolioService,
  DemoProfileService,
  DemoTransactionsService
} from 'Demo'

import { ProfileService } from './profile'
import { AdvisoryService } from './advisor'
import { HistoryService } from './history'
import { OrganizationService } from './organization'
import { SharingService } from './sharing'
import { SurveysService } from './survey'
import { PredictionService } from './prediction'
import { PortfolioService, PortfolioSharedService } from './portfolio'
import { AnalyticsService, AnalyticsSharedService } from './analytics'
import { TransactionsService, TransactionsSharedService } from './transactions'
import { HoldingsService, HoldingsSharedService } from './holdings'
import { SessionStorageService } from './sessionStorage'
import { PlaidService } from './plaid'
import { ExchangeService } from './exchange'

export const Service = {
  HoldingsService: 'HoldingsService',
  TransactionsService: 'TransactionsService',
  AnalyticsService: 'AnalyticsService',
  PredictionService: 'PredictionService',
  ProfileService: 'ProfileService',
  PortfolioService: 'PortfolioService',
  SurveyService: 'SurveyService',
  SharingService: 'SharingService',
  HistoryService: 'HistoryService',
  AdvisoryService: 'AdvisoryService',
  OrganizationService: 'OrganizationService',
  PlaidService: 'PlaidService',
  ExchangeService: 'ExchangeService',

  SessionStorageService: 'SessionStorageService',
}

class Container {
  dependencies: any = {}

  public configureBaseDependencies() {
    this.dependencies[Service.HoldingsService] = () => new HoldingsService()
    this.dependencies[Service.TransactionsService] = () => new TransactionsService()
    this.dependencies[Service.AnalyticsService] = () => new AnalyticsService()
    this.dependencies[Service.PredictionService] = () => new PredictionService()
    this.dependencies[Service.PortfolioService] = () => new PortfolioService()
    this.dependencies[Service.ProfileService] = () => new ProfileService()
    this.dependencies[Service.SurveyService] = () => new SurveysService()
    this.dependencies[Service.HistoryService] = () => new HistoryService()
    this.dependencies[Service.SharingService] = () => new SharingService()
    this.dependencies[Service.AdvisoryService] = () => new AdvisoryService()
    this.dependencies[Service.OrganizationService] = () => new OrganizationService()
    this.dependencies[Service.PlaidService] = () => new PlaidService()
    this.dependencies[Service.ExchangeService] = () => new ExchangeService()

    this.dependencies[Service.SessionStorageService] = () => new SessionStorageService()
  }

  public configureDemoDependencies() {
    this.configureBaseDependencies()

    this.dependencies[Service.HoldingsService] = () => new DemoHoldingsService()
    this.dependencies[Service.TransactionsService] = () => new DemoTransactionsService()
    this.dependencies[Service.AnalyticsService] = () => new DemoAnalyticsService()
    this.dependencies[Service.PortfolioService] = () => new DemoPortfolioService()
    this.dependencies[Service.ProfileService] = () => new DemoProfileService()
  }

  public configureSharingDependencies(sharingKey: string) {
    this.configureBaseDependencies()

    this.dependencies[Service.HoldingsService] = () => new HoldingsSharedService(sharingKey)
    this.dependencies[Service.TransactionsService] = () => new TransactionsSharedService(sharingKey)
    this.dependencies[Service.AnalyticsService] = () => new AnalyticsSharedService(sharingKey)
    this.dependencies[Service.PortfolioService] = () => new PortfolioSharedService(sharingKey)
  }

  public resolve<T>(name: string) {
    return this.dependencies[name]() as T
  }
}

export const container = new Container()

