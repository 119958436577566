import { withTranslation } from 'react-i18next'

import { UnderMaintenanceImg } from 'App/assets/UnderMaintenanceImg'
import { StaticPage } from './StaticPage'
import { StaticPageTitle, StaticPageSubTitle } from './Typographies'

export const UnderMaintenance = withTranslation()(({ t }) => (
  <StaticPage
    title={t('app.navbar.routes.maintenance')}
    messageComponent={(
      <>
        <StaticPageTitle mb={2.5}>
          {t('app.static.maintenance.title')}
        </StaticPageTitle>
        <StaticPageSubTitle>
          {t('app.static.maintenance.subTitle')}
        </StaticPageSubTitle>
      </>
    )}
    imgComponent={<UnderMaintenanceImg width="100%" height="100%" />}
  />
))
