import React from 'react'
import { useField } from 'formik'
import TextFieldMUI from '@mui/material/TextField'

import { localizeError } from 'Shared/utils'

interface TextAreaProps {
  name: string
  label?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
}

export const TextArea: React.FC<TextAreaProps> = (props: TextAreaProps) => {
  const {
    name,
    label,
    placeholder,
    required,
    disabled,
  } = props
  const [ field, meta ] = useField(props)
  const error = meta.touched && meta.error

  return (
    <TextFieldMUI
      id={name}
      data-testid={name}
      label={label}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      error={!!error}
      helperText={localizeError(error)}
      multiline
      fullWidth
      minRows={3}
      maxRows={10}
      variant="outlined"
      {...field}
    />
  )
}
