import { ChartPreRender } from 'Services/analytics'

export enum ChartType {
  CAPITAL_GROWTH = 'CapitalGrowth',
  PROPERTY_BALANCE = 'PropertyBalance',
  OPERATIONS = 'Operations',
  TREE_MAP = 'TreeMap',
  PROPERTY_PERIOD_ALLOCATION = 'PropertyPeriodAllocation',
  PROPERTY_COMPARING_TO_ALLOCATION = 'PropertyComparingToAllocation',
  MARKET_UNIT_HEAT_MAP = 'MarketUnitHeatMap',
  DIVIDENDS = 'Dividends',
  DIVIDENDS_PROJECTION = 'DividendsProjection',
  TAGS_STRUCTURE = 'TagsStructure',
  SCORECARD = 'Scorecard',
  ACTUAL_SUNBURST = 'ActualSunburst',
  EXPECTED_SUNBURST = 'ExpectedSunburst',
}

export interface ChartDetails {
  name: string
  type: ChartType
  metadata: any
  errors: any
  data: any
}

export interface Chart {
  key: string
  name: string
  type: ChartType
  metadata: any
  errors: any
  data: any
  isLoading: boolean
}

export type GetChartPayload = {
  chart: ChartDetails
  key: string
}

export interface ChartFilterState {
  selectedTags: string[]
}

export interface AnalyticsState {
  isLoading: boolean
  initialLoadCompleted: boolean
  chartPreRenders: ChartPreRender[]
  chartRenders: { [key: string]: Chart }
  chartFilter: ChartFilterState
  tags: string[]
}

export interface ChartProps<T> {
  data: T,
  metadata: any,
  isHiddenMode: boolean
  currency: string
}

export enum ChartState {
  LoadingFirstTime = 'LoadingFirstTime',
  Loading = 'Loading',
  NoData = 'NoData',
  Success = 'Success',
}

export type ChartElementsPerDeviceType = {
  mobile: number
  tablet: number
  desktop: number
}

export type ChartChangedState = {
  key: string
  favorite: boolean
}

export enum ChartTimePeriod {
  ThreeMonths = 3,
  OneYear = 12,
  TwoYears = 24,
  FiveYears = 60,
  TenYears = 120,
  ThirtyYears = 360,
}

export enum ChartProjectionPeriod {
  FiveYears = 5,
  TenYears = 10,
  FifteenYears = 15,
  ThirtyYears = 30,
}

export enum AnalyticsSessionParameters {
  Period = 'period',
  ProjectionPeriod = 'projectionPeriod',
  Interval = 'interval',
  Operations = 'operations',
  Benchmark = 'benchmark',
}
