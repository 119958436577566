import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Stack } from '@mui/material'
import { Formik, Form, FormikHelpers } from 'formik'

import { TextField, TextLarge, SubmitButton } from 'Shared/components'

interface Props {
  onPromoCodeApplied: (promoCode: string) => void
}

type PromoCodeForm = { promoCode: string }

export const UsePromoCodeForm: React.FC<Props> = ({ onPromoCodeApplied }) => {
  const { t } = useTranslation()

  const initialValues = { promoCode: '' }

  const handleSubmit = (values: PromoCodeForm, helpers: FormikHelpers<PromoCodeForm>) => {
    if (values.promoCode) {
      onPromoCodeApplied(values.promoCode)
      helpers.resetForm()
    }
  }

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
      gap={1}
      spacing={1}
    >
      <TextLarge fontWeight={700} mx={1}>
        {t('pricing.promoCode.inactiveMessage')}
      </TextLarge>

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {formProps => (
          <Form autoComplete="off" name="promo-code" id="promo-code-form">
            <Stack direction="row" alignItems="center" spacing={1}>
              <TextField name="promoCode" placeholder={t('pricing.promoCode.placeholder')} />
              <SubmitButton formProps={formProps} label={t('common.actions.apply')} />
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  )
}
