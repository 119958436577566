import React from 'react'
import { useField } from 'formik'

import { AutocompleteOption, CustomAutocomplete } from 'Shared/components/NonForm'
import { Nullable } from 'Shared/types'

interface Props {
  name: string
  label: string
  options: AutocompleteOption[]
  placeholder?: string
  required?: boolean
  disabled?: boolean
  extraProps?: any
}

export const FormikCreatableAutocomplete: React.FC<Props> = props => {
  const [ field, meta, helpers ] = useField<Nullable<string>>(props.name)

  const setTouched = () => {
    helpers.setTouched(true)
  }

  const handleOnInputChange = (_: any, newInputValue: Nullable<string>) => {
    helpers.setValue(newInputValue)
  }

  const handleOnChange = (value: Nullable<string>) => {
    helpers.setValue(value)
  }

  return (
    <CustomAutocomplete
      {...props}
      {...field}
      error={meta.touched && meta.error}
      onBlur={setTouched}
      onChange={handleOnChange}
      extraProps={{
        freeSolo: true,
        onInputChange: handleOnInputChange
      }}
    />
  )
}
