import { add } from 'date-fns'

import { Transaction } from 'Services/transactions'
import type { ITypedAction } from 'Shared/types'
import { TransactionsActionTypes } from 'Transactions/transactions-constants'
import type { GetTransactionsPayload, TransactionsState } from 'Transactions/transactions-types'

const today = new Date()
const monthAgo = add(today, { months: -1 })

export const initialState: TransactionsState = {
  isLoading: true,
  transactions: [],
  transactionsFilter: {
    dateFrom: monthAgo,
    dateTo: today,
    selectedHoldings: [],
    selectedOperations: [],
    searchQuery: '',
  },
  selectedTransactionKeys: []
}

export const transactions = (
  state: TransactionsState = initialState,
  action: ITypedAction<any>,
): TransactionsState => {
  switch(action.type) {

    case TransactionsActionTypes.GET_TRANSACTIONS: {
      const { from, to } = action.payload as GetTransactionsPayload
      return {
        ...state,
        isLoading: true,
        transactionsFilter: {
          ...state.transactionsFilter,
          dateFrom: from,
          dateTo: to
        }
      }
    }

    case TransactionsActionTypes.GET_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        transactions: action.payload as Transaction[],
        isLoading: false,
      }
    }

    case TransactionsActionTypes.GET_TRANSACTIONS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      }
    }

    case TransactionsActionTypes.SET_TRANSACTIONS_FILTER_CHANGED: {
      return {
        ...state,
        transactionsFilter: action.payload
      }
    }

    case TransactionsActionTypes.SET_SELECTED_TRANSACTIONS: {
      return {
        ...state,
        selectedTransactionKeys: action.payload
      }
    }

    case TransactionsActionTypes.RESET_STATE_TRANSACTIONS: {
      return initialState
    }

    default:
      return state
  }
}
