import { Transaction } from 'Services/transactions'
import type { IAction, ITypedAction, ITypedActionWithToken } from 'Shared/types'
import type { GetTransactionsPayload, TransactionsFilterState } from 'Transactions/transactions-types'
import { TransactionsActionTypes } from './transactions-constants'

const getTransactions = (token: string, from: Date, to: Date): ITypedActionWithToken<GetTransactionsPayload> => ({
  type: TransactionsActionTypes.GET_TRANSACTIONS,
  payload: { from, to },
  token
})

const getTransactionsSuccess = (payload: Transaction[]): ITypedAction<Transaction[]> => ({
  type: TransactionsActionTypes.GET_TRANSACTIONS_SUCCESS,
  payload
})

const getTransactionsFailure = (): IAction => ({
  type: TransactionsActionTypes.GET_TRANSACTIONS_FAILURE,
})

const deleteTransaction = (token: string, transactionKey: string): ITypedActionWithToken<string> => ({
  type: TransactionsActionTypes.DELETE_TRANSACTION,
  payload: transactionKey,
  token
})

const deleteTransactionSuccess = (): IAction => ({
  type: TransactionsActionTypes.DELETE_TRANSACTION_SUCCESS
})

const deleteTransactionFailure = (): IAction => ({
  type: TransactionsActionTypes.DELETE_TRANSACTION_FAILURE
})

const changeFilter = (filterState: TransactionsFilterState): ITypedAction<TransactionsFilterState> => ({
  type: TransactionsActionTypes.SET_TRANSACTIONS_FILTER_CHANGED,
  payload: filterState
})

const setSelectedTransactions = (transactionKeys: string[]): ITypedAction<string[]> => ({
  type: TransactionsActionTypes.SET_SELECTED_TRANSACTIONS,
  payload: transactionKeys
})

const resetState = (): IAction => ({
  type: TransactionsActionTypes.RESET_STATE_TRANSACTIONS
})

export const TransactionsActions = {
  getTransactions,
  getTransactionsSuccess,
  getTransactionsFailure,
  deleteTransaction,
  deleteTransactionSuccess,
  deleteTransactionFailure,
  changeFilter,
  setSelectedTransactions,
  resetState,
}
