import { HistoryItem } from 'Services/history'
import { SearchTerm, Dictionary } from 'Shared/types'

const sharingTerms: SearchTerm<HistoryItem>[] = [
  i => i.actorUsername ?? '',
  i => i.actorPreferredName ?? '',
  i => i.portfolioUsername ?? '',
  i => i.portfolioUserPreferredName ?? '',
]

export const queryFilter = (query: string, client: HistoryItem) => {
  if (!query) {
    return true
  }

  return sharingTerms
    .some(term => term(client)
      .toSearchTerm()
      .indexOf(query) !== -1)
}

export const managedByFilter = (managedBy: string, advisorClientMap: Dictionary<string>, historyItem: HistoryItem) =>
  !managedBy || advisorClientMap[historyItem.actorUsername] === managedBy
