import React from 'react'
import { useFormikContext, useField } from 'formik'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

import { localizeError } from 'Shared/utils'

interface Props {
  name: string
  label: string
  placeholder: string
  prefixSymbol?: string
  disabled?: boolean
  required?: boolean
  readOnly?: boolean
  inputComponent?: any
  inputProps?: any
  customOnChange?: Function
  onFocus?: (e: React.FocusEvent) => void
}

// TODO: potentially remove it
export const BaseNumberTextField: React.FC<Props> = (props: Props) => {
  const {
    prefixSymbol,
    label,
    disabled,
    placeholder,
    required,
    readOnly,
    inputComponent,
    inputProps,
    customOnChange,
    onFocus,
    name,
  } = props
  const [ field, meta ] = useField(props)
  const { isSubmitting } = useFormikContext()
  const error = meta.touched && meta.error
  const handleOnChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      field.onChange(e)
      if (customOnChange) {
        customOnChange(e?.target?.value)
      }
    }, [ customOnChange ]
  )

  return (
    <TextField
      label={label}
      placeholder={placeholder}
      error={!!error}
      helperText={localizeError(error)}
      variant="outlined"
      size="small"
      margin="dense"
      disabled={isSubmitting || disabled}
      required={required}
      fullWidth
      InputProps={{
        ...(prefixSymbol && { startAdornment: <InputAdornment position="start" disablePointerEvents>{prefixSymbol}</InputAdornment> }),
        inputComponent: inputComponent || null,
        inputProps: {
          ...inputProps,
          'data-testid': name
        },
        readOnly,
      }}
      InputLabelProps={{ shrink: true }}
      sx={{ my: 0 }}
      {...field}
      onChange={handleOnChange}
      onFocus={onFocus}
    />
  )
}
