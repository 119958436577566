import React from 'react'
import { DateTimePicker as DateTimePickerMUI, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ukUA, enUS } from '@mui/x-date-pickers/locales'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import { SupportedLanguage, languageService } from 'config/i18n-types'
import { muiMinDate } from 'Shared/constants'
import { localizeError } from 'Shared/utils'

interface Props {
  name: string
  label: string
  required?: boolean
  disabled?: boolean
  disableFuture?: boolean
  disablePast?: boolean
}

export const DateTimePicker: React.FC<Props> = (props: Props) => {
  const { i18n } = useTranslation()
  const { name, label, required, disabled, disableFuture, disablePast } = props
  const [ field, meta, helpers ] = useField(props)
  const error = meta.touched && meta.error

  const [ inputDate, setInputDate ] = React.useState<Date | null>(field.value)

  const handleChange = (date: Date | null) => {
    setInputDate(date)
    helpers.setValue(date)
  }

  const locale = React.useMemo(() => {
    const language = i18n.resolvedLanguage as SupportedLanguage
    return languageService.getLocale(language)
  }, [ i18n.resolvedLanguage ])

  const localeText = React.useMemo(
    () => i18n.resolvedLanguage === SupportedLanguage.UK ?
      ukUA.components.MuiLocalizationProvider.defaultProps.localeText :
      enUS.components.MuiLocalizationProvider.defaultProps.localeText,
    [ i18n.resolvedLanguage ])

  // TODO: Move LocalizationProvider to top level component
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={locale}
      localeText={localeText}
    >
      <DateTimePickerMUI
        label={label}
        value={inputDate}
        minDate={muiMinDate}
        disabled={disabled}
        onChange={newValue => handleChange(newValue)}
        disableFuture={disableFuture}
        disablePast={disablePast}
        slotProps={{
          textField: {
            variant: 'outlined',
            size: 'small',
            fullWidth: true,
            required: required,
            onBlur: () => helpers.setTouched(true),
            error: !!error,
            helperText: localizeError(error),
            inputProps: { 'data-testid': name }
          },
        }}
      />
    </LocalizationProvider>
  )
}
